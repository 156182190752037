<div class="container-fluid">
  <div class="row">
    <app-history-view [title]="'Results'" [data]="noteData" [commentQty]="data.comments?.length" [alias]="config.alias"
                      (reviewAction)="handleReviewAction($event)"></app-history-view>
  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="row my-2">
      <textarea class="form-control" [formControl]="note" jargonLoadingState="builder-control"
                placeholder="Start typing or drag a builder here..."></textarea>
    </div>
  </ng-container>
</div>