import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { validateAllFormFields } from 'src/app/shared/validators';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';

type MedicationData = {
  _modified: number;
  date_time: Date | number;
  medication: string;
  site: string;
  repeat_status: string;
  next_date_time: Date | number | null;
}

interface MedicationFormData extends BuilderDataAbstract {
  records: Array<MedicationData>;
}

@Component({
  selector: 'app-injection-medications',
  templateUrl: './injection-medications.component.html',
  styleUrls: ['./injection-medications.component.scss']
})
export class InjectionMedicationsComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;
  public injectionList: string[] = [
    'Buscopan',
    'Ceftriaxone',
    'Celestone',
    'Diclofenac',
    'Ferinject',
    'Ferrimed',
    'Maxolon',
    'MgsO4',
    'Perfalgan',
    'Phenergan',
    'Solu Cortef',
    'Venofer',
    'Xefo',
    'Zofran 4mg / 2ml',
    'Zofran 8mg / 4ml',
  ].sort((a, b) => a.localeCompare(b));
  public siteList: string[] = [
    'Right Deltoid',
    'Left Deltoid',
    'Right Thigh',
    'Left Thigh',
    'Right Hip',
    'Left Hip',
    'Right Buttock',
    'Left Buttock',
  ].sort((a, b) => a.localeCompare(b));
  public repeatList: string[] = [
    'Once Off',
    'Repeat',
  ];

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      records: this.fb.array([])
    });
    this.addRow();
  }

  public getRow(): FormArray {
    return this.formData.get('records') as FormArray;
  }

  public removeRow(idx: number) {
    this.getRow().removeAt(idx);
    if (this.getRow().length === 0) {
      this.addRow();
    }
  }

  public addRow() {
    this.getRow().push(this.fb.group({
      date_time: [null, Validators.required],
      medication: [null, Validators.required],
      site: [null, Validators.required],
      repeat_status: [null, Validators.required],
      next_date_time: [null],
    }));
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: MedicationFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'INJECTABLE MEDICATIONS:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: MedicationFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: INJECTABLE MEDICATIONS`,
      data.records.map(
        (c, i) => `(${i + 1}): ${moment(c.date_time).format('YYYY-MM-dd')} - ${c.medication} ${c.site} ${c.repeat_status} ${c.next_date_time ? ` (${moment(c.next_date_time).format('YYYY-MM-dd')})` : ''}`
      ).join('; ')
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as MedicationFormData;
    data.records = data.records.map(d => ({ ...{ date_time: moment(d.date_time).valueOf() }, ...d, _modified: moment().valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['records'] = data.records;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['records'].length; i++) {
      this.addRow();
    }
    super.setBuilderData(data);
  }

}
