<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <div class="row mb-3">
      <div class="col-12 mb-3">
        <label class="me-4">
          <input type="radio" formControlName="result" jargonLoadingState="builder-control" value="POSITIVE"> POSITIVE
        </label>
        <label>
          <input type="radio" formControlName="result" jargonLoadingState="builder-control" value="NEGATIVE"> NEGATIVE
        </label>
      </div>
      <div class="col-12 mb-3">
      </div>
      <div class="col-12">
        <textarea type="text" class="form-control" placeholder="Notes/Comments" formControlName="note"
                  jargonLoadingState="builder-control"></textarea>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove</button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control"
              (click)="saveData()">Save</button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p>
    <span>[{{data.result}}{{data.note ? ' - ' + data.note : ''}}]</span>
  </p>
</ng-template>