import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { SectionConfigModel } from '../../models/section-config.model';
import { ReviewCommentModel } from '../../models/clincal-note.model';
import { UIComponentAlias, UiService } from './ui.service';
import { ClinicalNoteService } from './clinical-note.service';

export type ReviewAction = 'open' | 'add' | 'close' | 'data';

export interface ReviewSubjectModel {
  action: ReviewAction;
  config?: SectionConfigModel;
  comments?: ReviewCommentModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  private reviewSubject$: BehaviorSubject<ReviewSubjectModel> = new BehaviorSubject<ReviewSubjectModel>({ action: 'close' });
  public reviewSubject: Observable<ReviewSubjectModel> = this.reviewSubject$.asObservable();
  private sectionSub?: Subscription;

  constructor(
    private uiService: UiService,
    private clinicalNoteService: ClinicalNoteService
  ) {
  }

  public open(config: SectionConfigModel) {
    this.sectionSub = this.clinicalNoteService.section(config.alias).pipe(
      tap((section) => this.reviewSubject$.next({
        action: 'open',
        config,
        comments: [...(section.comments || [])]
      }))
    ).subscribe();

    // must close other sidebars as only 1 can be open at a time
    this.uiService.close(UIComponentAlias.SIDEBAR_BUILDERS);
    // also close other reviews on sections
    this.uiService.close(UIComponentAlias.SIDEBAR_REVIEWS);
    this.uiService.open(UIComponentAlias.SIDEBAR_REVIEWS, { sectionAlias: config.alias });
  }

  public close() {
    this.sectionSub?.unsubscribe();
    this.reviewSubject$.next({ action: 'close' });
    this.uiService.close(UIComponentAlias.SIDEBAR_REVIEWS);
  }

  public create() {
    this.reviewSubject$.next({ action: 'add' });
  }
}
