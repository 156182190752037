<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--Provider-->
    <div class="row mb-3">
      <div class="col-3">Select Lab:</div>
      <div class="col-3">
        <select type="text"
                class="form-control"
                formControlName="provider"
                jargonLoadingState="builder-control">
          <option [value]="null" disabled>Select...</option>
          <option *ngFor="let provider of providers" [ngValue]="provider">{{ provider.type.provider }}</option>
        </select>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row mb-3">
      <div class="col-auto d-flex mb-2" *ngIf="providerLoading">
        <p class="text-muted">Loading Provider Tests...</p>
      </div>
      <div class="col-12">
        <div class="row mt-3" *ngFor="let group of providerTests">
          <div class="col-12" *ngIf="group.name !== 'default'">
            <h4 style="border-bottom: 1px solid #ccc">{{ group.name }}</h4>
          </div>
          <div class="col-auto d-flex mb-2" *ngFor="let test of group.tests">
            <app-filter-checkbox [color]="'#244ea3'"
                                 [label]="test.label"
                                 [tooltip]="test.title"
                                 [checked]="test.status"
                                 (onChange)="filterTest(test)"
                                 [name]="test.label">
            </app-filter-checkbox>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="submitRequestData()">
        Submit Request
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p>
    <strong>{{data.provider}} </strong>
  </p>
  <p>
    <strong>Ordered Tests </strong>
    <span>[{{data.tests}}]</span>
  </p>
</ng-template>