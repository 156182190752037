import { Directive, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs';
import { ActiveUserService } from '../services/common/active-user.service';
import { ClinicalNoteService } from '../services/common/clinical-note.service';
import { ViewModeEnum } from '../types/enum/viewMode';
import { ViewMode } from '../types/util';

@Directive({
  selector: '[jargonViewModeForm]'
})
export class ViewModeFormDirective implements OnInit {

  @Input('formGroup') formGroup?: FormGroup;
  @Input('formControlName') formControlName?: string;
  @Input('formControl') formControl?: FormControl;

  private mode!: ViewMode;
  private activeMode: ViewMode;

  constructor(
    private activeUserService: ActiveUserService,
    private clinicalNoteService: ClinicalNoteService,
    @Host() @SkipSelf() @Optional() private parentControl: ControlContainer
  ) {
    this.activeMode = this.activeUserService.claims!['mode'] as ViewMode;
  }

  @Input('jargonViewModeForm') set allowedMode(mode: ViewMode) {
    this.mode = mode;
  }

  ngOnInit() {
    this.toggleControlState();
    this.clinicalNoteService.config.pipe(
      tap(() => this.toggleControlState())
    ).subscribe();
  }

  toggleControlState() {
    let state = this.activeMode === this.mode;
    if (this.mode === ViewModeEnum.WRITE && this.clinicalNoteService.isReadOnly) {
      state = false;
    }
    this.handleFormGroup(state);
    this.handleFormControl(state);
  }

  handleFormGroup(state: boolean) {
    if (state)
      this.formGroup?.enable();
    else
      this.formGroup?.disable();
  }

  handleFormControl(state: boolean) {
    if (this.formControl) {
      if (state)
        this.formControl.enable();
      else
        this.formControl.disable();
    }
    if (this.formControlName) {
      if (state)
        this.parentControl.control?.get(this.formControlName)?.enable();
      else
        this.parentControl.control?.get(this.formControlName)?.disable();
    }

  }
}
