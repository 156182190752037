import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { IntegrationModel } from '../../../../shared/models/integration.model';
import { take } from 'rxjs/operators';
import { catchError, distinctUntilChanged, finalize, firstValueFrom, Observable, of, Subscription, switchMap, tap, throwError } from 'rxjs';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import * as moment from 'moment';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { UiService } from '../../../../shared/services/common/ui.service';
import { SettingsModel } from '../../../../shared/models/settings.model';
import { ProviderTestModel } from '../../../../shared/models/provider-test.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsHttpService } from '../../../../shared/services/http/settings-http.service';
import { DEFAULT_GROUP_NAME } from 'src/app/modules/settings/general/general.component';
import { IntegrationTypes } from 'src/app/shared/types/enum/integration';


type Tests = ProviderTestModel & { status: boolean }

interface TestsGroup {
  name: string;
  order: number;
  tests: Tests[]
}

interface QuickLabsData extends BuilderDataAbstract {
  provider: string;
  tests: Array<string>;
}

@Component({
  selector: 'app-quick-labs',
  templateUrl: './quick-labs.component.html',
  styleUrls: ['./quick-labs.component.scss']
})
export class QuickLabsComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public providers: IntegrationModel[] = [];
  public providerTests: TestsGroup[] = [];
  public providerLoading: boolean = false;

  private providerSubscriptions: Subscription[] = [];
  private selectedProvider?: IntegrationModel;

  constructor(
    public uiService: UiService,
    private fb: FormBuilder,
    private settingsHttpService: SettingsHttpService,
  ) {
    super();
    this.formData = this.fb.group({
      provider: [null, Validators.required],
      tests: [[], Validators.required]
    });
    this.loadProviders();
    this.toggleProviders();
  }

  override ngOnDestroy() {
    if (this.providerSubscriptions.length) {
      this.providerSubscriptions.forEach(sub => sub.unsubscribe());
    }
    super.ngOnDestroy();
  }

  public groupTests(tests: Tests[]): TestsGroup[] {
    const orders: { [key: string]: number } = {};
    // handle legacy
    const grouped = tests.reduce((o, v) => {
      if (v._group) {
        o[v._group] = [...(o[v._group] || []), v];
      } else {
        o[DEFAULT_GROUP_NAME] = [...(o[DEFAULT_GROUP_NAME] || []), v];
      }
      return o;
    }, {} as Record<string, Tests[]>)

    return Object.keys(grouped).reduce<TestsGroup[]>((o, v) => {
      return [...o, {
        name: v,
        order: orders[v],
        tests: grouped[v]
      }]
    }, []).sort((a, b) => {
      if (a.order === undefined) {
        return 1;
      }
      if (b.order === undefined) {
        return -1;
      }
      return a.order < b.order ? -1 : (a.order > b.order ? 1 : 0);
    });
  }

  public filterTest(test: Tests) {
    test.status = true;
  }

  public override async validate() {
    return true;
  }

  public async submitRequestData() {
    const msg = await firstValueFrom(
      this.uiService.request('providers', 'submit', {
        provider: this.selectedProvider,
        tests: this.providerTests.reduce((o, v) => [...o, ...v.tests.filter(v => v.status).map(t => t.data.id)], [] as number[])
      })
    );
    if (msg && msg.data && msg.data.quickLabSubmitSuccess) {
      this.saveData();
    }
  }

  serialize(data: QuickLabsData): HistoryData[] {
    if (!data) {
      return [];
    }

    const displayData = {
      provider: data.provider,
      tests: data.tests.join('; '),
    };

    return [{
      type: 'builder',
      builderTitle: 'QUICK LABS',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, displayData]
    }];
  }

  asString(data: QuickLabsData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: QUICK LABS`,
      `Provider: ${data.provider}`,
      `Ordered Tests: ${data.tests.join('; ')}`
    ].join('\n');
  }

  protected override setBuilderData() {
  }

  protected getBuilderData(): BuilderData {
    const data = {
      _modified: moment().valueOf(),
      provider: this.selectedProvider?.type.name,
      tests: Object.values(this.providerTests).reduce((o, v) => [...o, ...v.tests.filter(v => v.status).map(v => v.label)], [] as string[])
    } as QuickLabsData;

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData = data;
    }
    return builderData;
  }

  private async loadProviders() {
    const msg = await firstValueFrom(
      this.uiService.request('providers', 'provider-list', {})
    );
    if (msg && msg.providers && msg.providers.length) {
      this.providers = (msg.providers as IntegrationModel[]).filter(
        p => [
          IntegrationTypes.LANCET as string,
          IntegrationTypes.PATH_CARE_VERMAAK as string
        ].includes(p.type.alias)
      ).sort((a, b) => a.type.provider.localeCompare(b.type.provider));
    }
  }

  private toggleProviders() {
    this.providerSubscriptions.push((this.formData.get('provider')?.valueChanges as Observable<IntegrationModel>).pipe(
      distinctUntilChanged((previous: IntegrationModel, current: IntegrationModel) => previous && current && previous.id === current.id),
      tap((provider: IntegrationModel) => {
        this.providerLoading = true;
        this.selectedProvider = provider;
      }),
      switchMap(() => {
        return this.settingsHttpService.find<Tests[]>(this.activeUserService.user?.id!, `pathology::${this.selectedProvider?.type.alias}`).pipe(
          take(1),
          tap((res) => {
            this.providerTests = this.groupTests(res.data.map(v => {
              if (this.data && this.data['tests'] && this.data['tests'].length) {
                v['status'] = this.data['tests'].includes(v.label);
              } else {
                v['status'] = false;
              }
              return v;
            }));
            this.providerLoading = false;
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              return of(null);
            }
            this.providerLoading = false;
            return throwError(() => err);
          })
        );
      }),
    ).subscribe());
  }

}
