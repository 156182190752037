<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col">
      <label>Attachments</label>
    </div>
    <div class="col-auto">
      <app-review-actions [commentQty]="data.comments?.length" [alias]="config.alias"
                          (reviewAction)="handleReviewAction($event)"></app-review-actions>
    </div>
  </div>
  <div class="row" *ngIf="files">
    <div *ngIf="data.files?.length === 0">
      <p>No Attachments To Display</p>
    </div>
    <ng-container *ngFor="let group of files | keyvalue">
      <div class="col-12">
        <label>{{group.value.title}}</label>
      </div>
      <div class="col-12 col-md-6 col-xl-4 attachment-wrapper"
           *ngFor="let file of group.value.files">
        <span class="attachment-container" [title]="file.name" (click)="openFilePreview(file)">
          <mat-icon [inline]="true">file_present</mat-icon>
          <label>{{file.name}}</label>
          <ng-container *jargonViewMode="'write'">
            <mat-icon [inline]="true" class="delete" [title]="'Delete '+file.name" (click)="deleteFile(file)">delete
            </mat-icon>
          </ng-container>
        </span>
      </div>
    </ng-container>
  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="row my-2">
      <div class="col-12">
        <jargon-dropzone [modal]="true"></jargon-dropzone>
      </div>
    </div>
  </ng-container>
</div>