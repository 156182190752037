import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActiveUserService } from '../services/common/active-user.service';

/**
 * JWT Interceptor to add Bearer Token to Authorization header
 *
 * @export
 * @class AuthInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Header to signify this interceptor should be skipped
     *
     * @type {string}
     * @memberof AuthInterceptor
     */
    public skipHeader: string = 'Skip--Auth';

    /**
     * Creates an instance of TokenRefreshInterceptor.
     * @param {ActiveUserService} activeUserService
     * @memberof TokenRefreshInterceptor
     */
    constructor(
        private activeUserService: ActiveUserService
    ) { }

    /**
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof AuthInterceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(
            req.clone({
                withCredentials: req.url.includes(environment.api),
                setHeaders: {
                    ...(this.activeUserService.token ? { Authorization: `Bearer ${this.activeUserService.token}` } : {})
                }
            })
        );
    }
}
