
export type Cage = {
  symbol: 'C' | 'A' | 'G' | 'E';
  question: string;
  control: 'cut_down' | 'annoyed' | 'guilty' | 'eye_opener';
}

export const CAGE = (): Cage[] => ([
  {
    symbol: 'C',
    question: 'Have you ever felt you need to CUT down on your drinking?',
    control: 'cut_down'
  },
  {
    symbol: 'A',
    question: 'Have people ANNOYED you by criticizing your drinking?',
    control: 'annoyed'
  },
  {
    symbol: 'G',
    question: 'Have you ever felt bad or GUILTY about your drinking?',
    control: 'guilty'
  },
  {
    symbol: 'E',
    question: 'Have you ever had a drink first thing in the morning to steady your nerves or cure a hangover (EYE OPENER)?',
    control: 'eye_opener'
  }
]);

export const MaritalStatuses = (): Array<string> => ([
  'Single',
  'Married',
  'Divorced',
  'Widowed',
  'Living with Partner',
  'Civil Union',
].sort((a, b) => a.localeCompare(b)));

export const Religions = (): Array<string> => ([
  'Christian (non-denominational)',
  'Catholic',
  'Methodist',
  'Protestant',
  'Baptist',
  'Anglican',
  'Lutehran',
  'Presbyterian',
  'Evangelist',
  'Pentecostal',
  'Buddhist',
  'Hindu',
  'Islamic',
  'Jewish',
  'Sikh',
  'Jehovah\'s Witness',
  'Agnostic',
  'Atheist',
  'Other',
].sort((a, b) => a.localeCompare(b)));

export const LivingSituation = (): Array<string> => ([
  'Living alone',
  'Living with partner',
  'Living with housemate(s)',
  'Living with family',
  'Living with live-in caregiver',
  'Assisted living',
].sort((a, b) => a.localeCompare(b)));

export const PhysicalActivity = (): Array<string> => ([
  'None',
  'Low (15 min - 30min/day 1-2 times/week)',
  'Moderate (30min/day 3 - 5 times/week)',
  'High (30min+ 5 - 7 days/week)',
].sort((a, b) => a.localeCompare(b)));

export const Flags = (): Array<string> => ([
  'Malaria',
  'Yellow Fever'
].sort((a, b) => a.localeCompare(b)));
