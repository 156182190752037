import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReviewCommentModel } from '../../models/clincal-note.model';
import { CallsAPI } from '../abstract/http/calls-api';
import { HttpOptions } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class ClinicalNoteCommentHttpService extends CallsAPI<ReviewCommentModel, ReviewCommentModel[]> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/patient/{:patientId}/case/{:guid}/notes/{:section}/comment';

    /**
       * @param {*} body
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public create(patientId: number, guid: string, section: string, body: any, options?: HttpOptions): Observable<ReviewCommentModel> {
        return this.http.post<ReviewCommentModel>(this.buildAction('/', { patientId, guid, section }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
     * @param {*} id
     * @param {*} body
     * @param {HttpOptions} options
     * @returns
     * @memberof HttpApiService
     */
    public update(patientId: number, guid: string, section: string, commentId: string, body: ReviewCommentModel | FormData, options?: HttpOptions): Observable<ReviewCommentModel> {
        return this.http.patch<ReviewCommentModel>(this.buildAction('/{:commentId}', { patientId, guid, section, commentId }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
     * @param {*} id
     * @param {*} body
     * @param {HttpOptions} options
     * @returns
     * @memberof HttpApiService
     */
    public delete(patientId: number, guid: string, section: string, commentId: string, options?: HttpOptions): Observable<ReviewCommentModel> {
        return this.http.delete<ReviewCommentModel>(this.buildAction('/{:commentId}', { patientId, guid, section, commentId }), this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

}