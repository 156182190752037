<div class="container-fluid mt-2">
    <div class="row justify-content-end">
        <div class="col-auto ui-toolbar">
            <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="minimizeFrame()">-</button>
            <button class="btn btn-sm btn-primary ml-2" *ngIf="isInFrame" (click)="closeFrame()">X</button>
        </div>
    </div>
</div>
<div class="container-fluid text-center mt-5">
    <div class="row">
        <div class="col-12">
            <h1>Ooops... something went wrong</h1>
        </div>
        <div class="col-12">
            <p>An unexpected error occured.</p>
        </div>
    </div>
</div>