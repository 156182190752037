import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { HeadAndNeckExamRegions } from './head-and-neck-exam.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

interface HeadAndNeckFormData extends BuilderDataAbstract {
  tml: BuilderPresentNote;
  tmr: BuilderPresentNote;
  sinuses: BuilderPresentNote & { regions: Array<string> };
  thyromegaly: BuilderPresentNote;
  other: BuilderPresentNote;

  // deprecated
  neck_stiff?: BuilderPresentNote;
  neck_lad?: BuilderPresentNote;
}

@Component({
  selector: 'app-head-and-neck-exam',
  templateUrl: './head-and-neck-exam.component.html',
  styleUrls: ['./head-and-neck-exam.component.scss']
})
export class HeadAndNeckExamComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public regions: Array<{ id: number, name: string }> = HeadAndNeckExamRegions();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      tml: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      tmr: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      sinuses: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)]
      }),
      // neck_stiff: this.fb.group({
      //   present: [null],
      //   note: [null, Validators.maxLength(500)]
      // }),
      // neck_lad: this.fb.group({
      //   present: [null],
      //   note: [null, Validators.maxLength(500)]
      // }),
      thyromegaly: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      other: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
    });

    //toggle form validation
    if (!this.isReadOnly) {
      if (this.controlSub) this.controlSub.unsubscribe();
      this.controlSub = merge(
        ...Object.values(this.formData.controls)
          .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
      ).subscribe({
        next: v => {
          switch (v.get('present')?.value) {
            case true:
              v.get('note')?.enable({ emitEvent: false });
              v.get('regions')?.enable({ emitEvent: false });
              break;
            case false:
              v.get('note')?.enable({ emitEvent: false });
              v.get('regions')?.disable({ emitEvent: false });
              v.get('regions')?.patchValue('', { emitEvent: false });
              break;
            case null:
              v.get('note')?.disable({ emitEvent: false });
              v.get('note')?.patchValue('', { emitEvent: false });
              v.get('regions')?.disable({ emitEvent: false });
              v.get('regions')?.patchValue('', { emitEvent: false });
              break;
          }
        }
      });
    }

  }

  public clearInput(form: keyof HeadAndNeckFormData) {
    (this.formData as FormGroup).get(form)?.reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: HeadAndNeckFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'HEAD AND NECK EXAM:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: HeadAndNeckFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: HEAD AND NECK EXAM`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value} ${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as HeadAndNeckFormData;
    data.tml['_modified'] = moment().valueOf();
    data.tmr['_modified'] = moment().valueOf();
    data.sinuses['_modified'] = moment().valueOf();
    // data.neck_stiff['_modified'] = moment().valueOf();
    // data.neck_lad['_modified'] = moment().valueOf();
    data.thyromegaly['_modified'] = moment().valueOf();
    data.other['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['tml'] = data.tml;
      builderData['tmr'] = data.tmr;
      builderData['sinuses'] = data.sinuses;
      // builderData['neck_stiff'] = data.neck_stiff;
      // builderData['neck_lad'] = data.neck_lad;
      builderData['thyromegaly'] = data.thyromegaly;
      builderData['other'] = data.other;
    }
    return builderData;
  }

  private prepareSerializedList(data: HeadAndNeckFormData) {
    return [
      ...(data.tml.present !== null ? [{ title: 'Left Ear:', value: data.tml.present ? 'Abnormal' : 'Normal', note: `${data.tml.note ? ' [' + data.tml.note + ']' : ''}` }] : []),
      ...(data.tmr.present !== null ? [{ title: 'Right Ear:', value: data.tmr.present ? 'Abnormal' : 'Normal', note: `${data.tmr.note ? ' [' + data.tmr.note + ']' : ''}` }] : []),
      ...(data.sinuses.present !== null ? [{ title: 'Sinuses:', value: data.sinuses.present ? 'Present' : 'Not Present', note: `${data.sinuses.regions && data.sinuses.regions.length ? data.sinuses.note ? ' [Region - ' + this.getJoinedList(data.sinuses.regions) : ' [Region - ' + this.getJoinedList(data.sinuses.regions) + ']' : ''}${data.sinuses.note ? data.sinuses.regions && data.sinuses.regions.length ? '; Notes - ' + data.sinuses.note + ']' : ' [Notes - ' + data.sinuses.note + ']' : ''}` }] : []),
      ...(data.neck_stiff && data.neck_stiff.present !== null ? [{ title: 'Neck Stiff:', value: data.neck_stiff.present ? 'Present' : 'Not Present', note: `${data.neck_stiff.note ? ' [' + data.neck_stiff.note + ']' : ''}` }] : []),
      ...(data.neck_lad && data.neck_lad.present !== null ? [{ title: 'Neck LAD:', value: data.neck_lad.present ? 'Present' : 'Not Present', note: `${data.neck_lad.note ? ' [' + data.neck_lad.note + ']' : ''}` }] : []),
      ...(data.thyromegaly.present !== null ? [{ title: 'Thyromegaly:', value: data.thyromegaly.present ? 'Present' : 'Not Present', note: `${data.thyromegaly.note ? ' [' + data.thyromegaly.note + ']' : ''}` }] : []),
      ...(data.other.present !== null ? [{ title: 'Other:', value: data.other.present ? 'Present' : 'Not Present', note: `${data.other.note ? ' [' + data.other.note + ']' : ''}` }] : []),
    ].filter(v => v.value !== null);
  }

}
