<div class="container-fluid g-0 preview-wrapper"
     [ngStyle]="{ height: widgetHeight+'px', 'background-color': backgroundColor }">
    <div class="row g-0">
        <div class="col-auto" *ngIf="showLeftScroll">
            <button (click)="scrollLeft()" type="button"
                    class="btn btn-primary scroll-btn scroll-btn-left">&#8678;</button>
        </div>
        <div class="col overflow-hidden">
            <div #menuWrapper class="row g-0 preview-tabs" (scroll)="computeScroll()">
                <ng-container *ngFor="let section of sections">
                    <div class="col preview-tab"
                         *ngIf="displaySection(section) | async"
                         [ngClass]="{ 'active': section.alias === activeSection?.alias}"
                         [ngStyle]="section.alias !== activeSection?.alias ? { 'background-color': foregroundColor } : {}"
                         (click)="setActiveSection(section)">
                        {{section.title}}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-auto" *ngIf="showRightScroll">
            <button (click)="scrollRight()" type="button"
                    class="btn btn-primary scroll-btn scroll-btn-right">&#8680;</button>
        </div>
    </div>
    <div class="row g-0 preview-content" [ngStyle]="{ 'background-color': foregroundColor }">
        <ng-container *ngIf="data$ | async as data; else loading">
            <app-history-data-preview [data]="data" *ngIf="data.length > 0; else noData"></app-history-data-preview>
            <ng-template #noData>
                <p class="no-data">No Data</p>
            </ng-template>
        </ng-container>
        <ng-template #loading>
            <app-loader class="loader"></app-loader>
        </ng-template>
        <div style="display:none; overflow:hidden;">
            <ng-template #sectionHost></ng-template>
            <ng-template #builderHost></ng-template>
        </div>
    </div>
</div>