import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { Role } from 'src/app/shared/types/enum/user';
import { ExaminationSectionComponent } from '../../sections/components/examination-section/examination-section.component';
import { MainComplaintSectionComponent } from '../../sections/components/main-complaint-section/main-complaint-section.component';
import { InvestigationSectionComponent } from '../../sections/components/investigation-section/investigation-section.component';
import { ResultsSectionComponent } from '../../sections/components/results-section/results-section.component';
import { AdditionalNotesSectionComponent } from '../../sections/components/additional-notes-section/additional-notes-section.component';
import { AttachmentsSectionComponent } from '../../sections/components/attachments-section/attachments-section.component';
import { DiagnosisSectionComponent } from '../../sections/components/diagnosis-section/diagnosis-section.component';
import { TreatmentSectionComponent } from '../../sections/components/treatment-section/treatment-section.component';

export const UI_SECTIONS = {
  MAIN_COMPLAINT: 'main_complaint',
  EXAMINATION: 'examination',
  INVESTIGATION: 'investigation',
  RESULTS: 'results',
  ADDITIONAL_NOTES: 'additional_notes',
  ATTACHMENTS: 'attachments',
  DIAGNOSIS: 'diagnosis',
  TREATMENT: 'treatment',
};

export const AVAILABLE_SECTIONS: { [key: string]: SectionConfigModel } = {
  [UI_SECTIONS.MAIN_COMPLAINT]: { alias: UI_SECTIONS.MAIN_COMPLAINT, title: 'Main Complaint', component: MainComplaintSectionComponent, active: true },
  [UI_SECTIONS.EXAMINATION]: { alias: UI_SECTIONS.EXAMINATION, title: 'Examination Notes', component: ExaminationSectionComponent, active: true },
  [UI_SECTIONS.INVESTIGATION]: { alias: UI_SECTIONS.INVESTIGATION, title: 'Investigation Notes', component: InvestigationSectionComponent, active: false },
  [UI_SECTIONS.RESULTS]: { alias: UI_SECTIONS.RESULTS, title: 'Results Notes', component: ResultsSectionComponent, active: false },
  [UI_SECTIONS.ADDITIONAL_NOTES]: { alias: UI_SECTIONS.ADDITIONAL_NOTES, title: 'Additional Notes', component: AdditionalNotesSectionComponent, active: false },
  [UI_SECTIONS.ATTACHMENTS]: { alias: UI_SECTIONS.ATTACHMENTS, title: 'Attachments', component: AttachmentsSectionComponent, active: true },
  [UI_SECTIONS.DIAGNOSIS]: { alias: UI_SECTIONS.DIAGNOSIS, title: 'Diagnosis', component: DiagnosisSectionComponent, active: true },
  [UI_SECTIONS.TREATMENT]: { alias: UI_SECTIONS.TREATMENT, title: 'Treatment', component: TreatmentSectionComponent, active: true },
};

export const PRESETS: (role: string) => SectionConfigModel[] = (role: string) => ({
  [Role.GP]: [
    AVAILABLE_SECTIONS[UI_SECTIONS.MAIN_COMPLAINT],
    AVAILABLE_SECTIONS[UI_SECTIONS.EXAMINATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.INVESTIGATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.RESULTS],
    AVAILABLE_SECTIONS[UI_SECTIONS.ADDITIONAL_NOTES],
    { ...AVAILABLE_SECTIONS[UI_SECTIONS.ATTACHMENTS], builders: { include: [] } },
    AVAILABLE_SECTIONS[UI_SECTIONS.DIAGNOSIS],
    AVAILABLE_SECTIONS[UI_SECTIONS.TREATMENT],
  ],
  [Role.SP]: [
    AVAILABLE_SECTIONS[UI_SECTIONS.MAIN_COMPLAINT],
    AVAILABLE_SECTIONS[UI_SECTIONS.EXAMINATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.INVESTIGATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.RESULTS],
    AVAILABLE_SECTIONS[UI_SECTIONS.ADDITIONAL_NOTES],
    { ...AVAILABLE_SECTIONS[UI_SECTIONS.ATTACHMENTS], builders: { include: [] } },
    AVAILABLE_SECTIONS[UI_SECTIONS.DIAGNOSIS],
    AVAILABLE_SECTIONS[UI_SECTIONS.TREATMENT],
  ],
  [Role.PCN]: [
    AVAILABLE_SECTIONS[UI_SECTIONS.MAIN_COMPLAINT],
    AVAILABLE_SECTIONS[UI_SECTIONS.EXAMINATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.INVESTIGATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.RESULTS],
    AVAILABLE_SECTIONS[UI_SECTIONS.ADDITIONAL_NOTES],
    { ...AVAILABLE_SECTIONS[UI_SECTIONS.ATTACHMENTS], builders: { include: [] } },
    AVAILABLE_SECTIONS[UI_SECTIONS.DIAGNOSIS],
    AVAILABLE_SECTIONS[UI_SECTIONS.TREATMENT],
  ],
  [Role.SCN]: [
    AVAILABLE_SECTIONS[UI_SECTIONS.MAIN_COMPLAINT],
    AVAILABLE_SECTIONS[UI_SECTIONS.EXAMINATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.INVESTIGATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.RESULTS],
    AVAILABLE_SECTIONS[UI_SECTIONS.ADDITIONAL_NOTES],
    { ...AVAILABLE_SECTIONS[UI_SECTIONS.ATTACHMENTS], builders: { include: [] } },
    AVAILABLE_SECTIONS[UI_SECTIONS.DIAGNOSIS],
    AVAILABLE_SECTIONS[UI_SECTIONS.TREATMENT],
  ]
}[role] || [
    AVAILABLE_SECTIONS[UI_SECTIONS.MAIN_COMPLAINT],
    AVAILABLE_SECTIONS[UI_SECTIONS.EXAMINATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.INVESTIGATION],
    AVAILABLE_SECTIONS[UI_SECTIONS.RESULTS],
    AVAILABLE_SECTIONS[UI_SECTIONS.ADDITIONAL_NOTES],
    { ...AVAILABLE_SECTIONS[UI_SECTIONS.ATTACHMENTS], builders: { include: [] } },
    AVAILABLE_SECTIONS[UI_SECTIONS.DIAGNOSIS],
    AVAILABLE_SECTIONS[UI_SECTIONS.TREATMENT],
  ]);
