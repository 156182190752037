import { Component, Injector, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { AttachmentModel } from '../../../../shared/models/attachment.model';
import { FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, merge, Observable, Subscription, tap } from 'rxjs';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { AttachmentModalOptions, AttachmentsService } from '../../../../shared/services/common/attachments.service';
import { UI_SECTIONS } from '../../../ui/config/sections';
import { validateAllFormFields } from '../../../../shared/validators';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import * as moment from 'moment';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface HivTestFormData extends BuilderDataAbstract {
  result: string;
  attachments: Array<string>;
  note: string;
  _id?: string;
}

@Component({
  selector: 'app-hiv-test',
  templateUrl: './hiv-test.component.html',
  styleUrls: ['./hiv-test.component.scss']
})
export class HivTestComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public disabled: boolean = false;
  public files: AttachmentModel[] = [];
  public options: AttachmentModalOptions = {
    type: 'other',
    data: {
      reason: 'Consent for HIV Test',
      date_time: moment().valueOf()
    }
  }

  private attachmentsSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private attachmentService: AttachmentsService
  ) {
    super();
    this.formData = this.fb.group({
      result: [null, Validators.required],
      attachments: [null, Validators.required],
      note: [null, Validators.maxLength(500)]
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.attachmentsSubscription = merge(
      this.formData.get('attachments')?.valueChanges as Observable<any>,
      this.clinicalNoteService.section(UI_SECTIONS.ATTACHMENTS)
    ).pipe(
      tap(res => {
        const formAttachmentsData = this.formData.get('attachments')?.value;
        if (res.files && res.files.length && formAttachmentsData) {
          this.files = res.files.filter((v: AttachmentModel) => formAttachmentsData.includes(`_id:${v._id}`));
        }
      })
    ).subscribe();
  }

  override ngOnDestroy(): void {
    this.attachmentsSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  public attachmentsAdded(files: AttachmentModel[]) {
    this.formData.patchValue({
      attachments: files.map(f => `_id:${f._id}`)
    });
    this.files = this.files.concat(files);
    this.saveData();
  }

  public async deleteFile(file: AttachmentModel) {
    if (confirm("Are you sure you wish to delete the file: \"" + file.name + "\" ?\n NOTE: This action Cannot be undone!\n\nPress OK to confirm this action.") === true) {
      firstValueFrom(this.attachmentService.delete(file._id))
        .then(() => {
          this.formData.patchValue({
            attachments: this.files.filter(v => v._id !== file._id).map(f => `_id:${f._id}`)
          });
          this.saveData();
        });
    }
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: HivTestFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const previewData = {
      result: data.result,
      note: data.note,
      fileNames: this.files.map(v => v.name).join(', ')
    };

    return [{
      type: 'builder',
      builderTitle: 'HIV TEST:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, previewData]
    }];
  }

  asString(data: HivTestFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: HIV TEST`,
      `Result: ${data.result}`
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as HivTestFormData;
    data['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData = { ...builderData, ...data };
    }

    return builderData;
  }
}
