import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PastMedicalHistoryComponent } from './components/past-medical-history/past-medical-history.component';
import { EHRTabItemComponent } from './shared/components/ehr-tab-item/ehr-tab-item.component';
import { AbstractEHRTabComponent } from './shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { SharedModule } from '../../shared/shared.module';
import { FamilyAndSocialHistoryComponent } from './components/family-and-social-history/family-and-social-history.component';
import { VisitsComponent } from './components/visits/visits.component';
import { LabsComponent } from './components/labs/labs.component';
import { ImagingComponent } from './components/imaging/imaging.component';
import { VaccinationsComponent } from './components/vaccinations/vaccinations.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    EHRTabItemComponent,
    AbstractEHRTabComponent,
    PastMedicalHistoryComponent,
    FamilyAndSocialHistoryComponent,
    VisitsComponent,
    LabsComponent,
    ImagingComponent,
    VaccinationsComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatIconModule,
        ReactiveFormsModule
    ],
  exports: [
    EHRTabItemComponent
  ]
})
export class EhrModule { }
