<div class="container-fluid">
  <div class="row>">
    <div class="col-12 mt-2">
      <input class="form-control" placeholder="Search" [formControl]="visitsSearch">
    </div>
    <div class="col-12 mt-2">
      <app-jargon-table [config]="tableConfig" [loading]="loading">
        <ng-template appTemplateRef="action" let-data="data">
          <button class="btn btn-sm btn-outline-primary" (click)="viewVisit(data)">
            View Case
          </button>
        </ng-template>
      </app-jargon-table>
    </div>
    <div class="col-12">
      <app-pagination [pagination]="pagination" (change)="pageChange($event)"></app-pagination>
    </div>
  </div>
</div>
