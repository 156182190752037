<div class="container-fluid g-0">
  <ng-container *ngIf="!hasStatusComment; else statusCommentTmpl">
    <button class="btn btn-primary btn-sm builder-add" [ngStyle]="buttonStyle" (click)="toggleStatus(true)"
            [disabled]="disabled">
      <mat-icon>add_circle</mat-icon>
    </button>
    <button class="btn btn-outline-primary btn-sm builder-remove" [ngStyle]="buttonStyle" (click)="toggleStatus(false)"
            [disabled]="disabled">
      <mat-icon>remove_circle</mat-icon>
    </button>
  </ng-container>

  <ng-template #statusCommentTmpl>
    <ng-container *ngIf="!multi; else statusCommentMultiTmpl">
      <div class="input-group status-comment-dynamic-group view-mode-{{viewMode}}">
        <button *ngIf="present === true" class="btn btn-primary btn-sm" [ngStyle]="buttonStyle" type="button"
                [disabled]="disabled">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button *ngIf="present === false" class="btn btn-outline-primary btn-sm" [ngStyle]="buttonStyle" type="button"
                [disabled]="disabled">
          <mat-icon>remove_circle</mat-icon>
        </button>
        <ng-container *ngIf="templates[present ? 'positive' : 'negative']"
                      [ngTemplateOutlet]="templates[present ? 'positive' : 'negative']"
                      [ngTemplateOutletContext]="{ data: {} }">
        </ng-container>
        <ng-container *jargonViewMode="'write'">
          <button class="btn btn-outline-secondary btn-sm" [ngStyle]="buttonStyle" type="button"
                  (click)="clearInput()" [disabled]="disabled">
            <mat-icon>cancel</mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #statusCommentMultiTmpl>
    <div class="input-group status-comment-dynamic-group view-mode-{{viewMode}} mb-2"
         *ngFor="let _ of [].constructor(count); let i = index">
      <button *ngIf="present === true" class="btn btn-primary btn-sm" [ngStyle]="buttonStyle" type="button"
              [disabled]="disabled">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button *ngIf="present === false" class="btn btn-outline-primary btn-sm" [ngStyle]="buttonStyle" type="button"
              [disabled]="disabled">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <ng-container *ngIf="templates[present ? 'positive' : 'negative']"
                    [ngTemplateOutlet]="templates[present ? 'positive' : 'negative']"
                    [ngTemplateOutletContext]="{ index: i }">
      </ng-container>
      <ng-container *jargonViewMode="'write'">
        <button class="btn btn-outline-secondary btn-sm" [ngStyle]="buttonStyle" type="button"
                (click)="clearInput(i)" [disabled]="disabled">
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-container>
    </div>
  </ng-template>

</div>