import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { CVSRegions } from './cvs-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

interface CvsHistoryFormData extends BuilderDataAbstract {
  chest_pain: {
    present: boolean;
    regions: Array<string>;
    note: string;
    _modified: number;
  },
  palpitations: {
    present: boolean;
    note: string;
    _modified: number;
  }
}

@Component({
  selector: 'app-cvs-history',
  templateUrl: './cvs-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './cvs-history.component.scss']
})
export class CvsHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public regions: Array<{ id: number, name: string }> = CVSRegions();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      chest_pain: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        regions: [[]]
      }),
      palpitations: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
      })
    });

    //toggle form validation

    if (!this.isReadOnly) {
      if (this.controlSub) this.controlSub.unsubscribe();
      this.controlSub = merge(
        ...Object.values(this.formData.controls)
          .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
      ).subscribe({
        next: v => {
          switch (v.get('present')?.value) {
            case true:
              v.get('note')?.enable({ emitEvent: false });
              v.get('regions')?.enable({ emitEvent: false });
              break;
            case false:
              v.get('note')?.enable({ emitEvent: false });
              v.get('regions')?.disable({ emitEvent: false });
              v.get('regions')?.patchValue('', { emitEvent: false });
              break;
            case null:
              v.get('note')?.disable({ emitEvent: false });
              v.get('note')?.patchValue('', { emitEvent: false });
              v.get('regions')?.disable({ emitEvent: false });
              v.get('regions')?.patchValue('', { emitEvent: false });
              break;
          }
        }
      });
    }

  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: CvsHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'CVS HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: CvsHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: CVS HISTORY`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as CvsHistoryFormData;
    data.chest_pain['_modified'] = moment().valueOf();
    data.palpitations['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['chest_pain'] = data.chest_pain;
      builderData['palpitations'] = data.palpitations;
    }
    return builderData;
  }

  private prepareSerializedList(data: CvsHistoryFormData) {
    let list = [];
    if (data.chest_pain.present !== null) {
      list.push({ title: 'Chest Pain', value: `${data.chest_pain.present ? 'Present' : 'Not Present'}${data.chest_pain.regions && data.chest_pain.regions.length ? ': Region - ' + this.getJoinedList(data.chest_pain.regions) : ''}${data.chest_pain.note ? '; ' + data.chest_pain.note : ''}` })
    }
    if (data.palpitations.present !== null) {
      list.push({ title: 'Palpitations', value: `${data.palpitations.present ? 'Present' : 'Not Present'}${data.palpitations.note ? ': ' + data.palpitations.note : ''}` })
    }
    return list;
  }
}
