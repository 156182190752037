import { Component, OnInit, TemplateRef } from '@angular/core';
import { Toast, ToastService } from '../../services/common/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: { class: 'toast-container position-fixed top-0 end-0 p-3' },
})
export class ToastContainerComponent {

  constructor(public toastService: ToastService) { }

  isTemplate(toast: Toast) {
    return toast.content instanceof TemplateRef;
  }

  asTemplate(toast: Toast): TemplateRef<any> {
    return toast.content as TemplateRef<any>;
  }

}
