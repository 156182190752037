import { AfterViewInit, Component, ComponentRef, Injector, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { AbstractSectionComponent } from '../abstract-section/abstract-section.component';
import { ComponentHostDirective } from 'src/app/shared/directives/component-host.directive';
import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import { BuilderConfigItemModel } from 'src/app/shared/models/builder-config.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { BuilderData, ClinicalNoteModel } from 'src/app/shared/models/clincal-note.model';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderItemComponent } from 'src/app/modules/builders/shared/components/builder-item/builder-item.component';
import { catchError, finalize, firstValueFrom, map, Observable, of, Subscription, tap } from 'rxjs';
import { resolveBuilder } from '../../../../ui/config/builders';
import { LoadingStateService } from '../../../../../shared/services/common/loading-state.service';
import { UIComponentAlias, UiService, UIToggleEvent } from 'src/app/shared/services/common/ui.service';
import * as moment from 'moment';

export type BuilderValidationResult = {
  valid: boolean;
  invalidBuilders: string[]
};

export type SectionControls = {
  getSerializedBuilders: () => Promise<HistoryData[]>;
  validateBuilders: () => Promise<BuilderValidationResult>;
  openBuilder: (alias: string) => void;
  save: () => Promise<boolean | null>;
}

export type SectionBuilderControls = {
  detatch: (item: BuilderConfigItemModel) => Promise<boolean>;
  toggle: (builder: BuilderConfigItemModel, state?: boolean) => void;
  scrollToSection: () => void;
  scrollToBuilders: () => void;
  onSave: () => Promise<void>
}

@Component({
  selector: 'noteSection-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss']
})
export class SectionItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('topOffset') public topOffset!: number;
  @Input('config') public config!: SectionConfigModel;
  @ViewChild('sectionHost', { static: true, read: ComponentHostDirective }) sectionHost!: ComponentHostDirective;
  @ViewChildren(BuilderItemComponent) builderItems!: QueryList<BuilderItemComponent>;

  public restrictedBuilderHover: boolean = false;
  public existingBuilderHover: boolean = false;
  public builders: BuilderConfigItemModel[] = [];
  public data$!: Observable<ClinicalNoteModel>;
  public openBuilder?: BuilderConfigItemModel;
  public builderControls: SectionBuilderControls = {
    detatch: this.detatchBuilder.bind(this),
    toggle: this.toggleBuilder.bind(this),
    scrollToSection: this.scrollToSection.bind(this),
    scrollToBuilders: this.scrollToBuilders.bind(this),
    onSave: this.onSave.bind(this),
  };
  public isReviewOpen: boolean = false;

  private sectionRef!: ComponentRef<AbstractSectionComponent>;
  private subscriptions: Subscription[] = [];
  private _isViewInitialized: boolean = false;
  private serializeListeners: Function[] = [];
  public shouldRender: boolean = true;

  constructor(
    protected injector: Injector,
    protected clinicalNoteService: ClinicalNoteService,
    protected loadingStateService: LoadingStateService,
    private uiService: UiService
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.clinicalNoteService.section(this.config.alias);
    this.sectionHost.viewContainerRef.clear();
    this.sectionRef = this.sectionHost.viewContainerRef.createComponent<AbstractSectionComponent>(this.config.component);
    this.sectionRef.instance.config = this.config;
    this.sectionRef.instance.controls = {
      getSerializedBuilders: this.getSerializedBuilders.bind(this),
      validateBuilders: this.validateBuilders.bind(this),
      openBuilder: (alias: string) => {
        const builder = this.builders.find(b => b.alias === alias);
        if (builder) {
          this.toggleBuilder(builder, true)
        }
      },
      save: this.doSave.bind(this)
    }

    this.subscriptions.push(this.data$.pipe(
      tap(data => {
        if (!this.config.active && !data.notes) {
          this.sectionHost.viewContainerRef.clear();
          this.shouldRender = false;
        }
        if (data) {
          this.sectionRef.instance.data = data;
          this.builders = Object.keys(data.builders || {}).map(b => resolveBuilder(b));
        }
      })
    ).subscribe());

    this.subscriptions.push(this.uiService.events.pipe(
      tap(({ component, state, options }: UIToggleEvent) => {
        if (component === UIComponentAlias.SIDEBAR_REVIEWS) {
          if (!state) {
            // always close
            this.isReviewOpen = false;
          } else if (options && options['sectionAlias'] === this.config.alias) {
            // only open if its for this alias
            this.isReviewOpen = true;
          }
        }
      })
    ).subscribe());
  }

  ngAfterViewInit(): void {
    this._isViewInitialized = true;
    this.serializeListeners.forEach(f => f());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  scrollToSection() {
    const element = document.getElementById('section-container__' + this.config.alias);
    if (element) {
      window.scrollTo(0, window.scrollY + element.getBoundingClientRect().top - this.topOffset - 30);
    }
  }

  scrollToBuilders() {
    const element = document.getElementById('section-container__' + this.config.alias);
    if (element) {
      window.scrollTo(0, window.scrollY + element.getBoundingClientRect().bottom - this.topOffset - 30);
    }
  }

  drop(event: CdkDragDrop<BuilderConfigItemModel>) {
    const success = this.attachBuilder(event.item.data);
    if (success) {
      this.openBuilder = event.item.data;
      this.uiService.close(UIComponentAlias.SIDEBAR_BUILDERS);
    }
  }

  attachBuilder(item: BuilderConfigItemModel): boolean {
    if (this.config.builders) {
      // decide if its allowed here
      if (this.config.builders.include && !this.config.builders.include.includes(item.alias)) {
        return false; // not allowed
      }
      if (this.config.builders.exclude && this.config.builders.exclude.includes(item.alias)) {
        return false; // not allowed
      }
    }
    if (this.builders.includes(item)) {
      return false;
    }
    this.builders.push(item);
    return true;
  }

  async detatchBuilder(item: BuilderConfigItemModel): Promise<boolean> {
    const idx = this.builders.findIndex((i) => i === item);
    if (idx === -1) {
      return false;
    }
    // @TODO: clean out associated data
    if (this.openBuilder === item) {
      this.openBuilder = undefined;
    }

    this.loadingStateService.start('builder-control');
    return firstValueFrom(this.clinicalNoteService.deleteBuilder(this.config.alias, item.alias).pipe(
      catchError(err => {
        console.error(err);
        return of(false);
      }),
      finalize(() => this.loadingStateService.end('builder-control'))
    ));
  }

  toggleBuilder(builder: BuilderConfigItemModel, state?: boolean) {
    if (state === true) {
      this.openBuilder = builder;
      return;
    }

    if (state === false || this.openBuilder === builder) {
      this.openBuilder = undefined;
      return;
    }

    this.openBuilder = builder;
  }

  builderData(alias: string): Observable<BuilderData | undefined> {
    return this.data$.pipe(
      catchError(err => {
        console.error(err);
        return of(undefined);
      }),
      map(data => {
        if (data && data.builders && data.builders[alias]) {
          return data.builders[alias];
        }
        return undefined;
      })
    )
  }

  async getSerializedBuilders(): Promise<HistoryData[]> {
    const resolveData = () => {
      return this.builderItems ? this.builderItems.reduce((out, builder) => [
        ...out,
        ...builder.serialize().map(s => ({
          ...s,
          action: () => {
            builder.controls.toggle(builder.config, true);
            builder.controls.scrollToBuilders();
          }
        }))
      ], [] as HistoryData[]) : []
    };

    if (this._isViewInitialized) {
      return resolveData();
    }
    return new Promise<HistoryData[]>((resolve) => {
      this.serializeListeners.push(() => {
        resolve(resolveData());
      });
    });
  }

  dropHover(event: CdkDragEnter<BuilderConfigItemModel>): void {
    this.restrictedBuilderHover = false;
    this.existingBuilderHover = false;
    if (this.config.builders) {
      // decide if it's allowed here
      if (this.config.builders.include && !this.config.builders.include.includes(event.item.data.alias)) {
        this.restrictedBuilderHover = true;
      }
      if (this.config.builders.exclude && this.config.builders.exclude.includes(event.item.data.alias)) {
        this.restrictedBuilderHover = true;
      }
    }
    if (this.builders.includes(event.item.data)) {
      this.existingBuilderHover = true;
    }
  }

  async validateBuilders(): Promise<BuilderValidationResult> {
    const output = { valid: true, invalidBuilders: [] } as BuilderValidationResult;
    await Promise.all(this.builderItems.map(async (builderItem) => {
      const valid = await builderItem.validate();
      output.valid = output.valid && valid;
      if (!valid) {
        output.invalidBuilders.push(builderItem.config.alias);
      }
    }));
    return output;
  }

  async doSave(): Promise<boolean | null> {
    const sectionData: ClinicalNoteModel = {
      ...{ builders: {} },
      ...this.sectionRef.instance.data
    };

    // map all builder data to section to store
    this.builderItems.map(builderItem => {
      const builderData = builderItem.getData();
      if (sectionData.builders[builderItem.config.alias]) {
        // if its changed, mark it as changed
        if (JSON.stringify(sectionData.builders[builderItem.config.alias]) !== JSON.stringify(builderData)) {
          const now = moment().valueOf();
          sectionData.builders[builderItem.config.alias] = { ...JSON.parse(JSON.stringify(builderData)), _modified: now };
        } else {
          sectionData.builders[builderItem.config.alias] = { ...JSON.parse(JSON.stringify(builderData)) };
        }
        // if no change, leave it alone.
      } else {
        // if its new, add it in
        const now = moment().valueOf();
        sectionData.builders = {
          ...sectionData.builders || {},
          [builderItem.config.alias]: { _created: now, ...JSON.parse(JSON.stringify(builderData)), _modified: now }
        };
      }
    });
    return await firstValueFrom(this.clinicalNoteService.saveSection(
      this.config.alias,
      sectionData
    )).then((saved) => {
      if (saved) {
        this.onSave();
      }
      return saved;
    }).catch((error) => {
      this.scrollToSection();
      return false;
    });
  }

  async onSave() {
    const data = await firstValueFrom(this.clinicalNoteService.section(this.config.alias));
    const content = [
      this.sectionRef.instance.asString(data),
      ...this.builderItems.map(builder => builder.asString(data.builders[builder.config.alias]))
    ].join('\n\n');
    this.uiService.message('section-save', this.config.alias, content)
  }

}
