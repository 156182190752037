<div>
    <button *ngIf="!isReviewOpen" class="btn-notes btn-open-notes" (click)="toggleNotes('open')">
        <mat-icon>drive_file_rename_outline</mat-icon>
        <span *ngIf="commentQty && commentQty > 0"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{commentQty}}
            <span class="visually-hidden">comments</span>
        </span>
    </button>
    <ng-container *jargonViewMode="'review'">
        <button *ngIf="isReviewOpen" class="btn-notes btn-add-notes" (click)="toggleNotes('add')">
            <mat-icon>add</mat-icon>
        </button>
    </ng-container>
    <button *ngIf="isReviewOpen" class="btn-notes btn-close-notes" (click)="toggleNotes('close')">
        <mat-icon>close</mat-icon>
    </button>
</div>