import { Component, Injector, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { AttachmentModel } from 'src/app/shared/models/attachment.model';
import { UI_SECTIONS } from '../../../ui/config/sections';
import { distinctUntilChanged, firstValueFrom, map, merge, Observable, Subscription, switchMap, take, tap } from 'rxjs';
import { AttachmentModalOptions, AttachmentsService } from '../../../../shared/services/common/attachments.service';

interface EcgTestFormData extends BuilderDataAbstract {
  attachments: Array<string>;
  note: string;
  _id?: string;
}

@Component({
  selector: 'app-ecg-test',
  templateUrl: './ecg-test.component.html',
  styleUrls: ['./ecg-test.component.scss']
})
export class EcgTestComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public disabled: boolean = false;
  public files: AttachmentModel[] = [];
  public options: AttachmentModalOptions = {
    type: 'other',
    data: {
      name: 'ECG',
      reason: 'ECG',
      date_time: moment().valueOf()
    }
  }

  private attachmentsSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private attachmentService: AttachmentsService
  ) {
    super();
    this.formData = this.fb.group({
      attachments: [[]],
      note: [null, Validators.maxLength(500)]
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.attachmentsSubscription = merge(
      (this.formData.get('attachments')?.valueChanges as Observable<any>).pipe(
        switchMap((v) => this.clinicalNoteService.section(UI_SECTIONS.ATTACHMENTS).pipe(
          take(1),
          map(({ files }) => [v, files])
        ))
      ),
      this.clinicalNoteService.section(UI_SECTIONS.ATTACHMENTS).pipe(
        map(({ files }) => [this.formData.get('attachments')?.value, files])
      )
    ).pipe(
      distinctUntilChanged(),
      tap(([value, files]) => {
        if (files && value) {
          this.files = files.filter((v: AttachmentModel) => value.includes(`_id:${v._id}`));
        }
      })
    ).subscribe();
  }

  override ngOnDestroy(): void {
    this.attachmentsSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  public attachmentsAdded(files: AttachmentModel[]) {
    this.formData.patchValue({
      attachments: files.map(f => `_id:${f._id}`)
    });
    this.saveData();
  }

  public async deleteFile(file: AttachmentModel) {
    if (confirm("Are you sure you wish to delete the file: \"" + file.name + "\" ?\n NOTE: This action Cannot be undone!\n\nPress OK to confirm this action.") === true) {
      firstValueFrom(this.attachmentService.delete(file._id))
        .then(() => {
          this.formData.patchValue({
            attachments: this.files.filter(v => v._id !== file._id).map(f => `_id:${f._id}`)
          });
          this.saveData();
        });
    }
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid && (this.formData.get('attachments')?.value as Array<string>).length > 0;
  }

  serialize(data: EcgTestFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const previewData = {
      note: data.note,
      fileNames: this.files.map(v => v.name).join(', ')
    };

    return [{
      type: 'builder',
      builderTitle: 'ECG:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, previewData],
      templateData: previewData
    }];
  }

  asString(data: EcgTestFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: ECG`,
      `Notes/Comments: ${data.note}`
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as EcgTestFormData;
    data['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData = { ...builderData, ...data };
    }

    return builderData;
  }
}
