import { Component, Input, OnInit } from '@angular/core';
import { HistoryData } from '../history-view/history-view.component';

@Component({
  selector: 'app-history-data-preview',
  templateUrl: './history-data-preview.component.html',
  styleUrls: ['./history-data-preview.component.scss']
})
export class HistoryDataPreviewComponent implements OnInit {
  @Input('data') data: Array<HistoryData> = [];
  constructor() { }

  ngOnInit(): void {
  }

}
