<div class="mb-4 section-item" [ngClass]="{'d-none': !shouldRender}">
    <div class="section-container px-2 py-3"
         id="{{ 'section-container__' + this.config.alias }}"
         [ngClass]="{'restrict-disallowed': restrictedBuilderHover, 'restrict-existing': existingBuilderHover, 'comment-focused': isReviewOpen}"
         cdkDropList
         (cdkDropListDropped)="drop($event)"
         (cdkDropListEntered)="dropHover($event)">
        <div class="section-content">
            <ng-template #sectionHost appComponentHost></ng-template>
        </div>
    </div>
    <div class="builder-container" [ngClass]="{'active': !!openBuilder}">
        <div class="row">
            <div class="col-12 builder-label-wrapper">
                <div class="row g-0 builder-label-row">
                    <div class="col-auto builder-label"
                         *ngFor="let builder of builders; let i = index"
                         (click)="toggleBuilder(builder)"
                         [ngClass]="{'active': openBuilder === builder}"
                         [ngStyle]="{ 'z-index': builders.length-i }">
                        {{ builder.label || 'Loading...' }}
                    </div>
                </div>
            </div>
            <div class="col-12 builder-window">
                <div *ngFor="let builder of builders;let index = index" class="builder-display"
                     [ngClass]="{'active': openBuilder === builder}">
                    <builder-item [(config)]="builders[index]"
                                  [data]="builderData(builder.alias) | async"
                                  [section]="config"
                                  [controls]="builderControls">
                    </builder-item>
                </div>
            </div>
        </div>
    </div>
</div>