<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--BOM-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">BOM</label>
      </div>
      <div class="col" formGroupName="bom">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Polydipsia-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Polydipsia</label>
      </div>
      <div class="col" formGroupName="polydipsia">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Genital Sores-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Genital Sores</label>
      </div>
      <div class="col" formGroupName="genital_sores">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Genital Discharge-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Genital Discharge</label>
      </div>
      <div class="col" formGroupName="genital_discharge">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove</button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control"
              (click)="saveData()">Save</button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value ? 'Present' : 'Not Present' }}{{item.note ? ': ' + item.note : ''}}]</span>
  </p>
</ng-template>