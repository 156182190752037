import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { Flags } from './travel-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

type AddRow = 'travel';

interface SHData {
  _modified: number;
}


interface FormDataTravel extends SHData {
  date_time: number;
  destination: string;
  flags: Array<string>;
  reason: string;
  note: string;
}

interface TravelHistoryFormData extends BuilderDataAbstract {
  travel: Array<FormDataTravel>;
}

@Component({
  selector: 'app-travel-history',
  templateUrl: './travel-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './travel-history.component.scss']
})
export class TravelHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public flags: Array<string> = Flags();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      travel: this.fb.array([]),
    });
    this.addRow('travel');
  }

  public getRow(form: AddRow): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: AddRow, idx: number) {
    this.getRow(form).removeAt(idx);
    if (this.getRow(form).length === 0) {
      this.addRow(form);
    }
  }

  public clearInputs(form: AddRow) {
    this.getRow(form).reset();
  }

  public addRow(form: AddRow) {
    const row = {
      travel: () => ({
        date_time: [null],
        destination: [null, Validators.maxLength(200)],
        flags: [[]],
        reason: [null, Validators.maxLength(200)],
        note: [null, Validators.maxLength(200)],
      }),
    }[form];
    const group = this.fb.group(row());
    this.getRow(form).push(group);
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: TravelHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'TRAVEL:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: TravelHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: TRAVEL`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as TravelHistoryFormData;

    Object.entries(data)
      .filter(([key, value]) => ['travel'].includes(key))
      .forEach(([control, controlValue]) => {
        if (control === 'travel' && controlValue.length) {
          return data[control] = controlValue.filter((data: FormDataTravel) => !this.isEmpty(data, ['date_time', 'destination', 'reason', 'note']));
        }
      });


    data.travel = data.travel.length ? data.travel.map(d => ({
      ...d,
      _modified: moment().valueOf(),
      date_time: moment(d.date_time).valueOf()
    })) : [];

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['travel'] = data.travel;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['travel'].length; i++) {
      this.addRow('travel');
    }
    super.setBuilderData(data);
  }

  private prepareSerializedList(data: TravelHistoryFormData) {

    const travelValue = data.travel.length ?
      data.travel.map((c, i) => `${'(' + (i + 1) + ')'}${c.date_time ? ': ' + moment(c.date_time).format('YYYY-MM-DD') : ''}${c.destination ? ' ' + c.destination : ''}${c.flags.length ? ', ' + c.flags.join(' Area, ') + ' Area' : ''}${c.note ? ', Risk factors - ' + c.note : ''}`)
        .join('; ')
      : null;

    const list: Array<{ title: string, value: string | null | undefined }> = [
      { title: 'Travel', value: travelValue },
    ].filter(v => v.value !== null && v.value !== undefined);

    return list;
  }
}
