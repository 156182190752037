import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ReviewAction } from '../../services/common/review.service';
import { BehaviorSubject, distinctUntilChanged, filter, Subscription, tap } from 'rxjs';
import { UIComponentAlias, UiService, UIToggleEvent } from '../../services/common/ui.service';
import * as moment from 'moment';

export type HistoryMode = 'chronological' | 'typed';
export type HistoryAction = {
  label: string;
  action: Function;
};
export type HistoryComparator = {
  dateTime: string;
  author?: string;
  content?: string;
  type?: string;
  icon?: string;
  title?: string;
  templateData?: any;
};

export type HistoryData = HistoryComparator & {
  builderTitle?: string;
  builderTitleData?: string;
  sectionTitle?: string;
  template?: [TemplateRef<unknown>, any];
  action?: Function;
  actions?: Array<HistoryAction>;
};

@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss']
})
export class HistoryViewComponent implements OnInit, OnDestroy {

  @Input('alias') public alias?: string;
  @Input('title') public title?: string;
  @Input('commentQty') public commentQty?: number;
  @Input('mode') public mode: HistoryMode = 'chronological';

  @Input('data') set data(value: Array<HistoryData>) {
    if (Array.isArray(value)) {
      this.data$.next(value);
    }
  };
  @Output('reviewAction') reviewAction: EventEmitter<ReviewAction> = new EventEmitter<ReviewAction>();
  public isExpanded: boolean = false;
  public isReviewOpen: boolean = false;
  public displayData: Array<HistoryData> = [];

  private subscriptions: Subscription[] = [];
  private data$: BehaviorSubject<Array<HistoryData>> = new BehaviorSubject<Array<HistoryData>>([]);

  constructor(private uiService: UiService) {
    this.subscriptions.push(
      this.data$.pipe(
        distinctUntilChanged((prev, curr) => {
          const comp = ({ author, dateTime, content, type, title, templateData, actions }: HistoryData) => (
            { author, dateTime, content, type, title, templateData, actions }
          );
          return JSON.stringify(prev.map(comp)) === JSON.stringify(curr.map(comp));
        }),
        tap(() => this.setMode(this.mode))
      ).subscribe()
    )
  }

  ngOnInit(): void {
    this.subscriptions.push(this.uiService.events.pipe(
      tap(({ component, state, options }: UIToggleEvent) => {
        if (component === UIComponentAlias.SIDEBAR_REVIEWS) {
          if (!state) {
            // always close
            this.isReviewOpen = false;
          } else if (options && options['sectionAlias'] === this.alias) {
            // only open if its for this alias
            this.isReviewOpen = true;
          }
        }
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setMode(mode: HistoryMode) {
    this.mode = mode;
    this.displayData = this.data$.getValue();
    switch (mode) {
      case 'chronological':
        this.sortByChronology(this.displayData);
        break;
      case 'typed':
        this.sortByType();
        break;
    }
  }

  toggleNotes(action: ReviewAction) {
    this.reviewAction.emit(action);
  }

  private sortByChronology(data: Array<HistoryData>) {
    return data.sort((a, b) => {
      if (moment(b.dateTime).isBefore(moment(a.dateTime))) {
        return 1;
      }
      if (moment(b.dateTime).isAfter(moment(a.dateTime))) {
        return -1;
      }
      return 0;
    });
  }

  private sortByType() {
    const groupedData = this.displayData.reduce((o: { [key: string]: Array<HistoryData> }, b) => {
      const group = b.type ? b.type : 'notes';
      o[group] = !o[group] ? [b] : [...o[group], b];
      return o;
    }, {});
    const builderData = groupedData['builder'] ? this.sortByChronology(groupedData['builder']) : [];
    const noteData = groupedData['notes'] ? this.sortByChronology(groupedData['notes']) : [];
    this.displayData = [
      {
        author: '',
        dateTime: '',
        type: 'builder',
        title: 'BUILDERS'
      },
      ...builderData,
      {
        author: '',
        dateTime: '',
        title: 'WRITTEN NOTES'
      },
      ...noteData
    ];
  }
}
