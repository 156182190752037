<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <div class="row mb-3">
      <div class="col-12 mb-3">
        <label class="me-4">
          <input type="radio" name="fasting" formControlName="fasting" [value]="true"
                 jargonLoadingState="builder-control"> Fasting
        </label>
        <label>
          <input type="radio" name="fasting" formControlName="fasting" [value]="false"
                 jargonLoadingState="builder-control"> Non-fasting
        </label>
      </div>
      <div class="col-12 mb-3">
        <div class="col-4">
          <div class="input-group">
            <input type="number" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <span class="input-group-text">Result (mmol/l)</span>
          </div>
        </div>
        <div class="col-8"></div>
      </div>
      <div class="col-12">
        <textarea type="text" class="form-control" placeholder="Notes/Comments" formControlName="note"
                  jargonLoadingState="builder-control"></textarea>
      </div>
    </div>

  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" (click)="deleteBuilder()"
              jargonLoadingState="builder-control">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" (click)="saveData()" jargonLoadingState="builder-control">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p>
    <span>[{{data.fasting ? 'Fasting' : 'Non-fasting' }} - {{ data.result }}mmol/l{{data.note ? '; ' + data.note :
      ''}}]</span>
  </p>
</ng-template>