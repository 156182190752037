import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { StatusComment } from '../../shared/components/status-comment/status-comment.component';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface VaccinationHistoryFormData extends BuilderDataAbstract {
  flu_annual: StatusComment;
  hepatitis: StatusComment;
  varicella: StatusComment;
  pvc: StatusComment;
  meningococcal: StatusComment;
  ppsv: StatusComment;
  dpt: StatusComment;
  other: StatusComment;
}

@Component({
  selector: 'app-vaccination-history',
  templateUrl: './vaccination-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './vaccination-history.component.scss']
})
export class VaccinationHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      flu_annual: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      hepatitis: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      varicella: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      pvc: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      meningococcal: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      ppsv: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      dpt: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
      other: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(200)]
      }),
    });
    this.registerFormListener(true);
  }

  public getRow(form: keyof VaccinationHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInput(form: keyof VaccinationHistoryFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: VaccinationHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'VACCINATIONS:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: VaccinationHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: VACCINATIONS`,
      ...list.map(
        v => `${v.title}: ${v.value ? 'YES' : 'NO'}${v.note ? ': ' + v.note : ''}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as VaccinationHistoryFormData;
    data.flu_annual['_modified'] = moment().valueOf();
    data.hepatitis['_modified'] = moment().valueOf();
    data.varicella['_modified'] = moment().valueOf();
    data.pvc['_modified'] = moment().valueOf();
    data.meningococcal['_modified'] = moment().valueOf();
    data.ppsv['_modified'] = moment().valueOf();
    data.dpt['_modified'] = moment().valueOf();
    data.other['_modified'] = moment().valueOf();
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['flu_annual'] = data.flu_annual;
      builderData['hepatitis'] = data.hepatitis;
      builderData['varicella'] = data.varicella;
      builderData['pvc'] = data.pvc;
      builderData['meningococcal'] = data.meningococcal;
      builderData['ppsv'] = data.ppsv;
      builderData['dpt'] = data.dpt;
      builderData['other'] = data.other;
    }
    return builderData;
  }

  private prepareSerializedList(data: VaccinationHistoryFormData) {
    return [
      ...(data.flu_annual ? [{ title: 'Flu Annual', value: data.flu_annual.present, note: data.flu_annual.note }] : []),
      ...(data.hepatitis ? [{ title: 'Hepatitis B >50', value: data.hepatitis.present, note: data.hepatitis.note }] : []),
      ...(data.varicella ? [{ title: 'Varicella', value: data.varicella.present, note: data.varicella.note }] : []),
      ...(data.pvc ? [{ title: 'PCV13/Prevnar13>50', value: data.pvc.present, note: data.pvc.note }] : []),
      ...(data.meningococcal ? [{ title: 'Meningococcal/MCV', value: data.meningococcal.present, note: data.meningococcal.note }] : []),
      ...(data.ppsv ? [{ title: 'PPSV23Pneumovax>60 Zostavax', value: data.ppsv.present, note: data.ppsv.note }] : []),
      ...(data.dpt ? [{ title: 'DPT (every 10yrs)', value: data.dpt.present, note: data.dpt.note }] : []),
      ...(data.other ? [{ title: 'Other', value: data.other.present, note: data.other.note }] : []),
    ].filter(v => v.value !== null);
  }
}
