import { Injectable } from '@angular/core';
import { filter, map, Observable, of, switchMap, take, throwError, timeout } from 'rxjs';
import { Pagination } from '../../types/paginatedResponse';
import { ClinicalNoteService } from './clinical-note.service';
import { UiService } from './ui.service';

const TIMEOUT = 5000;

export type VisitHistory = {
  id: string | number;
  case_id: string | number;
  date: string;
  practitioner: string;
  diagnosis: string;
}

export type InfoDataList<T> = {
  data: T[],
  pagination: Pagination
}

export type InfoDataOptions = {
  page?: number;
  limit?: number;
  sort?: [string, 'asc' | 'desc'];
  search?: string
}

@Injectable({
  providedIn: 'root'
})
export class ClientInfoService {

  constructor(
    private clinicalNoteService: ClinicalNoteService,
    private uiService: UiService
  ) {
  }

  loadVisits(options: InfoDataOptions): Observable<null | InfoDataList<VisitHistory>> {
    return this.uiService.request('client-info', 'visits-list', options).pipe(
      take(1),
      switchMap(res => {
        // validate structure
        if (res !== null && res.data.some((d: InfoDataList<VisitHistory>) => Object.keys(d).sort().join('::') !== ['id', 'case_id', 'date', 'practitioner', 'diagnosis'].sort().join('::'))) {
          return throwError(() => new Error('Invalid Visit Structure'));
        }
        return of(res);
      })
    );
  }

  viewVisit(visit: VisitHistory) {
    this.uiService.message('client-info', 'visits-view', visit);
  }
}
