<div class="container-fluid d-flex flex-column mt-4">
  <div class="row g-0">
    <div class="col ep-collapse-sidebar d-flex justify-content-end align-items-center"
         [ngClass]="{'opened': sidebarOpen, 'closed': !sidebarOpen}"
         >
      <img (click)="toggle()" src="/assets/images/icons/ep-arrow-icons.svg">
      <mat-icon *ngIf="sidebarOpen && !sideBarLocked" (click)="submitViewSetting()">lock_open</mat-icon>
      <mat-icon *ngIf="sidebarOpen && sideBarLocked" (click)="submitViewSetting()">lock</mat-icon>
    </div>
  </div>


  <div class="row me-0 flex-grow-1">
    <div class="col-auto pe-0" *ngIf="!buildersListView">
      <div *ngFor="let section of sidebarNav">
        <div class="btn-sidebar-section" (click)="toggle(section)"
             [ngClass]="{'active': section.alias === currentSectionNav.alias}">
          <mat-icon class="material-icons-outlined">{{ section.icon }}</mat-icon>
          <label>{{ section.title }}</label>
        </div>
      </div>
    </div>
    <div *ngIf="!buildersListView" class="col builder-pane" [ngClass]="{ 'show': sidebarOpen, 'hide': !sidebarOpen }">
      <div class="row">
        <p class="builder-title">{{ currentSectionNav.title }}</p>
        <div class="col-12" *ngIf="currentSectionNav.alias === 'custom' && !orderedGroups.length">
          <p class="text-muted my-2">
            No custom builders have been added yet.<br>
            To set up custom builders, go to <a [routerLink]="['/settings']">Settings</a>.
          </p>
        </div>
        <div class="col-12" *ngFor="let groupAlias of orderedGroups">
          <p *ngIf="groupAlias !== 'default'" class="builder-title">{{ groupAlias }}</p>
          <div class="row">
            <div class="col-6" *ngFor="let builder of groupedBuilders[groupAlias]" cdkDropList>
              <div class="col-6 mb-4">
                <div class="drag-button-container">
                  <button class="btn btn-outline-primary section-builder-btn">
                    {{ builder.title}}
                  </button>
                  <button cdkDrag [cdkDragData]="builder"
                          class="btn btn-outline-primary section-builder-btn drag-button">
                    {{ builder.title}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-5"></div>
      </div>
    </div>

    <div class="col-auto pe-0" *ngIf="buildersListView">
        <div class="btn-sidebar-section active">
          <mat-icon class="material-icons-outlined">list</mat-icon>
          <label>BUILDERS</label>
        </div>
    </div>

    <div *ngIf="buildersListView" class="col builder-pane" [ngClass]="{ 'show': sidebarOpen, 'hide': !sidebarOpen }">
      <div class="row">
        
        <div class="col-12" *ngFor="let group of sidebarNav">
          
          <p *ngIf="group.alias !== 'default'" class="builder-title">{{ group.title }}</p>

          <div class="col-12" *ngIf="group.alias === 'custom'">
            <p class="text-muted my-2">
              No custom builders have been added yet.<br>
              To set up custom builders, go to <a [routerLink]="['/settings']">Settings</a>.
            </p>
          </div>

          
          <div class="row">
            <div class="col-6" *ngFor="let builder of group.builders" cdkDropList>
              <div class="col-6 mb-4">
                <div class="drag-button-container">
                  <button class="btn btn-outline-primary section-builder-btn">
                    {{ builder.title}}
                  </button>
                  <button cdkDrag [cdkDragData]="builder"
                          class="btn btn-outline-primary section-builder-btn drag-button">
                    {{ builder.title}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-5"></div>
      </div>
    </div>
  </div>
</div>