import { Component, inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { CustomBuilderConfigItemModel, CustomBuilderConfigModel, CustomBuilderFieldModel } from 'src/app/shared/models/builder-config.model';
import { SettingsHttpService } from 'src/app/shared/services/http/settings-http.service';
import { TitleStrategy } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { LoadingStateDirective } from 'src/app/shared/directives/loading-state.directive';
import { validateAllFormFields } from 'src/app/shared/validators';

interface CustomBuilderFormData extends BuilderDataAbstract {
  [key: string]: any;
}

@Component({
  selector: 'app-custom-builder',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomBuilderComponent extends AbstractBuilderComponent<CustomBuilderConfigItemModel> {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public builderConfig?: CustomBuilderConfigModel;
  public viewMode: 'readonly' | 'write';
  public initialized: boolean = false;

  private builderDataset?: BuilderData;
  private settingsHttpService = inject(SettingsHttpService);

  constructor() {
    super();
    this.formData = new FormGroup({});
    this.viewMode = this.activeUserService.viewMode === 'review' || this.clinicalNoteService.isReadOnly
      ? 'readonly'
      : 'write';
  }

  override ngOnInit(): void {
    this.loadBuilderConfig();
    super.ngOnInit();
  }

  public title: string = 'Loading...';

  protected override setBuilderData(data: BuilderData): void {
    this.builderDataset = data;
    if (this.initialized) {
      this.formData.patchValue(data);
      if (this.isReadOnly) {
        this.formData.disable();
      }
    }
  }
  protected override getBuilderData(): BuilderData {
    return {
      ...this.formData.value,
      _custom_builder_config: this.config._custom_builder_config
    };
  }
  override serialize(data: CustomBuilderFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: this.builderConfig?.title || 'Loading...',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, this.prepareSerializedList(data)]
    }];
  }
  override asString(data: CustomBuilderFormData): string {
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: ${this.builderConfig?.title}`,
      ...this.prepareSerializedList(data).map(({ label, value }) => `${label}: ${value}`)
    ].join('\n');
  }


  public getControlName(label: string) {
    return encodeURIComponent(label);
  }

  public getControlLabel(label: string) {
    return decodeURIComponent(label);
  }

  public getGroup(label: string): FormGroup {
    return this.formData.get(this.getControlName(label)) as FormGroup;
  }

  public getArray(label: string): FormArray {
    return this.formData.get(this.getControlName(label)) as FormArray;
  }

  public addMultiEntry(control: FormArray, config: CustomBuilderFieldModel) {
    control.push(
      config.inputs.reduce((inputs, input) => {
        inputs.addControl(
          this.getControlName(input.label),
          new FormControl(null, input.required ? [Validators.required] : [])
        );
        return inputs;
      }, new FormGroup({}, config.required ? [Validators.required] : []))
    );
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  private async loadBuilderConfig() {
    if (this.config._custom_builder_config) {
      // pickup load event of this property being set by below else statement
      this.builderConfig = this.config._custom_builder_config;
    } else {
      // load the config from the stored data or fresh from api
      if (this.data && this.data['_custom_builder_config']) {
        this.builderConfig = this.data['_custom_builder_config'];
      } else {
        this.builderConfig = await firstValueFrom(
          this.settingsHttpService.find<CustomBuilderConfigModel>(
            this.config.scope === 'practice' ? this.activeUserService.practiceId! : this.activeUserService.id!,
            this.config.alias,
            undefined,
            this.config.scope
          ).pipe(
            map(v => v.data)
          )
        );
      }
      // tell parent whats been changed
      this.configChange.emit({
        ...this.config,
        title: this.builderConfig!.title,
        label: this.builderConfig!.label,
        _custom_builder_config: this.builderConfig
      });
      return;
    }
    this.title = this.builderConfig!.title;
    this.formData = this.builderConfig!.fields.reduce((fields, field) => {
      if (field.multiple) {
        fields.addControl(
          this.getControlName(field.label),
          new FormArray([
            field.inputs.reduce((inputs, input) => {
              inputs.addControl(
                this.getControlName(input.label),
                new FormControl(null, input.required ? [Validators.required] : [])
              );
              return inputs;
            }, new FormGroup({}, field.required ? [Validators.required] : []))
          ], field.required ? [Validators.required] : [])
        );
      } else {
        fields.addControl(
          this.getControlName(field.label),
          field.inputs.reduce((inputs, input) => {
            inputs.addControl(
              this.getControlName(input.label),
              new FormControl(null, input.required ? [Validators.required] : [])
            );
            return inputs;
          }, new FormGroup({}, field.required ? [Validators.required] : []))
        );
      }
      return fields;
    }, new FormGroup({}));
    this.initialized = true;
    if (this.builderDataset) this.setBuilderData(this.builderDataset);
  }

  private prepareSerializedList(data: CustomBuilderFormData) {
    return this.builderConfig!.fields.reduce((fields, field) => {
      let fieldOutput = { label: field.label, value: `` };
      if (field.multiple) {
        const rows: string[] = [];
        const fieldData: any[] = data[this.getControlName(field.label)];
        fieldData.forEach((fieldRow, idx) => {
          const inputData: string[] = [];
          field.inputs.forEach(input => {
            let value = fieldRow[this.getControlName(input.label)];
            value = Array.isArray(value) ? value.join(',') : value;
            if (value) {
              inputData.push(`${input.label} - ${value}`)
            }
          })
          rows.push(`(#${idx + 1}: ${inputData.join('; ')})`);
        });
        fieldOutput.value += rows.join('  ');
      } else {
        const fieldData = data[this.getControlName(field.label)];
        const inputData: string[] = [];
        field.inputs.forEach(input => {
          let value = fieldData[this.getControlName(input.label)];
          value = Array.isArray(value) ? value.join(',') : value;
          if (value) {
            inputData.push(`${input.label} - ${value}`)
          }
        })
        fieldOutput.value += inputData.join('; ');
      }
      fields.push(fieldOutput);
      return fields;
    }, [] as { label: string, value: string }[])
  }
}
