import { Component } from '@angular/core';
import * as moment from 'moment';
import { AbstractSectionComponent } from '../../shared/components/abstract-section/abstract-section.component';
import { validateFormField } from '../../../../shared/validators';

@Component({
  selector: 'noteSection-examination',
  templateUrl: './examination-section.component.html',
  styleUrls: ['../../shared/components/abstract-section/abstract-section.component.scss', './examination-section.component.scss']
})
export class ExaminationSectionComponent extends AbstractSectionComponent {

  public override async validate(): Promise<boolean> {
    validateFormField(this.note);
    return this.note.valid;
  }

  public override async save(): Promise<boolean | null> {

    if (this.note.value && this.note.value !== '') {
      this.data.notes = [...(this.data.notes || []), {
        author: this.activeUserService.user!,
        _modified: moment().valueOf(),
        content: this.note.value,
      }];
    }

    return super.save();
  }

}
