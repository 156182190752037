import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { BodyRegions, CNSExtent, HeadacheTypes } from './cns-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

export interface CNSHistoryFormData extends BuilderDataAbstract {
  headache: {
    present: boolean;
    type: string;
    note: string;
    _modified: number;
  };
  neck_stiffness: {
    present: boolean;
    duration_days: number;
    note: string;
    _modified: number;
  };
  weakness: {
    present: boolean;
    extent: string;
    regions: Array<string>;
    note: string;
    _modified: number;
  };
  dizziness: {
    present: boolean;
    note: string;
    _modified: number;
  };
  frequency: string;
}

@Component({
  selector: 'app-cns-history',
  templateUrl: './cns-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './cns-history.component.scss']
})
export class CnsHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public constant: boolean = false;
  public intermittent: boolean = false;
  public showFrequency: boolean = false;
  public extent: Array<string> = CNSExtent();
  public types: Array<string> = HeadacheTypes();
  public regions: Array<{ id: number, name: string }> = BodyRegions();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      headache: this.fb.group({
        present: [null],
        type: [null],
        note: [null, Validators.maxLength(500)]
      }),
      neck_stiffness: this.fb.group({
        present: [null],
        duration_days: [null],
        note: [null, Validators.maxLength(500)]
      }),
      weakness: this.fb.group({
        present: [null],
        extent: [null],
        regions: [],
        note: [null, Validators.maxLength(500)]
      }),
      dizziness: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      frequency: [null]
    });
    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  get durationUnit() {
    return (this.formData.get('neck_stiffness') as FormGroup).get('duration_days')?.value === 1 ? 'day' : 'days';
  }

  public getRow(form: keyof CNSHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof CNSHistoryFormData) {
    this.getRow(form).reset();
  }

  public resolveFrequency(frequency: 'constant' | 'intermittent' | null) {
    switch (frequency) {
      case 'constant':
        this.formData.get('frequency')?.patchValue('constant');
        this.constant = true;
        this.intermittent = false;
        break;
      case 'intermittent':
        this.formData.get('frequency')?.patchValue('intermittent');
        this.intermittent = true;
        this.constant = false;
        break;
      case null:
        this.formData.get('frequency')?.patchValue(null);
        this.intermittent = false;
        this.constant = false;
        break;
    }
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: CNSHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'CNS HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: CNSHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: CNS HISTORY`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.title !== 'Frequency' ? `${v.value ? 'Present' : 'Not Present'}${v.note}` : `[${`${v.value}`.split(' ').map(v => v[0].toUpperCase() + v.slice(1)).join(' ')}`}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as CNSHistoryFormData;
    data.headache['_modified'] = moment().valueOf();
    data.neck_stiffness['_modified'] = moment().valueOf();
    data.weakness['_modified'] = moment().valueOf();
    data.dizziness['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['headache'] = data.headache;
      builderData['neck_stiffness'] = data.neck_stiffness;
      builderData['weakness'] = data.weakness;
      builderData['dizziness'] = data.dizziness;
      builderData['frequency'] = data.frequency;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    this.resolveFrequency(data['frequency']);
    super.setBuilderData(data);
  }

  override registerFormListener() {
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .filter(c => c instanceof FormGroup && c.get('present'))
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: control => {
        switch (control.get('present')?.value) {
          case true:
          case false:
            control.get('note')?.enable({ emitEvent: false });
            control.get('type')?.enable({ emitEvent: false });
            control.get('duration_days')?.enable({ emitEvent: false });
            control.get('extent')?.enable({ emitEvent: false });
            control.get('regions')?.enable({ emitEvent: false });
            break;
          case null:
            control.get('note')?.disable({ emitEvent: false });
            control.get('type')?.disable({ emitEvent: false });
            control.get('duration_days')?.disable({ emitEvent: false });
            control.get('extent')?.disable({ emitEvent: false });
            control.get('regions')?.disable({ emitEvent: false });
            control.get('note')?.patchValue('', { emitEvent: false });
            control.get('type')?.patchValue(null, { emitEvent: false });
            control.get('duration_days')?.patchValue(null, { emitEvent: false });
            control.get('extent')?.patchValue(null, { emitEvent: false });
            control.get('regions')?.patchValue([[]], { emitEvent: false });
            break;
        }
      }
    });

    //disable frequency if dizziness is not present
    this.formData.get('dizziness')?.valueChanges.subscribe(c => {
      if (c.present) {
        this.formData.get('frequency')?.enable({ emitEvent: false });
        this.showFrequency = true;
      } else {
        this.formData.get('frequency')?.disable({ emitEvent: false });
        this.formData.get('frequency')?.patchValue(null, { emitEvent: false });
        this.resolveFrequency(null);
        this.showFrequency = false;
      }
    });
  }

  private prepareSerializedList(data: CNSHistoryFormData) {
    return [
      {
        title: 'Headache',
        value: data.headache.present,
        note: `${data.headache.type ? ': Type - ' + data.headache.type : ''}${data.headache.note ? '; ' + data.headache.note : ''}`
      },
      {
        title: 'Neck Stiffness',
        value: data.neck_stiffness.present,
        note: `${data.neck_stiffness.duration_days ? ': Length - ' + data.neck_stiffness.duration_days + ' ' + this.durationUnit : ''}${data.neck_stiffness.note ? '; ' + data.neck_stiffness.note : ''}`
      },
      {
        title: 'Weakness',
        value: data.weakness.present,
        note: `${data.weakness.extent ? ': Extent - ' + data.weakness.extent : ''}${data.weakness.regions && data.weakness.regions.length ? '; Body Region(s) ' + this.getJoinedList(data.weakness.regions) : ''}${data.weakness.note ? '; ' + data.weakness.note : ''}`
      },
      {
        title: 'Dizziness',
        value: data.dizziness.present,
        note: `${data.dizziness.note ? ': ' + data.dizziness.note : ''}`
      },
      {
        title: 'Frequency',
        value: data.frequency ? data.frequency : null,
        note: ''
      },
    ].filter(v => v.value !== null);
  }
}
