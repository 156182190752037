import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, finalize, firstValueFrom, map, of, Subscription, tap } from 'rxjs';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { UiService } from 'src/app/shared/services/common/ui.service';


@Component({
  selector: 'ui-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})

export class NotesComponent implements OnInit, OnDestroy {

  @Input() topOffset!: number;
  public sections!: SectionConfigModel[];
  public isSaving: boolean = false;
  public showNewPatientMessage: boolean = false;

  private subscriptions: Subscription[] = [];
  constructor(
    private clinicalNoteService: ClinicalNoteService,
    private uiService: UiService,
    private dialogService: DialogService,
    private loading: LoadingStateService
  ) {
    this.subscriptions.push(
      this.clinicalNoteService.config.subscribe(res => this.sections = res.sections)
    );
    this.showNewPatientMessage = !this.clinicalNoteService.isExistingPatient;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submit() {
    this.dialogService.show({
      variant: 'confirm',
      title: 'Finalise Clinical Notes',
      message: [
        'Are you sure you want to finalise these clinical notes?',
        'Once finalised, builder data will be saved to the patient EHR and these notes will become uneditable.'
      ],
      actions: [{
        text: 'Cancel',
        class: 'btn-outline-danger',
        action: async () => true
      }, {
        text: 'Confirm',
        class: 'btn-outline-success',
        action: async () => {
          return await firstValueFrom(this.submitNotes().pipe(
            map(() => true),
            tap(() => this.dialogService.show({
              variant: 'success',
              title: 'Finalised Clinical Notes Submitted Successfully!'
            })),
            catchError(() => of(false))
          ));
        }
      }]
    })
  }

  private submitNotes() {
    this.loading.start('builder-control');
    this.isSaving = true;
    return this.clinicalNoteService.submit().pipe(
      finalize(() => {
        this.loading.end('builder-control');
        this.isSaving = false;
      })
    );
  }

}
