import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateAllFormFields } from '../../../../shared/validators';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { RESPExamRegions } from './respiratory-exam.config';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

type RespDynamicRows = 'percussion' | 'auscultation';

type RespData = {
  present: boolean;
  regions: Array<string>;
  note: string;
};

export interface RespExamFormData extends BuilderDataAbstract {
  trachea: BuilderPresentNote & { tug: boolean };
  percussion: BuilderPresentNote & { data: Array<RespData> };
  auscultation: BuilderPresentNote & { data: Array<RespData> };
}

@Component({
  selector: 'app-respiratory-exam',
  templateUrl: './respiratory-exam.component.html',
  styleUrls: ['./respiratory-exam.component.scss']
})
export class RespiratoryExamComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public regions: Array<{ name: string, value: number }> = RESPExamRegions();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      trachea: this.fb.group({
        present: [null],
        tug: [null],
        note: [null, Validators.maxLength(500)]
      }),
      percussion: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([])
      }),
      auscultation: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([]),
      })
    });
    this.addRow('percussion');
    this.addRow('auscultation');

    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  public getRow(form: keyof RespExamFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: keyof RespExamFormData, idx: number) {
    this.getRow(form).removeAt(idx);
  }

  public clearInputs(form: keyof RespExamFormData) {
    this.getRow(form).reset();
  }

  public getDataCount(alias: RespDynamicRows): number {
    return ((this.formData.get(alias) as FormGroup).get('data') as FormArray).length;
  }

  public deleteRow(form: RespDynamicRows) {
    return (idx: number) => {
      ((this.formData.get(form) as FormGroup).get('data') as FormArray).removeAt(idx);
      if (((this.formData.get(form) as FormGroup).get('data') as FormArray).length === 0) {
        this.addRow(form);
      } else {
        const present = this.isPresent<RespDynamicRows>(form);
        (this.formData.get(form) as FormGroup).get('present')?.patchValue(!present);
        setTimeout(() => (this.formData.get(form) as FormGroup).get('present')?.patchValue(present), 0);
      }
    };
  }

  public addRow(form: RespDynamicRows) {
    const rawData = {
      percussion: {
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)]
      },
      auscultation: {
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)]
      }
    };

    const group = this.fb.group(rawData[form]);
    ((this.formData.get(form) as FormGroup).get('data') as FormArray).push(group);
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: RespExamFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'RESPIRATORY EXAM:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: RespExamFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: RESPIRATORY EXAM`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.present ? 'Present' : 'Not Present'}${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as RespExamFormData;
    data.trachea['_modified'] = moment().valueOf();
    data.percussion['_modified'] = moment().valueOf();
    data.auscultation['_modified'] = moment().valueOf();

    Object.entries(data).forEach(([control, controlValue]) => {
      // remove data objects when not present
      if (data[control as RespDynamicRows]['data'] && !this.isPresent<RespDynamicRows>(control as RespDynamicRows)) {
        (data[control as RespDynamicRows]).data = [];
      }
    });

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['trachea'] = data.trachea;
      builderData['percussion'] = data.percussion;
      builderData['auscultation'] = data.auscultation;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    Object.entries(data).forEach(([key, value]) => {
      if (value && value.present) {
        if (data[key] && data[key].data && data[key].data.length) {
          for (let i = 1; i < data[key].data.length; i++) {
            this.addRow(key as RespDynamicRows);
          }
        }
      }
    });
    super.setBuilderData(data);
  }

  override registerFormListener() {
    // clear trachea inputs when required
    (this.formData.get('trachea') as FormGroup)?.get('present')?.valueChanges.subscribe(present => {
      switch (present) {
        case true:
          (this.formData.get('trachea') as FormGroup).get('tug')?.enable({ emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('note')?.enable({ emitEvent: false });
          break;
        case false:
          (this.formData.get('trachea') as FormGroup).get('note')?.enable({ emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('tug')?.disable({ emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('tug')?.patchValue(null, { emitEvent: false });
          break;
        case null:
          (this.formData.get('trachea') as FormGroup).get('tug')?.disable({ emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('note')?.disable({ emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('tug')?.patchValue(null, { emitEvent: false });
          (this.formData.get('trachea') as FormGroup).get('note')?.patchValue(null, { emitEvent: false });
          break;
      }
    });
  }

  private prepareSerializedList(data: RespExamFormData) {
    let percussionValue, auscultationValue;

    const getControlPreview = (control: BuilderPresentNote & { data: Array<RespData> }) => {
      if (control.present && control.data && control.data.length) {
        if (control.data.length === 1 && !control.data[0].note && control.data[0].regions.length === 0) {
          return '';
        } else {
          return control.data
            .map((c, i) => ` [${'(' + (i + 1) + ') '}${this.getJoinedList(c.regions)}${c.note ? ' - ' + c.note : ''}]`)
            .join('; ');
        }
      }
      return '';
    };

    percussionValue = getControlPreview(data.percussion);
    auscultationValue = getControlPreview(data.auscultation);

    const list: Array<{ title: string, present: boolean, value: string | null | undefined }> = [
      {
        title: 'Trachea',
        present: data.trachea.present,
        value: `${(data.trachea.tug !== null || data.trachea.note) ? ' [' : ''}${data.trachea.tug !== null ? data.trachea.tug ? 'Tug - Yes' : 'Tug - No' : ''}${data.trachea.note ? data.trachea.tug !== null ? '; ' + data.trachea.note : data.trachea.note : ''}${(data.trachea.tug !== null || data.trachea.note) ? ']' : ''}`
      },
      {
        title: 'Percussion',
        present: data.percussion.present,
        value: percussionValue ? percussionValue : `${data.percussion.present === false && data.percussion.note ? ' [' + data.percussion.note + ']' : ''}`
      },
      {
        title: 'Auscultation',
        present: data.auscultation.present,
        value: auscultationValue ? auscultationValue : `${data.auscultation.present === false && data.auscultation.note ? ' [' + data.auscultation.note + ']' : ''}`
      }
    ].filter(v => v.present !== null);

    return list;
  }
}
