<div class="container-fluid">
  <div class="modal-header">
    <h4 class="modal-title attachments-modal-title">Upload Attachment</h4>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <label>Attachment Type</label>
        <select jargonLoadingState="attachment-modal" type="text" class="form-control" [disabled]="disableToggle"
                [(ngModel)]="attachmentType"
                (ngModelChange)="attachmentTypeToggle()">
          <option hidden disabled [ngValue]="null">Select</option>
          <option *ngFor="let type of types" [ngValue]="type.alias">{{type.title}}</option>
        </select>
      </div>
    </div>
    <ng-container [ngSwitch]="attachmentType" [formGroup]="formData">
      <div *ngSwitchCase="'imaging'" formGroupName="imaging">
        <div class="row mb-3">
          <div class="form-group col-6" formGroupName="radiologist">
            <label>Radiologist</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="name">
          </div>
          <div class="form-group col-6">
            <label>Imaging Type</label>
            <select jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="type">
              <option hidden disabled [ngValue]="null">Select</option>
              <option value="CT Scan">CT Scan</option>
              <option value="MRI">MRI</option>
              <option value="Nuclear">Nuclear Imaging</option>
              <option value="PET Scan">PET Scan</option>
              <option value="Ultrasound">Ultrasound</option>
              <option value="X-Ray">X-Ray</option>
            </select>
          </div>
        </div>
        <div class="row mb-3>">
          <div class="form-group col-12">
            <label>Body Regions(s)</label>
            <ng-select jargonLoadingState="attachment-modal" [items]="regions"
                       [multiple]="true"
                       placeholder="Body Region(s)"
                       [hideSelected]="true"
                       formControlName="regions"
                       bindLabel="name"
                       bindValue="name">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date</label>
            <input jargonLoadingState="attachment-modal" class="form-control" [maxDate]="maxDateNow"
                   formControlName="date_time" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Notes/Comments</label>
            <textarea type="text" class="form-control" [formControl]="noteControl"></textarea>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'labs'" formGroupName="labs">
        <div class="row mb-3">
          <div class="form-group col-6" formGroupName="pathology_lab">
            <label>Pathology Lab</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control"
                   formControlName="name">
          </div>
          <div class="form-group col-6">
            <label>Test Category</label>
            <select jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="type">
              <option disabled [ngValue]="null">Select</option>
              <option *ngFor="let type of testCategories" [ngValue]="type.alias">{{type.title}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Test(s) Run</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control"
                   formControlName="tests">
          </div>
          <div class="form-group col-6">
            <label>Date of Test</label>
            <input jargonLoadingState="attachment-modal" class="form-control" [maxDate]="maxDateNow"
                   formControlName="date_time" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date of Result</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control"
                   formControlName="result_date_time" [maxDate]="maxDateNow" ngbDatepicker
                   #attachmentResultDate="ngbDatepicker" (click)="attachmentResultDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Summary of Results</label>
            <textarea type="text" class="form-control" placeholder="Type here" formControlName="summary"></textarea>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'photo'" formGroupName="photo">
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date</label>
            <input jargonLoadingState="attachment-modal" class="form-control" [maxDate]="maxDateNow"
                   formControlName="date_time" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Notes/Comments</label>
            <textarea type="text" class="form-control" [formControl]="noteControl"></textarea>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'referral_letter'" formGroupName="referral_letter">
        <div class="row mb-3">
          <div class="form-group col-12" formGroupName="referring_doctor">
            <label>Referring Practitioner</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="name">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date</label>
            <input jargonLoadingState="attachment-modal" class="form-control" [maxDate]="maxDateNow"
                   formControlName="date_time" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Notes/Comments</label>
            <textarea type="text" class="form-control" [formControl]="noteControl"></textarea>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'script'" formGroupName="script">
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Script Type</label>
            <select jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="type">
              <option disabled [ngValue]="null">Select</option>
              <option value="repeat">Repeat</option>
              <option value="single">Single</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date of Script</label>
            <input jargonLoadingState="attachment-modal" class="form-control" formControlName="date_time"
                   [maxDate]="maxDateNow" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Notes/Comments</label>
            <textarea type="text" class="form-control" [formControl]="noteControl"></textarea>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'other'" formGroupName="other">
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Name/Type</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="name">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-6">
            <label>Reason</label>
            <input jargonLoadingState="attachment-modal" type="text" class="form-control" formControlName="reason">
          </div>
          <div class="form-group col-6">
            <label>Date</label>
            <input jargonLoadingState="attachment-modal" class="form-control" formControlName="date_time"
                   [maxDate]="maxDateNow" ngbDatepicker
                   #attachmentDate="ngbDatepicker"
                   (click)="attachmentDate.toggle()">
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-12">
            <label>Notes/Comments</label>
            <textarea type="text" class="form-control" [formControl]="noteControl"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="attachmentType">
      <div class="form-group col-12">
        <jargon-dropzone [(files)]="attachments"></jargon-dropzone>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button jargonLoadingState="attachment-modal" type="button" class="btn btn-outline-primary me-2"
            (click)="modalRef.close('cancel')">Cancel</button>
    <button jargonLoadingState="attachment-modal" *ngIf="attachmentType" type="button" class="btn btn-primary"
            (click)="submit()">Submit</button>
  </div>
</div>