<div class="container-fluid py-4" [formGroup]="formData">

  <div class="row">

    <div class="col-6">
      <div class="row mb-3">
        <div class="col-12 col-lg-4 ">
          <label class="form-label">Pulse</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="pulse">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">bpm</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Respiratory Rate</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="respiratory_rate">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">RR/min</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Blood Pressure</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="blood_pressure">
          <input type="text" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">mmHG</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Height</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="height">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">cm</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Calculated BMI:</label>
        </div>
        <div class="col-12 col-lg-8">{{ bmi }}</div>
      </div>
    </div>

    <div class="col-6">
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Blood Glucose</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="blood_glucose">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">mmol/I</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Oxygen Saturation (SpO2)</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="oxygen_saturation">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">SpO2</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Body Temperature</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" style="max-width: 310px;" formGroupName="temperature">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">C</span>
          <select type="text" class="form-control" formControlName="region" jargonLoadingState="builder-control">
            <option hidden disabled value="null">Region</option>
            <option value="Axilla">Axilla</option>
            <option value="Ear">Ear</option>
            <option value="Rectal">Rectal</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-4">
          <label class="form-label">Weight</label>
        </div>
        <div class="col-12 col-lg-8 input-group w-auto" formGroupName="weight">
          <input type="number" class="form-control" formControlName="value"
                 jargonLoadingState="builder-control">
          <span class="input-group-text" style="width: 80px">kg</span>
        </div>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="!!data.pulse">
    <strong>Pulse: </strong>
    <span>{{data.pulse.value + data.pulse.unit }}</span>
  </p>
  <p *ngIf="!!data.respiratory_rate">
    <strong>RR: </strong>
    <span>{{data.respiratory_rate.value + data.respiratory_rate.unit}}</span>
  </p>
  <p *ngIf="!!data.oxygen_saturation">
    <strong>SpO2: </strong>
    <span>{{data.oxygen_saturation.value + data.oxygen_saturation.unit}}</span>
  </p>
  <p *ngIf="!!data.blood_pressure">
    <strong>BP: </strong>
    <span>{{data.blood_pressure.value + data.blood_pressure.unit }}</span>
  </p>
  <p *ngIf="!!data.blood_glucose">
    <strong>Blood Glucose: </strong>
    <span>{{data.blood_glucose.value + data.blood_glucose.unit }}</span>
  </p>
  <p *ngIf="!!data.temperature">
    <strong>Body Temp: </strong>
    <span>
      {{
      data.temperature.value + data.temperature.unit
      + (data.temperature.region ? ' - ' + data.temperature.region:'')
      }}
    </span>
  </p>
  <p *ngIf="!!data.height">
    <strong>Height: </strong>
    <span>{{data.height.value + data.height.unit }}</span>
  </p>
  <p *ngIf="!!data.weight">
    <strong>Weight: </strong>
    <span>{{data.weight.value + data.weight.unit }}</span>
  </p>
  <p *ngIf="!!data.bmi">
    <strong>Calculated BMI: </strong>
    <span>{{ data.bmi }}</span>
  </p>
</ng-template>