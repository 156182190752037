import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const validateAllFormFields = (formGroup: FormGroup | FormArray) => {
  Object.keys(formGroup.controls).forEach((field, index) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup || control instanceof FormArray) {
      validateAllFormFields(control);
    }
  });
};

export const validateFormField = (control: FormControl) => {
  control.markAsTouched({ onlySelf: true });
};
