import { Injectable } from '@angular/core';
import { JwtTokenModel } from '../../models/jwt-token.model';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';
import { ViewMode } from '../../types/util';

export class InvalidIntegrationSetupError extends Error { }

@Injectable({
  providedIn: 'root'
})
export class ActiveUserService {
  private initialisationError: Error | null = null;
  private userData?: UserModel;
  private tokenData?: JwtTokenModel;
  private initialised: boolean = false;
  private initialisedListeners: Array<(err: Error | null) => void> = [];
  private refreshTimeout: any;
  private tokenRefreshing?: Promise<boolean>;

  constructor(private http: HttpClient) { }

  public get id(): string | number | null {
    return this.userData?.id || null;
  }

  public get practiceId(): string | number | null {
    return this.claims ? this.claims['pid'] : null;
  }

  public get user(): UserModel | null {
    return this.userData || null;
  }

  public get token(): string | null {
    return this.tokenData?.token || null;
  }

  public get namespace(): string | null {
    return (this.tokenData?.claims['namespace'] || null) as string | null;
  }

  public get viewMode(): ViewMode {
    return this.claims!['mode'] as ViewMode;
  }

  public get claims(): { [key: string]: string | number } | null {
    return this.tokenData?.claims || null;
  }

  public async loadActiveUser(force: boolean = false): Promise<UserModel | null> {
    if (this.initialised && !force) {
      return this.user;
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    await this.refreshSession(params.get('token'))
      .then(() => {
        this.initialised = true;
        this.initialisedListeners.forEach(cb => cb(null));
      })
      .catch(err => {
        this.initialised = false;
        this.initialisedListeners.forEach(cb => cb(err));
      });
    return this.user;
  }

  public async isAuthed(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.onInitialised(() => {
        return resolve(!!this.user);
      });
    });
  }

  public setToken(token: string) {
    this.tokenData = this.parseToken(token);
    this.queueRefresh();
  }

  public onInitialised(cb: (error: Error | null) => void) {
    if (this.initialised || this.initialisationError) {
      cb(this.initialisationError);
      return;
    }
    this.initialisedListeners.push(cb);
  }

  public logout() {
    this.clearSession();
    window.close();
  }

  public async refreshSession(token: string | null = null): Promise<boolean> {
    if (this.tokenRefreshing) return this.tokenRefreshing;
    this.tokenRefreshing = firstValueFrom(
      this.http.post<{ token: string }>(`${environment.api}/auth/token`, {
        token: token ? token : this.token
      })
    ).then(async (res) => {
      this.tokenRefreshing = undefined;
      const { user: userData } = this.parseMetaFromToken(res.token as string);
      this.userData = userData as UserModel;
      this.setToken(res.token as string);
      return true;
    }).catch((err) => {
      console.error(err);
      this.tokenRefreshing = undefined;
      this.logout();
      return false;
    });
    return this.tokenRefreshing;
  }

  private clearSession() {
    this.userData = undefined;
    this.tokenData = undefined;
  }

  private parseToken(token: string): JwtTokenModel {
    return {
      token,
      claims: JSON.parse(atob(token.split('.')[1])) as { [key: string]: string | number }
    };
  }

  private queueRefresh() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    if (this.claims) {
      const timeout = Math.abs(
        moment.unix(this.claims['exp'] as number)
          .subtract(3, 'minutes')
          .diff(moment(), 'milliseconds')
      );
      this.refreshTimeout = setTimeout(() => {
        this.refreshSession();
      }, timeout);
    }
  }

  public parseMetaFromToken(tokenString?: string) {
    const token = tokenString ? tokenString : this.token as string;
    const payload = JSON.parse(atob(token.split('.')[1])) as { [key: string]: string | number }
    const iss = payload['iss'];
    const usedSubKeys = new Set<string>();
    return Object.keys(payload).reduce((out, key) => {
      if (key === 'sub') {
        out['user']['id'] = payload[key];
      } else if (['role', 'name'].includes(key)) {
        out['user'][key] = payload[key];
      } else {
        const match = key.match(new RegExp(`${iss}\/([A_Za-z0-9-_]+)\/?([A_Za-z0-9-_\/]+)?`));
        if (match && !!match[1]) {
          if (usedSubKeys.has(match[1]) || typeof out[match[1]] === 'undefined') {
            usedSubKeys.add(match[1]);
            if (match[2]) {
              // set nested
              const setDeep = (keys: string[], ref: { [key: string]: any }, value: any) => {
                const refKey = keys.shift();
                if (keys.length) {
                  setDeep(keys, ref[refKey!], value);
                  return;
                }
                ref[refKey!] = value;
              };

              if (typeof out[match[1]] === 'undefined') out[match[1]] = {};
              setDeep(
                (match[2]).split('/'),
                out[match[1]],
                payload[key]
              );
            } else {
              // set top level
              out[match[1]] = payload[key];
            }
          }
        }
      }
      return out;
    }, { user: {} } as any);
  }
}

export function ActiveUserServiceFactory(provider: ActiveUserService) {
  return () => provider.loadActiveUser();
}
