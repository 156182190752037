<div class="container-fluid py-4" [formGroup]="formData">

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end screening-gender-toggle">
      <mat-label class="form-label mat-slide-toggle-content me-2" (click)="genderToggle(false)">Male</mat-label>
      <mat-slide-toggle (change)="genderToggle($event.checked)"
                        [color]="'primary'"
                        [checked]="isFemale"></mat-slide-toggle>
      <mat-label class="form-label mat-slide-toggle-content ms-2" (click)="genderToggle(true)">Female</mat-label>
    </div>
  </ng-container>
  <div class="col-12 mb-4">
    <!--Skin-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Skin</label>
      </div>
      <div class="col" formGroupName="skin">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('skin')"
                            [count]="getDataCount('skin')"
                            (remove)="deleteRow('skin')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker
                       [maxDate]="maxDateNow" container="body"
                       #msDate="ngbDatepicker" jargonLoadingState="builder-control" (click)="msDate.toggle()">
                <input type="text" class="form-control" placeholder="Practitioner Name"
                       jargonLoadingState="builder-control"
                       formControlName="practitioner_name">
                <input type="text" class="form-control" jargonLoadingState="builder-control" placeholder="Results"
                       formControlName="note">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index" let-disabled="disabled">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('skin')" class="btn btn-outline-primary" (click)="addRow('skin')">Add+</button>
        </ng-container>
      </div>
    </div>

    <!--Colonoscopy-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Colorectal screening</label>
      </div>
      <div class="col" formGroupName="colonoscopy">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('colonoscopy')"
                            [count]="getDataCount('colonoscopy')"
                            (remove)="deleteRow('colonoscopy')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker
                       [maxDate]="maxDateNow" container="body"
                       #msDate="ngbDatepicker" (click)="msDate.toggle()" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Practitioner Name"
                       formControlName="practitioner_name" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Results" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('colonoscopy')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('colonoscopy')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Prostate-->
    <div *ngIf="formData.get('prostate')" class="row mb-3">
      <div class="col-2">
        <label class="form-label">Prostate</label>
      </div>
      <div class="col" formGroupName="prostate">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('prostate')"
                            [count]="getDataCount('prostate')"
                            (remove)="deleteRow('prostate')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker
                       [maxDate]="maxDateNow" container="body"
                       #msDate="ngbDatepicker" (click)="msDate.toggle()" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Practitioner Name"
                       formControlName="practitioner_name" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Results" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('prostate')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('prostate')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Pap Smear-->
    <div *ngIf="formData.get('papSmear')" class="row mb-3">
      <div class="col-2">
        <label class="form-label">Pap Smear</label>
      </div>
      <div class="col" formGroupName="papSmear">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('papSmear')"
                            [count]="getDataCount('papSmear')"
                            (remove)="deleteRow('papSmear')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker
                       [maxDate]="maxDateNow" container="body"
                       #msDate="ngbDatepicker" (click)="msDate.toggle()" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Practitioner Name"
                       jargonLoadingState="builder-control"
                       formControlName="practitioner_name">
                <input type="text" class="form-control" placeholder="Results" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('papSmear')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('papSmear')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Mammogram-->
    <div *ngIf="formData.get('mammogram')" class="row mb-3">
      <div class="col-2">
        <label class="form-label">Mammogram</label>
      </div>
      <div class="col" formGroupName="mammogram">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('mammogram')"
                            [count]="getDataCount('mammogram')"
                            (remove)="deleteRow('mammogram')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker
                       [maxDate]="maxDateNow" container="body"
                       #msDate="ngbDatepicker" (click)="msDate.toggle()" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Practitioner Name"
                       formControlName="practitioner_name" jargonLoadingState="builder-control">
                <input type="text" class="form-control" placeholder="Results" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('mammogram')" class="btn btn-outline-primary"
                  (click)="addRow('mammogram')">Add+
          </button>
        </ng-container>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let n = index">
    <strong>{{item.title}} </strong>
    <ng-container *ngIf="item.value; else comment">
      <span *ngFor="let c of item.itemData; let i = index;">[({{i + 1}}): {{c.date | date: 'YYYY-MM-dd'}},
        {{c.practitioner_name}}{{c.note ? ', ' + c.note : ''}}]{{ i === item.itemData.length - 1 ? '' : '; '}}</span>
    </ng-container>
    <ng-template #comment>[<span>{{item.note}}</span>]</ng-template>
  </p>
</ng-template>