import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';

export interface Toast {
    content: string | TemplateRef<any>;
    variant?: 'success' | 'info' | 'warning' | 'danger';
    classname?: string;
    delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
    toasts: Toast[] = [];

    show(content: string | TemplateRef<any>, options: Omit<Toast, 'content'> = {}) {
        if (options.variant) {
            options.classname = Array.from(
                new Set([
                    ...(options.classname || '').split(' '),
                    ...{
                        'success': ['bg-success', 'text-dark'],
                        'info': ['bg-info', 'text-light'],
                        'warning': ['bg-warning', 'text-dark'],
                        'danger': ['bg-danger', 'text-light'],
                    }[options.variant]
                ])
            ).join(' ');
        }
        this.toasts.push({ content, ...options });
    }

    httpError(error: HttpErrorResponse, prefix: string = '') {
        let content = prefix;
        switch (error.status) {
            case 422:
                content += 'Validation Errors';
                break;
            default:
                content += 'Error';
        }

        if (error?.error.errors) {
            Object.values(error?.error.errors as { string: string }[]).forEach((errObj: { string: string }) => {
                Object.values(errObj).forEach(v => content += '\n' + v);
            }, []);
        } else if (error?.error.message) {
            content += '\n' + error?.error.message;
        }

        this.show(content, { variant: 'danger' });
    }

    remove(toast: Toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    clear() {
        this.toasts.splice(0, this.toasts.length);
    }
}