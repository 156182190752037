import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, concat, distinctUntilChanged, filter, finalize, firstValueFrom, from, map, mapTo, of, switchMap, tap, throwError } from 'rxjs';
import { BuilderConfigModel, CustomBuilderConfigModel, CustomBuilderListModel, CustomBuilderMetaModel, CustomInputType } from 'src/app/shared/models/builder-config.model';
import { SettingsModel } from 'src/app/shared/models/settings.model';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import { CUSTOM_BUILDER_LIST_ALIAS, ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { SettingsHttpService, SettingsTypes } from 'src/app/shared/services/http/settings-http.service';

class CustomBuilderAlreadyExistsError extends Error {
  override message: string = 'Builder Already Exists';
}
@Component({
  selector: 'app-custom-builder-setup',
  templateUrl: './custom-builder-setup.component.html',
  styleUrls: ['./custom-builder-setup.component.scss']
})
export class CustomBuilderSetupComponent implements OnInit, OnDestroy {

  public builderConfig?: CustomBuilderConfigModel;
  public form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(1)]),
    label: new FormControl('', [Validators.required, Validators.minLength(1)]),
    alias: new FormControl(null, [Validators.required, Validators.pattern(/^_custom-builder::[a-z0-9-]{4,}$/)]),

    scope: new FormControl('user', [Validators.required, Validators.pattern(/^user|practice$/)]),
    group: new FormControl(null),
    active: new FormControl(true, [Validators.required]),

    fields: new FormArray([], [Validators.required, Validators.minLength(1)])
  });

  private oldScope?: SettingsTypes;
  private isCreate: boolean = true;
  private isSaving: boolean = false;
  private subscriptions: Subscription[] = [];

  get fieldsControl() {
    return (this.form.get('fields') as FormArray);
  }

  constructor(
    private route: ActivatedRoute,
    private activeUserService: ActiveUserService,
    private settingsHttpService: SettingsHttpService,
    private loading: LoadingStateService,
    private clinicalNoteService: ClinicalNoteService,
    private router: Router
  ) {
    this.subscriptions.push(
      this.route.params.pipe(
        map(({ scope, alias }) => ({ scope, alias })),
        distinctUntilChanged(),
        switchMap(
          ({ scope, alias }) => (scope && alias)
            ? this.settingsHttpService.find<CustomBuilderConfigModel>(scope === 'practice' ? this.activeUserService.practiceId! : this.activeUserService.id!, alias, undefined, scope).pipe(
              map(d => d.data),
              catchError(err => err.status === 404 ? of(null) : throwError(() => err)),
            )
            : of(null)
        ),
        tap(config => {
          this.isCreate = !config;
          if (config) {
            this.oldScope = config.scope;
          }
          this.builderConfig = config || this.emptyConfig();
          this.buildFormFromConfig();
        })
      ).subscribe()
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getScopedId(scope: SettingsTypes | undefined) {
    if (scope == 'practice') {
      return this.activeUserService.practiceId!;
    }
    return this.activeUserService.id!;
  }

  public async save() {
    if (this.isSaving) return;
    this.isSaving = true;
    const formData: CustomBuilderConfigModel = this.form.getRawValue();
    this.builderConfig = Object.assign(this.builderConfig!, formData);

    const scopeChanged = this.oldScope && this.oldScope !== this.builderConfig!.scope;

    if (this.isCreate) {
      this.generateAlias();
    }

    this.loading.start('custom-builder-setup');
    await firstValueFrom(
      (
        this.isCreate || scopeChanged
          ? this.settingsHttpService.create<CustomBuilderConfigModel>(this.getScopedId(this.builderConfig!.scope), this.builderConfig!.alias, this.builderConfig!, undefined, this.builderConfig!.scope)
          : this.settingsHttpService.update(this.getScopedId(this.builderConfig!.scope), this.builderConfig!.alias, this.builderConfig!, undefined, this.builderConfig!.scope)
      ).pipe(
        switchMap(() => this.settingsHttpService.find<CustomBuilderListModel>(this.getScopedId(this.builderConfig!.scope), CUSTOM_BUILDER_LIST_ALIAS, undefined, this.builderConfig!.scope).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              return of(null);
            }
            return throwError(() => err);
          })
        )),
        switchMap((res) => {
          const data = res ? res.data : { builders: [] };
          const asMeta = this.clinicalNoteService.customBuilderConfigAsMeta(this.builderConfig!);
          const idx = data.builders.findIndex(b => b.alias === asMeta.alias);
          // if somehow we already know about it, store it (should never happen)
          if (idx === -1) {
            data.builders.push(asMeta);
          } else {
            data.builders.splice(idx, 1, asMeta);
          }

          if (res) return this.settingsHttpService.update<CustomBuilderListModel>(this.getScopedId(this.builderConfig!.scope), CUSTOM_BUILDER_LIST_ALIAS, data, undefined, this.builderConfig!.scope)
          return this.settingsHttpService.create<CustomBuilderListModel>(this.getScopedId(this.builderConfig!.scope), CUSTOM_BUILDER_LIST_ALIAS, data, undefined, this.builderConfig!.scope)
        }),
        switchMap(() => {
          if (scopeChanged) {
            return this.settingsHttpService.find<CustomBuilderListModel>(this.getScopedId(this.oldScope), CUSTOM_BUILDER_LIST_ALIAS, undefined, this.oldScope).pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                  return of(null);
                }
                return throwError(() => err);
              }),
              switchMap((res: SettingsModel<CustomBuilderListModel> | null) => {
                if (res) {
                  const data = res ? res.data : { builders: [] };
                  const idx = data.builders.findIndex(b => b.alias === this.builderConfig!.alias)
                  if (idx !== -1) {
                    data.builders.splice(idx, 1);
                    return this.settingsHttpService.update<CustomBuilderListModel>(this.getScopedId(this.oldScope), CUSTOM_BUILDER_LIST_ALIAS, data, undefined, this.oldScope)
                  }
                }
                return of(null);
              }),
            )
          }
          return of(null);
        }),
        finalize(() => {
          this.loading.end('custom-builder-setup');
          this.isSaving = false;
          this.router.navigate(['/settings']);
        }),
        switchMap(() => {
          return from(this.clinicalNoteService.refreshSettings(true));
        }),
      )
    );
  }

  public isMultipleSelectionType(type: CustomInputType) {
    return ['select', 'multiselect', 'radio'].includes(type)
  }

  public newFieldGroup(): FormGroup {
    return new FormGroup({
      label: new FormControl('', [Validators.required, Validators.minLength(1)]),
      style: new FormControl('', [Validators.required, Validators.minLength(1)]),
      multiple: new FormControl('', [Validators.required, Validators.minLength(1)]),
      required: new FormControl(false, [Validators.required, Validators.minLength(1)]),
      inputs: new FormArray([
        this.newInput()
      ], [Validators.required, Validators.minLength(1)])
    });
  }

  public newInput(typeAlias?: CustomInputType): FormGroup {
    const type = new FormControl(typeAlias || 'text', [Validators.required, Validators.pattern(/text|textarea|select|multiselect|checkbox|radio/)]);
    const details = new FormControl(this.isMultipleSelectionType(typeAlias || 'text') ? [] : '', [Validators.required, Validators.minLength(1)]);
    type.registerOnChange(() => details.setValue(null))
    return new FormGroup({
      type,
      details,
      label: new FormControl('', [Validators.required, Validators.minLength(1)]),
      required: new FormControl(false, [Validators.required])
    });
  }

  private generateAlias() {
    if (this.builderConfig?.alias) return;
    this.builderConfig!.alias = [
      '_custom-builder',
      this.getUUID()
    ].join('::')
  }

  private getUUID() {
    if (window.crypto && typeof window.crypto.randomUUID === 'function') {
      return window.crypto.randomUUID();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (symbol: string) => {
      var array;

      if (symbol === 'y') {
        array = ['8', '9', 'a', 'b'];
        return array[Math.floor(Math.random() * array.length)];
      }

      array = new Uint8Array(1);
      window.crypto.getRandomValues(array);
      return (array[0] % 16).toString(16);
    });
  }

  private buildFormFromConfig() {
    (this.form.get('fields') as FormArray).clear();
    this.builderConfig?.fields.forEach(field => {
      const fieldGroup = this.newFieldGroup();
      (fieldGroup.get('inputs') as FormArray).clear();
      field.inputs.forEach(input => {
        (fieldGroup.get('inputs') as FormArray).push(this.newInput(input.type));
      });
      (this.form.get('fields') as FormArray).push(fieldGroup);
    });
    this.form.patchValue(this.builderConfig!);
    this.builderConfig?.fields.forEach((field, i) => {
      const group = (this.form.get('fields') as FormArray).controls[i];
      field.inputs.forEach((input, j) => {
        ((group.get('inputs') as FormArray).controls[j].get('details') as FormControl).patchValue(input.details);
      });
    });
  }

  private emptyConfig(): CustomBuilderConfigModel {
    return {
      title: '',
      label: '',
      alias: '',
      scope: 'user',
      group: '',
      active: true,
      fields: [{
        label: '',
        style: '',
        multiple: false,
        required: false,
        inputs: [{
          label: '',
          type: 'text',
          details: '',
          required: false,
        }]
      }],
      author: this.activeUserService.user!
    };
  }
}
