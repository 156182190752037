import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

export interface GynaeHistoryFormData extends BuilderDataAbstract {
  last_period: {
    date: number;
    note: string;
    _modified: number;
  };
  missed_period: {
    present: boolean;
    note: string;
    _modified: number;
  };
  pregnant: {
    present: boolean;
    note: string;
    _modified: number;
  };
  pvd: {
    present: boolean;
    note: string;
    _modified: number;
  };
  previous_pregnancy: {
    present: boolean;
    para: number;
    gravida: number;
    ectopic_pregnancies: number;
    vaginal_birth: number;
    c_section: number;
    vbacs: number;
    miscarriages: number;
    stillbirths: number;
    note: string;
    _modified: number;
  };
}

@Component({
  selector: 'app-gynae-history',
  templateUrl: './gynae-history.component.html',
  styleUrls: ['./gynae-history.component.scss']
})
export class GynaeHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;


  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      last_period: this.fb.group({
        date: [null],
        note: [null, Validators.maxLength(500)]
      }),
      missed_period: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      pregnant: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      pvd: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      previous_pregnancy: this.fb.group({
        present: [null],
        para: [null],
        gravida: [null],
        ectopic_pregnancies: [null],
        vaginal_birth: [null],
        c_section: [null],
        vbacs: [null],
        miscarriages: [null],
        stillbirths: [null],
        note: [null, Validators.maxLength(500)]
      })
    });

    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  public getRow(form: keyof GynaeHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInput(form: keyof GynaeHistoryFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: GynaeHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'GYNAE HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: GynaeHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: GYNAE HISTORY`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as GynaeHistoryFormData;
    data.last_period['_modified'] = moment().valueOf();
    data.last_period['date'] = moment(data.last_period['date']).valueOf();
    data.missed_period['_modified'] = moment().valueOf();
    data.pregnant['_modified'] = moment().valueOf();
    data.pvd['_modified'] = moment().valueOf();
    data.previous_pregnancy['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['last_period'] = data.last_period;
      builderData['missed_period'] = data.missed_period;
      builderData['pregnant'] = data.pregnant;
      builderData['pvd'] = data.pvd;
      builderData['previous_pregnancy'] = data.previous_pregnancy;
    }
    return builderData;
  }

  override registerFormListener() {
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: control => {
        switch (control.get('present')?.value) {
          case true:
          case false:
            control.get('note')?.enable({ emitEvent: false });
            control.get('para')?.enable({ emitEvent: false });
            control.get('gravida')?.enable({ emitEvent: false });
            control.get('ectopic_pregnancies')?.enable({ emitEvent: false });
            control.get('vaginal_birth')?.enable({ emitEvent: false });
            control.get('c_section')?.enable({ emitEvent: false });
            control.get('vbacs')?.enable({ emitEvent: false });
            control.get('miscarriages')?.enable({ emitEvent: false });
            control.get('stillbirths')?.enable({ emitEvent: false });
            break;
          case null:
            control.get('note')?.disable({ emitEvent: false });
            control.get('para')?.disable({ emitEvent: false });
            control.get('gravida')?.disable({ emitEvent: false });
            control.get('ectopic_pregnancies')?.disable({ emitEvent: false });
            control.get('vaginal_birth')?.disable({ emitEvent: false });
            control.get('c_section')?.disable({ emitEvent: false });
            control.get('vbacs')?.disable({ emitEvent: false });
            control.get('miscarriages')?.disable({ emitEvent: false });
            control.get('stillbirths')?.disable({ emitEvent: false });
            control.get('note')?.patchValue('', { emitEvent: false });
            control.get('para')?.patchValue(null, { emitEvent: false });
            control.get('gravida')?.patchValue(null, { emitEvent: false });
            control.get('ectopic_pregnancies')?.patchValue(null, { emitEvent: false });
            control.get('vaginal_birth')?.patchValue(null, { emitEvent: false });
            control.get('c_section')?.patchValue(null, { emitEvent: false });
            control.get('vbacs')?.patchValue(null, { emitEvent: false });
            control.get('miscarriages')?.patchValue(null, { emitEvent: false });
            control.get('stillbirths')?.patchValue(null, { emitEvent: false });
            break;
        }
      }
    });
  }

  private prepareSerializedList(data: GynaeHistoryFormData) {
    let previousPregnanciesData = [];
    if (data.previous_pregnancy.present) {
      data.previous_pregnancy.para ? previousPregnanciesData.push('Para: ' + data.previous_pregnancy.para) : '';
      data.previous_pregnancy.gravida ? previousPregnanciesData.push('Gravida: ' + data.previous_pregnancy.gravida) : '';
      data.previous_pregnancy.ectopic_pregnancies ? previousPregnanciesData.push('Ectopic: ' + data.previous_pregnancy.ectopic_pregnancies) : '';
      data.previous_pregnancy.vaginal_birth ? previousPregnanciesData.push('Vaginal Births: ' + data.previous_pregnancy.vaginal_birth) : '';
      data.previous_pregnancy.c_section ? previousPregnanciesData.push('Caesareans: ' + data.previous_pregnancy.c_section) : '';
      data.previous_pregnancy.vbacs ? previousPregnanciesData.push('VBACs: ' + data.previous_pregnancy.vbacs) : '';
      data.previous_pregnancy.miscarriages ? previousPregnanciesData.push('Miscarriages: ' + data.previous_pregnancy.miscarriages) : '';
      data.previous_pregnancy.stillbirths ? previousPregnanciesData.push('Stillbirths: ' + data.previous_pregnancy.stillbirths) : '';
      data.previous_pregnancy.note ? previousPregnanciesData.push('Complications: ' + data.previous_pregnancy.note) : '';
    }

    const list = [
      {
        title: 'Last Period',
        present: !!data.last_period.date || !!data.last_period.note ? true : null,
        value: `${data.last_period.date ? moment(data.last_period.date).format('YYYY-MM-DD') : ''}${data.last_period.note ? data.last_period.date ? ', ' + data.last_period.note : data.last_period.note : ''}`
      },
      {
        title: 'Missed Period',
        present: data.missed_period.present,
        value: `${data.missed_period.present ? 'Yes' : 'No'}${data.missed_period.note ? ': ' + data.missed_period.note : ''}`
      },
      {
        title: 'Pregnant',
        present: data.pregnant.present,
        value: `${data.pregnant.present ? 'Yes' : 'No'}${data.pregnant.note ? ': ' + data.pregnant.note : ''}`
      },
      {
        title: 'PVD',
        present: data.pvd.present,
        value: `${data.pvd.present ? 'Yes' : 'No'}${data.pvd.note ? ': ' + data.pvd.note : ''}`
      },
      {
        title: 'Previous Pregnancies',
        present: data.previous_pregnancy.present,
        value: previousPregnanciesData.join('; ')
      }
    ].filter(v => v.present !== null);

    return list;
  }
}
