import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, map, observable, Observable, of, race, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import { UI_SECTIONS } from 'src/app/modules/ui/config/sections';
import { AttachmentFormMetaData, AttachmentsModalComponent } from '../../components/attachments-modal/attachments-modal.component';
import { AttachmentModel, AttachmentType } from '../../models/attachment.model';
import { MessageResponse } from '../../types/messageResponse';
import { AttachmentHttpService } from '../http/attachment-http.service';
import { ActiveUserService } from './active-user.service';
import { ClinicalNoteService } from './clinical-note.service';

export interface AttachmentModalOptions {
    attachments?: File[],
    type?: AttachmentType,
    disableToggle?: boolean,
    lockType?: boolean,
    data?: AttachmentFormMetaData
}

@Injectable({
    providedIn: 'root'
})
export class AttachmentsService {

    constructor(
        private modalService: NgbModal,
        private attachmentHttpService: AttachmentHttpService,
        private clinicalNoteService: ClinicalNoteService,
        private activeUserService: ActiveUserService
    ) {
    }

    public upload(options: AttachmentModalOptions = {}): Observable<AttachmentModel[] | null> {
        const subject = new Subject<AttachmentModel[]>();

        const modalRef = this.modalService.open(AttachmentsModalComponent, { fullscreen: 'sm' });
        modalRef.componentInstance.closure = (files: AttachmentModel[]) => subject.next(files);
        modalRef.componentInstance.modalRef = modalRef;

        // Set presets form options
        if (options.attachments && options.attachments.length) modalRef.componentInstance.attachments = [...options.attachments];
        if (options.type) modalRef.componentInstance.attachmentType = options.type;
        if (options.lockType) modalRef.componentInstance.lockType = options.lockType;
        if (options.disableToggle) modalRef.componentInstance.disableToggle = options.disableToggle;
        if (options.data) modalRef.componentInstance.data = options.data;

        return race(
            subject.asObservable(),
            modalRef.hidden.pipe(map(() => null))
        );
    }

    public downloadBlob(fileId: string): Observable<any> {
        return this.attachmentHttpService.download(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, fileId);
    }

    public downloadDataUrl(fileId: string): Observable<string | ArrayBuffer | null> {
        return this.attachmentHttpService.download(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, fileId).pipe(
            switchMap(v => {
                return from(
                    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(v);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = () => reject(reader.error);
                    })
                )
            })
        );
    }

    public download(fileId: string): Observable<string> {
        return of(this.attachmentHttpService.downloadPath(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, fileId)).pipe(
            tap((url) => {
                window.open(url + (url.includes('?') ? '&' : '?') + 'access_token=' + this.activeUserService.token);
            })
        );
    }

    public downloadPath(fileId: string): string {
        return this.attachmentHttpService.downloadPath(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, fileId);
    }

    public delete(fileId: string): Observable<MessageResponse> {
        return this.attachmentHttpService.delete(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, fileId).pipe(
            switchMap((v) => this.clinicalNoteService.loadSection(UI_SECTIONS.ATTACHMENTS).pipe(map(() => v)))
        );
    }
}
