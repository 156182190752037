import { Validators } from "@angular/forms";

export const ROW_DATA = () => ({
  skin: {
    date: [null, Validators.required],
    practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
    note: [null, [Validators.maxLength(500)]]
  },
  colonoscopy: {
    date: [null, Validators.required],
    practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
    note: [null, [Validators.maxLength(500)]]
  },
  prostate: {
    date: [null, Validators.required],
    practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
    note: [null, [Validators.maxLength(500)]]
  },
  papSmear: {
    date: [null, Validators.required],
    practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
    note: [null, [Validators.maxLength(500)]]
  },
  mammogram: {
    date: [null, Validators.required],
    practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
    note: [null, [Validators.maxLength(500)]]
  }
});
