import { Component, Injectable, Input, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

export type DialogActionBtnOptions = {
  text: string;
  action: (ref: NgbModalRef) => Promise<boolean>;
  class?: string | string[];
  template?: TemplateRef<any>
};

export type DialogOptions = {
  // Use:
  variant?: 'success' | 'warning' | 'danger' | 'info' | 'confirm';
  title?: string;
  message?: string | Array<string>;
  messages?: Array<string>;
  actions?: DialogActionBtnOptions[];
  // OR
  template?: TemplateRef<any>;
  content?: TemplateRef<any>;
  options?: NgbModalOptions;
}

@Component({
  selector: 'app-dialog-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss('close')"></button>
    </div>
    <div class="modal-body" *ngIf="message || messages || content">
        <ng-container *ngIf="message"><p *ngFor="let m of messageAsArray">{{m}}</p></ng-container>
        <ng-container *ngIf="messages"><ul><li *ngFor="let message of messages">{{message}}</li></ul></ng-container>
        <ng-container *ngIf="content">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-container>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="actions && actions.length; else noActions">
            <button *ngFor="let action of actions" type="button" class="btn"
                    [ngClass]="action.class ? action.class : 'btn-outline-primary'" (click)="runAction(action.action)"
                    [disabled]="isLoading">{{action.text}}</button>
        </ng-container>
        <ng-template #noActions>
            <button type="button" class="btn btn-outline-dark" (click)="modalRef.close('cancel')" [disabled]="isLoading">Close</button>
        </ng-template>
    </div>
  `
})
export class AppDialogModal {
  @Input() title?: string;
  @Input() message?: string | Array<string>;
  @Input() messages?: Array<string>;
  @Input() content?: TemplateRef<any>;
  @Input() actions?: DialogActionBtnOptions[];
  @Input() modalRef!: NgbModalRef;
  public isLoading: boolean = false;

  get messageAsArray(): string[] {
    return this.message ? (Array.isArray(this.message) ? this.message : [this.message]) : [];
  }

  constructor() { }

  async runAction(action: (ref: NgbModalRef) => Promise<boolean>) {
    this.isLoading = true;
    const close = await action(this.modalRef);
    this.isLoading = false;
    if (close) {
      this.modalRef.close();
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private modalService: NgbModal) { }

  show(options: DialogOptions): NgbModalRef {
    const component = options.template ? options.template : AppDialogModal;
    const modalOptions: NgbModalOptions = {
      ...options.options
      // additional defaults
    };

    const modalRef = this.modalService.open(component, modalOptions);
    modalRef.componentInstance.modalRef = modalRef;

    modalRef.componentInstance.variant = options.variant; // prebuilts ?
    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.message = options.message;
    modalRef.componentInstance.messages = options.messages;
    modalRef.componentInstance.content = options.content;
    modalRef.componentInstance.actions = options.actions;

    return modalRef;
  }

  httpError(error: HttpErrorResponse) {
    let title;
    switch (error.status) {
      case 422:
        title = 'Validation Error'
        break;
      default:
        title = 'Error'
    }

    let messageData: DialogOptions = {};
    if (error?.error.errors) {
      messageData.messages = Object.values(error?.error.errors as { string: string }[]).reduce((out: Array<string>, errObj: { string: string }) => {
        return out.concat(...Object.values(errObj) as unknown as string)
      }, []);
    } else if (error?.error.message) {
      messageData.message = error?.error.message;
    }

    this.show({
      title,
      variant: 'danger',
      ...messageData,
      actions: [
        {
          text: 'Ok',
          action: async () => true
        }
      ]
    });
  }

}
