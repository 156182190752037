<div class="container-fluid" [formGroup]="form">
    <div class="row">
        <div class="col">
            <label class="sectionTitle">EDIT CUSTOM BUILDER</label>
        </div>
        <div class="col-auto">
            <button jargonLoadingState="custom-builder-setup" class="btn btn-primary" (click)="save()">Save
                Builder</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label class="form-label">Builder Name*</label>
                <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                       formControlName="label" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label class="form-label">Display Name*</label>
                <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                       formControlName="title" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label class="form-label">Group</label>
                <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                       formControlName="group" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label class="form-label">Availability*</label>
                <select jargonLoadingState="custom-builder-setup" class="form-control"
                        formControlName="scope">
                    <option value="practice">Practice</option>
                    <option value="user">Private</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <label>Builder Inputs</label>
        </div>
        <div class="col-12">
            <div class="row my-2" *ngFor="let control of fieldsControl.controls; index as i">
                <div class="col-12">
                    <ng-container
                                  *ngTemplateOutlet="customFieldTmpl;context:{ formGroup: control, key: 'field_'+i, index: i }">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button class="btn btn-primary" (click)="fieldsControl.push(newFieldGroup())">Add New Input</button>
        </div>
    </div>

    <ng-template #customFieldTmpl let-formGroup="formGroup" let-key="key" let-index="index">
        <div class="custom-builder-field" [formGroup]="formGroup">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="form-label">Input Name*</label>
                        <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                               formControlName="label" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="form-label">Input Style*</label>
                        <select jargonLoadingState="custom-builder-setup" class="form-control" formControlName="style">
                            <option value="single">Grouped Fields On Single Line</option>
                            <option value="multiple">Single Field Per Line</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="form-label">Input Records</label>
                        <div class="form-check">
                            <input jargonLoadingState="custom-builder-setup" type="checkbox" class="form-check-input"
                                   formControlName="multiple"
                                   [attr.id]="'custom_form__multiple_'+key" />
                            <label class="form-check-label" [attr.for]="'custom_form__multiple_'+key">Allow Multiple
                                Entries</label>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <mat-icon style="color: red; cursor: pointer; transform: scale(1.5em)" class="float-end"
                              (click)="formGroup.parent.removeAt(index)">delete</mat-icon>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-auto">
                            <label>&nbsp;</label>
                        </div>
                        <div class="col">
                            <label class="form-label">Field Label*</label>
                        </div>
                        <div class="col">
                            <label class="form-label">Field Type*</label>
                        </div>
                        <div class="col">
                            <label class="form-label">Details</label>
                        </div>
                        <div class="col">
                            <label class="form-label">Required Status</label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <ng-container *ngFor="let control of formGroup.get('inputs').controls; index as i">
                        <ng-container
                                      *ngTemplateOutlet="customFieldInputTmpl;context:{ formGroup: control, key: key+'_input_'+i, index: i }">
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-primary" (click)="formGroup.get('inputs').push(newInput())">
                        Add Field
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #customFieldInputTmpl let-formGroup="formGroup" let-key="key" let-index="index">
        <div class="row mb-2" [formGroup]="formGroup">
            <div class="col-auto">
                <mat-icon style="color: red; cursor: pointer; transform: scale(1.5)"
                          (click)="formGroup.parent.removeAt(index)">delete</mat-icon>
            </div>
            <div class="col">
                <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                       formControlName="label" />
            </div>
            <div class="col">
                <select jargonLoadingState="custom-builder-setup" class="form-control" formControlName="type">
                    <option value="checkbox">Checkbox</option>
                    <option value="select">Dropdown</option>
                    <option value="textarea">Long Text</option>
                    <option value="multiselect">Multiselect Dropdown</option>
                    <option value="radio">Radio Buttons</option>
                    <option value="text">Short Text</option>
                </select>
            </div>
            <div class="col">
                <ng-container [ngSwitch]="formGroup.get('type').value">
                    <ng-container *ngSwitchCase="'checkbox'">
                        <input jargonLoadingState="custom-builder-setup" type="text" class="form-control"
                               [formControl]="formGroup.get('details')" />
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="ngSelectTmpl"
                                  [ngTemplateOutletContext]="{control: formGroup.get('details')}"
                                  *ngSwitchCase="'select'"></ng-container>
                    <ng-container [ngTemplateOutlet]="ngSelectTmpl"
                                  [ngTemplateOutletContext]="{control: formGroup.get('details')}"
                                  *ngSwitchCase="'multiselect'"></ng-container>
                    <ng-container [ngTemplateOutlet]="ngSelectTmpl"
                                  [ngTemplateOutletContext]="{control: formGroup.get('details')}"
                                  *ngSwitchCase="'radio'"></ng-container>
                    <ng-container *ngSwitchDefault>
                        <input jargonLoadingState="custom-builder-setup" type="text" class="form-control" disabled />
                    </ng-container>
                </ng-container>
            </div>
            <div class="col">
                <div class="form-check">
                    <input jargonLoadingState="custom-builder-setup"
                           type="checkbox"
                           class="form-check-input"
                           formControlName="required"
                           [attr.id]="'custom_input__required'+key" />
                    <label class="form-check-label" [attr.for]="'custom_input__required'+key">Required Status</label>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #ngSelectTmpl let-control="control">
        <ng-select [items]="control.value || []"
                   jargonLoadingState="custom-builder-setup"
                   [multiple]="true"
                   [closeOnSelect]="true"
                   [formControl]="control"
                   placeholder="Add Options"
                   [addTag]="true"
                   addTagText="Add Option: "></ng-select>
    </ng-template>
</div>