

export const Sites = (): Array<{ name: string }> => ([
  { name: 'Right hypochondriac' },
  { name: 'Right flank' },
  { name: 'Right illiac' },
  { name: 'Epigastric' },
  { name: 'Umbilical' },
  { name: 'Hypogastric/pubic' },
  { name: 'Left illiac' },
  { name: 'Left hypochondriac' },
  { name: 'Left flank' },
  { name: 'Generalized' }
].sort((a, b) => a.name.localeCompare(b.name)));
