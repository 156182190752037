import { Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { UiService } from 'src/app/shared/services/common/ui.service';
import { catchError, finalize, firstValueFrom, map, of, tap, take, Subscription } from 'rxjs';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnDestroy {
  @HostBinding('style.left') leftOffsetStyle = '0px';
  public isSaving: boolean = false;
  public isSavingSections: boolean = false;

  get isInFrame() {
    return this.uiService.isInFrame;
  }

  @Input('leftOffset')
  set leftOffset(val: number) {
    this.leftOffsetStyle = val + 'px';
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private clinicalNoteService: ClinicalNoteService,
    public uiService: UiService,
    private dialogService: DialogService,
    private loading: LoadingStateService,
  ) {

    this.subscriptions.push(this.loading.on('builder-control').subscribe({
      next: (saving) => this.isSavingSections = saving
    }));
    this.subscriptions.push(this.loading.on('builder-submission').subscribe({
      next: (saving) => this.isSavingSections = saving
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submitReview() {
    this.uiService.message('review', 'submit');
  }

  submit() {
    this.dialogService.show({
      variant: 'confirm',
      title: 'Finalise Clinical Notes',
      message: [
        'Are you sure you want to finalise these clinical notes?',
        'Once finalised, builder data will be saved to the patient EHR and these notes will become uneditable.',
      ],
      actions: [
        {
          text: 'Cancel',
          class: 'btn-outline-danger',
          action: async () => true,
        },
        {
          text: 'Confirm',
          class: 'btn-outline-success',
          action: async () => {
            return await firstValueFrom(
              this.submitNotes().pipe(
                map(() => true),
                tap(() =>
                  this.dialogService.show({
                    variant: 'success',
                    title: 'Finalised Clinical Notes Submitted Successfully!',
                  })
                ),
                catchError(() => of(false))
              )
            );
          },
        },
      ],
    });
  }

  async save() {
    this.loading.start('builder-control');
    return firstValueFrom(
      this.uiService.saveAll().pipe(
        take(1),
        catchError(error => {
          console.error('Error saving:', error);
          return of(null);
        }),
        finalize(() => {
          this.loading.end('builder-control');
        }),
      )
    );
  }

  private submitNotes() {
    this.loading.start(['builder-control', 'builder-submission']);
    return this.clinicalNoteService.submit().pipe(
      finalize(() => {
        this.loading.end(['builder-control', 'builder-submission']);
      })
    );
  }
}
