
export const AttachmentsBodyRegions: Array<{ id: number, name: string }> = [
  {
    id: 1,
    name: 'Upper Abdomen'
  },
  {
    id: 2,
    name: 'Lower Abdomen'
  },
  {
    id: 3,
    name: 'Head'
  },
  {
    id: 4,
    name: 'Neck'
  },
  {
    id: 5,
    name: 'Back'
  },
  {
    id: 6,
    name: 'Upper Limb (right)'
  },
  {
    id: 7,
    name: 'Upper Limb (Left)'
  },
  {
    id: 8,
    name: 'Lower limb (right)'
  },
  {
    id: 9,
    name: 'lower limb (left)'
  }
].sort((a, b) => a.name.localeCompare(b.name));
