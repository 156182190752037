<div class="row history-record g-0" *ngFor="let note of data">
    <div *ngIf="note.title; else nonTitleObj" class="row">
        <div class="col">
            <hr>
        </div>
        <div class="col-auto" style="display: flex;">
            <ng-container *ngIf="note.actions && note.actions.length; else noActionsRef">
                <ng-container *ngTemplateOutlet="noteWithActionsRef; context: {note}"></ng-container>
            </ng-container>
            <ng-template #noActionsRef>
                <ng-container *ngIf="note.action; else noActionRef">
                    <ng-container *ngTemplateOutlet="noteWithActionRef; context: {note}"></ng-container>
                </ng-container>
                <ng-template #noActionRef>
                    <ng-container *ngTemplateOutlet="noteNoActionsRef; context: {note}"></ng-container>
                </ng-template>
            </ng-template>
            <p class="note-title">{{ note.title }}</p>
        </div>
        <div class="col">
            <hr>
        </div>
    </div>
    <ng-template #nonTitleObj>
        <div class="col-auto no-print">
            <ng-container *ngIf="note.actions && note.actions.length; else noActionsRef">
                <ng-container *ngTemplateOutlet="noteWithActionsRef; context: {note}"></ng-container>
            </ng-container>
            <ng-template #noActionsRef>
                <ng-container *ngIf="note.action; else noActionRef">
                    <ng-container *ngTemplateOutlet="noteWithActionRef; context: {note}"></ng-container>
                </ng-container>
                <ng-template #noActionRef>
                    <ng-container *ngTemplateOutlet="noteNoActionsRef; context: {note}"></ng-container>
                </ng-template>
            </ng-template>
        </div>
        <div class="col">
            <div class="row g-0">
                <div class="col-auto"><strong>{{note.type === 'builder' ? note.builderTitle :
                        (note.author || note.sectionTitle)}}</strong>{{note.builderTitleData}}</div>
                <div class="col no-print"></div>
                <div class="col-auto date"><span class="show-print">[</span>{{note.dateTime}}<span class="show-print">]</span></div>
                <div class="co1-12">
                    <ng-container *ngIf="note.template; else noTemplate">
                        <ng-container *ngTemplateOutlet="note.template[0]; context: { data: note.template[1] }">
                        </ng-container>
                    </ng-container>
                    <ng-template #noTemplate>
                        <div style="white-space:pre-wrap">{{note.content}}</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>
</div>


<ng-template #noteNoActionsRef let-note="note">
    <mat-icon *ngIf="note.icon; else noIconRef" class="type-icon">{{note.icon}}</mat-icon>
    <ng-template #noIconRef>
        <ng-container [ngSwitch]="note.type">
            <mat-icon *ngSwitchCase="'builder'" class="type-icon">build</mat-icon>
            <mat-icon *ngSwitchDefault class="type-icon">edit</mat-icon>
        </ng-container>
    </ng-template>
</ng-template>

<ng-template #noteWithActionRef let-note="note">
    <span (click)="$event.preventDefault(); note.action()" class="actionable">
        <mat-icon *ngIf="note.icon; else noIconRef" class="type-icon">{{note.icon}}</mat-icon>
        <ng-template #noIconRef>
            <ng-container [ngSwitch]="note.type">
                <mat-icon *ngSwitchCase="'builder'" class="type-icon">build</mat-icon>
                <mat-icon *ngSwitchDefault class="type-icon">edit</mat-icon>
            </ng-container>
        </ng-template>
    </span>
</ng-template>

<ng-template #noteWithActionsRef let-note="note">
    <span ngbDropdown #actionsDrop="ngbDropdown">
        <span (click)="actionsDrop.open()" class="actionable">
            <mat-icon *ngIf="note.icon; else noIconRef" class="type-icon">{{note.icon}}</mat-icon>
            <ng-template #noIconRef>
                <ng-container [ngSwitch]="note.type">
                    <mat-icon *ngSwitchCase="'builder'" class="type-icon">build</mat-icon>
                    <mat-icon *ngSwitchDefault class="type-icon">edit</mat-icon>
                </ng-container>
            </ng-template>
        </span>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
            <a ngbDropdownItem href="#" *ngFor="let action of note.actions"
               (click)="$event.preventDefault(); action.action()">
                {{action.label}}
            </a>
        </div>
    </span>
</ng-template>