import { Component, EventEmitter, inject, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SectionBuilderControls } from 'src/app/modules/sections/shared/components/section-item/section-item.component';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderConfigItemModel } from 'src/app/shared/models/builder-config.model';
import { AuthorModel, BuilderData } from 'src/app/shared/models/clincal-note.model';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { catchError, finalize, firstValueFrom, map, merge, Subscription, tap, throwError } from 'rxjs';
import { LoadingStateService } from '../../../../../shared/services/common/loading-state.service';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import * as moment from 'moment';
import { ViewModeEnum } from 'src/app/shared/types/enum/viewMode';

export interface BuilderDataAbstract {
  _created?: number;
  _modified?: number;
  author?: AuthorModel;
}

export interface BuilderPresentNote {
  present: boolean;
  note: string;
  _modified: number;
}

@Component({ template: '' })
export abstract class AbstractBuilderComponent<BC extends BuilderConfigItemModel = BuilderConfigItemModel> implements OnInit, OnDestroy {

  @Input('section') section!: SectionConfigModel;
  @Input('controls') controls!: SectionBuilderControls;
  @Input('config') config!: BC;
  @Output() configChange: EventEmitter<BC> = new EventEmitter<BC>();

  public maxDateNow = {
    day: moment().date(),
    month: moment().month() + 1,
    year: moment().year()
  };

  protected abstract getBuilderData(): BuilderData;

  protected formData!: FormGroup;
  protected controlSub?: Subscription;

  protected loadingStateService: LoadingStateService = inject(LoadingStateService);
  protected dialogService: DialogService = inject(DialogService);
  protected clinicalNoteService: ClinicalNoteService = inject(ClinicalNoteService);
  protected activeUserService: ActiveUserService = inject(ActiveUserService);

  protected _config!: BC;

  private _data?: BuilderData | null;

  constructor() {
  }

  @Input('data') set data(data: BuilderData | undefined | null) {
    this._data = data;
    if (this._data) {
      this.setBuilderData(JSON.parse(JSON.stringify(this._data)));
    }
  };

  get data(): BuilderData | undefined | null {
    return this._data;
  }

  get isReadOnly() {
    return this.activeUserService.viewMode === ViewModeEnum.REVIEW
      || this.clinicalNoteService.isReadOnly;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  deleteBuilder() {
    this.dialogService.show({
      title: 'Are You Sure?',
      message: 'This will remove any information provided within this builder. Are you sure you wish to remove this builder and associated data?',
      actions: [
        {
          text: 'Confirm',
          action: async (): Promise<boolean> => this.controls.detatch(this.config)
        },
        {
          text: 'Cancel',
          action: async (): Promise<boolean> => true
        }
      ]
    });
    return;
  }

  abstract serialize(data: BuilderDataAbstract): HistoryData[];

  abstract asString(data: BuilderDataAbstract): string;

  public getData() {
    return this.getBuilderData();
  }

  public async validate(): Promise<boolean> {
    return true;
  }

  async saveData() {
    await firstValueFrom(this.clinicalNoteService.saveBuilder(this.section.alias, this.config.alias, this.getBuilderData()).pipe(
      finalize(() => this.loadingStateService.end('builder-control'))
    ));
    this.controls.onSave();
    this.controls.toggle(this.config);
    this.controls.scrollToSection();
  }

  isEmpty<T extends { [key: string]: any }>(data: T, fields?: Array<string>, allowNulls: boolean = false): boolean {
    if (fields) {
      for (let field of fields) {
        if (typeof data[field] !== 'undefined' && (allowNulls || data[field] !== null)) {
          return false;
        }
      }
      return true;
    } else {
      return Object.values(data).reduce((out, value) => {
        if (value !== null && typeof value === 'object') {
          return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
        }
        return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
      }, true);
    }
  }

  getJoinedList(fields: Array<string>): string {
    if (!fields) {
      return '';
    }
    return fields.join(', ');
  }

  public isPresent<T extends string>(alias: T): boolean {
    return (this.formData.get(alias) as FormGroup).get('present')?.value;
  }

  protected setBuilderData(data: BuilderData): void {
    this.formData.patchValue(data);
    if (this.isReadOnly) {
      this.formData.disable();
    }
  }

  protected registerFormListener(noteValidation = false) {
    if (this.isReadOnly) {
      return;
    }
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .filter(c => c instanceof FormGroup && c.get('present'))
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: v => {
        if (noteValidation) {
          return this.toggleNoteValidation(v);
        }
        this.toggleValidation(v);
      }
    });

    if (!noteValidation) {
      Object.values(this.formData.controls)
        .filter(c => c instanceof FormGroup && c.get('present'))
        .forEach(c => this.toggleValidation(c as FormGroup));
    }
  }

  private toggleValidation(control: FormGroup) {
    switch (control.get('present')?.value) {
      case true:
        control.get('data')?.enable({ emitEvent: false });
        control.get('note')?.disable({ emitEvent: false });
        control.get('note')?.patchValue('', { emitEvent: false });
        break;
      case false:
        control.get('data')?.disable({ emitEvent: false });
        control.get('note')?.enable({ emitEvent: false });
        break;
      case null:
        control.get('data')?.disable({ emitEvent: false });
        control.get('note')?.disable({ emitEvent: false });
        control.get('note')?.patchValue('', { emitEvent: false });
        break;
    }
  }

  private toggleNoteValidation(control: FormGroup) {
    switch (control.get('present')?.value) {
      case true:
      case false:
        control.get('note')?.enable({ emitEvent: false });
        break;
      case null:
        control.get('note')?.disable({ emitEvent: false });
        control.get('note')?.patchValue('', { emitEvent: false });
        break;
    }
  }

}
