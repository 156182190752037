<div class="container-fluid diagnosis-section">
  <div class="row no-gutters">
    <div class="col">
      <label>DIAGNOSIS (ICD10)*</label>
    </div>
    <div class="col-auto">
      <app-review-actions [commentQty]="data.comments?.length" [alias]="config.alias"
                          (reviewAction)="handleReviewAction($event)"></app-review-actions>
    </div>
  </div>
  <div class="row my-2" [formGroup]="formData">
    <div class="col-12 mb-2">
      <ng-container formArrayName="diagnoses">
        <div class="row g-2 mb-2 diagnosis-items" *ngFor="let row of getRow().controls; let i = index"
             [formGroupName]="i">
          <div class="col-3">
            <medprax-code-typeahead type="icd10"
                                    formControlName="icd10"
                                    jargonViewModeForm="write"
                                    jargonLoadingState="builder-control"
                                    (change)="populateDescription($event, row)">
            </medprax-code-typeahead>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="Description" formControlName="description"
                   jargonLoadingState="builder-control"
                   jargonViewModeForm="write">
          </div>
          <div class="col-3">
            <div class="input-group">
              <select type="text" class="form-control" formControlName="type" jargonViewModeForm="write"
                      jargonLoadingState="builder-control">
                <option hidden disabled [value]="null">Condition Type</option>
                <option value="acute">Acute</option>
                <option value="chronic">Chronic</option>
              </select>
              <ng-container *jargonViewMode="'write'">
                <button class="btn btn-outline-secondary btn-sm"
                        type="button"
                        jargonLoadingState="builder-control"
                        (click)="removeRow(i)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *jargonViewMode="'write'">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-outline-primary" (click)="addDiagnosis()" jargonLoadingState="builder-control">Add
              +</button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-12 mb-2">
      <div class="form-group">
        <label class="form-label">Differential Diagnosis</label>
        <textarea class="form-control" formControlName="differential"></textarea>
      </div>
    </div>
  </div>
</div>


<ng-template #preview let-data="data">
  <p *ngIf="data.diagnoses && data.diagnoses.length">
    <strong>Diagnosis data</strong><br />
    <span *ngFor="let c of data.diagnoses; let i = index">
      ({{i + 1}}) {{c.icd10.Code}} - {{c.description}} (Condition Type: {{c.type}})
    </span><br />
  </p>
  <p *ngIf="data.differential && data.differential.length">
    <strong>Differential Diagnosis</strong><br />
    <span>{{data.differential}}</span><br />
  </p>
</ng-template>