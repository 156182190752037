

export const CoughType = (): Array<string> => ([
  'Dry',
  'Semi-Productive',
  'Productive',
].sort((a, b) => a.localeCompare(b)));

export const Sputum = (): Array<string> => ([
  'Yellow',
  'Brown',
  'Green',
  'Blood-stained',
].sort((a, b) => a.localeCompare(b)));

export const MMRC = (): Array<string> => ([
  '0 (Breathlessness only on strenuous exercise)',
  '1 (Breathless when hurrying on the level or walking up a slight hill)',
  '2 (Walks slower than other people of same age on the level due to shortness of breath or need to stop for breath when walking at own pace)',
  '3 (Short of breath after walking a few minutes on the level or about 90m (100 yards)))',
  '4 (Too breathless to leave the house, or breathless when dressing or undressing)',
].sort((a, b) => a.localeCompare(b)));
