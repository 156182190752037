<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--Soft-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Soft</label>
      </div>
      <div class="col" formGroupName="soft">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('soft')"
                            [count]="getDataCount('soft')"
                            (remove)="deleteRow('soft')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           appendTo="#app-wrapper"
                           jargonLoadingState="builder-control"
                           formControlName="regions"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('soft')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('soft')">Add +
          </button>
        </ng-container>
      </div>
    </div>

    <!--Non-Tender-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Non-Tender</label>
      </div>
      <div class="col" formGroupName="non_tender">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('non_tender')"
                            [count]="getDataCount('non_tender')"
                            (remove)="deleteRow('non_tender')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           appendTo="#app-wrapper"
                           formControlName="regions"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('non_tender')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('non_tender')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Tender-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Tender</label>
      </div>
      <div class="col" formGroupName="tender">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('tender')"
                            [count]="getDataCount('tender')"
                            (remove)="deleteRow('tender')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regionsTenderness"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           appendTo="#app-wrapper"
                           formControlName="regions"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('tender')" class="btn btn-outline-primary" (click)="addRow('tender')">Add+</button>
        </ng-container>
      </div>
    </div>

    <!--Masses-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Masses</label>
      </div>
      <div class="col" formGroupName="masses">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('masses')"
                            [count]="getDataCount('masses')"
                            (remove)="deleteRow('masses')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           appendTo="#app-wrapper"
                           jargonLoadingState="builder-control"
                           formControlName="regions"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('masses')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('masses')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Flank Pain-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Flank Pain</label>
      </div>
      <div class="col" formGroupName="flank_pain">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('flank_pain')"
                            [count]="getDataCount('flank_pain')"
                            (remove)="deleteRow('flank_pain')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           appendTo="#app-wrapper"
                           [hideSelected]="true"
                           formControlName="regions"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('flank_pain')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('flank_pain')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--R/G-->
    <!-- <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">R/G</label>
      </div>
      <div class="col" formGroupName="r_g">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div> -->

    <!--Peritonism-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Peritonism</label>
      </div>
      <div class="col" formGroupName="peritonism">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <ng-select [items]="peritonism_classifiers"
                       placeholder="Classifier"
                       appendTo="#app-wrapper"
                       formControlName="classifier"
                       jargonLoadingState="builder-control">
            </ng-select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--B/S-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">B/S</label>
      </div>
      <div class="col" formGroupName="b_s">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" jargonLoadingState="builder-control"
                   formControlName="note">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" jargonLoadingState="builder-control"
                   formControlName="note">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--PR-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">PR</label>
      </div>
      <div class="col" formGroupName="pr">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" jargonLoadingState="builder-control"
                   formControlName="note">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" jargonLoadingState="builder-control"
                   formControlName="note">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}}: </strong>
    <span>{{item.present ? 'Present' : 'Not Present'}}{{item.value}}</span>
  </p>
</ng-template>