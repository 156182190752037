import { Injectable, OnDestroy } from '@angular/core';
import { filter, firstValueFrom, map, Observable, of, Subject, Subscription, take, tap, timeout, zip, catchError, switchMap } from 'rxjs';
import { ActiveUserService } from './active-user.service';
import { ClinicalNoteService } from './clinical-note.service';
import { ToastService } from './toast.service';
import { _DisposeViewRepeaterStrategy } from '@angular/cdk/collections';

const TIMEOUT = 10000;

export enum UIComponentAlias {
  SIDEBAR_REVIEWS = 'sidebar-reviews',
  SIDEBAR_BUILDERS = 'sidebar-builders',
}

export interface UIToggleEvent {
  component: UIComponentAlias;
  state: boolean;
  options?: Record<string, any>
}

export type WindowMessage<T = any> = {
  messageId: string;
  caseId: string | number;
  source?: string;
  target?: string;
  alias: string;
  data: T;
}

type HasReactNativeWebView = typeof window & {
  ReactNativeWebView?: {
    postMessage: (data: string) => void;
  }
};

interface SaveListener {
  alias: string;
  save: () => Observable<boolean | null>;
}

interface ValidationListener {
  alias: string;
  validate: () => Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UiService implements OnDestroy {

  public isMaximized: boolean = false;
  public focused: boolean = false;
  private events$: Subject<UIToggleEvent> = new Subject<UIToggleEvent>();
  public events: Observable<UIToggleEvent> = this.events$.asObservable();
  private messages$: Subject<WindowMessage> = new Subject();
  public messages: Observable<WindowMessage> = this.messages$.asObservable();

  private subscriptions: Subscription[] = [];
  private saveListeners: SaveListener[] = [];
  private validationListeners: ValidationListener[] = [];

  constructor(
    private activeUserService: ActiveUserService,
    private clinicalNoteService: ClinicalNoteService,
    private toastService: ToastService
  ) {
    window.addEventListener('focus', this.onFocus.bind(this));
    window.addEventListener('blur', this.onBlur.bind(this));
    const onmessage = window.onmessage;
    window.onmessage = (message: MessageEvent<any>) => {
      console.log('[MESSAGE] >> ', message, message.origin, this.activeUserService.namespace);
      if (message.origin === this.activeUserService.namespace) {
        this.messages$.next(message.data);
      }
      if (onmessage) {
        onmessage.bind(window)(message);
      }
    };

    // subscribe to UI triggers from parent window
    this.subscriptions.push(
      this.messages.pipe(
        filter(({ target }) => target === 'section-reload'),
        tap(async ({ alias }) => firstValueFrom(this.clinicalNoteService.loadSection(alias)))
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  request<T = any>(source: string, alias: string, data: any): Observable<null | T> {
    const messageId = this.message(source, alias, data);
    return this.messages.pipe(
      filter(m => m.messageId === messageId && m.target === source && m.alias === alias),
      timeout({
        each: TIMEOUT,
        with: () => of(null)
      }),
      map(m => m ? m.data as T : null)
    );
  }

  get isInFrame(): boolean {
    return window.parent !== window
      // Check for React Native Hooks
      || !!(window as HasReactNativeWebView).ReactNativeWebView;
  }

  message(source: string, alias: string, data?: any) {
    const messageId = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
    const postFn = (data: any, origin: string) => (window as HasReactNativeWebView).ReactNativeWebView
      ? (window as HasReactNativeWebView).ReactNativeWebView!.postMessage(JSON.stringify({ data, origin }))
      : window.parent.postMessage(data, origin);
    postFn({
      // responses should match these ids
      guid: this.clinicalNoteService.guid,
      caseId: this.clinicalNoteService.caseId,
      patientId: this.clinicalNoteService.patientId,
      messageId,
      source,
      alias,
      data: (data ? data : {}),
    }, this.activeUserService.namespace || '*');
    return messageId;
  }

  open(component: UIComponentAlias, options?: any) {
    this.events$.next({ component, state: true, options });
  }

  close(component: UIComponentAlias, options?: any) {
    this.events$.next({ component, state: false, options });
  }

  toggle(component: UIComponentAlias, state: boolean, options?: any) {
    this.events$.next({ component, state, options });
  }

  minimizeFrame() {
    this.message('ui', 'minimize');
  }

  maximizeFrame() {
    if (this.isMaximized) {
      this.message('ui', 'reset');
    } else {
      this.message('ui', 'maximize');
    }
    this.isMaximized = !this.isMaximized;
  }

  closeFrame() {
    this.message('ui', 'close');
  }

  addSaveListener(listener: SaveListener) {
    this.saveListeners.push(listener);
  }

  addValidationListener(listener: ValidationListener) {
    this.validationListeners.push(listener);
  }

  saveAll(): Observable<any> {
    if (this.saveListeners.length === 0) {
      return of(false);
    }
    const doSave = () => zip(
      ...this.saveListeners.map(listener =>
        listener.save()
      )
    ).pipe(
      tap(saved => {
        if (saved.filter(v => v === null).length === saved.length) {
          this.toastService.show('Nothing To Save', { variant: 'info' });
        } else if (saved.filter(v => v === false).length) {
          setTimeout(() => this.scrollToError(), 0);
          this.toastService.show('Some Issues Were Encountered When Saving', { variant: 'warning' });
        } else if (saved.filter(v => v === true).length) {
          this.toastService.show('Clinical Notes Saved', { variant: 'success' });
        }
      }),
      map(v => v.filter(i => i !== null).reduce((o, i) => o && i, true))
    );
    if (this.validationListeners.length > 0) {
      return zip(
        ...this.validationListeners.map(listener =>
          listener.validate()
        )
      ).pipe(
        map(v => v.reduce((o, i) => o && i, true)),
        switchMap(valid => {
          if (valid) {
            return doSave();
          }
          setTimeout(() => this.scrollToError(), 0);
          this.toastService.show('Validation Errors', { variant: 'danger', delay: 30000 });
          return of(false);
        })
      )
    }
    return doSave();
  }

  private onFocus() {
    if (this.focused) {
      return;
    }
    this.focused = true;
    this.message('ui', 'focus');
  }

  private onBlur() {
    if (!this.focused) {
      return;
    }
    this.focused = false;
    this.message('ui', 'blur');
  }

  private scrollToError(): void {
    const elem = document.querySelector(
      `input.invalid,
        textarea.invalid,
        select.invalid,
        input.ng-invalid,
        textarea.ng-invalid,
        select.ng-invalid,
        .form-control.ng-invalid
        epione-intl-tel-input.ng-invalid,
        .ng-select.ng-invalid,
        .has-errors`
    );
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
}
