<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Allergies</label>
      </div>
    </div>
    <ng-container formArrayName="allergies">
      <div class="row g-2" *ngFor="let row of getRow().controls; let i = index" [formGroupName]="i">
        <div class="col-3">
          <preferences-typeahead
                                 alias="history_allergies-allergy"
                                 formControlName="allergy"
                                 placeholder="Start typing"
                                 jargonLoadingState="builder-control"></preferences-typeahead>
        </div>
        <div class="col-6">
          <input type="text" class="form-control" placeholder="Description" formControlName="description"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Diagnosis Date" formControlName="date_time" ngbDatepicker
                   [maxDate]="maxDateNow"
                   container="body" #allergiesDate="ngbDatepicker" (click)="allergiesDate.toggle()"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm"
                      type="button"
                      jargonLoadingState="builder-control"
                      (click)="removeRow( i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow()">Add +</button>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="data.allergies && data.allergies.length">
    [<span *ngFor="let c of data.allergies; let i = index">
      {{ i === 0 ? '' : ';'}}({{i + 1}}): {{c.allergy}} {{ !c.description || !c.date_time ? '' : '-' }}
      {{c.description}} {{ c.date_time | date: 'YYYY-MM-dd' }}
    </span>]
  </p>
</ng-template>