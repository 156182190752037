import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface GeneralFormData extends BuilderDataAbstract {
  cyanosis: BuilderPresentNote;
  anemia: BuilderPresentNote;
  jaundice: BuilderPresentNote;
  dehydration: BuilderPresentNote;
  clubbing: BuilderPresentNote;
  oedema: BuilderPresentNote;
  lymphadenopathy: BuilderPresentNote;
}

@Component({
  selector: 'app-cadjcol',
  templateUrl: './cadjcol.component.html',
  styleUrls: ['./cadjcol.component.scss']
})
export class CadjcolComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      cyanosis: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      anemia: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      jaundice: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      dehydration: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      clubbing: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      oedema: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      lymphadenopathy: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
    });
  }

  public getRow(form: keyof GeneralFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof GeneralFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: GeneralFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'CADJCOL:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: GeneralFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: CADJCOL`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value ? 'Present ' : 'Not Present '}${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as GeneralFormData;
    data.cyanosis['_modified'] = moment().valueOf();
    data.anemia['_modified'] = moment().valueOf();
    data.jaundice['_modified'] = moment().valueOf();
    data.dehydration['_modified'] = moment().valueOf();
    data.clubbing['_modified'] = moment().valueOf();
    data.oedema['_modified'] = moment().valueOf();
    data.lymphadenopathy['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['cyanosis'] = data.cyanosis;
      builderData['anemia'] = data.anemia;
      builderData['jaundice'] = data.jaundice;
      builderData['dehydration'] = data.dehydration;
      builderData['clubbing'] = data.clubbing;
      builderData['oedema'] = data.oedema;
      builderData['lymphadenopathy'] = data.lymphadenopathy;
    }
    return builderData;
  }

  private prepareSerializedList(data: GeneralFormData) {
    return [
      { title: 'Cyanosis', value: data.cyanosis.present, note: `${data.cyanosis.note ? ': ' + data.cyanosis.note : ''}` },
      { title: 'Anemia', value: data.anemia.present, note: `${data.anemia.note ? ': ' + data.anemia.note : ''}` },
      { title: 'Jaundice', value: data.jaundice.present, note: `${data.jaundice.note ? ': ' + data.jaundice.note : ''}` },
      { title: 'Dehydration/deformities', value: data.dehydration.present, note: `${data.dehydration.note ? ': ' + data.dehydration.note : ''}` },
      { title: 'Clubbing', value: data.clubbing.present, note: `${data.clubbing.note ? ': ' + data.clubbing.note : ''}` },
      { title: 'Oedema', value: data.oedema.present, note: `${data.oedema.note ? ': ' + data.oedema.note : ''}` },
      { title: 'Lymphadenopathy', value: data.lymphadenopathy.present, note: `${data.lymphadenopathy.note ? ': ' + data.lymphadenopathy.note : ''}` },
    ].filter(v => v.value !== null);
  }
}
