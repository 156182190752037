import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface UGTHistoryFormData extends BuilderDataAbstract {
  bom: BuilderPresentNote,
  polydipsia: BuilderPresentNote,
  genital_sores: BuilderPresentNote,
  genital_discharge: BuilderPresentNote
}

@Component({
  selector: 'app-ugt-history',
  templateUrl: './ugt-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './ugt-history.component.scss']
})
export class UgtHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      bom: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      polydipsia: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      genital_sores: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      genital_discharge: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      })
    });
    this.registerFormListener(true);
  }

  public getRow(form: keyof UGTHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof UGTHistoryFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: UGTHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'UGT HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: UGTHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: UGT HISTORY`,
      ...list.filter(v => v.value !== null).map(v => `${v.title}: ${v.value ? 'Present' : 'Not Present'}${v.note ? ': ' + v.note : ''}`)
    ].join('\n');
  }


  protected getBuilderData(): BuilderData {
    const data = this.formData.value as UGTHistoryFormData;
    data.bom['_modified'] = moment().valueOf();
    data.polydipsia['_modified'] = moment().valueOf();
    data.genital_sores['_modified'] = moment().valueOf();
    data.genital_discharge['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['bom'] = data.bom;
      builderData['polydipsia'] = data.polydipsia;
      builderData['genital_sores'] = data.genital_sores;
      builderData['genital_discharge'] = data.genital_discharge;
    }
    return builderData;
  }


  private prepareSerializedList(data: UGTHistoryFormData) {
    return [
      { title: 'BOM', value: data.bom.present, note: data.bom.note },
      { title: 'Polydipsia', value: data.polydipsia.present, note: data.polydipsia.note },
      { title: 'Genital Sores', value: data.genital_sores.present, note: data.genital_sores.note },
      { title: 'Genital Discharge', value: data.genital_discharge.present, note: data.genital_discharge.note }
    ].filter(v => v.value !== null);
  }
}
