<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <!--Headache-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Headache</label>
      </div>
      <div class="col" formGroupName="headache">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="text" class="form-control" style="flex: 1" formControlName="type"
                    jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Type</option>
              <option *ngFor="let type of types" [value]="type">{{ type }}</option>
            </select>
            <input type="text" class="form-control" placeholder="Notes" style="flex: 4" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Neck Stiffness-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Neck Stiffness</label>
      </div>
      <div class="col" formGroupName="neck_stiffness">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <div class="input-group w-auto">
              <input type="number" class="form-control" placeholder="How long?" formControlName="duration_days"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">{{ durationUnit }}</span>
            </div>
            <input type="text" class="form-control" placeholder="Notes" style="flex: 3" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Weakness-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Weakness</label>
      </div>
      <div class="col" formGroupName="weakness">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="text" class="form-control" style="flex: 1" formControlName="extent"
                    jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Extent</option>
              <option *ngFor="let e of extent" [value]="e">{{ e }}</option>
            </select>
            <ng-select [items]="regions"
                       [multiple]="true"
                       placeholder="Body Region(s)"
                       formControlName="regions"
                       appendTo="#app-wrapper"
                       [hideSelected]="true"
                       jargonLoadingState="builder-control"
                       bindLabel="name"
                       bindValue="name">
            </ng-select>
            <input type="text" class="form-control" placeholder="Notes" style="flex: 3" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Dizziness-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Dizziness</label>
      </div>
      <div class="col" formGroupName="dizziness">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Frequency-->
    <div *ngIf="showFrequency" class="row mb-3">
      <div class="col-2">
        <label class="form-label">Frequency</label>
      </div>
      <div class="col input-group cns-btn">
        <button [ngClass]="constant ? 'btn btn-primary' : 'btn btn-outline-primary'"
                jargonLoadingState="builder-control"
                (click)="resolveFrequency('constant')"
                type="button">Constant
        </button>
        <button [ngClass]="intermittent ? 'btn btn-primary' : 'btn btn-outline-primary'"
                jargonLoadingState="builder-control"
                (click)="resolveFrequency('intermittent')"
                type="button">Intermittent
        </button>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span *ngIf="item.title !== 'Frequency'; else frequency">[{{ item.value ? 'Present' : 'Not Present'
      }}{{item.note}}]</span>
    <ng-template #frequency><span>[{{item.value | titlecase}}]</span></ng-template>
  </p>
</ng-template>