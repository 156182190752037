import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { ReviewAction } from '../../services/common/review.service';
import { UIComponentAlias, UiService, UIToggleEvent } from '../../services/common/ui.service';

@Component({
  selector: 'app-review-actions',
  templateUrl: './review-actions.component.html',
  styleUrls: ['./review-actions.component.scss']
})
export class ReviewActionsComponent implements OnInit {

  @Input('alias') public alias?: string;
  @Input('commentQty') public commentQty?: number;
  @Output('reviewAction') reviewAction: EventEmitter<ReviewAction> = new EventEmitter<ReviewAction>();

  public isReviewOpen: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private uiService: UiService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.uiService.events.pipe(
      tap(({ component, state, options }: UIToggleEvent) => {
        if (component === UIComponentAlias.SIDEBAR_REVIEWS) {
          if (!state) {
            // always close
            this.isReviewOpen = false;
          } else if (options && options['sectionAlias'] === this.alias) {
            // only open if its for this alias
            this.isReviewOpen = true;
          }
        }
      })
    ).subscribe());
  }

  toggleNotes(action: ReviewAction) {
    this.reviewAction.emit(action);
  }

}
