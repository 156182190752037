<div class="container-fluid">
  <div class="row>">
    <div class="col-12 mt-2">
      <input class="form-control" placeholder="Search">
    </div>
    <div class="col-12 mt-2">
      <app-jargon-table [config]="tableConfig"></app-jargon-table>
    </div>
    <div class="col-12">
      <app-pagination [pagination]="pagination" (change)="reload($event)"></app-pagination>
    </div>
  </div>
</div>
