import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { CoughType, MMRC, Sputum } from './respiratory-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge, tap } from 'rxjs';

interface RESPHistoryFormData extends BuilderDataAbstract {
  cough: {
    present: boolean;
    type: string;
    sputum: string;
    volume_daily_ml: number;
    duration_days: number;
    note: string;
    _modified: number;
  };
  sore_throat: {
    present: boolean;
    note: string;
    _modified: number;
  };
  pnd: {
    present: boolean;
    note: string;
    _modified: number;
  };
  running_nose: {
    present: boolean;
    note: string;
    _modified: number;
  };
  congestion: {
    present: boolean;
    note: string;
    _modified: number;
  };
  dyspnoea: {
    present: boolean;
    duration: string;
    note: string;
    _modified: number;
  };
}

@Component({
  selector: 'app-respiratory-history',
  templateUrl: './respiratory-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './respiratory-history.component.scss']
})
export class RespiratoryHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public coughType: Array<string> = CoughType();
  public sputum: Array<string> = Sputum();
  public mmrc: Array<string> = MMRC();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      cough: this.fb.group({
        present: [null],
        type: [null],
        sputum: [null],
        volume_daily_ml: [null],
        duration_days: [null],
        note: [null, Validators.maxLength(500)]
      }),
      sore_throat: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      pnd: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      running_nose: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      congestion: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      dyspnoea: this.fb.group({
        present: [null],
        duration: [null],
        note: [null, Validators.maxLength(500)]
      })
    });

    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  get durationUnit() {
    return (this.formData.get('cough') as FormGroup).get('duration_days')?.value === 1 ? 'day' : 'days';
  }

  public getRow(form: keyof RESPHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof RESPHistoryFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: RESPHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'RESPIRATORY HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: RESPHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: RESPIRATORY HISTORY`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value ? 'Present' : 'Not Present'}${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as RESPHistoryFormData;
    data.cough['_modified'] = moment().valueOf();
    data.sore_throat['_modified'] = moment().valueOf();
    data.pnd['_modified'] = moment().valueOf();
    data.running_nose['_modified'] = moment().valueOf();
    data.congestion['_modified'] = moment().valueOf();
    data.dyspnoea['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['cough'] = data.cough;
      builderData['sore_throat'] = data.sore_throat;
      builderData['pnd'] = data.pnd;
      builderData['running_nose'] = data.running_nose;
      builderData['congestion'] = data.congestion;
      builderData['dyspnoea'] = data.dyspnoea;
    }
    return builderData;
  }

  override registerFormListener() {
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: control => {
        switch (control.get('present')?.value) {
          case true:
          case false:
            control.get('note')?.enable({ emitEvent: false });
            control.get('duration_days')?.enable({ emitEvent: false });
            control.get('duration')?.enable({ emitEvent: false });
            control.get('type')?.enable({ emitEvent: false });
            if (control.get('type')?.value !== 'Dry') {
              control.get('sputum')?.enable({ emitEvent: false });
              control.get('volume_daily_ml')?.enable({ emitEvent: false });
            } else {
              control.get('sputum')?.patchValue(null, { emitEvent: false });
              control.get('sputum')?.disable({ emitEvent: false });
              control.get('volume_daily_ml')?.patchValue(null, { emitEvent: false });
              control.get('volume_daily_ml')?.disable({ emitEvent: false });
            }
            break;
          case null:
            control.get('note')?.disable({ emitEvent: false });
            control.get('type')?.disable({ emitEvent: false });
            control.get('sputum')?.disable({ emitEvent: false });
            control.get('volume_daily_ml')?.disable({ emitEvent: false });
            control.get('duration_days')?.disable({ emitEvent: false });
            control.get('duration')?.disable({ emitEvent: false });
            control.get('note')?.patchValue('', { emitEvent: false });
            control.get('type')?.patchValue(null, { emitEvent: false });
            control.get('sputum')?.patchValue(null, { emitEvent: false });
            control.get('volume_daily_ml')?.patchValue(null, { emitEvent: false });
            control.get('duration_days')?.patchValue(null, { emitEvent: false });
            control.get('duration')?.patchValue(null, { emitEvent: false });
            break;
        }
      }
    });
  }

  private prepareSerializedList(data: RESPHistoryFormData) {
    return [
      { title: 'Cough', value: data.cough.present, note: `${data.cough.type ? ': ' + data.cough.type : ''}${data.cough.sputum ? '; ' + data.cough.sputum : ''}${data.cough.volume_daily_ml ? '; +/-' + data.cough.volume_daily_ml + 'ml/day' : ''}${data.cough.duration_days ? '; for ' + data.cough.duration_days + ' days' : ''}` },
      { title: 'Sore Throat', value: data.sore_throat.present, note: `${data.sore_throat.note ? ': ' + data.sore_throat.note : ''}` },
      { title: 'PND', value: data.pnd.present, note: `${data.pnd.note ? ': ' + data.pnd.note : ''}` },
      { title: 'Runny Nose', value: data.running_nose.present, note: `${data.running_nose.note ? ': ' + data.running_nose.note : ''}` },
      { title: 'Congestion', value: data.congestion.present, note: `${data.congestion.note ? ': ' + data.congestion.note : ''}` },
      { title: 'Dyspnoea', value: data.dyspnoea.present, note: `${data.dyspnoea.duration ? ': ' + data.dyspnoea.duration : ''}${data.dyspnoea.note ? data.dyspnoea.duration ? ', ' + data.dyspnoea.note : ': ' + data.dyspnoea.note : ''}` },
    ].filter(v => v.value !== null);
  }
}
