
export const FHRelations = (): Array<string> => ([
  'Mother',
  'Father',
  'Sister',
  'Brother',
  'Uncle (maternal)',
  'Aunt (maternal)',
  'Uncle (paternal)',
  'Aunt (paternal)',
  'Grandmother (maternal)',
  'Grandfather (maternal)',
  'Grandmother (paternal)',
  'Grandfather (paternal)',
].sort((a, b) => a.localeCompare(b)));
