import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractSectionComponent } from '../../shared/components/abstract-section/abstract-section.component';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveUserService } from '../../../../shared/services/common/active-user.service';
import { ClinicalNoteService } from '../../../../shared/services/common/clinical-note.service';
import { ReviewService } from '../../../../shared/services/common/review.service';
import * as moment from 'moment';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { MedpraxICD10CodeQueryItem } from 'src/app/shared/types/medprax';
import { validateAllFormFields } from 'src/app/shared/validators';
import { MedpraxCodeValue } from 'src/app/shared/components/medprax-code-typeahead/medprax-code-typeahead.component';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { ClinicalNoteModel } from 'src/app/shared/models/clincal-note.model';

interface DiagnosisFormData extends Partial<ClinicalNoteModel> {
  differential: string;
  diagnoses: Array<{
    _id: string;
    _modified: number;
    icd10: MedpraxICD10CodeQueryItem;
    type: 'chronic' | 'acute';
    description: string;
  }>;
}

@Component({
  selector: 'app-diagnosis-section',
  templateUrl: './diagnosis-section.component.html',
  styleUrls: ['../../shared/components/abstract-section/abstract-section.component.scss', './diagnosis-section.component.scss']
})
export class DiagnosisSectionComponent extends AbstractSectionComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public formData: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      differential: [''],
      diagnoses: this.fb.array([])
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.data && this.data.diagnoses && this.data.diagnoses.length) {
      this.data.diagnoses?.forEach(() => this.addDiagnosis());
    } else {
      this.addDiagnosis();
    }
    this.formData.patchValue(this.data);
  }

  public addDiagnosis() {
    const group = this.fb.group({
      icd10: [null, Validators.required],
      type: [null, Validators.required],
      description: [null, Validators.maxLength(500)]
    });
    (this.formData.get('diagnoses') as FormArray).push(group);
  }

  public populateDescription(code: MedpraxCodeValue, group: AbstractControl) {
    if (!code) {
      return;
    }
    const value: { description: null | string, type?: null | string } = { description: code ? (code as MedpraxICD10CodeQueryItem).Description : null };
    if ((code as MedpraxICD10CodeQueryItem).IsCdl) {
      value['type'] = 'chronic';
    }
    group.patchValue(value, { emitEvent: false });
  }

  public getRow(): FormArray {
    return this.formData.get('diagnoses') as FormArray;
  }

  public removeRow(idx: number) {
    this.getRow().removeAt(idx);
  }

  public override async validate(): Promise<boolean> {
    let empty = false;
    if (this.formData.value.diagnoses && this.formData.value.diagnoses.length === 1) {
      empty = !!Object.values(this.formData.value.diagnoses[0]).reduce((out, value) => {
        if (value !== null && typeof value === 'object') {
          return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
        }
        return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
      }, true);
      if (empty) {
        this.removeRow(0);
      }
    }

    validateAllFormFields(this.formData);
    const res = this.formData.valid;
    if (empty) {
      this.addDiagnosis();
    }
    return res;
  }

  public override async save(): Promise<boolean | null> {
    let empty = false;
    if (this.formData.value.diagnoses && this.formData.value.diagnoses.length === 1) {
      empty = !!Object.values(this.formData.value.diagnoses[0]).reduce((out, value) => {
        if (value !== null && typeof value === 'object') {
          return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
        }
        return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
      }, true);
      if (empty) {
        this.removeRow(0);
      }
    }

    const data = this.formData.value as DiagnosisFormData;
    this.data.differential = data.differential;
    this.data.diagnoses = data.diagnoses.map(d => ({ ...d, _modified: moment().valueOf() }));

    let res = await super.save();

    if (empty) {
      this.addDiagnosis();
    }

    return res;
  }

  override serialize(data: DiagnosisFormData): HistoryData[] {
    if (!data || !data.diagnoses || data.diagnoses.length === 0) {
      return [...super.serialize(data)];
    }

    return [...super.serialize(data), {
      type: 'section',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  override asString(data: DiagnosisFormData): string {
    const dataStrings = (data.diagnoses?.map(
      (c, i) => `(${i + 1}) ${c.icd10.Code} - ${c.description} (Condition Type: ${c.type})`
    ) || []);

    if (dataStrings.length > 0) {
      dataStrings.unshift(`[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author?.name ? `; ${data.author?.name}` : ''}]:`)
    }

    const notes = (data.notes || []).sort((a, b) => a._modified && b._modified ? (a._modified > b._modified ? 1 : -1) : -1)
    return [
      notes.filter(v => !v._modified || v._modified < data._modified!).map(
        (note) => `[${moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z')}; ${note.author.name}] ${note.content}`
      ),
      ...dataStrings,
      notes.filter(v => v._modified && v._modified > data._modified!).map(
        (note) => `[${moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z')}; ${note.author.name}] ${note.content}`
      )
    ].filter(v => !!v && v !== '').join('\n');
  }
}
