import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { FHRelations } from './family-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

type FHDynamicRows = 'relatives' | 'diseases';

type FHData = {
  _modified: number;
  relation: string;
  illness: string;
  description: string;
}

interface FHFormData extends BuilderDataAbstract {
  relatives: Array<FHData>;
  diseases: Array<FHData>;
}

@Component({
  selector: 'app-family-history',
  templateUrl: './family-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './family-history.component.scss']
})
export class FamilyHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public relations: Array<string> = FHRelations();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      relatives: this.fb.array([]),
      diseases: this.fb.array([])
    });
    this.addRow('relatives');
    this.addRow('diseases');
  }

  public getRow(form: FHDynamicRows): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: FHDynamicRows, idx: number) {
    this.getRow(form).removeAt(idx);
    if (this.getRow(form).length === 0) {
      this.addRow(form);
    }
  }

  public addRow(form: FHDynamicRows) {
    const row = {
      relatives: {
        relation: [null, Validators.required],
        illness: [null, Validators.required],
        description: [null]
      },
      diseases: {
        relation: [null, Validators.required],
        illness: [null, Validators.required],
        description: [null]
      }
    }[form];
    const group = this.fb.group(row);
    this.getRow(form).push(group);
  }

  public override async validate() {
    const data = this.formData.value as FHFormData;

    let fHControls: Array<FHDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          return out && (value === null || value === '')
        }, true);
        if (empty) {
          (this.formData.get(control as FHDynamicRows) as FormArray).removeAt(0);
          fHControls.push(control as FHDynamicRows);
        }
      }
    });

    validateAllFormFields(this.formData);
    let res = this.formData.valid;

    if (fHControls.length > 0) {
      fHControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  public override async saveData() {
    const data = this.formData.value as FHFormData;

    let fHControls: Array<FHDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          return out && (value === null || value === '')
        }, true);
        if (empty) {
          (this.formData.get(control as FHDynamicRows) as FormArray).removeAt(0);
          fHControls.push(control as FHDynamicRows);
        }
      }
    });

    let res = await super.saveData();

    if (fHControls.length > 0) {
      fHControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  serialize(data: FHFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'FAMILY HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: FHFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: FAMILY HISTORY`,
      ...(data.relatives && data.relatives.length ? [
        `Relatives with Illnesses`,
        ...data.relatives.map((c, i) => `(${i + 1}): ${c.relation} - ${c.illness}, ${c.description}`)
      ] : []),
      ...(data.diseases && data.diseases.length ? [
        `Infectious Diseases`,
        ...data.diseases.map((c, i) => `(${i + 1}): ${c.relation} - ${c.illness}, ${c.description}`)
      ] : []),
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as FHFormData;
    data.relatives = data.relatives.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf() }));
    data.diseases = data.diseases.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['relatives'] = data.relatives;
      builderData['diseases'] = data.diseases;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['relatives'].length; i++) {
      this.addRow('relatives');
    }
    for (let i = 1; i < data['diseases'].length; i++) {
      this.addRow('diseases');
    }
    super.setBuilderData(data);
  }

}
