import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateParserFormatter, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EpioneDateFormatter } from './shared/filters/epione-date-formatter';
import { ActiveUserService, ActiveUserServiceFactory } from './shared/services/common/active-user.service';
import { httpInterceptorProviders } from './shared/interceptors';
import { UiModule } from './modules/ui/ui.module';
import { HttpClientModule } from '@angular/common/http';
import { ClinicalNoteService, ClinicalNoteServiceFactory } from './shared/services/common/clinical-note.service';
import { PagesModule } from './modules/pages/pages.module';
import { UnixDateAdapter } from './shared/adapters/unix-date-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WidgetModule } from './modules/widget/widget.module';
import { SettingsModule } from './modules/settings/settings.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    UiModule,
    PagesModule,
    SettingsModule,
    BrowserAnimationsModule,
    WidgetModule,
    SharedModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NgbDateParserFormatter, useClass: EpioneDateFormatter },
    { provide: NgbDateAdapter, useClass: UnixDateAdapter },
    httpInterceptorProviders,
    ActiveUserService,
    ClinicalNoteService,
    { provide: APP_INITIALIZER, useFactory: ActiveUserServiceFactory, deps: [ActiveUserService], multi: true },
    { provide: APP_INITIALIZER, useFactory: ClinicalNoteServiceFactory, deps: [ClinicalNoteService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
