import { AfterViewInit, Component } from '@angular/core';
import { Pagination } from '../../../../shared/types/paginatedResponse';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { TableConfig } from '../../../../shared/components/jargon-table/jargon-table.component';

export type ImagingResult = {
  id: number;
  date: string;
  radiologist: string;
  referring_doctor: string;
  imaging: string;
  comments: string;
};

@Component({
  selector: 'app-imaging',
  templateUrl: './imaging.component.html',
  styleUrls: ['./imaging.component.scss']
})
export class ImagingComponent extends AbstractEHRTabComponent implements AfterViewInit {

  public pagination?: Pagination;

  public tableConfig: TableConfig<ImagingResult> = {
    columns: [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Radiologist',
        field: 'radiologist'
      },
      {
        title: 'Referring Practitioner',
        field: 'referring_doctor'
      },
      {
        title: 'Imaging',
        field: 'imaging'
      },
      {
        title: 'Notes/Comments',
        field: 'comments'
      },
      {
        title: 'Action',
        column_slot: 'action'
      }
    ],
    data: []
  };

  constructor() {
    super();
    this.pagination = {
      total: 6,
      count: 6,
      per_page: 5,
      current_page: 1,
      total_pages: 2,
      links: {
        previous: '',
        next: '',
      }
    };
  }

  public reload(page?: number) {
    //@TODO: add reload logic
  }

  public downloadImage(id: number) {
    //@TODO: add download logic
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableConfig.data = [
        {
          id: 1,
          date: '2022-01-20',
          radiologist: 'Solomon & Partners',
          referring_doctor: 'Dr. Samantha Fee',
          imaging: 'X-Ray (Chest)',
          comments: 'Lorem ipsum dolor sit amet'
        },
        {
          id: 2,
          date: '2021-10-12',
          radiologist: 'Dr Russel Seider',
          referring_doctor: 'Dr. Sian Basnett',
          imaging: 'X-Ray (Right Leg)',
          comments: 'None'
        },
        {
          id: 3,
          date: '2021-04-01',
          radiologist: 'Solomon & Partners',
          referring_doctor: 'Dr. Darren Nyoni',
          imaging: 'CT Scan (Chest)',
          comments: 'Lorem ipsum dolor sit amet'
        },
        {
          id: 4,
          date: '2020-10-14',
          radiologist: 'Dr Russel Seider',
          referring_doctor: 'Dr. Samantha Fee',
          imaging: 'MRI (Head)',
          comments: 'Lorem ipsum dolor sit amet'
        },
        {
          id: 5,
          date: '2019-02-06',
          radiologist: 'Dr Ryan Rynolds',
          referring_doctor: 'Dr Gerrad Battler',
          imaging: 'X-Ray (Left Leg)',
          comments: 'None'
        },
      ];
    }, 0);
  }

}
