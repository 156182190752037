import { Component, Injector } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, Validators } from '@angular/forms';
import { validateAllFormFields } from '../../../../shared/validators';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

export interface UgtExamFormData extends BuilderDataAbstract {
  note: string;
}

@Component({
  selector: 'app-ugt-exam',
  templateUrl: './ugt-exam.component.html',
  styleUrls: ['./ugt-exam.component.scss']
})
export class UgtExamComponent extends AbstractBuilderComponent {

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      present: [null],
      note: [null, [Validators.required, Validators.maxLength(500)]]
    });
  }

  public clearInput() {
    this.formData.get('note')?.reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: UgtExamFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'UGT EXAM:',
      builderTitleData: `${' [' + data.note + ']'}`,
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')
    }];
  }

  asString(data: UgtExamFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: UGT EXAM`,
      data.note,
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as UgtExamFormData;
    data['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['ugt_data'] = data;
    }
    return builderData;
  }

}
