import { Injectable } from '@angular/core';
import { CallsAPI } from '../abstract/http/calls-api';
import { environment } from '../../../../environments/environment';
import { HttpOptions } from '../abstract/http/http-api.service';
import { catchError, Observable, throwError } from 'rxjs';
import { SettingsModel } from '../../models/settings.model';
import { ProviderTestModel } from '../../models/provider-test.model';

export type SettingsTypes = 'user' | 'practice';

@Injectable({
  providedIn: 'root'
})
export class SettingsHttpService extends CallsAPI<SettingsModel, SettingsModel[]> {
  protected apiBasePath: string = environment.api;
  protected resourcePath: string = '/{:type}/{:userId}/preferences/{:alias}';

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public create<T>(userId: string | number, alias: string, body: T, options?: HttpOptions, type: SettingsTypes = 'user'): Observable<SettingsModel<T>> {
    return this.http.post<SettingsModel<T>>(this.buildAction('/', { userId, alias, type }), body, this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(() => this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {*} id
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public update<T>(userId: string | number, alias: string, body: T, options?: HttpOptions, type: SettingsTypes = 'user'): Observable<SettingsModel<T>> {
    return this.http.patch<SettingsModel<T>>(this.buildAction('/', { userId, alias, type }), body, this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(() => this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {*} id
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public find<T>(userId: string | number, alias: string, options?: HttpOptions, type: SettingsTypes = 'user'): Observable<SettingsModel<T>> {
    return this.http.get<SettingsModel<T>>(this.buildAction('/', { userId, alias, type }), this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(() => this.handleValidationErrors(err));
      })
    );
  }

}
