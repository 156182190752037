import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { BuilderSidebarComponent } from './builder-sidebar/builder-sidebar.component';
import { NotesComponent } from './notes/notes.component';
import { CommentsComponent } from './comments/comments.component';
import { UIRoutingModule } from './ui-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { SectionsModule } from '../sections/sections.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BuildersModule } from '../builders/builders.module';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { EhrModule } from '../ehr-tabs/ehr.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    BuilderSidebarComponent,
    NotesComponent,
    CommentsComponent,
    FooterComponent
  ],
  imports: [
    UIRoutingModule,
    CommonModule,
    MatIconModule,
    BuildersModule,
    SectionsModule,
    DragDropModule,
    NgbNavModule,
    EhrModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    FormsModule,
    SharedModule
  ]
})
export class UiModule { }
