import { Component } from '@angular/core';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { EHRSectionModel } from '../../../../shared/components/medical-and-family-history/medical-and-family-history.component';
import { FhirEHRHttpService } from 'src/app/shared/services/http/fhir-ehr-http.service';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { catchError, finalize, firstValueFrom, forkJoin, map, merge, of, take, tap } from 'rxjs';
import { FhirAllergyIntoleranceModel } from 'src/app/shared/models/fhir/allergy-intolerance.model';
import { FhirMedicationStatementModel } from 'src/app/shared/models/fhir/medication-usage.model';
import * as moment from 'moment';
import { FhirConditionModel } from 'src/app/shared/models/fhir/condition.model';
import { FhirEncounterModel } from 'src/app/shared/models/fhir/encounter.model';
import { FhirOrganisationModel } from 'src/app/shared/models/fhir/organisation.model';
import { FhirProcedureModel } from 'src/app/shared/models/fhir/procedure.model';
import { FhirLocationModel } from 'src/app/shared/models/fhir/location.model';
import { FhirBundleResourceModel } from 'src/app/shared/models/fhir/fhir-resource.model';

const DATA_CACHE: {
  timestamp: number;
  data: EHRSectionModel[];
} = {
  timestamp: 0,
  data: []
};

@Component({
  selector: 'ehrTab-past-medical-history',
  templateUrl: './past-medical-history.component.html',
  styleUrls: ['../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component.scss', './past-medical-history.component.scss']
})
export class PastMedicalHistoryComponent extends AbstractEHRTabComponent {

  public pmhSections: EHRSectionModel[] = [];
  public loadingStates: { [key: string]: boolean } = {};

  constructor(
    private fhirhttp: FhirEHRHttpService,
    private clinicalNoteService: ClinicalNoteService
  ) {
    super();
  }

  get isLoading(): boolean {
    return false === Object.keys(this.loadingStates).reduce((out, key) => {
      return out && this.loadingStates[key];
    }, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // if (DATA_CACHE.timestamp < moment().subtract(1, 'minute').valueOf()) {
    firstValueFrom(forkJoin([
      this.loadAllergies().pipe(catchError(e => { console.error(e); return of(null); })),
      this.loadMedicationUsage().pipe(catchError(e => { console.error(e); return of(null); })),
      this.loadConditions().pipe(catchError(e => { console.error(e); return of(null); })),
      this.loadAdmissions().pipe(catchError(e => { console.error(e); return of(null); })),
      this.loadProcedures().pipe(catchError(e => { console.error(e); return of(null); }))
    ]).pipe(
      tap((sections: Array<EHRSectionModel | null>) => {
        this.pmhSections = (sections.filter(v => v !== null) as EHRSectionModel[]).sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        DATA_CACHE.data = this.pmhSections;
        DATA_CACHE.timestamp = moment().valueOf();
      })
    ));
    // } else {
    //   this.pmhSections = DATA_CACHE.data;
    // }
  }

  private loadAllergies() {
    const title = 'Allergies';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirAllergyIntoleranceModel>('AllergyIntolerance', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'allergies',
          order: 1,
          data: data.entry.map(entry => entry.resource['code'].text as string)
        };
        return section
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

  private loadMedicationUsage() {
    const title = 'Medications';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirMedicationStatementModel>('MedicationStatement', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'medications',
          order: 3,
          data: data.entry.map(entry => {
            const date = moment(entry.resource.effectiveDateTime);
            return `${entry.resource.medication.concept?.text || ''} ${entry.resource.dosage[0].text || ''} (${date && date.isValid() ? date.format('YYYY-MM-DD') : 'Not Specified'})`;
          })
        };
        return section;
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

  private loadConditions() {
    const title = 'Chronic Conditions';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirConditionModel>('Condition', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'chronic_conditions',
          order: 2,
          data: data.entry.map(entry => `${entry.resource.code.text}`)
        };
        return section;
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

  private loadAdmissions() {
    const title = 'Hospital Admissions';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirEncounterModel>('Encounter', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'hospital_admissions',
          order: 3,
          data: data.entry.map(entry => {
            const reason = (entry.resource.contained.find(({ id }) => id === entry.resource.reason[0].reference?.reference.substring(1)) as FhirConditionModel);
            const detail = reason?.note.map(n => n.text).join(';');
            const code = reason?.code.coding && reason?.code.coding.length ? ` (${reason.code.coding[0].code})` : (reason?.code.text ? ` (${reason?.code.text})` : '');
            const facility = (entry.resource.contained.find(({ id }) => id === entry.resource.serviceProvider.reference.substring(1)) as FhirOrganisationModel)?.name;
            const date = moment(entry.resource.plannedStartDate);
            return `${detail}${code} at ${facility} (${date && date.isValid() ? date.format('YYYY-MM-DD') : 'Not Specified'})`;
          })
        };
        return section;
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

  private loadProcedures() {
    const title = 'Surgeries/Procedures';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirProcedureModel>('Procedure', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'hospital_admissions',
          order: 3,
          data: data.entry.map(entry => {
            const detail = entry.resource.note.map(n => n.text).join(';');
            const code = entry.resource.code.coding && entry.resource.code.coding.length ? entry.resource.code.coding![0].code : entry.resource.code.text;
            const location = (entry.resource.contained.find(({ id }) => id === entry.resource.location.reference.substring(1)) as FhirLocationModel);
            const facility = location && location.contained ? (location.contained.find(({ id }) => id === location.managingOrganization.reference.substring(1)) as FhirOrganisationModel)?.name : null;
            const date = moment(entry.resource.occurrenceDateTime);
            return `${detail} (${code})${facility ? ` at ${facility}` : ''} (${date && date.isValid() ? date.format('YYYY-MM-DD') : 'Not Specified'})`;
          })
        };
        return section;
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }
}
