<div class="container-fluid g-0" *ngIf="pagination">
  <div class="row justify-content-end">
    <div class="col-auto">
      <ngb-pagination
        [(page)]="page"
        [pageSize]="pagination.per_page"
        [collectionSize]="pagination.total"
        [rotate]="rotate"
        [maxSize]="maxSize"
        [boundaryLinks]="boundaryLinks"></ngb-pagination>
    </div>
  </div>
</div>
