import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MedpraxTariffCodeQueryItem } from '../../types/medprax';
import { HttpApiService } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class MedpraxTariffHttpService extends HttpApiService<MedpraxTariffCodeQueryItem> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/coding/medprax/tariff';
}