

import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ClinicalNoteModel, NoteDataModel } from 'src/app/shared/models/clincal-note.model';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { SectionControls } from '../section-item/section-item.component';
import { ReviewAction, ReviewService } from '../../../../../shared/services/common/review.service';
import { Observable, from, catchError, forkJoin, tap, map } from 'rxjs';
import { LoadingStateService } from '../../../../../shared/services/common/loading-state.service';
import { HistoryData } from '../../../../../shared/components/history-view/history-view.component';
import * as moment from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { UiService } from 'src/app/shared/services/common/ui.service';

@Component({ template: '' })
export class AbstractSectionComponent implements OnInit, OnDestroy {
  @Input('config') config!: SectionConfigModel;
  @Input('controls') controls!: SectionControls;

  public noteData: HistoryData[] = [];
  public isSaving: boolean = false;
  public editing?: NoteDataModel;
  public note: FormControl = new FormControl<any>('', [Validators.maxLength(1500)]);
  private _data!: ClinicalNoteModel;

  protected activeUserService: ActiveUserService = inject(ActiveUserService);
  protected clinicalNoteService: ClinicalNoteService = inject(ClinicalNoteService);
  protected reviewService: ReviewService = inject(ReviewService);
  protected loadingStateService: LoadingStateService = inject(LoadingStateService);
  protected dialogService: DialogService = inject(DialogService);
  protected uiService: UiService = inject(UiService);
  constructor() {
  }

  ngOnInit(): void {
    this.init();
    this.uiService.addValidationListener({
      alias: this.config.alias,
      validate: () => this.validateSection()
    });
    this.uiService.addSaveListener({
      alias: this.config.alias,
      save: () => this.saveSection()
    });
  }

  ngOnDestroy(): void {
    this.teardown();
  }

  init(): void {
  }

  teardown(): void {
  }

  @Input('data') set data(d: ClinicalNoteModel) {
    this._data = d;
    this.setNoteData();
  }

  get data(): ClinicalNoteModel {
    return this._data;
  }

  saveSection(): Observable<any> {
    return from(this.save()).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  validateSection(): Observable<boolean> {
    return forkJoin([
      from(this.validate()),
      from(this.controls.validateBuilders()).pipe(
        tap(v => {
          if (!v.valid) {
            this.controls.openBuilder(v.invalidBuilders[0])
          }
        })
      )
    ]).pipe(
      map(([validSection, { valid: validBuilders }]) => {
        return validSection && validBuilders;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  serialize(data: Partial<ClinicalNoteModel>): HistoryData[] {
    return (data.notes || []).map<HistoryData>((note) => ({
      author: note.author.name,
      dateTime: moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z'),
      content: note.content,
    }));
  }

  asString(data: Partial<ClinicalNoteModel>): string {
    return (data.notes || []).map(
      (note) => `[${moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z')}; ${note.author.name}] ${note.content}`
    ).join('\n');
  }

  setNoteData() {
    const notes = this.data.notes ? this.data.notes.map((note) => ({
      author: note.author.name,
      dateTime: moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z'),
      content: note.content,
      ...(!this.clinicalNoteService.isReadOnly && note._id ? {
        actions: [
          { label: 'Edit', action: () => this.editNote(note) },
          { label: 'Delete', action: () => this.deleteNote(note) }
        ]
      } : {})
    })) : [];
    this.controls.getSerializedBuilders().then(v => {
      this.noteData = [...notes, ...v];
    });
  }

  async editNote(note: NoteDataModel): Promise<void> {
    const idx = this.data.notes?.findIndex(n => n._id === note._id);
    if (idx !== undefined && idx !== -1) {
      const content = note.content;
      this.data.notes?.splice(idx, 1);
      this.setNoteData();
      setTimeout(() => this.note.patchValue(content));
    }
  }

  async deleteNote(note: NoteDataModel): Promise<void> {
    this.dialogService.show({
      title: 'Delete Written Note',
      message: 'Are you sure you want to remove this written note?',
      actions: [
        {
          text: 'Confirm',
          action: async (): Promise<boolean> => {
            const idx = this.data.notes?.findIndex(n => n._id === note._id);
            if (idx !== undefined && idx !== -1) {
              this.data.notes?.splice(idx, 1);
              this.doSave();
            }
            return true;
          }
        },
        {
          text: 'Cancel',
          action: async (): Promise<boolean> => true
        }
      ]
    });
  }

  handleReviewAction(action: ReviewAction) {
    switch (action) {
      case 'open':
        this.reviewService.open(this.config);
        break;
      case 'close':
        this.reviewService.close();
        break;
      case 'add':
        this.reviewService.create();
        break;
    }
  }

  // to be overridden
  async save(): Promise<boolean | null> {
    return this.doSave();
  }

  // to be overridden
  async validate(): Promise<boolean> {
    return true;
  }

  async doSave(): Promise<boolean | null> {
    const res = await this.controls.save();
    this.note.reset();
    return res;
  }

  isEmpty<T extends { [key: string]: any }>(data: T, fields: Array<string>, allowNulls: boolean = false): boolean {
    for (let field of fields) {
      if (typeof data[field] !== 'undefined' && (allowNulls || data[field] !== null)) {
        return false;
      }
    }
    return true;
  }

}
