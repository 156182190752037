<ng-select #codeSelect [items]="codes$ | async"
           [multiple]="multiple"
           appendTo="#app-wrapper"
           [bindLabel]="bindLabel"
           [placeholder]="placeholder"
           [hideSelected]="true"
           [trackByFn]="trackByFn"
           [minTermLength]="2"
           [disabled]="disabled"
           [loading]="codesLoading"
           [addTag]="true"
           addTagText="Use - "
           typeToSearchText="Please enter 2 or more characters"
           [typeahead]="codesInput$"
           (keyup.enter)="onKeyUp($event, codeSelect)"
           (blur)="onBlur(codeSelect)"
           [(ngModel)]="selectedCodes"
           (open)="onDropdownOpen()"
           (change)="onSelect()">
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <!-- Check if item is a string -->
        <div *ngIf="isString(item) else defaultItemTemplate">
            <span class="ng-value-label">
                <strong>{{ item }}</strong>
            </span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>

        <ng-template #defaultItemTemplate>
            <div *ngIf="item.FullDescription || item.Description else noCodes">
                <span class="ng-value-label">
                    <strong>[{{ item[bindLabel] }}]</strong>
                    <span>{{item.FullDescription || item.Description}}</span>
                    {{isNappi ? (item.RxUnit ? '('+item.DispensingVolumePacksize + ' ' + item.RxUnit + ')' : '' ) : ''}}
                </span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
        </ng-template>
    </ng-template>

    <ng-template #noCodes>
        <div>
            <span class="ng-value-label">{{searchTerm}}</span>
        </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice:0:2">
            <span class="ng-value-label"
                  [title]="($any(item).FullDescription || $any(item).Description) + (isNappi ? ($any(item).RxUnit ? '('+$any(item).DispensingVolumePacksize + ' ' + $any(item).RxUnit + ')' : '') : '')">
                <strong>{{ $any(item)[bindLabel] }}</strong>
            </span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{items.length - 2}} more...</span>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <figure class="medprax-typeahead-option">
            <blockquote class="blockquote">
                <strong>[{{ item[bindLabel] }}]</strong>
                <span>{{item.FullDescription || item.Description}}</span>
                {{isNappi ? (item.RxUnit ? '('+item.DispensingVolumePacksize + ' ' + item.RxUnit + ')' : '' ) : ''}}
            </blockquote>
            <figcaption class="blockquote-footer" *ngIf="isNappi && item.Manufacturer">
                <small class="text-muted">
                    <strong>[{{item.Manufacturer.Code}}]</strong>
                    {{item.Manufacturer.Description}}
                </small>
            </figcaption>
        </figure>
    </ng-template>
</ng-select>