export const CGSScale: Array<number> = [
  3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
];

export const CGSRegions = (): Array<{ name: string, value: number}> => ([
  {
    name: 'Right biceps',
    value: 0
  },
  {
    name: 'Left biceps',
    value: 1
  },
  {
    name: 'Right radial brachialis',
    value: 2
  },
  {
    name: 'Left radial brachialis',
    value: 3
  },
  {
    name: 'Right triceps',
    value: 4
  },
  {
    name: 'Left triceps',
    value: 5
  },
  {
    name: 'Right distal finger flexes',
    value: 6
  },
  {
    name: 'Right distal finger flexes',
    value: 7
  },
  {
    name: 'Left distal finger flexes',
    value: 8
  },
  {
    name: 'Right knee jerk',
    value: 9
  },
  {
    name: 'Left knee jerk',
    value: 10
  },
  {
    name: 'Right ankle jerk',
    value: 11
  },
  {
    name: 'Left ankle jerk',
    value: 12
  },
  {
    name: 'Jaw jerk',
    value: 13
  }
].sort((a, b) => a.name.localeCompare(b.name)));

export const CGSScore = (): Array<{ name: string, value: number}> => ([
  {
    name: '0 (No response)',
    value: 0
  },
  {
    name: '1+ (A slight but definitely present response)',
    value: 1
  },
  {
    name: '2+ (A brisk response)',
    value: 2
  },
  {
    name: '3+ (A very brisk response)',
    value: 3
  },
  {
    name: '4+ (Clonus)',
    value: 4
  },
].sort((a, b) => a.name.localeCompare(b.name)));
