import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComplaintSectionComponent } from './components/main-complaint-section/main-complaint-section.component';
import { AbstractSectionComponent } from './shared/components/abstract-section/abstract-section.component';
import { SectionItemComponent } from './shared/components/section-item/section-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExaminationSectionComponent } from './components/examination-section/examination-section.component';
import { BuildersModule } from '../builders/builders.module';
import { InvestigationSectionComponent } from './components/investigation-section/investigation-section.component';
import { ResultsSectionComponent } from './components/results-section/results-section.component';
import { AdditionalNotesSectionComponent } from './components/additional-notes-section/additional-notes-section.component';
import { AttachmentsSectionComponent } from './components/attachments-section/attachments-section.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DiagnosisSectionComponent } from './components/diagnosis-section/diagnosis-section.component';
import { TreatmentSectionComponent } from './components/treatment-section/treatment-section.component';
import { MatIconModule } from '@angular/material/icon';
import { AttachmentPreviewComponent } from './components/attachments-section/attachment-preview/attachment-preview.component';



@NgModule({
  declarations: [
    MainComplaintSectionComponent,
    ExaminationSectionComponent,
    AbstractSectionComponent,
    SectionItemComponent,
    InvestigationSectionComponent,
    ResultsSectionComponent,
    AdditionalNotesSectionComponent,
    AttachmentsSectionComponent,
    DiagnosisSectionComponent,
    TreatmentSectionComponent,
    AttachmentPreviewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DragDropModule,
    BuildersModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    MatIconModule
  ],
  exports: [
    SectionItemComponent
  ]
})
export class SectionsModule { }
