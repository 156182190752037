import { Component, Input, OnInit } from '@angular/core';

export type EHRInfoDataList = Array<{
  title: string;
  alias: string;
  data: EHRInfoDataList;
} | string>;

export interface EHRSectionModel {
  title: string;
  alias: string;
  order: number;
  data: EHRInfoDataList;
}

@Component({
  selector: 'app-medical-and-family-history',
  templateUrl: './medical-and-family-history.component.html',
  styleUrls: ['./medical-and-family-history.component.scss']
})
export class MedicalAndFamilyHistoryComponent implements OnInit {

  @Input() public sections: EHRSectionModel[] = []
  public currentSection!: EHRSectionModel;

  constructor() {
  }

  ngOnInit(): void {
    this.currentSection = this.sections[0];
  }

  public changeSelected(filteredSection: EHRSectionModel): void {
    this.currentSection = filteredSection;
    const sectionToScrollTo = document.getElementById(filteredSection.alias);
    if (sectionToScrollTo) {
      sectionToScrollTo.scrollIntoView();
    }
  }

  public isString(data: any) {
    return typeof data === 'string';
  }

  public asChild(data: any) {
    return { ...data, child: true };
  }

}
