import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClinicalNoteModel } from '../../models/clincal-note.model';
import { CallsAPI } from '../abstract/http/calls-api';
import { HttpOptions } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class ClinicalNoteHttpService extends CallsAPI<ClinicalNoteModel, ClinicalNoteModel[]> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/patient/{:patientId}/case/{:guid}/notes/{:section}';

    /**
       * @param {*} id
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public find(patientId: number, guid: string, section: string, options?: HttpOptions): Observable<ClinicalNoteModel> {
        return this.http.get<ClinicalNoteModel>(this.buildAction('', { patientId, guid, section }), this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
       * @param {*} body
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public create(patientId: number, guid: string, section: string, body: any, options?: HttpOptions): Observable<ClinicalNoteModel> {
        return this.http.post<ClinicalNoteModel>(this.buildAction('/', { patientId, guid, section }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
     * @param {*} id
     * @param {*} body
     * @param {HttpOptions} options
     * @returns
     * @memberof HttpApiService
     */
    public update(patientId: number, guid: string, section: string, body: ClinicalNoteModel | FormData, options?: HttpOptions): Observable<ClinicalNoteModel> {
        return this.http.patch<ClinicalNoteModel>(this.buildAction('/', { patientId, guid, section }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
    public submit(patientId: number, guid: string, options?: HttpOptions): Observable<void> {
        return this.http.post<ClinicalNoteModel>(
            this.buildAction('/patient/{:patientId}/case/{:guid}/notes/_submit', { patientId, guid }, true),
            this.buildOptions(options)
        ).pipe(
            map(() => undefined),
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }
}