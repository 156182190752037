import { Component, EnvironmentInjector, OnDestroy, OnInit, createComponent, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ElementRef, AfterViewInit } from '@angular/core';
import { Observable, Subject, Subscription, map, switchMap, from, reduce, filter, tap, first, catchError, of } from 'rxjs';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { AVAILABLE_BUILDERS, resolveBuilder } from '../../ui/config/builders';
import * as moment from 'moment';
import { AbstractBuilderComponent } from '../../builders/shared/components/abstract-builder/abstract-builder.component';
import { BuilderConfigItemModel } from 'src/app/shared/models/builder-config.model';
import { AVAILABLE_SECTIONS, UI_SECTIONS } from '../../ui/config/sections';
import { AbstractSectionComponent } from '../../sections/shared/components/abstract-section/abstract-section.component';
import { UiService } from 'src/app/shared/services/common/ui.service';

const SCROLL_DISTANCE = 200;
const HIDDEN_WIDGET_SECTIONS = [
  UI_SECTIONS.ATTACHMENTS
];

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('builderHost', { read: ViewContainerRef }) builderHost!: ViewContainerRef;
  @ViewChild('sectionHost', { read: ViewContainerRef }) sectionHost!: ViewContainerRef;
  @ViewChild('menuWrapper') menuWrapper!: ElementRef;

  public sections: SectionConfigModel[] = [];
  public activeSection?: SectionConfigModel;
  public data$?: Observable<HistoryData[]>;
  public widgetHeight: number = 250;
  public backgroundColor: string = '#eeeeee';
  public foregroundColor: string = '#eeeeee';
  public showLeftScroll: boolean = false;
  public showRightScroll: boolean = false;

  private subscriptions: Subscription[] = [];
  private activeSection$: Subject<SectionConfigModel> = new Subject<SectionConfigModel>();

  constructor(
    private clinicalNoteService: ClinicalNoteService,
  ) {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    this.widgetHeight = params.get('height') ? parseInt(params.get('height') as string) : this.widgetHeight;
    this.backgroundColor = params.get('bgColor') || this.backgroundColor;
    this.foregroundColor = params.get('fgColor') || this.foregroundColor;

    this.data$ = this.activeSection$.asObservable().pipe(
      switchMap(section => this.clinicalNoteService.section(section.alias).pipe(
        map(data => ({ section, data }))
      )),
      switchMap(({ section, data }) => {
        const builders = Object.keys(data.builders || {}).map(b => resolveBuilder(b));
        this.builderHost.clear();
        return from(
          Promise.all([
            new Promise<HistoryData[]>((resolve) => {

              // add to DOM
              const ref = this.sectionHost.createComponent<AbstractSectionComponent>(AVAILABLE_SECTIONS[section.alias].component);
              ref.instance.config = section;
              ref.instance.controls = {
                getSerializedBuilders: async () => [],
                save: async () => false,
                validateBuilders: async () => ({ valid: false, invalidBuilders: [] }),
                openBuilder: (alias: string) => { }
              };
              ref.instance.data = data;

              // Queue in digest cycle to run once added in dom
              setTimeout(() => {
                const sectionData = ref.instance.serialize(JSON.parse(JSON.stringify(data)));
                resolve(sectionData);
              }, 0);
            }),
            new Promise<HistoryData[]>((resolve) => {

              // add to DOM
              const refs = builders.map<[ComponentRef<AbstractBuilderComponent>, BuilderConfigItemModel]>((config) => {
                const ref = this.builderHost.createComponent<AbstractBuilderComponent>(config.component);
                ref.instance.section = section;
                ref.instance.config = config;
                ref.instance.data = data.builders[config.alias];
                return [ref, config];
              });

              // Queue in digest cycle to run once added in dom
              setTimeout(() => resolve(refs.reduce<HistoryData[]>((output, [ref, config]) => {
                const builderData = ref.instance.serialize(JSON.parse(JSON.stringify(data.builders[config.alias])));
                return [...output, ...builderData];
              }, [])), 0);
            })
          ]).then(
            ([...res]) => res.reduce((o, v) => [...o, ...v], [])
          )
        )
      })
    );

    this.subscriptions.push(
      this.clinicalNoteService.config.subscribe(res => {
        this.sections = res.sections.filter(s => !HIDDEN_WIDGET_SECTIONS.includes(s.alias));
        this.setActiveSection(this.sections[0]);
      })
    );
  }

  ngAfterViewInit(): void {
    this.computeScroll();
  }

  scrollLeft() {
    this.menuWrapper.nativeElement.scrollTo({ left: (this.menuWrapper.nativeElement.scrollLeft - SCROLL_DISTANCE), behavior: 'smooth' });
  }

  scrollRight() {
    this.menuWrapper.nativeElement.scrollTo({ left: (this.menuWrapper.nativeElement.scrollLeft + SCROLL_DISTANCE), behavior: 'smooth' });
  }

  computeScroll() {
    this.showRightScroll = (this.menuWrapper.nativeElement.offsetWidth + this.menuWrapper.nativeElement.scrollLeft) < this.menuWrapper.nativeElement.scrollWidth;
    this.showLeftScroll = this.menuWrapper.nativeElement.scrollLeft > 0;
  }

  setActiveSection(section: SectionConfigModel) {
    this.activeSection$.next(section);
    this.activeSection = section;
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  displaySection(section: SectionConfigModel): Observable<boolean> {
    if (!section.active) {
      return this.clinicalNoteService.section(section.alias).pipe(
        first(),
        map(data => !!data.notes && data.notes.length > 0),
        catchError(error => {
          return of(false);
        })
      );
    }
    return of(true);
  }

}
