import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, Validators } from '@angular/forms';
import { validateAllFormFields } from '../../../../shared/validators';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface RMTestFormData extends BuilderDataAbstract {
  result: string;
  note: string;
  _modified: number;
}

@Component({
  selector: 'app-rapid-malaria-test',
  templateUrl: './rapid-malaria-test.component.html',
  styleUrls: ['./rapid-malaria-test.component.scss']
})
export class RapidMalariaTestComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      result: [null, Validators.required],
      note: [null, Validators.maxLength(500)]
    });
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: RMTestFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'RAPID MALARIA:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: RMTestFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: RAPID MALARIA`,
      `${data.result}${data.note ? ' - ' + data.note : ''}`
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as RMTestFormData;
    data['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData = data;
    }
    return builderData;
  }

}
