
export const HeadAndNeckExamRegions = (): Array<{ id: number, name: string }> => ([
  {
    id: 1,
    name: 'Maxillary'
  },
  {
    id: 2,
    name: 'Ethmoid'
  },
  {
    id: 3,
    name: 'Sphenoid'
  },
  {
    id: 4,
    name: 'Frontal'
  }
].sort((a, b) => a.name.localeCompare(b.name)));
