<div class="container-fluid py-4" [formGroup]="formData">

    <div class="col-12 mb-4">
        <div class="row">
            <div class="col-12">
                <label class="form-label">New Vaccination Record</label>
            </div>
        </div>
        <ng-container formArrayName="vaccinations">
            <div class="row g-2" *ngFor="let row of getRow().controls; let i = index" [formGroupName]="i">
                <div class="col">
                    <input class="form-control" placeholder="Date" formControlName="date_time"
                           ngbDatepicker
                           [maxDate]="maxDateNow"
                           container="body" #vaccinationDate="ngbDatepicker" (click)="vaccinationDate.toggle()"
                           jargonLoadingState="builder-control">
                </div>
                <div class="col">
                    <preferences-typeahead
                                           alias="injections_vaccinations-disease"
                                           formControlName="disease"
                                           placeholder="Targeted Disease"
                                           jargonLoadingState="builder-control"></preferences-typeahead>
                </div>
                <div class="col">
                    <input type="text" class="form-control" placeholder="Lot #" formControlName="lot_number"
                           jargonLoadingState="builder-control">
                </div>
                <div class="col">
                    <input class="form-control" placeholder="Expiry Date" formControlName="expiry_date_time"
                           ngbDatepicker
                           [minDate]="maxDateNow"
                           container="body" #expiryDate="ngbDatepicker" (click)="expiryDate.toggle()"
                           jargonLoadingState="builder-control">
                </div>
                <div class="col">
                    <input class="form-control" placeholder="Return Date" formControlName="return_date_time"
                           ngbDatepicker
                           [minDate]="maxDateNow"
                           container="body" #returnDate="ngbDatepicker" (click)="returnDate.toggle()"
                           jargonLoadingState="builder-control">
                </div>
                <div class="col">
                    <select class="form-control" formControlName="site" jargonLoadingState="builder-control">
                        <option hidden disabled [value]="null">Site</option>
                        <option *ngFor="let site of sitesList" [value]="site">{{ site }}</option>
                    </select>
                </div>
                <div class="col">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Next Vaccination" formControlName="next"
                               jargonLoadingState="builder-control">
                        <ng-container *jargonViewMode="'write'">
                            <button class="btn btn-outline-secondary btn-sm"
                                    type="button"
                                    jargonLoadingState="builder-control"
                                    (click)="removeRow( i)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *jargonViewMode="'write'">
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow()">Add
                        +</button>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *jargonViewMode="'write'">
        <div class="col-auto mb-4 d-flex justify-content-end">
            <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
                    (click)="deleteBuilder()">Remove
            </button>
            <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
            </button>
        </div>
    </ng-container>
</div>

<ng-template #preview let-data="data">
    <p *ngIf="data.vaccinations && data.vaccinations.length">
        [<span *ngFor="let c of data.vaccinations; let i = index">
            {{ i === 0 ? '' : ';'}}({{i + 1}}):
            {{ c.date_time | date: 'YYYY-MM-dd' }}
            {{ ' - ' }}
            {{ c.disease }}
            {{ ' #' }}
            {{ c.lot_number }}
            {{ ' ' }}
            {{ c.site }}
            {{ ' (' }}
            {{ c.expiry_date_time | date: 'YYYY-MM-dd' }}
            {{ !c.return_date_time ? '' : '/' }}
            {{ c.return_date_time | date: 'YYYY-MM-dd' }}
            {{ ') '}}
            {{ !c.next ? '' : ' - ' }}
            {{ c.next }}
        </span>]
    </p>
</ng-template>