<div class="container-fluid g-0 d-flex flex-column" [ngClass]="{ 'show': sidebarOpen, 'hide': !sidebarOpen }">
  <strong class="text-uppercase mt-2 d-flex">Comments: {{ subjectData?.config?.title }}</strong>
  <div class="comment-list d-flex flex-column" #commentListRef>
    <ng-container *ngIf="subjectData?.comments?.length; else noCommentsRef">
      <div *ngFor="let comment of subjectData?.comments">
        <div class="card comments-card mb-2">
          <div class="card-body">
            <div class="row">
              <div class="col-auto">
                <mat-icon class="comment-icon">account_circle</mat-icon>
              </div>
              <div class="col d-flex">
                <h6 class="align-self-center">{{ comment.author.name }}</h6>
              </div>
              <div *ngIf="commentToEdit?._id !== comment._id" class="col-auto">
                <ng-container *jargonViewMode="'review'">
                  <div class="action-dropdown" ngbDropdown>
                    <a class="nav-link" tabindex="0" ngbDropdownToggle id="actionDropdown" role="button">
                      <mat-icon>more_vert</mat-icon>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="actionDropdown"
                         class="dropdown-menu dropdown-menu-right">
                      <a ngbDropdownItem (click)="edit(comment)">Edit Comment</a>
                      <a ngbDropdownItem (click)="delete(comment._id)">Delete Comment</a>
                    </div>
                  </div>
                </ng-container>
                <ng-container *jargonViewMode="'write'">
                  <div (click)="acknowledge(comment)">
                    <mat-icon class="acknowledge">done</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <ng-container *ngIf="commentToEdit?._id === comment._id; else displayComment">
                <div>
                  <textarea rows="3" class="form-control" [formControl]="contentEdit"
                            jargonLoadingState="comment-edit"></textarea>
                </div>
                <div class="mt-2">
                  <button class="btn btn-primary btn-sm me-2 comment-btn" (click)="saveComment('edit')"
                          jargonLoadingState="comment-edit">Comment</button>
                  <button class="btn btn-outline-primary btn-sm comment-btn"
                          (click)="cancelCommenting('edit')" jargonLoadingState="comment-edit">Cancel</button>
                </div>
              </ng-container>
              <ng-template #displayComment>
                <p class="mb-1">{{ comment.content }}</p>
                <div class="text-muted d-flex justify-content-end">
                  <small class="text-muted">{{comment._modified | date: 'YYYY-MM-dd HH:mm:ss Z'}}</small>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noCommentsRef>
      <p class="text-muted">No Comments Available</p>
    </ng-template>
    <ng-container *ngIf="sidebarOpen">
      <div class="card comments-card" [ngStyle]="{'display': addNew ? 'flex' : 'none'}">
        <div class="card-body">
          <div class="row">
            <div class="col-auto">
              <mat-icon class="comment-icon">account_circle</mat-icon>
            </div>
            <div class="col d-flex">
              <h6 class="align-self-center">{{ reviewer }}</h6>
            </div>
          </div>
          <div class="row">
            <div>
              <textarea rows="3" class="form-control" [formControl]="contentCreate"
                        jargonLoadingState="comment-create"></textarea>
            </div>
            <div class="mt-2">
              <button class="btn btn-primary btn-sm me-2 comment-btn" (click)="saveComment('create')"
                      jargonLoadingState="comment-create">Comment</button>
              <button class="btn btn-outline-primary btn-sm comment-btn"
                      (click)="cancelCommenting('create')" jargonLoadingState="comment-create">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>