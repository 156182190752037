<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-auto mb-4 d-flex justify-content-end screening-gender-toggle">
    <mat-label class="form-label mat-slide-toggle-content me-2" (click)="genderToggle(false)">Male</mat-label>
    <mat-slide-toggle (change)="genderToggle($event.checked)"
                      [color]="'primary'"
                      [checked]="isFemale"></mat-slide-toggle>
    <mat-label class="form-label mat-slide-toggle-content ms-2" (click)="genderToggle(true)">Female</mat-label>
  </div>

  <div class="col-12 mb-4">

    <!--Hb-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Hb</label>
      </div>
      <div class="col d-flex" formGroupName="hb">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
            <option value="+4">+4</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('hb')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--ERY-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">ERY</label>
      </div>
      <div class="col d-flex" formGroupName="ery">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('ery')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--BIL-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">BIL</label>
      </div>
      <div class="col d-flex" formGroupName="bil">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('bil')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--UBG-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">UBG</label>
      </div>
      <div class="col d-flex" formGroupName="ubg">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="norm.">norm.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
            <option value="+4">+4</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('ubg')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--KET-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">KET</label>
      </div>
      <div class="col d-flex" formGroupName="ket">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('ket')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--GLU-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">GLU</label>
      </div>
      <div class="col d-flex" formGroupName="glu">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="norm.">norm.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
            <option value="+4">+4</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('glu')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--PRO-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">PRO</label>
      </div>
      <div class="col d-flex" formGroupName="pro">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('pro')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--pH-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">pH</label>
      </div>
      <div class="col d-flex" formGroupName="ph">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('ph')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--NIT-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">NIT</label>
      </div>
      <div class="col d-flex" formGroupName="nit">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="pos.">pos.</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('nit')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--LEU-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">LEU</label>
      </div>
      <div class="col d-flex" formGroupName="leu">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Result</option>
            <option value="neg.">neg.</option>
            <option value="+1">+1</option>
            <option value="+2">+2</option>
            <option value="+3">+3</option>
          </select>
        </div>
        <div class="input-group ms-2">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('leu')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--bHCG-->
    <div *ngIf="isFemale" class="row mb-3">
      <div class="col-2">
        <label class="form-label">bHCG</label>
      </div>
      <div class="col" formGroupName="bhcg">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="text" class="form-control" formControlName="result" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Result</option>
              <option value="Inconclusive">Inconclusive</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
            </select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>{{item.result ? item.note ? '[' + item.result : '[' + item.result + ']' : ''}}{{item.note ? item.result ?
      item.note + ']' : '[' + item.note + ']' : ''}}</span>
  </p>
</ng-template>