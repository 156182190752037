import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { validateAllFormFields } from 'src/app/shared/validators';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';

type VaccinationsData = {
  _modified: number;
  date_time: Date | number;
  disease: string;
  lot_number: string;
  expiry_date_time: Date | number;
  return_date_time: Date | number | null;
  site: string;
  next: string | null;
}

interface VaccinationsFormData extends BuilderDataAbstract {
  vaccinations: Array<VaccinationsData>;
}

@Component({
  selector: 'app-injection-vaccinations',
  templateUrl: './injection-vaccinations.component.html',
  styleUrls: ['./injection-vaccinations.component.scss']
})
export class InjectionVaccinationsComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;
  public sitesList: string[] = [
    'Oral',
    'Right Deltoid',
    'Left Deltoid',
    'Right Thigh',
    'Left Thigh',
    'Right Hip',
    'Left Hip',
    'Right Buttock',
    'Left Buttock',
  ].sort((a, b) => a.localeCompare(b));

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      vaccinations: this.fb.array([])
    });
    this.addRow();
  }

  public getRow(): FormArray {
    return this.formData.get('vaccinations') as FormArray;
  }

  public removeRow(idx: number) {
    this.getRow().removeAt(idx);
    if (this.getRow().length === 0) {
      this.addRow();
    }
  }

  public addRow() {
    this.getRow().push(this.fb.group({
      date_time: [null, Validators.required],
      disease: [null, Validators.required],
      lot_number: [null, Validators.required],
      expiry_date_time: [null, Validators.required],
      return_date_time: [null],
      site: [null, Validators.required],
      next: [null],
    }));
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: VaccinationsFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'VACCINATIONS:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: VaccinationsFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: VACCINATIONS`,
      data.vaccinations.map(
        (c, i) => `(${i + 1}):${moment(c.date_time).format('YYYY-MM-dd')} - ${c.disease} #${c.lot_number} ${c.site} `
          + `(${moment(c.expiry_date_time).format('YYYY-MM-dd')}${c.return_date_time ? `/${moment(c.return_date_time).format('YYYY-MM-dd')}` : ''}) `
          + `${c.next ? ` - ${c.next}` : ''}`
      ).join('; ')
    ].join('\n');
  }


  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as VaccinationsFormData;
    data.vaccinations = data.vaccinations.map(d => ({ ...{ date_time: moment(d.date_time).valueOf() }, ...d, _modified: moment().valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['vaccinations'] = data.vaccinations;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['vaccinations'].length; i++) {
      this.addRow();
    }
    super.setBuilderData(data);
  }

}
