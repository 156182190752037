import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, concat, debounceTime, distinctUntilChanged, filter, firstValueFrom, map, Observable, of, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { ActiveUserService } from '../../services/common/active-user.service';
import { SettingsHttpService } from '../../services/http/settings-http.service';

const CACHE_DATA: {
  [key: string]: {
    exists: boolean | null;
    data: string[];
  }
} = {};

export type OptionValue = string | string[] | null;

@Component({
  selector: 'preferences-typeahead',
  templateUrl: './preferences-typeahead.component.html',
  styleUrls: ['./preferences-typeahead.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PreferencesTypeaheadComponent,
    multi: true
  }]
})
export class PreferencesTypeaheadComponent implements OnInit, ControlValueAccessor {
  @Input() public alias!: string;
  @Input() public multiple: boolean = false;
  @Input() public placeholder: string = 'Start Typing...';
  @Output('change') public changeEvent: EventEmitter<OptionValue> = new EventEmitter();
  public options$!: Observable<Array<string>>;
  public optionsLoading = false;
  public optionsInput$ = new Subject<string>();
  public disabled: boolean = false;
  public _selectedOptions: OptionValue = null;
  public searchTerm: string = '';

  private loadingReq?: Observable<string[]>;

  get selectedOptions(): OptionValue {
    return this._selectedOptions
  }
  set selectedOptions(val: OptionValue) {
    if (this.multiple) {
      this._selectedOptions = Array.isArray(val) ? val : (val ? [val] : null);
    } else {
      this._selectedOptions = Array.isArray(val) ? val[0] : (val ? val : null);
    }
    this.onChange(this.selectedOptions);
    this.addToList(this.selectedOptions);
    if (this.onTouched) {
      this.onTouched();
    }
  }

  constructor(
    private activeUserService: ActiveUserService,
    private settingsHttpService: SettingsHttpService
  ) {
  }

  writeValue(obj: OptionValue): void {
    this.selectedOptions = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  public registerOnChange(fn: any): void {
    this.onChange = (value) => {
      this.changeEvent.emit(value);
      if (fn) {
        fn(value);
      }
    };
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  ngOnInit(): void {
    if (!CACHE_DATA[this.alias]) {
      CACHE_DATA[this.alias] = {
        exists: null,
        data: []
      };
    }
    this.loadOptions();
  }

  onKeyUp(event: Event, tagSelect:any): void {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Enter' && this.isEmptySelection()) {
      this.handleSelectedTag(tagSelect);
    }
  }
  
  onBlur(tagSelect: any): void {
    if (this.isEmptySelection()) {
      this.handleSelectedTag(tagSelect);
    }
  }
  
  private isEmptySelection(): boolean {
    return !this.selectedOptions || (Array.isArray(this.selectedOptions) && this.selectedOptions.length === 0);
  }

  private handleSelectedTag(tagSelect: any): void {
    const options = tagSelect.itemsList.filteredItems;
    const searchTerm = this.searchTerm;
    if (options.length > 0) {
      this.selectedOptions = this.multiple ? [options[0].label] : options[0].label;
    } else if (searchTerm) {
      const newTag = { label: searchTerm, value: searchTerm };
      this.addToList(newTag.label);
      this.selectedOptions = this.multiple ? [newTag.label] : newTag.label;
    }
    this.onChange(this.selectedOptions);
  }

  private onChange: (value: OptionValue) => void = (value) => {
    this.changeEvent.emit(value);
  };

  private onTouched?: VoidFunction;

  private loadOptions() {
    this.options$ = concat(
      of([]), // default items
      this.optionsInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(v => v !== null),
        tap(() => this.optionsLoading = true),
        switchMap(term => this.loadData(term).pipe(
          catchError(() => of([])), // empty list on error
        )),
        tap((res) => {
          this.optionsLoading = false;
        })
      )
    );
  }

  private loadData(term: string): Observable<string[]> {
    this.searchTerm = term;
    return of(term).pipe(
      switchMap(() => {
        if (this.loadingReq) {
          return this.loadingReq;
        }
        if (CACHE_DATA[this.alias].exists === null) {
          this.loadingReq = this.settingsHttpService.find<string[]>(this.activeUserService.id!, this.alias).pipe(
            catchError((err: HttpErrorResponse) => {
              console.error(err);
              if (err.status === 404) {
                CACHE_DATA[this.alias].data = [];
                CACHE_DATA[this.alias].exists = false;
                return of({ data: [] });
              }
              return throwError(() => err);
            }),
            map(v => {
              this.loadingReq = undefined;
              CACHE_DATA[this.alias].data = Array.isArray(v.data) ? v.data : Object.values(v.data);
              CACHE_DATA[this.alias].exists = CACHE_DATA[this.alias].exists === null ? true : CACHE_DATA[this.alias].exists;
              return [...CACHE_DATA[this.alias].data];
            })
          )
          return this.loadingReq;
        }

        return of([...CACHE_DATA[this.alias].data]);
      }),
      map(list => {
        if (term === '') {
          return list;
        }
        const parts = term.toLowerCase().split(' ');
        return list.filter(v => parts.some(p => v.toLowerCase().includes(p)));
      })
    )
  }

  private addToList(options: OptionValue) {
    if (CACHE_DATA[this.alias] && CACHE_DATA[this.alias].exists !== null) {
      let values = Array.isArray(options) ? options : [options];
      let data = new Set(CACHE_DATA[this.alias].data);
      const len = data.size;
      values.filter(v => v != null).forEach(v => data.add(v!));
      if (data.size !== len) {
        firstValueFrom(
          this.settingsHttpService[CACHE_DATA[this.alias].exists ? 'update' : 'create'](this.activeUserService.id!, this.alias, [...data.values()]).pipe(
            tap((res) => {
              CACHE_DATA[this.alias].exists = true
              CACHE_DATA[this.alias].data = res.data;
            })
          )
        )
      }
    }
  }
}
