<div class="container-fluid py-4" [formGroup]="formData">

    <div class="mb-2">
        <div class="row">
            <div class="col">
                <div class="input-group">
                    <button *ngIf="formData.get('encounter_type')?.value !== 'follow-up'"
                            class="encounter-btn btn btn-primary btn-sm"
                            type="button"
                            [disabled]="isReadOnly"
                            (click)="setEncounterType('initial')">
                        Initial Wound Encounter
                    </button>
                    <button *ngIf="formData.get('encounter_type')?.value !== 'initial'"
                            class="encounter-btn btn btn-outline-primary btn-sm"
                            type="button"
                            [disabled]="isReadOnly"
                            (click)="setEncounterType('follow-up')">
                        Follow-Up Encounter
                    </button>
                    <ng-container *jargonViewMode="'write'">
                        <button *ngIf="formData.get('encounter_type')?.value !== null"
                                class="encounter-btn btn btn-outline-secondary btn-sm"
                                type="button"
                                (click)="setEncounterType(null)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-4" formGroupName="data">
        <ng-container *ngIf="formData.get('encounter_type')?.value === 'initial'">
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Type of Wound</label>
                    <select class="form-control" formControlName="type">
                        <option [ngValue]="null" disabled="true">Select One...</option>
                        <option *ngFor="let option of woundTypeList" [ngValue]="option">{{option}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Cause/History of Wound</label>
                    <textarea class="form-control" formControlName="cause"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Initial Description of Wound</label>
                    <textarea class="form-control" formControlName="description"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Assessment for Treatment Plan</label>
                    <textarea class="form-control" formControlName="treatment"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Action Taken</label>
                    <textarea class="form-control" formControlName="action"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Home Care Plan (if applicable)</label>
                    <textarea class="form-control" formControlName="home_care"></textarea>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="formData.get('encounter_type')?.value === 'follow-up'">
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Assessment for Treatment Plan</label>
                    <textarea class="form-control" formControlName="treatment"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Action Taken</label>
                    <textarea class="form-control" formControlName="action"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-label">Home Care Plan (if applicable)</label>
                    <textarea class="form-control" formControlName="home_care"></textarea>
                </div>
            </div>
        </ng-container>

    </div>

    <ng-container *jargonViewMode="'write'">
        <div class="col-auto mb-4 d-flex justify-content-end">
            <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
                    (click)="deleteBuilder()">Remove
            </button>
            <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
            </button>
        </div>
    </ng-container>
</div>

<ng-template #preview let-data="data">
    <ng-container *ngIf="data.encounter_type === 'initial'">
        <p *ngIf="data.data.type">
            <strong>Type of Wound: </strong>
            <span>{{data.data.type}}</span>
        </p>
        <p *ngIf="data.data.cause">
            <strong>Cause/History of Wound: </strong>
            <span>{{data.data.cause}}</span>
        </p>
        <p *ngIf="data.data.description">
            <strong>Initial Description of Wound: </strong>
            <span>{{data.data.description}}</span>
        </p>
    </ng-container>
    <p *ngIf="data.data.treatment">
        <strong>Assessment for Treatment Plan: </strong>
        <span>{{data.data.treatment}}</span>
    </p>
    <p *ngIf="data.data.action">
        <strong>Action Taken: </strong>
        <span>{{data.data.action}}</span>
    </p>
    <p *ngIf="data.data.treatment">
        <strong>Home Care Plan: </strong>
        <span>{{data.data.treatment}}</span>
    </p>
</ng-template>