import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { TemplateRefDirective } from '../../directives/template-ref.directive';
import { Sort } from '@angular/material/sort';
import { ReviewAction } from '../../services/common/review.service';

export type TableColumn<T extends { [key: string]: any }, TH = T> = {
  title?: string;
  field?: keyof T;
  column_slot?: string;
  header_slot?: string;
  data?: Partial<T> | TH | unknown;
};

export type TableConfig<T extends { [key: string]: any }> = {
  columns: Array<TableColumn<T>>;
  data: Array<T>;
};

@Component({
  selector: 'app-jargon-table',
  templateUrl: './jargon-table.component.html',
  styleUrls: ['./jargon-table.component.scss']
})
export class JargonTableComponent<T> {

  @Input() public loading?: boolean;
  @Input() public config?: TableConfig<T>;
  @Output('sortState') sortState: EventEmitter<{ active: string, direction: 'asc' | 'desc' }> = new EventEmitter<{ active: string, direction: 'asc' | 'desc' }>();
  @ContentChildren(TemplateRefDirective) templateList?: QueryList<TemplateRefDirective>;

  public get templates(): { [key: string]: TemplateRef<unknown> } {
    return this.templateList ? this.templateList.reduce((out, tmpl) => ({
      ...out,
      [tmpl.name]: tmpl.templateRef
    }), {}) : {};
  }

  public getDataValue(row: T, key: keyof T | undefined) {
    return row[key as keyof T];
  }

  public sortData($event: Sort) {
    const sortData = {
      active: this.config!.columns.find(c => c.title === $event.active)!.field,
      direction: $event.direction
    } as { active: string, direction: 'asc' | 'desc' }
    this.sortState.emit(sortData)
  }
}
