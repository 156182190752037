import { Component, Input, OnInit } from '@angular/core';
import { AttachmentsBodyRegions } from '../../../modules/sections/components/attachments-section/attachments-section.config';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AttachmentModel, AttachmentType, LabsTestCategory } from '../../models/attachment.model';
import { LoadingStateService } from '../../services/common/loading-state.service';
import { DialogService } from '../../services/common/dialog.service';
import { AttachmentHttpService } from '../../services/http/attachment-http.service';
import { ClinicalNoteService } from '../../services/common/clinical-note.service';
import { catchError, finalize, firstValueFrom, map, switchMap, tap, throwError } from 'rxjs';
import { UI_SECTIONS } from 'src/app/modules/ui/config/sections';
import { validateAllFormFields } from '../../validators';
import { UiService } from '../../services/common/ui.service';
import { ActiveUserService } from '../../services/common/active-user.service';


export const ATTACHMENT_TYPES: {
  IMAGING: { alias: AttachmentType, title: string };
  LABS: { alias: AttachmentType, title: string };
  PHOTO: { alias: AttachmentType, title: string };
  REFERRAL_LETTER: { alias: AttachmentType, title: string };
  SCRIPT: { alias: AttachmentType, title: string };
  OTHER: { alias: AttachmentType, title: string };
} = {
  IMAGING: { alias: 'imaging', title: 'Imaging' },
  LABS: { alias: 'labs', title: 'Labs' },
  PHOTO: { alias: 'photo', title: 'Photo' },
  REFERRAL_LETTER: { alias: 'referral_letter', title: 'Referral Letter' },
  SCRIPT: { alias: 'script', title: 'Script' },
  OTHER: { alias: 'other', title: 'Other' },
};
export const LABS_CATEGORIES: {
  BLOODS: { alias: LabsTestCategory, title: string };
  COVID: { alias: LabsTestCategory, title: string };
  NON_BLOODS: { alias: LabsTestCategory, title: string };
} = {
  BLOODS: { alias: 'bloods', title: 'Bloods' },
  COVID: { alias: 'covid', title: 'COVID' },
  NON_BLOODS: { alias: 'non-bloods', title: 'Non-Bloods' },
};

export interface AttachmentFormMetaData {
  radiologist?: {
    id: number;
    name: string;
  };
  date_time?: number;
  type?: string;
  reason?: string;
  regions?: [[]]
  pathology_lab?: {
    id: number;
    name: string;
  };
  result_date_time?: number;
  tests?: string[];
  summary?: string;
  referring_doctor?: {
    id: number;
    name: string;
  };
  name?: string;
}

@Component({
  selector: 'app-attachments-modal',
  templateUrl: './attachments-modal.component.html',
  styleUrls: ['./attachments-modal.component.scss']
})
export class AttachmentsModalComponent implements OnInit {

  @Input() public modalRef!: NgbModalRef;
  @Input() public disableToggle: boolean = false;
  @Input() public lockType: boolean = false;
  @Input() public attachmentType: AttachmentType | null = null;
  @Input() public attachments: File[] = [];
  @Input() public closure!: (files: AttachmentModel[]) => void;
  @Input('data') public data?: AttachmentFormMetaData;
  public maxDateNow = {
    day: moment().date(),
    month: moment().month() + 1,
    year: moment().year()
  };

  public regions: Array<{ id: number, name: string }> = AttachmentsBodyRegions;
  public formData: FormGroup;

  constructor(
    private fb: FormBuilder,
    private loadingStateService: LoadingStateService,
    private clinicalNoteService: ClinicalNoteService,
    private attachmentHttpService: AttachmentHttpService,
    private dialogService: DialogService,
    private uiService: UiService,
    private activeUserService: ActiveUserService
  ) {
    this.formData = this.fb.group({
      note: [null, [Validators.required, Validators.maxLength(1000)]],
    });
  }

  get types() {
    return Object.values(ATTACHMENT_TYPES);
  }

  get testCategories() {
    return Object.values(LABS_CATEGORIES);
  }

  get noteControl() {
    return this.formData.get('note') as FormControl;
  }

  ngOnInit(): void {
    this.attachmentTypeToggle();
    if (this.attachmentType) {
      this.formData.get(this.attachmentType)?.patchValue(this.data);
    }
  }

  public attachmentTypeToggle() {
    Object.values(ATTACHMENT_TYPES).forEach(t => this.formData.removeControl(t.alias));
    switch (this.attachmentType) {
      case ATTACHMENT_TYPES.IMAGING.alias:
        this.formData.addControl(ATTACHMENT_TYPES.IMAGING.alias, this.fb.group({
          radiologist: this.fb.group({
            id: [null],
            name: [null, [Validators.required, Validators.maxLength(100)]]
          }),
          date_time: [null, Validators.required],
          type: [null, Validators.required],
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          regions: [[]]
        }));
        break;
      case ATTACHMENT_TYPES.LABS.alias:
        this.formData.addControl(ATTACHMENT_TYPES.LABS.alias, this.fb.group({
          pathology_lab: this.fb.group({
            id: [null],
            name: [null, [Validators.required, Validators.maxLength(100)]]
          }),
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          date_time: [null, Validators.required],
          result_date_time: [null, Validators.required],
          type: [null, Validators.required],
          tests: [null, Validators.required],
          summary: [null, Validators.maxLength(1000)]
        }));
        break;
      case ATTACHMENT_TYPES.PHOTO.alias:
        this.formData.addControl(ATTACHMENT_TYPES.PHOTO.alias, this.fb.group({
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          date_time: [null, Validators.required]
        }));
        break;
      case ATTACHMENT_TYPES.REFERRAL_LETTER.alias:
        this.formData.addControl(ATTACHMENT_TYPES.REFERRAL_LETTER.alias, this.fb.group({
          referring_doctor: this.fb.group({
            id: [null],
            name: [null, [Validators.required, Validators.maxLength(100)]]
          }),
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          date_time: [null, Validators.required]
        }));
        break;
      case ATTACHMENT_TYPES.SCRIPT.alias:
        this.formData.addControl(ATTACHMENT_TYPES.SCRIPT.alias, this.fb.group({
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          type: [null, Validators.required],
          date_time: [null, Validators.required],
        }));
        break;
      case ATTACHMENT_TYPES.OTHER.alias:
        this.formData.addControl(ATTACHMENT_TYPES.OTHER.alias, this.fb.group({
          name: [null, [Validators.required, Validators.maxLength(100)]],
          reason: [null, [Validators.required, Validators.maxLength(500)]],
          date_time: [null, Validators.required],
        }));
    }
    if (this.lockType && this.attachmentType) {
      this.formData.get(this.attachmentType)?.disable({ emitEvent: false })
    }
  }

  public submit() {
    validateAllFormFields(this.formData);
    if (!this.attachments.length) {
      return;
    }
    const attachmentTypeData = (this.formData.get(this.attachmentType!) as FormGroup).getRawValue();
    if (attachmentTypeData['date_time']) {
      attachmentTypeData['date_time'] = moment(attachmentTypeData['date_time']).valueOf();
    }
    if (attachmentTypeData['result_date_time']) {
      attachmentTypeData['result_date_time'] = moment(attachmentTypeData['result_date_time']).valueOf();
    }
    const formData = this.buildFormData({
      type: this.attachmentType,
      note: this.noteControl.value,
      meta: attachmentTypeData,
      files: this.attachments
    });
    this.saveAttachment(formData);
  }

  private buildFormData(data: any, formData?: FormData, parent?: string) {
    if (!formData) {
      formData = new FormData();
    }

    if (data instanceof File) {
      formData.append(parent!, data);
    } else if (data instanceof Date) {
      formData.append(parent!, data.toISOString());
    } else if (Array.isArray(data)) {
      data.forEach((v, i) => {
        this.buildFormData(v, formData, parent ? `${parent}[${i}]` : `${i}`);
      });
    } else if (data && typeof data === 'object') {
      Object.keys(data).forEach(key => {
        this.buildFormData(data[key], formData, parent ? `${parent}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parent!, value);
    }
    return formData;
  }

  private async saveAttachment(data: FormData) {
    this.loadingStateService.start('attachment-modal');
    await firstValueFrom(
      this.attachmentHttpService.upload(this.clinicalNoteService.patientId!, this.clinicalNoteService.guid!, data).pipe(
        tap((res: AttachmentModel[]) => {
          const attachments = res.filter(file => ['imaging', 'labs'].includes(file.type))
          if (attachments.length) {
            this.uiService.message('attachments', 'create', { attachments, token: this.activeUserService.token })
          }
          this.closure(res);
        }),
        switchMap((v) => this.clinicalNoteService.loadSection(UI_SECTIONS.ATTACHMENTS).pipe(map(() => v))),
        tap(() => this.modalRef?.close()),
        finalize(() => {
          this.loadingStateService.end('attachment-modal');
          this.dialogService.show({
            variant: 'success',
            title: 'Attachments Uploaded Successfully!'
          });
        }),
        catchError((err) => {
          this.dialogService.show({
            variant: 'danger',
            title: 'Error',
            message: err.message
          });
          console.error(err);
          return throwError(() => err);
        })
      )
    );
  }
}
