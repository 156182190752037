import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UiService } from '../../../shared/services/common/ui.service';
import { ActivatedRoute, ActivationEnd, ChildActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription, filter, firstValueFrom, map, tap, withLatestFrom, zip } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnDestroy {

  @ViewChild(RouterOutlet) routerOutlet?: RouterOutlet;

  public icon: string = 'settings';
  public title: string = 'Clinical Notes - Settings';
  public back: string = '/main';

  private subscriptions: Subscription[] = [];
  get isInFrame() {
    return this.uiService.isInFrame;
  }

  constructor(
    public uiService: UiService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.subscriptions.push(
      this.router.events.pipe(
        filter((e: any) => e instanceof NavigationEnd),
        tap((navEnd: NavigationEnd) => {
          setTimeout(async () => {
            let title, icon;
            if (this.routerOutlet?.activatedRoute) {
              [title, icon] = await Promise.all([
                firstValueFrom(this.routerOutlet?.activatedRoute?.title),
                firstValueFrom(this.routerOutlet?.activatedRoute?.data.pipe(map(({ icon }) => icon)))
              ]);
            }
            this.title = title || 'Clinical Notes - Settings';
            this.icon = icon || 'settings';
            this.back = navEnd.url === '/settings' ? '/main' : '/settings';
          }, 0);
        })
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public minimizeFrame() {
    this.uiService.minimizeFrame();
  }

  public maximizeFrame() {
    this.uiService.maximizeFrame();
  }

  public closeFrame() {
    this.uiService.closeFrame();
  }
}
