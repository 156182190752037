<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <!--Chest Pain-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Chest Pain</label>
      </div>
      <div class="col" formGroupName="chest_pain">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <ng-select [items]="regions"
                       [multiple]="true"
                       formControlName="regions"
                       [hideSelected]="true"
                       appendTo="#app-wrapper"
                       placeholder="Region(s)"
                       jargonLoadingState="builder-control"
                       bindLabel="name"
                       bindValue="name">
            </ng-select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Palpitations-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Palpitations</label>
      </div>
      <div class="col" formGroupName="palpitations">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value }}]</span>
  </p>
</ng-template>