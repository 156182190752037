import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRedirectGuard } from 'src/app/shared/guards/auth-redirect.guard';
import { PreviewComponent } from './preview/preview.component';

const routes: Routes = [
  { path: 'widget', pathMatch: 'full', redirectTo: '/widget/preview' },
  { path: 'widget/preview', canActivate: [AuthRedirectGuard], component: PreviewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetRoutingModule { }
