import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { Cage, CAGE, Flags, LivingSituation, MaritalStatuses, PhysicalActivity, Religions } from './social-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { tap } from 'rxjs';

type AddRow = 'occupation' | 'substances' | 'habits' | 'travel';

interface SHData {
  _modified: number;
}

interface SHFormDataOccupation extends SHData {
  title: string;
  company: string;
  from: number | string;
  to: number | string;
  risk: string;
}

interface SHFormDataBibliography extends SHData {
  marital_status: string;
  religion: string;
  living_situation: string;
  mobility: string;
  pets: string;
}

interface SHFormDataRegimen extends SHData {
  exercise: string;
  diet: string;
}

interface SHFormDataSmoking extends SHData {
  status: string;
  start_date_time: number;
  stop_date_time: number;
  qty_per_day: number;
  years: number;
}

interface SHFormDataAlcohol extends SHData {
  consumption: string;
  cage: {
    cut_down: boolean;
    annoyed: boolean;
    guilty: boolean;
    eye_opener: boolean;
  };
}

interface SHFormDataSubstances extends SHData {
  status: string;
  years: number;
  note: string;
}

interface SHFormDataHabits extends SHData {
  note: string;
}

interface SHFormDataTravel extends SHData {
  date_time: number;
  destination: string;
  flags: Array<string>;
  reason: string;
  note: string;
}

interface SocialHistoryFormData extends BuilderDataAbstract {
  occupation: Array<SHFormDataOccupation>;
  bibliographic: SHFormDataBibliography;
  regimen: SHFormDataRegimen;
  smoking: SHFormDataSmoking;
  alcohol: SHFormDataAlcohol;
  substances: Array<SHFormDataSubstances>;
  habits: Array<SHFormDataHabits>;
  travel: Array<SHFormDataTravel>;
}

@Component({
  selector: 'app-social-history',
  templateUrl: './social-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './social-history.component.scss']
})
export class SocialHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public shouldAddCage: boolean = false;
  public cages: Cage[] = CAGE();
  public maritalStatuses: Array<string> = MaritalStatuses();
  public religions: Array<string> = Religions();
  public livingSituation: Array<string> = LivingSituation();
  public physicalActivity: Array<string> = PhysicalActivity();
  public flags: Array<string> = Flags();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      occupation: this.fb.array([]),
      bibliographic: this.fb.group({
        marital_status: [null],
        religion: [null],
        living_situation: [null],
        mobility: [null],
        pets: [null]
      }),
      regimen: this.fb.group({
        exercise: [null],
        diet: [null]
      }),
      smoking: this.fb.group({
        status: [null],
        start_date_time: [null],
        stop_date_time: [null],
        qty_per_day: [null],
        years: [null]
      }),
      alcohol: this.fb.group({
        consumption: [null]
      }),
      substances: this.fb.array([]),
      habits: this.fb.array([]),
      travel: this.fb.array([]),
    });
    this.addRow('occupation');
    this.addRow('substances');
    this.addRow('habits');
    // this.addRow('travel');
  }

  public getRow(form: AddRow): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: AddRow, idx: number) {
    this.getRow(form).removeAt(idx);
    if (this.getRow(form).length === 0) {
      this.addRow(form);
    }
  }

  public clearInputs(form: AddRow) {
    this.getRow(form).reset();
  }

  public addRow(form: AddRow) {
    const row = {
      occupation: () => ({
        title: [null, [Validators.required, Validators.maxLength(50)]],
        company: [null, Validators.maxLength(50)],
        from: [null],
        to: [null],
        risk: [null, Validators.maxLength(200)]
      }),
      substances: () => ({
        status: [null],
        note: [null, Validators.maxLength(500)],
        years: [null],
      }),
      habits: () => ({
        note: [null, Validators.maxLength(500)],
      }),
      travel: () => ({
        date_time: [null],
        destination: [null, Validators.maxLength(200)],
        flags: [[]],
        reason: [null, Validators.maxLength(200)],
        note: [null, Validators.maxLength(200)],
      }),
    }[form];
    const group = this.fb.group(row());
    this.getRow(form).push(group);
  }

  public toggleCage() {
    this.shouldAddCage = !this.shouldAddCage;
    if (this.shouldAddCage) {
      this.addCage();
    } else {
      (this.formData.get('alcohol') as FormGroup).removeControl('cage');
    }
  }

  public getCageCount(cage: {
    cut_down: boolean;
    annoyed: boolean;
    guilty: boolean;
    eye_opener: boolean;
  }): number {
    let count = 0;
    Object.values(cage).forEach(item => {
      if (item) {
        count = count + 1;
      }
    });
    return count;
  }

  public toggleSmoking() {
    if ((this.formData.get('smoking') as FormGroup).get('status')?.value === 'Never') {
      (this.formData.get('smoking') as FormGroup).get('start_date_time')?.reset();
      (this.formData.get('smoking') as FormGroup).get('stop_date_time')?.reset();
      (this.formData.get('smoking') as FormGroup).get('qty_per_day')?.reset();
      (this.formData.get('smoking') as FormGroup).get('years')?.reset();
      (this.formData.get('smoking') as FormGroup).get('start_date_time')?.disable();
      (this.formData.get('smoking') as FormGroup).get('stop_date_time')?.disable();
      (this.formData.get('smoking') as FormGroup).get('qty_per_day')?.disable();
      (this.formData.get('smoking') as FormGroup).get('years')?.disable();
    } else {
      (this.formData.get('smoking') as FormGroup).get('start_date_time')?.enable();
      (this.formData.get('smoking') as FormGroup).get('stop_date_time')?.enable();
      (this.formData.get('smoking') as FormGroup).get('qty_per_day')?.enable();
      (this.formData.get('smoking') as FormGroup).get('years')?.enable();
    }
  }

  public override async validate() {
    let addOccupationRow = false;
    const occupationData = this.formData.get('occupation') as FormArray;
    if (occupationData.controls.length === 1) {
      const empty = Object.values((occupationData.controls[0] as FormGroup).value).reduce((out, value) => {
        return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
      }, true);
      if (empty) {
        occupationData.removeAt(0);
        addOccupationRow = true;
      }
    }
    validateAllFormFields(this.formData);

    const res = this.formData.valid;
    if (addOccupationRow) {
      this.addRow('occupation');
    }
    return res;
  }

  public override async saveData() {
    let addOccupationRow = false;
    const occupationData = this.formData.get('occupation') as FormArray;
    if (occupationData.controls.length === 1) {
      const empty = Object.values((occupationData.controls[0] as FormGroup).value).reduce((out, value) => {
        return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
      }, true);
      if (empty) {
        occupationData.removeAt(0);
        addOccupationRow = true;
      }
    }

    const res = await super.saveData();
    if (addOccupationRow) {
      this.addRow('occupation');
    }
    return res;
  }

  serialize(data: SocialHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'SOCIAL:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: SocialHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: SOCIAL`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as SocialHistoryFormData;

    Object.entries(data)
      .filter(([key, value]) => ['occupation', 'substances', 'habits', 'travel'].includes(key))
      .forEach(([control, controlValue]) => {
        // remove data objects when not present
        if (control === 'occupation' && controlValue.length) {
          return data[control] = controlValue.filter((data: SHFormDataOccupation) => !this.isEmpty(data, ['title', 'company', 'from', 'to', 'risk']));
        }
        if (control === 'substances' && controlValue.length) {
          return data[control] = controlValue.filter((data: SHFormDataSubstances) => !this.isEmpty(data, ['status', 'years', 'note']));
        }
        if (control === 'habits' && controlValue.length) {
          return data[control] = controlValue.filter((data: SHFormDataHabits) => !this.isEmpty(data, ['note']));
        }
        if (control === 'travel' && controlValue.length) {
          return data[control] = controlValue.filter((data: SHFormDataTravel) => !this.isEmpty(data, ['date_time', 'destination', 'reason', 'note']));
        }
      });


    data.occupation = data.occupation.length ? data.occupation.filter(v => !this.isEmpty(v))
      .map(d => ({ ...d, _modified: moment().valueOf(), from: moment(d.from).valueOf(), to: moment(d.to).valueOf() })) : [];
    data.bibliographic['_modified'] = moment().valueOf();
    data.regimen['_modified'] = moment().valueOf();
    data.smoking['_modified'] = moment().valueOf();
    data.smoking['start_date_time'] = moment(data.smoking['start_date_time']).valueOf();
    data.smoking['stop_date_time'] = moment(data.smoking['stop_date_time']).valueOf();
    data.alcohol['_modified'] = moment().valueOf();
    data.substances = data.substances.length ? data.substances.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf() })) : [];
    data.habits = data.habits.length ? data.habits.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf() })) : [];
    data.travel = data.travel.length ? data.travel.filter(v => !this.isEmpty(v)).map(d => ({
      ...d,
      _modified: moment().valueOf(),
      date_time: moment(d.date_time).valueOf()
    })) : [];

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['occupation'] = data.occupation;
      builderData['bibliographic'] = data.bibliographic;
      builderData['regimen'] = data.regimen;
      builderData['smoking'] = data.smoking;
      builderData['alcohol'] = data.alcohol;
      builderData['substances'] = data.substances;
      builderData['habits'] = data.habits;
      builderData['travel'] = data.travel;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['occupation'].length; i++) {
      this.addRow('occupation');
    }
    for (let i = 1; i < data['substances'].length; i++) {
      this.addRow('substances');
    }
    for (let i = 1; i < data['habits'].length; i++) {
      this.addRow('habits');
    }
    for (let i = 0; i < data['travel'].length; i++) {
      this.addRow('travel');
    }
    if (data['alcohol'].cage) {
      this.shouldAddCage = true;
      this.addCage();
    }
    super.setBuilderData(data);
  }

  private addCage() {
    (this.formData.get('alcohol') as FormGroup).addControl('cage', this.fb.group({
      cut_down: [null],
      annoyed: [null],
      guilty: [null],
      eye_opener: [null],
    }));
  }

  private prepareSerializedList(data: SocialHistoryFormData) {

    const occupationValue = data.occupation.length ?
      data.occupation.map((c, i) => `${'(' + (i + 1) + '): '}${c.title}${c.company ? ' at ' + c.company : ''}${c.from ? ' from ' + moment(c.from).format('MMM YYYY') : ''}${c.to ? ' - ' + moment(c.to).format('MMM YYYY') : c.from ? ' - Present' : ''}${c.risk ? ', Risk factors - ' + c.risk : ''}`)
        .join('; ')
      : null;

    let bibliographicValue, regimenValue, smokingValue, alcoholValue;
    if (!this.isEmpty(data.bibliographic, ['marital_status', 'religion', 'living_situation', 'mobility', 'pets'])) {
      bibliographicValue = `${data.bibliographic.marital_status ? data.bibliographic.marital_status + ';' : ''}
                             ${data.bibliographic.religion ? data.bibliographic.religion + ';' : ''}
                             ${data.bibliographic.living_situation ? data.bibliographic.living_situation + ';' : ''}
                             ${data.bibliographic.mobility ? data.bibliographic.mobility + ';' : ''}
                             ${data.bibliographic.pets === 'Yes' ? 'Pets in the home' : 'No pets'}`
    }
    if (!this.isEmpty(data.regimen, ['exercise', 'diet'])) {
      regimenValue = `${data.regimen.exercise ? 'Physical Activity - ' + data.regimen.exercise : ''}${data.regimen.diet ? '; Diet - ' + data.regimen.diet : ''}`;
    }
    if (!this.isEmpty(data.smoking, ['status', 'start_date_time', 'stop_date_time', 'qty_per_day', 'years', 'pack'])) {
      smokingValue = `${data.smoking.status}${data.smoking.years ? ': ' + data.smoking.years + ' pack years' : ''}`;
    }
    if (!this.isEmpty(data.alcohol, ['consumption', 'cage'])) {
      alcoholValue = `${data.alcohol.consumption ? data.alcohol.consumption + ' units/week' : ''}${data.alcohol.cage ? '; CAGE - ' + this.getCageCount(data.alcohol.cage) : ''}`;
    }

    const substancesValue = data.substances.length ?
      data.substances.map((c, i) => `${'(' + (i + 1) + ')'}${c.status ? c.status : ''}${c.years ? ' - ' + c.years : ''}${c.note ? ' - ' + c.note : ''}`)
        .join('; ')
      : null;

    const hobbiesValue = data.substances.length ?
      data.habits.map((c, i) => `${'(' + (i + 1) + ')'}${c.note ? ': ' + c.note + ')' : ''}`)
        .join('; ')
      : null;

    const travelValue = data.travel.length ?
      data.travel.map((c, i) => `${'(' + (i + 1) + ')'}${c.date_time ? ': ' + moment(c.date_time).format('YYYY-MM-DD') : ''}${c.destination ? ' ' + c.destination : ''}${c.flags.length ? ', ' + c.flags.join(' Area, ') + ' Area' : ''}${c.note ? ', Risk factors - ' + c.note : ''}`)
        .join('; ')
      : null;

    const list: Array<{ title: string, value: string | null | undefined }> = [
      { title: 'Occupation', value: occupationValue },
      { title: 'Bibliographic Details', value: bibliographicValue },
      { title: 'Diet and Exercise', value: regimenValue },
      { title: 'Smoking', value: smokingValue },
      { title: 'Alcohol Consumption', value: alcoholValue },
      { title: 'Substances', value: substancesValue },
      { title: 'Hobbies and Habits', value: hobbiesValue },
      { title: 'Travel', value: travelValue },
    ].filter(v => v.value !== null && v.value !== undefined);

    return list;
  }
}
