<ng-container>
  <app-medical-and-family-history *ngIf="!isLoading && pmhSections.length;else loadingRef"
                                  [sections]="pmhSections"></app-medical-and-family-history>
  <ng-template #loadingRef>
    <div class="loading-wrapper">
      <h4>Loading EHR Data:</h4>
      <ul>
        <li *ngFor="let state of loadingStates|keyvalue">
          {{state.key}}
          <app-loader *ngIf="!state.value">autorenew</app-loader>
          <mat-icon *ngIf="state.value" style="color: green; line-height: inherit;">done</mat-icon>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>