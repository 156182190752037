
export const PMHSystems = (): Array<string> => ([
  'CVS',
  'Respiratory',
  'UGT',
  'CNS',
  'GIT',
  'Gyneacological',
  'Reproductive',
  'MSK',
  'Endocrine',
  'Haematological'
].sort((a, b) => a.localeCompare(b)));

export const PMHTypes = (): Array<string> => ([
  'Acute',
  'Chronic'
]);

export const PMHDosage = (): Array<string> => ([
  'g',
  'mg',
  'ug',
  'ml',
  'units',
].sort((a, b) => a.localeCompare(b)));

export const PMHFrequency = (): Array<string> => ([
  'daily',
  'bd',
  'tds',
  'qid',
  '8 hourly',
  '12 hourly',
  'nocte',
  'mane',
  'stat',
  'prn',
].sort((a, b) => a.localeCompare(b)));

export const PMHDeliveryRoutes = (): Array<string> => ([
  'P.O.',
  'P.V.',
  'Buccally',
  'P.R. S.C., SL',
  'Nasally',
  'Nasally (In Each Nostril) Ocular (In Affected Eye)',
  'Ocular (In Both Eyes)'
].sort((a, b) => a.localeCompare(b)));
