<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <!--Abdo Pain-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Abdo Pain</label>
      </div>
      <div class="col" formGroupName="abdominal_pain">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <ng-select [items]="sites"
                       [multiple]="true"
                       placeholder="Site(s)"
                       appendTo="#app-wrapper"
                       formControlName="regions"
                       [hideSelected]="true"
                       jargonLoadingState="builder-control"
                       bindLabel="name"
                       bindValue="name">
            </ng-select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Diarrhoea-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Diarrhoea</label>
      </div>
      <div class="col" formGroupName="diarrhoea">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Constipation-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Constipation</label>
      </div>
      <div class="col" formGroupName="constipation">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Nausea-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Nausea</label>
      </div>
      <div class="col" formGroupName="nausea">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Vomiting-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Vomiting</label>
      </div>
      <div class="col" formGroupName="vomiting">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value ? 'Present' : 'Not Present' }}{{item.note}}]</span>
  </p>
</ng-template>