<div class="container-fluid p-0" *ngIf="sections.length">
  <div class="row g-0">

    <div class="col-auto ps-0 tab-section-select">
      <div *ngFor="let section of sections"
           [ngClass]="{'active': section.alias === currentSection.alias}" (click)="changeSelected(section)">
        {{ section.title }}
      </div>
    </div>

    <div class="col pe-0 section-details">
      <ng-container *ngFor="let section of sections">
        <ng-template *ngTemplateOutlet="detailDisplay; context: section"></ng-template>
      </ng-container>
    </div>

  </div>
</div>

<ng-template #detailDisplay let-title="title" let-alias="alias" let-data="data" let-child="child">
  <div class="section-preview" [ngClass]="child ? 'child' : 'm-3 pb-3'">
    <label id="{{alias}}">{{ title }} ({{data.length}})</label>
    <div *ngIf="!data.length">
      <label class="no-section-data">No data to display</label>
    </div>
    <ul>
      <li *ngFor="let data of data">
        <ng-container *ngIf="isString(data); else subData">{{ data }}</ng-container>
        <ng-template #subData>
          <ng-template *ngTemplateOutlet="detailDisplay; context: asChild(data)"></ng-template>
        </ng-template>
      </li>
    </ul>
  </div>
</ng-template>