import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class UnixDateAdapter extends NgbDateAdapter<number> {
  fromModel(value: number | null): NgbDateStruct | null {
    if (value !== null) {
      const date = moment(value);
      return {
        day: date.date(),
        month: date.month() + 1,
        year: date.year()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): number | null {
    if (date) {
      return moment({
        year: date.year,
        month: date.month - 1,
        day: date.day,
      }).valueOf();
    }
    return null;
  }
}
