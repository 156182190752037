import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { CGSRegions, CGSScale, CGSScore } from './cns-exam.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

interface BaseCNSData extends BuilderPresentNote {
  normal: boolean;
}

interface CNSExamFormData extends BuilderDataAbstract {
  general: BaseCNSData;
  fundi: BaseCNSData;
  focal_signs: BaseCNSData;
  diffuse_signs: BaseCNSData;
  cranial_nerves: BaseCNSData;
  gcs: BaseCNSData & { value: number };
  reflexes: BuilderPresentNote & {
    data: Array<{
      region: Array<string>;
      score: number;
      note: string;
    }>
  };
}

@Component({
  selector: 'app-cns-exam',
  templateUrl: './cns-exam.component.html',
  styleUrls: ['./cns-exam.component.scss']
})
export class CnsExamComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public scale: Array<number> = CGSScale;
  public regions: Array<{ name: string, value: number }> = CGSRegions();
  public score: Array<{ name: string, value: number }> = CGSScore();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      general: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      fundi: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      focal_signs: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      diffuse_signs: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      cranial_nerves: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      gcs: this.fb.group({
        present: [null],
        normal: [null],
        value: [null, [Validators.min(3), Validators.max(15)]],
        note: [null, Validators.maxLength(500)]
      }),
      reflexes: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            region: [null],
            score: [null],
            note: [null, Validators.maxLength(500)]
          })
        ])
      })
    });

    //toggle form validation
    if (!this.isReadOnly) {
      if (this.controlSub) this.controlSub.unsubscribe();
      this.controlSub = merge(
        ...Object.values(this.formData.controls)
          .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
      ).subscribe({
        next: v => {
          switch (v.get('present')?.value) {
            case true:
              v.get('note')?.enable({ emitEvent: false });
              v.get('normal')?.enable({ emitEvent: false });
              v.get('value')?.enable({ emitEvent: false });
              break;
            case false:
              v.get('note')?.enable({ emitEvent: false });
              v.get('data')?.disable({ emitEvent: false });
              v.get('normal')?.disable({ emitEvent: false });
              v.get('normal')?.patchValue('', { emitEvent: false });
              v.get('value')?.disable({ emitEvent: false });
              v.get('value')?.patchValue('', { emitEvent: false });
              break;
            case null:
              v.get('data')?.disable({ emitEvent: false });
              v.get('note')?.disable({ emitEvent: false });
              v.get('note')?.patchValue('', { emitEvent: false });
              v.get('normal')?.disable({ emitEvent: false });
              v.get('normal')?.patchValue('', { emitEvent: false });
              v.get('value')?.disable({ emitEvent: false });
              v.get('value')?.patchValue('', { emitEvent: false });
              break;
          }
        }
      });
    }
  }

  public addReflexes() {
    ((this.formData.get('reflexes') as FormGroup).get('data') as FormArray).push(
      this.fb.group({
        present: [null],
        region: [[]],
        score: [null],
        note: [null, Validators.maxLength(500)]
      })
    );
  }

  public getRow(form: keyof CNSExamFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof CNSExamFormData) {
    this.getRow(form).reset();
  }

  public getDataCount(): number {
    return ((this.formData.get('reflexes') as FormGroup).get('data') as FormArray).length;
  }

  public deleteRow() {
    return (idx: number) => {
      ((this.formData.get('reflexes') as FormGroup).get('data') as FormArray).removeAt(idx);
      if (((this.formData.get('reflexes') as FormGroup).get('data') as FormArray).length === 0) {
        this.addReflexes();
      } else {
        const present = this.isPresent('reflexes');
        (this.formData.get('reflexes') as FormGroup).get('present')?.patchValue(!present);
        setTimeout(() => (this.formData.get('reflexes') as FormGroup).get('present')?.patchValue(present), 0);
      }
    };
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: CNSExamFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'CNS EXAM:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: CNSExamFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: CNS EXAM`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value ? 'Present ' : 'Not Present '}${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as CNSExamFormData;
    data.general['_modified'] = moment().valueOf();
    data.fundi['_modified'] = moment().valueOf();
    data.focal_signs['_modified'] = moment().valueOf();
    data.diffuse_signs['_modified'] = moment().valueOf();
    data.cranial_nerves['_modified'] = moment().valueOf();
    data.gcs['_modified'] = moment().valueOf();
    data.reflexes['_modified'] = moment().valueOf();

    // remove reflex data objects when not present
    if (data.reflexes && !this.isPresent('reflexes')) {
      data.reflexes.data = [];
    }

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['general'] = data.general;
      builderData['fundi'] = data.fundi;
      builderData['focal_signs'] = data.focal_signs;
      builderData['diffuse_signs'] = data.diffuse_signs;
      builderData['cranial_nerves'] = data.cranial_nerves;
      builderData['gcs'] = data.gcs;
      builderData['reflexes'] = data.reflexes;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    if (data['reflexes'] && data['reflexes']['present']) {
      this.addReflexes();
    }
    super.setBuilderData(data);
  }

  private prepareSerializedList(data: CNSExamFormData) {
    const reflexValue = data.reflexes.present && data.reflexes.data.length ?
      ' [' + data.reflexes.data.map((c, i) => `${'(' + (i + 1) + ')'} ${this.getJoinedList(c.region)}${c.score ? '; ' + this.score.find(s => s.value == c.score)?.name : ''}${c.note ? '; ' + c.note : ''}`).join('; ') + ']'
      : `${data.reflexes.note ? ' [' + data.reflexes.note + ']' : ''}`;

    const list = [
      { title: 'General:', value: data.general.present, note: `${data.general.note ? ' [' + data.general.note + ']' : ''}` },
      { title: 'Fundi:', value: data.fundi.present, note: `${data.fundi.note ? ' [' + data.fundi.note + ']' : ''}` },
      { title: 'Focal Signs:', value: data.focal_signs.present, note: `${data.focal_signs.note ? ' [' + data.focal_signs.note + ']' : ''}` },
      { title: 'Diffuse Signs:', value: data.diffuse_signs.present, note: `${data.diffuse_signs.note ? ' [' + data.diffuse_signs.note + ']' : ''}` },
      { title: 'Cranial Nerves:', value: data.cranial_nerves.present, note: `${data.cranial_nerves.note ? ' [' + data.cranial_nerves.note + ']' : ''}` },
      { title: 'GCS:', value: data.gcs.present, note: `${data.gcs.note || data.gcs.value ? ' [' + (data.gcs.value || '') + (data.gcs.note ? ' ' + data.gcs.note : '') + ']' : ''}` },
      { title: 'Reflexes:', value: data.reflexes.present, note: reflexValue }
    ].filter(v => v.value !== null);

    return list;
  }

}
