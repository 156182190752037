<div class="container-fluid g-0" *ngIf="config">
  <table class="table"
         matSort
         matSortActive=""
         matSortDisableClear="true"
         matSortDirection="desc"
         (matSortChange)="sortData($event)">
    <thead>
      <tr class="text-uppercase text-jargon">
        <ng-container *ngFor="let column of config.columns; let i = index">
          <ng-container *ngIf="column.title; else noSort">
            <th [mat-sort-header]="column.title">
              <ng-container *ngIf="templates[column.header_slot || 'header']; else noHeaderTmpl"
                            [ngTemplateOutlet]="templates[column.header_slot || 'header']"
                            [ngTemplateOutletContext]="{ data: column, index: i }">
              </ng-container>
              <ng-template #noHeaderTmpl>{{ column.title }}</ng-template>
            </th>
          </ng-container>
          <ng-template #noSort>
            <th>
              <ng-container *ngIf="templates[column.header_slot || 'header']; else noHeaderTmpl"
                            [ngTemplateOutlet]="templates[column.header_slot || 'header']"
                            [ngTemplateOutletContext]="{ data: column, index: i }">
              </ng-container>
              <ng-template #noHeaderTmpl>{{ column.title }}</ng-template>
            </th>
          </ng-template>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="config.data.length; else noData">
        <tr *ngFor="let row of config.data">
          <td *ngFor="let column of config.columns; let i = index;">
            <ng-container
                          *ngIf="(column.column_slot  || 'cell') && templates[column.column_slot || 'cell']; else noCellTmpl"
                          [ngTemplateOutlet]="templates[column.column_slot || 'cell']"
                          [ngTemplateOutletContext]="{ data: row, index: i, column, value: getDataValue(row, column.field) }">
            </ng-container>
            <ng-template #noCellTmpl>
              {{ getDataValue(row, column.field) }}
            </ng-template>
          </td>
        </tr>
      </ng-container>
      <ng-template #noData>
        <tr>
          <td [attr.colspan]="config.columns.length" class="text-center text-muted">
            {{ loading === true ? 'Loading...' : 'No Data' }}
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
