<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!-- Files -->
    <ng-container *ngIf="!files.length; else fileList" class="row">
      <div class="col-12 mb-3">
        <jargon-dropzone [modal]="true" [modalOptions]="options" (onModalSave)="attachmentsAdded($event)"
                         label="Upload Consent Form">
        </jargon-dropzone>
      </div>
    </ng-container>
    <ng-template #fileList>
      <div class="row mb-3">
        <div class="col-12 col-md-6 col-xl-4 attachment-wrapper"
             *ngFor="let file of files">
          <span class="attachment-container" [title]="file.name">
            <mat-icon [inline]="true">file_present</mat-icon>
            <label>{{file.name}}</label>
            <ng-container *jargonViewMode="'write'">
              <mat-icon [inline]="true" class="delete" [title]="'Delete '+file.name" (click)="deleteFile(file)">delete
              </mat-icon>
            </ng-container>
          </span>
        </div>
      </div>
    </ng-template>

    <!-- Status -->
    <div class="row mb-3">
      <div class="col-12">
        <label class="me-4">
          <input type="radio" formControlName="result" jargonLoadingState="builder-control" value="POSITIVE"> POSITIVE
        </label>
        <label>
          <input type="radio" formControlName="result" jargonLoadingState="builder-control" value="NEGATIVE"> NEGATIVE
        </label>
      </div>
    </div>

    <!--Notes-->
    <div class="row mb-3">
      <div class="col-12">
        <textarea type="text" class="form-control" placeholder="Notes/Comments"
                  jargonLoadingState="builder-control" formControlName="note"></textarea>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="data.fileNames">
    <strong>Consent </strong>
    <span>[Consent Received - Files: {{data.fileNames}}];</span>
  </p>
  <p>
    <strong>Result </strong>
    <span>[{{data.result}}]</span>
  </p>
</ng-template>