import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SectionBuilderControls } from 'src/app/modules/sections/shared/components/section-item/section-item.component';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { ComponentHostDirective } from 'src/app/shared/directives/component-host.directive';
import { BuilderConfigItemModel } from 'src/app/shared/models/builder-config.model';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { SectionConfigModel } from 'src/app/shared/models/section-config.model';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../abstract-builder/abstract-builder.component';

@Component({
  selector: 'builder-item',
  templateUrl: './builder-item.component.html',
  styleUrls: ['./builder-item.component.scss']
})
export class BuilderItemComponent implements OnInit, OnDestroy {
  @Input('section') section!: SectionConfigModel;
  @Input('config') config!: BuilderConfigItemModel;
  @Output() configChange: EventEmitter<BuilderConfigItemModel> = new EventEmitter<BuilderConfigItemModel>();
  @Input('data') data?: BuilderData | null;
  @Input('controls') controls!: SectionBuilderControls;

  @ViewChild('builderHost', { static: true, read: ComponentHostDirective }) builderHost!: ComponentHostDirective;
  private builderRef!: ComponentRef<AbstractBuilderComponent>;
  private configSub?: Subscription;
  instance: any;

  constructor() { }

  ngOnInit(): void {
    this.builderHost.viewContainerRef.clear();
    this.builderRef = this.builderHost.viewContainerRef.createComponent<AbstractBuilderComponent>(this.config.component);
    this.builderRef.instance.section = this.section;
    this.builderRef.instance.config = this.config;
    this.builderRef.instance.data = this.data;
    this.builderRef.instance.controls = {
      ...this.controls,
      // override controls froms section or add builder specific generic controls
    };
    this.configSub = this.builderRef.instance.configChange.subscribe({
      next: (event: BuilderConfigItemModel) => this.configChange.emit(event)
    });
  }

  ngOnDestroy(): void {
    this.configSub?.unsubscribe();
  }

  serialize(): HistoryData[] {
    if (this.data) {
      return this.builderRef.instance.serialize(JSON.parse(JSON.stringify(this.data)));
    }
    return [];
  }

  asString(data: BuilderDataAbstract): string {
    return this.builderRef.instance.asString(data);
  }

  validate() {
    return this.builderRef.instance.validate();
  }

  getData() {
    return this.builderRef.instance.getData();
  }

}
