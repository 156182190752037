import { AfterViewInit, Component } from '@angular/core';
import { Pagination } from '../../../../shared/types/paginatedResponse';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { TableConfig } from '../../../../shared/components/jargon-table/jargon-table.component';

export type Vaccination = {
  id: number;
  date: string;
  disease: string;
  vaccines: string;
  doctor: string;
  site: string;
};

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss']
})
export class VaccinationsComponent extends AbstractEHRTabComponent implements AfterViewInit {

  public pagination?: Pagination;

  public tableConfig: TableConfig<Vaccination> = {
    columns: [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Targeted Disease',
        field: 'disease',
      },
      {
        title: 'Vaccines',
        field: 'vaccines',
      },
      {
        title: 'Practitioner',
        field: 'doctor',
      },
      {
        title: 'Site',
        field: 'site',
      }
    ],
    data: []
  };

  constructor() {
    super();
    this.pagination = {
      total: 6,
      count: 6,
      per_page: 5,
      current_page: 1,
      total_pages: 2,
      links: {
        previous: '',
        next: '',
      }
    };
  }

  public reload(page?: number) {
    //@TODO: add reload logic
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableConfig.data = [
        {
          id: 1,
          date: '2022-01-20',
          disease: 'Measles, Mumps, Rubella',
          vaccines: 'MMR Pfizer',
          doctor: 'Sr Joan Jones',
          site: 'Left Arm'
        },
        {
          id: 2,
          date: '2021-10-12',
          disease: 'Influenza',
          vaccines: 'FluBeGone',
          doctor: 'Sr. Anne Smith',
          site: 'Right Arm'
        },
        {
          id: 3,
          date: '2021-04-01',
          disease: 'Tetnis',
          vaccines: 'Tetnis Pfizer',
          doctor: 'Dr. John Black',
          site: 'Right Arm'
        },
        {
          id: 4,
          date: '2020-10-14',
          disease: 'Yellow Fever',
          vaccines: 'Y231',
          doctor: 'Dr. Kevin Brown',
          site: 'Right Arm'
        },
        {
          id: 5,
          date: '2019-08-10',
          disease: 'Influenza',
          vaccines: 'FluBeGone',
          doctor: 'Dr. John Doe',
          site: 'Right Arm'
        },
      ];
    }, 0);
  }

}
