import { BuilderConfigItemModel, BuilderConfigModel } from 'src/app/shared/models/builder-config.model';
import { Role } from 'src/app/shared/types/enum/user';
import { PastMedicalHistoryComponent } from '../../builders/components/past-medical-history/past-medical-history.component';
import { FamilyHistoryComponent } from '../../builders/components/family-history/family-history.component';
import { AllergiesComponent } from '../../builders/components/allergies/allergies.component';
import { SocialHistoryComponent } from '../../builders/components/social-history/social-history.component';
import { VaccinationHistoryComponent } from '../../builders/components/vaccination-history/vaccination-history.component';
import { MedicalScreeningComponent } from '../../builders/components/medical-screening/medical-screening.component';
import { CvsHistoryComponent } from '../../builders/components/cvs-history/cvs-history.component';
import { RespiratoryHistoryComponent } from '../../builders/components/respiratory-history/respiratory-history.component';
import { GitHistoryComponent } from '../../builders/components/git-history/git-history.component';
import { UgtHistoryComponent } from '../../builders/components/ugt-history/ugt-history.component';
import { CnsHistoryComponent } from '../../builders/components/cns-history/cns-history.component';
import { GynaeHistoryComponent } from '../../builders/components/gynae-history/gynae-history.component';
import { VitalSignsComponent } from '../../builders/components/vital-signs/vital-signs.component';
import { CadjcolComponent } from '../../builders/components/cadjcol/cadjcol.component';
import { CnsExamComponent } from '../../builders/components/cns-exam/cns-exam.component';
import { HeadAndNeckExamComponent } from '../../builders/components/head-and-neck-exam/head-and-neck-exam.component';
import { CvsExamComponent } from '../../builders/components/cvs-exam/cvs-exam.component';
import { RespiratoryExamComponent } from '../../builders/components/respiratory-exam/respiratory-exam.component';
import { MskExamComponent } from '../../builders/components/msk-exam/msk-exam.component';
import { UgtExamComponent } from '../../builders/components/ugt-exam/ugt-exam.component';
import { GitExamComponent } from '../../builders/components/git-exam/git-exam.component';
import { UrinalysisComponent } from '../../builders/components/urinalysis/urinalysis.component';
import { EcgTestComponent } from '../../builders/components/ecg-test/ecg-test.component';
import { BloodGlucoseTestComponent } from '../../builders/components/blood-glucose-test/blood-glucose-test.component';
import { RapidCovidTestComponent } from '../../builders/components/rapid-covid-test/rapid-covid-test.component';
import { RapidMalariaTestComponent } from '../../builders/components/rapid-malaria-test/rapid-malaria-test.component';
import { HivTestComponent } from '../../builders/components/hiv-test/hiv-test.component';
import { QuickLabsComponent } from '../../builders/components/quick-labs/quick-labs.component';
import { WoundCareManagementComponent } from '../../builders/components/wound-care-management/wound-care-management.component';
import { InjectionVaccinationsComponent } from '../../builders/components/injection-vaccinations/injection-vaccinations.component';
import { InjectionFamilyPlanningComponent } from '../../builders/components/injection-family-planning/injection-family-planning.component';
import { InjectionMedicationsComponent } from '../../builders/components/injection-medications/injection-medications.component';
import { InjectionVitaminsComponent } from '../../builders/components/injection-vitamins/injection-vitamins.component';
import { CustomBuilderComponent } from '../../builders/components/custom/custom.component';
import { TravelHistoryComponent } from '../../builders/components/travel-history/travel-history.component';

export const AVAILABLE_BUILDERS: { [key: string]: BuilderConfigItemModel } = {
  //history
  history_past_medical_history: { title: 'Past Medical History', label: 'PMH', alias: 'history_past_medical_history', group: null, component: PastMedicalHistoryComponent },
  history_family_history: { title: 'Family History', label: 'Family History', alias: 'history_family_history', group: null, component: FamilyHistoryComponent },
  history_allergies: { title: 'Allergies', label: 'Allergies', alias: 'history_allergies', group: null, component: AllergiesComponent },
  history_social_history: { title: 'Social History', label: 'Social History', alias: 'history_social_history', group: null, component: SocialHistoryComponent },
  history_travel_history: { title: 'Travel History', label: 'Travel History', alias: 'history_travel_history', group: null, component: TravelHistoryComponent },
  history_vaccination_history: { title: 'Vaccination History', label: 'Vaccinations', alias: 'history_vaccination_history', group: null, component: VaccinationHistoryComponent },
  history_medical_screening: { title: 'Preventative Screening', label: 'Preventative Screening', alias: 'history_medical_screening', group: null, component: MedicalScreeningComponent },
  history_cvs_history: { title: 'CVS History', label: 'CVS History', alias: 'history_cvs_history', group: null, component: CvsHistoryComponent },
  history_resp_history: { title: 'Respiratory History', label: 'RESP History', alias: 'history_resp_history', group: null, component: RespiratoryHistoryComponent },
  history_ugt_history: { title: 'UGT History', label: 'UGT History', alias: 'history_ugt_history', group: null, component: UgtHistoryComponent },
  history_git_history: { title: 'GIT History', label: 'GIT History', alias: 'history_git_history', group: null, component: GitHistoryComponent },
  history_cns_history: { title: 'CNS History', label: 'CNS History', alias: 'history_cns_history', group: null, component: CnsHistoryComponent },
  history_gynae_history: { title: 'Gynae History', label: 'Gynae History', alias: 'history_gynae_history', group: null, component: GynaeHistoryComponent },
  //exam
  exam_vital_signs: { title: 'Vital Signs', label: 'Vitals', alias: 'exam_vital_signs', group: null, component: VitalSignsComponent },
  exam_cadjcol: { title: 'CADJCOL', label: 'CADJCOL', alias: 'exam_cadjcol', group: null, component: CadjcolComponent },
  exam_head_and_neck: { title: 'ENT Exam', label: 'ENT', alias: 'exam_head_and_neck', group: null, component: HeadAndNeckExamComponent },
  exam_cvs_exam: { title: 'CVS Exam', label: 'CVS Exam', alias: 'exam_cvs_exam', group: null, component: CvsExamComponent },
  exam_resp_exam: { title: 'Respiratory Exam', label: 'RESP Exam', alias: 'exam_resp_exam', group: null, component: RespiratoryExamComponent },
  exam_git_exam: { title: 'GIT Exam', label: 'GIT Exam', alias: 'exam_git_exam', group: null, component: GitExamComponent },
  exam_cns_exam: { title: 'CNS Exam', label: 'CNS Exam', alias: 'exam_cns_exam', group: null, component: CnsExamComponent },
  exam_msk_exam: { title: 'MSK Exam', label: 'MSK Exam', alias: 'exam_msk_exam', group: null, component: MskExamComponent },
  exam_ugt_exam: { title: 'UGT Exam', label: 'UGT Exam', alias: 'exam_ugt_exam', group: null, component: UgtExamComponent },
  exam_wcm: { title: 'Wound Care Management', label: 'WCM', alias: 'exam_wcm', group: null, component: WoundCareManagementComponent },
  //testing
  test_urinalysis: { title: 'Urinalysis', label: 'Urinalysis', alias: 'test_urinalysis', group: 'In-House Testing', component: UrinalysisComponent },
  test_ecg_test: { title: 'ECG', label: 'ECG', alias: 'test_ecg_test', group: 'In-House Testing', component: EcgTestComponent },
  test_blood_glucose_test: { title: 'Blood Glucose', label: 'Blood Glucose', alias: 'test_blood_glucose_test', group: 'In-House Testing', component: BloodGlucoseTestComponent },
  test_covid_test: { title: 'COVID Rapid Antigen', label: 'COVID Rapid', alias: 'test_covid_test', group: 'In-House Testing', component: RapidCovidTestComponent },
  test_malaria_test: { title: 'Rapid Malaria', label: 'Rapid Malaria', alias: 'test_malaria_test', group: 'In-House Testing', component: RapidMalariaTestComponent },
  test_hiv_test: { title: 'HIV Test', label: 'HIV Test', alias: 'test_hiv_test', group: 'In-House Testing', component: HivTestComponent },
  test_quick_labs: { title: 'Quick Labs', label: 'Quick Labs', alias: 'test_quick_labs', group: 'External Testing', component: QuickLabsComponent },
  // Injections
  injection_vaccinations: { title: 'Vaccinations', label: 'Vaccinations', alias: 'injection_vaccinations', group: null, component: InjectionVaccinationsComponent },
  injection_family_planning: { title: 'Family Planning', label: 'Family Planning', alias: 'injection_family_planning', group: null, component: InjectionFamilyPlanningComponent },
  injection_medication: { title: 'Medications', label: 'Injectable Medications', alias: 'injection_medication', group: null, component: InjectionMedicationsComponent },
  injection_vitamins: { title: 'Vitamins', label: 'Injectable Vitamins', alias: 'injection_vitamins', group: null, component: InjectionVitaminsComponent },
  // Custom
  custom: { title: 'Custom Builder', label: 'Custom', alias: 'custom', group: null, component: CustomBuilderComponent },
}
export const resolveBuilder = (alias: string) => {
  if (/^_custom-builder::[a-z0-9-]{4,}$/.test(alias)) {
    return {
      ...AVAILABLE_BUILDERS['custom'],
      alias
    };
  }
  return AVAILABLE_BUILDERS[alias];
}

export const BUILDER_GROUP_ORDER: { [key: string]: number } = {
  'default': 0,
  'In-House Testing': 1,
  'External Testing': 2
};

export const PRESETS: (role: string) => BuilderConfigModel[] = (role: string) => ({
  [Role.GP]: [
    {
      title: 'HISTORY',
      icon: 'folder_shared',
      alias: 'history',
      builders: [
        AVAILABLE_BUILDERS['history_past_medical_history'],
        AVAILABLE_BUILDERS['history_family_history'],
        AVAILABLE_BUILDERS['history_social_history'],
        AVAILABLE_BUILDERS['history_travel_history'],
        AVAILABLE_BUILDERS['history_vaccination_history'],
        AVAILABLE_BUILDERS['history_medical_screening'],
        AVAILABLE_BUILDERS['history_cvs_history'],
        AVAILABLE_BUILDERS['history_resp_history'],
        AVAILABLE_BUILDERS['history_ugt_history'],
        AVAILABLE_BUILDERS['history_git_history'],
        AVAILABLE_BUILDERS['history_cns_history'],
        AVAILABLE_BUILDERS['history_gynae_history'],
      ]
    },
    {
      title: 'EXAM',
      icon: 'manage_search',
      alias: 'exam',
      builders: [
        AVAILABLE_BUILDERS['exam_vital_signs'],
        AVAILABLE_BUILDERS['exam_cadjcol'],
        AVAILABLE_BUILDERS['exam_head_and_neck'],
        AVAILABLE_BUILDERS['exam_cvs_exam'],
        AVAILABLE_BUILDERS['exam_resp_exam'],
        AVAILABLE_BUILDERS['exam_git_exam'],
        AVAILABLE_BUILDERS['exam_cns_exam'],
        AVAILABLE_BUILDERS['exam_msk_exam'],
        AVAILABLE_BUILDERS['exam_ugt_exam'],
      ]
    },
    {
      title: 'TESTING',
      icon: 'science',
      alias: 'testing',
      builders: [
        AVAILABLE_BUILDERS['test_urinalysis'],
        AVAILABLE_BUILDERS['test_ecg_test'],
        AVAILABLE_BUILDERS['test_blood_glucose_test'],
        AVAILABLE_BUILDERS['test_covid_test'],
        AVAILABLE_BUILDERS['test_malaria_test'],
        AVAILABLE_BUILDERS['test_hiv_test'],
        AVAILABLE_BUILDERS['test_quick_labs'],
      ]
    },
    {
      title: 'INJECTIONS',
      icon: 'vaccines',
      alias: 'injections',
      builders: [
        AVAILABLE_BUILDERS['injection_vaccinations'],
        AVAILABLE_BUILDERS['injection_family_planning'],
        AVAILABLE_BUILDERS['injection_medication'],
        AVAILABLE_BUILDERS['injection_vitamins'],
      ]
    },
    {
      title: 'CUSTOM',
      icon: 'settings',
      alias: 'custom',
      builders: []
    }
  ],
  [Role.SP]: [
    {
      title: 'HISTORY',
      icon: 'folder_shared',
      alias: 'history',
      builders: [
        AVAILABLE_BUILDERS['history_past_medical_history'],
        AVAILABLE_BUILDERS['history_family_history'],
        AVAILABLE_BUILDERS['history_social_history'],
        AVAILABLE_BUILDERS['history_travel_history'],
        AVAILABLE_BUILDERS['history_vaccination_history'],
        AVAILABLE_BUILDERS['history_medical_screening'],
        AVAILABLE_BUILDERS['history_cvs_history'],
        AVAILABLE_BUILDERS['history_resp_history'],
        AVAILABLE_BUILDERS['history_ugt_history'],
        AVAILABLE_BUILDERS['history_git_history'],
        AVAILABLE_BUILDERS['history_cns_history'],
        AVAILABLE_BUILDERS['history_gynae_history'],
      ]
    },
    {
      title: 'EXAM',
      icon: 'manage_search',
      alias: 'exam',
      builders: [
        AVAILABLE_BUILDERS['exam_vital_signs'],
        AVAILABLE_BUILDERS['exam_cadjcol'],
        AVAILABLE_BUILDERS['exam_head_and_neck'],
        AVAILABLE_BUILDERS['exam_cvs_exam'],
        AVAILABLE_BUILDERS['exam_resp_exam'],
        AVAILABLE_BUILDERS['exam_git_exam'],
        AVAILABLE_BUILDERS['exam_cns_exam'],
        AVAILABLE_BUILDERS['exam_msk_exam'],
        AVAILABLE_BUILDERS['exam_ugt_exam'],
      ]
    },
    {
      title: 'TESTING',
      icon: 'science',
      alias: 'testing',
      builders: [
        AVAILABLE_BUILDERS['test_urinalysis'],
        AVAILABLE_BUILDERS['test_ecg_test'],
        AVAILABLE_BUILDERS['test_blood_glucose_test'],
        AVAILABLE_BUILDERS['test_covid_test'],
        AVAILABLE_BUILDERS['test_malaria_test'],
        AVAILABLE_BUILDERS['test_hiv_test'],
        AVAILABLE_BUILDERS['test_quick_labs'],
      ]
    },
    {
      title: 'INJECTIONS',
      icon: 'vaccines',
      alias: 'injections',
      builders: [
        AVAILABLE_BUILDERS['injection_vaccinations'],
        AVAILABLE_BUILDERS['injection_family_planning'],
        AVAILABLE_BUILDERS['injection_medication'],
        AVAILABLE_BUILDERS['injection_vitamins'],
      ]
    },
    {
      title: 'CUSTOM',
      icon: 'settings',
      alias: 'custom',
      builders: []
    }
  ],
  [Role.PCN]: [
    {
      title: 'HISTORY',
      icon: 'folder_shared',
      alias: 'history',
      builders: [
        AVAILABLE_BUILDERS['history_past_medical_history'],
        AVAILABLE_BUILDERS['history_family_history'],
        AVAILABLE_BUILDERS['history_social_history'],
        AVAILABLE_BUILDERS['history_travel_history'],
        AVAILABLE_BUILDERS['history_vaccination_history'],
        AVAILABLE_BUILDERS['history_medical_screening'],
      ]
    },
    {
      title: 'EXAM',
      icon: 'manage_search',
      alias: 'exam',
      builders: [
        AVAILABLE_BUILDERS['exam_vital_signs'],
        AVAILABLE_BUILDERS['exam_cadjcol'],
        AVAILABLE_BUILDERS['exam_wcm'],
      ]
    },
    {
      title: 'TESTING',
      icon: 'science',
      alias: 'testing',
      builders: [
        AVAILABLE_BUILDERS['test_urinalysis'],
        AVAILABLE_BUILDERS['test_ecg_test'],
        AVAILABLE_BUILDERS['test_blood_glucose_test'],
        AVAILABLE_BUILDERS['test_covid_test'],
        AVAILABLE_BUILDERS['test_malaria_test'],
        AVAILABLE_BUILDERS['test_hiv_test'],
        AVAILABLE_BUILDERS['test_quick_labs'],
      ]
    },
    {
      title: 'INJECTIONS',
      icon: 'vaccines',
      alias: 'injections',
      builders: [
        AVAILABLE_BUILDERS['injection_vaccinations'],
        AVAILABLE_BUILDERS['injection_family_planning'],
        AVAILABLE_BUILDERS['injection_medication'],
        AVAILABLE_BUILDERS['injection_vitamins'],
      ]
    },
    {
      title: 'CUSTOM',
      icon: 'settings',
      alias: 'custom',
      builders: []
    }
  ],
  [Role.SCN]: [
    {
      title: 'HISTORY',
      icon: 'folder_shared',
      alias: 'history',
      builders: [
        AVAILABLE_BUILDERS['history_past_medical_history'],
        AVAILABLE_BUILDERS['history_family_history'],
        AVAILABLE_BUILDERS['history_social_history'],
        AVAILABLE_BUILDERS['history_travel_history'],
        AVAILABLE_BUILDERS['history_vaccination_history'],
        AVAILABLE_BUILDERS['history_medical_screening'],
        AVAILABLE_BUILDERS['history_cvs_history'],
        AVAILABLE_BUILDERS['history_resp_history'],
        AVAILABLE_BUILDERS['history_ugt_history'],
        AVAILABLE_BUILDERS['history_git_history'],
        AVAILABLE_BUILDERS['history_cns_history'],
        AVAILABLE_BUILDERS['history_gynae_history'],
      ]
    },
    {
      title: 'EXAM',
      icon: 'manage_search',
      alias: 'exam',
      builders: [
        AVAILABLE_BUILDERS['exam_vital_signs'],
        AVAILABLE_BUILDERS['exam_cadjcol'],
        AVAILABLE_BUILDERS['exam_head_and_neck'],
        AVAILABLE_BUILDERS['exam_cvs_exam'],
        AVAILABLE_BUILDERS['exam_resp_exam'],
        AVAILABLE_BUILDERS['exam_git_exam'],
        AVAILABLE_BUILDERS['exam_cns_exam'],
        AVAILABLE_BUILDERS['exam_msk_exam'],
        AVAILABLE_BUILDERS['exam_ugt_exam'],
      ]
    },
    {
      title: 'TESTING',
      icon: 'science',
      alias: 'testing',
      builders: [
        AVAILABLE_BUILDERS['test_urinalysis'],
        AVAILABLE_BUILDERS['test_ecg_test'],
        AVAILABLE_BUILDERS['test_blood_glucose_test'],
        AVAILABLE_BUILDERS['test_covid_test'],
        AVAILABLE_BUILDERS['test_malaria_test'],
        AVAILABLE_BUILDERS['test_hiv_test'],
        AVAILABLE_BUILDERS['test_quick_labs'],
      ]
    },
    {
      title: 'INJECTIONS',
      icon: 'vaccines',
      alias: 'injections',
      builders: [
        AVAILABLE_BUILDERS['injection_vaccinations'],
        AVAILABLE_BUILDERS['injection_family_planning'],
        AVAILABLE_BUILDERS['injection_medication'],
        AVAILABLE_BUILDERS['injection_vitamins'],
      ]
    },
    {
      title: 'CUSTOM',
      icon: 'settings',
      alias: 'custom',
      builders: []
    }
  ]
}[role] || []);
