<div class="history-container">
  <div class="row g-0 history-header">
    <div class="col">
      <div class="title">{{title}}</div>
    </div>
    <div class="col-auto">
      <button *ngIf="!isReviewOpen" class="btn-open-notes position-relative" (click)="toggleNotes('open')">
        <mat-icon>drive_file_rename_outline</mat-icon>
        <span *ngIf="commentQty && commentQty > 0"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              style="z-index: 1">
          {{commentQty}}
          <span class="visually-hidden">comments</span>
        </span>
      </button>
      <ng-container *jargonViewMode="'review'">
        <button *ngIf="isReviewOpen" class="btn-add-notes" (click)="toggleNotes('add')">
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
      <button *ngIf="isReviewOpen" class="btn-close-notes" (click)="toggleNotes('close')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="col-auto">
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="history-mode-toggle" id="historyModeSelect"
                ngbDropdownToggle>{{mode === 'chronological' ? 'Chronological' : 'By Type'}}</button>
        <div ngbDropdownMenu aria-labelledby="historyModeSelect">
          <button ngbDropdownItem (click)="setMode('chronological')">Chronological</button>
          <button ngbDropdownItem (click)="setMode('typed')">By Type</button>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <button class="history-expand-toggle" (click)="isExpanded = !isExpanded">
        <mat-icon *ngIf="!isExpanded">unfold_more</mat-icon>
        <mat-icon *ngIf="isExpanded">unfold_less</mat-icon>
      </button>
    </div>
  </div>
  <div class="row g-0">
    <div class="col history-content" [ngClass]="{ 'history-expanded': isExpanded }">
      <app-history-data-preview [data]="displayData"></app-history-data-preview>
      <div *ngIf="displayData.length === 0" class="text-muted">No Entries Yet...</div>
    </div>
  </div>
</div>