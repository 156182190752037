import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/shared/services/common/ui.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  get isInFrame() {
    return this.uiService.isInFrame;
  }

  constructor(private uiService: UiService) { }


  ngOnInit(): void {
  }

  minimizeFrame() {
    this.uiService.minimizeFrame();
  }

  closeFrame() {
    this.uiService.closeFrame();
  }
}
