<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-2">
    <div class="row">
      <div class="col-12">
        <label class="form-label">PMH: {{['Medications', 'Medical Conditions', 'Procedures and Admissions'][formStep]}}
          ({{formStep+1}}/3)</label>
      </div>
    </div>
  </div>

  <!-- allergies -->
  <div class="col-12 mb-4" *ngIf="formStep === 1">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Allergies</label>
      </div>
    </div>
    <div class="row g-2 mb-1">
      <div class="col">
        <div class="form-check">
          <input type="checkbox"
                 class="form-check-input"
                 id="pmh__allergies__nill"
                 formControlName="nil_allergies" />
          <label class="form-check-label" for="pmh__allergies__nill">Nil Known</label>
        </div>
      </div>
    </div>
    <ng-container formArrayName="allergies">
      <div class="row g-2" *ngFor="let row of getRow('allergies').controls; let i = index" [formGroupName]="i">
        <div class="col-3">
          <preferences-typeahead
                                 alias="history_allergies-allergy"
                                 formControlName="allergy"
                                 placeholder="Start typing"
                                 jargonLoadingState="builder-control"></preferences-typeahead>
        </div>
        <div class="col-6">
          <input type="text" class="form-control" placeholder="Description" formControlName="description"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Diagnosis Date" formControlName="date_time" ngbDatepicker
                   [maxDate]="maxDateNow"
                   container="body" #allergiesDate="ngbDatepicker" (click)="allergiesDate.toggle()"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm"
                      type="button"
                      jargonLoadingState="builder-control"
                      (click)="removeRow('allergies', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row" *ngIf="!formData.get('nil_allergies')?.value">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('allergies')">Add
            +</button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Conditions -->
  <div class="col-12 mb-4" *ngIf="formStep === 1">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Chronic Conditions</label>
      </div>
    </div>
    <div class="row g-2 mb-1">
      <div class="col">
        <div class="form-check">
          <input type="checkbox"
                 class="form-check-input"
                 id="pmh__condition__nill"
                 formControlName="nil_conditions" />
          <label class="form-check-label" for="pmh__condition__nill">Nil Known</label>
        </div>
      </div>
    </div>
    <ng-container formArrayName="conditions">
      <div class="row g-2" *ngFor="let row of getRow('conditions').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <select type="text" class="form-control" formControlName="system" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">System</option>
            <option *ngFor="let system of systems" [value]="system">{{ system }}</option>
          </select>
        </div>
        <div class="col-1">
          <medprax-code-typeahead type="icd10" formControlName="icd10" jargonLoadingState="builder-control"
                                  placeholder="ICD10"
                                  (change)="patchConditionData(i, $event)">
          </medprax-code-typeahead>
        </div>
        <div class="col-5">
          <input type="text" class="form-control" placeholder="Description" formControlName="description"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Date" formControlName="date_time" [maxDate]="maxDateNow"
                   ngbDatepicker container="body"
                   #pmhDate="ngbDatepicker" (click)="pmhDate.toggle()" jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('conditions', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row" *ngIf="!formData.get('nil_conditions')?.value">
        <div class="col-12">
          <button class="btn btn-primary" jargonLoadingState="builder-control" (click)="addRow('conditions')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Admissions -->
  <div class="col-12 mb-4" *ngIf="formStep === 2">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Hospital Admissions</label>
      </div>
    </div>
    <div class="row g-2 mb-1">
      <div class="col">
        <div class="form-check">
          <input type="checkbox"
                 class="form-check-input"
                 id="pmh__admission__nill"
                 formControlName="nil_admissions" />
          <label class="form-check-label" for="pmh__admission__nill">Nil</label>
        </div>
      </div>
    </div>
    <ng-container formArrayName="admissions">
      <div class="row g-2" *ngFor="let row of getRow('admissions').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <medprax-code-typeahead type="icd10" formControlName="reason" placeholder="Diagnosis Code"
                                  jargonLoadingState="builder-control">
          </medprax-code-typeahead>
        </div>
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Facility Name" formControlName="facility"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-5">
          <input type="text" class="form-control" placeholder="Details" formControlName="details"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Date" formControlName="date_time" [maxDate]="maxDateNow"
                   ngbDatepicker container="body"
                   #pmhDate="ngbDatepicker" (click)="pmhDate.toggle()" jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button"
                      (click)="removeRow('admissions', i)" jargonLoadingState="builder-control">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row" *ngIf="!formData.get('nil_admissions')?.value">
        <div class="col-12">
          <button class="btn btn-primary" jargonLoadingState="builder-control" (click)="addRow('admissions')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>


  <!-- Procedures -->
  <div class="col-12 mb-4" *ngIf="formStep === 2">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Surgeries and Procedures</label>
      </div>
    </div>
    <div class="row g-2 mb-1">
      <div class="col">
        <div class="form-check">
          <input type="checkbox"
                 class="form-check-input"
                 id="pmh__procedure__nill"
                 formControlName="nil_procedures" />
          <label class="form-check-label" for="pmh__procedure__nill">Nil</label>
        </div>
      </div>
    </div>
    <ng-container formArrayName="procedures">
      <div class="row g-2" *ngFor="let row of getRow('procedures').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <medprax-code-typeahead type="tariff" formControlName="code" placeholder="Procedure Code"
                                  jargonLoadingState="builder-control">
          </medprax-code-typeahead>
        </div>
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Facility Name" formControlName="facility"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-5">
          <input type="text" class="form-control" placeholder="Details" formControlName="details"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Date" formControlName="date_time" [maxDate]="maxDateNow"
                   ngbDatepicker container="body"
                   jargonLoadingState="builder-control" #pmhDate="ngbDatepicker" (click)="pmhDate.toggle()">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('procedures', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row" *ngIf="!formData.get('nil_procedures')?.value">
        <div class="col-12">
          <button class="btn btn-primary" jargonLoadingState="builder-control" (click)="addRow('procedures')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>


  <!-- Medications -->
  <div class="col-12 mb-4" *ngIf="formStep === 0">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Current/Chronic Medications</label>
      </div>
    </div>
    <div class="row g-2 mb-1">
      <div class="col">
        <div class="form-check">
          <input type="checkbox"
                 class="form-check-input"
                 id="pmh__medication__nill"
                 formControlName="nil_medications" />
          <label class="form-check-label" for="pmh__medication__nill">Nil</label>
        </div>
      </div>
    </div>
    <ng-container formArrayName="medications">
      <div class="row g-2" *ngFor="let row of getRow('medications').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <input type="text" class="form-control" placeholder="Name" formControlName="name"
                 jargonLoadingState="builder-control">
        </div>
        <ng-container formGroupName="dosage">
          <div class="col-2">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Dosage" formControlName="quantity"
                     jargonLoadingState="builder-control">
              <select class="form-control" formControlName="unit" jargonLoadingState="builder-control">
                <option hidden disabled [value]="null">Unit</option>
                <option *ngFor="let type of dosageTypes" [value]="type">{{ type }}</option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <select class="form-control" formControlName="freq" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Frequency</option>
              <option *ngFor="let freq of frequency" [value]="freq">{{ freq }}</option>
            </select>
          </div>
          <div class="col-2">
            <input type="text" class="form-control" placeholder="Duration" formControlName="period"
                   jargonLoadingState="builder-control">
          </div>
          <div class="col-2">
            <select class="form-control" formControlName="route" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Route of Delivery</option>
              <option *ngFor="let route of deliveryRoutes" [value]="route">{{ route }}</option>
            </select>
          </div>
        </ng-container>
        <div class="col-2">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="Date" *ngIf="row.get('date_time')?.value"
                   formControlName="date_time"
                   jargonLoadingState="builder-control" ngbDatepicker [maxDate]="maxDateNow" container="body"
                   #pmhDate="ngbDatepicker"
                   (click)="pmhDate.toggle()">
            <input class="form-control" placeholder="Start Date (Year)" *ngIf="!row.get('date_time')?.value"
                   formControlName="date_year"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('medications', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row" *ngIf="!formData.get('nil_medications')?.value">
        <div class="col-12">
          <button class="btn btn-primary" jargonLoadingState="builder-control" (click)="addRow('medications')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control"
              [disabled]="formStep === 0"
              (click)="formStep = (formStep-1) % 3">Back
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control"
              *ngIf="formStep !== 2"
              (click)="formStep = (formStep+1) % 3">Next
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control"
              *ngIf="formStep === 2"
              (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="data.nil_allergies === true || (data.allergies && data.allergies.length)">
    <strong>Allergies</strong>
    <span *ngIf="data.nil_allergies">&nbsp;Nil Known</span>
    <ng-container *ngIf="!data.nil_allergies">
      [<span *ngFor="let c of data.allergies; let i = index">
        {{ i === 0 ? '' : ';'}}({{i + 1}}): {{c.allergy}} {{ !c.description || !c.date_time ? '' : '-' }}
        {{c.description}} {{ c.date_time | date: 'YYYY-MM-DD' }}
      </span>]
    </ng-container>
  </p>
  <p *ngIf="data.nil_conditions === true || (data.conditions && data.conditions.length)">
    <strong>Chronic Conditions</strong>
    <span *ngIf="data.nil_conditions">&nbsp;Nil Known</span>
    <ng-container *ngIf="!data.nil_conditions">
      [<span *ngFor="let c of data.conditions; let i = index">
        ({{i + 1}}): {{c.description}} ({{ isString(c.icd10) ? c.icd10 : c.icd10.Code}}) {{c.date_time | date:
        'YYYY-MM-DD'}}
      </span>]
    </ng-container>
  </p>
  <p *ngIf="data.nil_admissions === true || (data.admissions && data.admissions.length)">
    <strong>Hospital Admissions</strong>
    <span *ngIf="data.nil_admissions">&nbsp;Nil Known</span>
    <ng-container *ngIf="!data.nil_admissions">
      [<span *ngFor="let c of data.admissions; let i = index">
        ({{i + 1}}): {{ isString(c.reason) ? c.reason : (c.reason ? c.reason.Description + ' (' + c.reason.Code + ')' :
        '') }} at {{c.facility}}
        on {{c.date_time | date: 'YYYY-MM-DD'}}; {{c.details}}
      </span>]
    </ng-container>
  </p>
  <p *ngIf="data.nil_procedures === true || (data.procedures && data.procedures.length)">
    <strong>PSH</strong>
    <span *ngIf="data.nil_procedures">&nbsp;Nil Known</span>
    <ng-container *ngIf="!data.nil_procedures">
      [<span *ngFor="let c of data.procedures; let i = index">
        ({{i + 1}}): {{isString(c.code)? c.code : (c.code ? c.code?.Description + ' ('+c.code?.Code+'),' : '')}}
        {{c.facility}},
        {{c.date_time | date: 'YYYY-MM-DD'}}
      </span>]
    </ng-container>
  </p>
  <p *ngIf="data.nil_medications === true || (data.medications && data.medications.length)">
    <strong>Medications</strong>
    <span *ngIf="data.nil_medications">&nbsp;Nil Known</span>
    <ng-container *ngIf="!data.nil_medications">
      [<span *ngFor="let c of data.medications; let i = index">
        ({{i + 1}}): {{c.name}} {{c.dosage.quantity}}{{c.dosage.unit}} {{c.dosage.freq}}
        {{c.dosage.period}}
        {{c.dosage.route}}
        <span *ngIf="c?.date_time || c?.date_year">,
          <ng-container *ngIf="c?.date_time">{{c?.date_time | date: 'YYYY-MM-DD'}}</ng-container>
          <ng-container *ngIf="!c?.date_time">{{c?.date_year }}</ng-container>
        </span>
      </span>]
    </ng-container>
  </p>
</ng-template>