import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UiService } from '../../../shared/services/common/ui.service';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  firstValueFrom,
  forkJoin,
  from,
  merge,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { IntegrationModel } from '../../../shared/models/integration.model';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '../../../shared/services/common/dialog.service';
import { PRESETS } from '../../ui/config/builders';
import { ActiveUserService } from '../../../shared/services/common/active-user.service';
import { validateAllFormFields } from '../../../shared/validators';
import { LoadingStateService } from '../../../shared/services/common/loading-state.service';
import { ProviderTestModel } from '../../../shared/models/provider-test.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsHttpService, SettingsTypes } from '../../../shared/services/http/settings-http.service';
import { SettingsModel } from '../../../shared/models/settings.model';
import { CUSTOM_BUILDER_LIST_ALIAS, ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CustomBuilderConfigItemModel, CustomBuilderConfigModel, CustomBuilderListModel } from 'src/app/shared/models/builder-config.model';
import { Router } from '@angular/router';
import { BuilderSidebarComponent } from '../../ui/builder-sidebar/builder-sidebar.component';
import { IntegrationTypes } from 'src/app/shared/types/enum/integration';
import { UI_SETTINGS } from '../../ui/config/settings';

interface Capabilities {
  title: string;
  label: string;
  alias: string;
  group: string;
  status: boolean;
}

interface ProviderTestGroup {
  name: string;
  order: number;
  tests: ProviderTestModel[]
}

export const DEFAULT_GROUP_NAME = 'default';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {

  public settingsForm!: FormGroup;
  public providers: IntegrationModel[] = [];
  public selectedProvider: string = '';
  public capabilities!: Capabilities[];
  public providerTests: ProviderTestGroup[] = [];
  public customBuilders: CustomBuilderConfigItemModel[] = [];
  public sideBarListView: boolean = false;
  public sidebarViewSetting?: SettingsModel;

  public $tests?: Observable<ProviderTestModel[] | null>;
  public $testSearchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public showGroupInput: boolean = false;
  public loadingTests: boolean = false;

  private $testsDefault: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  private subscriptions: Subscription[] = [];
  private pathologyExists: boolean = false;
  private capabilitiesExists: boolean = false;

  constructor(
    public uiService: UiService,
    public loadingStateService: LoadingStateService,
    private router: Router,
    private settingsHttpService: SettingsHttpService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private activeUserService: ActiveUserService,
    private clinicalNoteService: ClinicalNoteService
  ) {
    this.clinicalNoteService.config.subscribe(res => {
      this.customBuilders = (res.builders.find(s => s.alias === 'custom')?.builders as CustomBuilderConfigItemModel[] || []).filter(
        b => b.scope === 'user' || (b.scope === 'practice' && b.author?.id === this.activeUserService.id)
      );
    });
  }

  ngOnInit(): void {
    this.settingsForm = this.formBuilder.group({
      provider: [null],
      tests: [[]]
    });

    this.subscriptions.push(
      this.uiService.request('providers', 'provider-list', {}).subscribe(msg => {
        if (msg && msg.providers && msg.providers.length) {
          this.providers = (msg.providers as IntegrationModel[]).filter(
            p => [
              IntegrationTypes.LANCET as string,
              IntegrationTypes.PATH_CARE_VERMAAK as string
            ].includes(p.type.alias)
          ).sort((a, b) => a.type.provider.localeCompare(b.type.provider));
        }
      })
    );
    this.subscriptions.push((this.settingsForm.get('provider')?.valueChanges as Observable<string>).pipe(
      distinctUntilChanged(),
      tap((providerAlias: string) => {
        this.selectedProvider = providerAlias;
        this.$tests = this.getTests();
      }),
      switchMap(() => {
        this.loadingTests = true;
        return this.settingsHttpService.find<ProviderTestModel[]>(this.activeUserService.user?.id!, `pathology::${this.selectedProvider}`).pipe(
          finalize(() => this.loadingTests = false),
          tap(({ data }) => {
            // PATCH: fix the labeling of the tests here based on recent changes and append ID values where we can
            data.forEach(t => {
              if (t.data.test_name && t.data.test_mnemonic && t.label === t.data.test_mnemonic) {
                t.label = t.data.test_name
              }
              if (t.data.id) {
                t.id = t.data.id
              }
            });
            this.providerTests = this.groupTests(data);
            this.pathologyExists = true;
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.pathologyExists = false;
              return of(null);
            }
            return throwError(() => err);
          })
        );
      })
    ).subscribe());
    this.loadCapabilities();
    this.loadViewSetting();
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  public testSortablePredicate(index: number, drag: CdkDrag<ProviderTestGroup>, drop: CdkDropList): boolean {
    // only default can be 0
    return drag.data.name !== DEFAULT_GROUP_NAME && index !== 0;
  }

  public addGroup(input: HTMLInputElement) {
    const group = input.value
    if (this.providerTests.find(({ name }) => name === group)) {
      this.dialogService.show({
        variant: 'danger',
        title: 'Unable To Add Group',
        message: 'Group with name ' + group + ' Already Exists'
      });
      this.showGroupInput = false;
      return;
    }
    input.value = '';
    this.providerTests.push({
      name: group,
      order: this.providerTests.length,
      tests: []
    });
    this.showGroupInput = false;
  }

  public removeGroup(group: string) {
    this.dialogService.show({
      variant: 'confirm',
      title: 'Remove Group "' + group + '"',
      messages: [
        'Are you sure you wish to remove this group?',
        'If so, would you like to remove all tests within this group or keep these tests and only remove the group?'
      ],
      actions: [
        {
          text: 'Keep Tests',
          class: 'btn-outline-info',
          action: async () => {
            const idx = this.providerTests.findIndex(({ name }) => name === group);
            const groupData = this.providerTests[idx];
            let defaultGroup = this.providerTests.find(({ name }) => name === DEFAULT_GROUP_NAME);
            if (!defaultGroup) {
              defaultGroup = {
                name: DEFAULT_GROUP_NAME,
                order: 0,
                tests: []
              };
              this.providerTests.unshift(defaultGroup);
            }
            defaultGroup.tests = [...defaultGroup.tests, ...groupData.tests];
            this.providerTests.splice(idx, 1);
            return true;
          }
        },
        {
          text: 'Delete Tests',
          class: 'btn-outline-warning',
          action: async () => {
            const idx = this.providerTests.findIndex(({ name }) => name === group);
            this.providerTests.splice(idx, 1);
            return true;
          }
        },
        {
          text: 'Cancel',
          class: 'btn-outline-secondary',
          action: async () => {
            return true;
          }
        }
      ]
    });
  }

  public drop(event: CdkDragDrop<ProviderTestModel[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  public dropGroup(event: CdkDragDrop<ProviderTestGroup[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  public groupTests(tests: ProviderTestModel[]): ProviderTestGroup[] {
    const orders: { [key: string]: number } = {};
    // handle legacy
    const grouped = tests.reduce((o, v) => {
      if (v._group) {
        orders[v._group] = v._groupOrder!;
        o[v._group] = [...(o[v._group] || []), v];
      } else {
        o[DEFAULT_GROUP_NAME] = [...(o[DEFAULT_GROUP_NAME] || []), v];
      }
      return o;
    }, {} as Record<string, ProviderTestModel[]>);

    return Object.keys(grouped).reduce<ProviderTestGroup[]>((o, v) => {
      return [...o, {
        name: v,
        order: orders[v],
        tests: grouped[v]
      }]
    }, []).sort((a, b) => {
      if (a.order === undefined) {
        return 1;
      }
      if (b.order === undefined) {
        return -1;
      }
      return a.order < b.order ? -1 : (a.order > b.order ? 1 : 0);
    });
  }

  public async loadCapabilities() {
    const builders = PRESETS(this.activeUserService.user!.role).find(v => v.alias === 'testing')!.builders;
    this.capabilities = builders.map(v => {
      return {
        title: v.title,
        label: v.label,
        alias: v.alias,
        group: v.group!,
        status: v.group !== 'In-House Testing',
      };
    });
    this.loadingStateService.start('settings-control');
    await firstValueFrom(this.settingsHttpService.find<string[]>(this.activeUserService.user?.id!, 'testing_capabilities').pipe(
      tap((res) => {
        if (res.data && res.data.length) {
          this.capabilities = this.capabilities.map((c: Capabilities) => {
            c.status = (res.data as Array<string>).includes(c.alias);
            return c;
          });
        }
        this.capabilitiesExists = true;
      }),
      catchError(err => {
        if (err.status === 404) {
          this.capabilitiesExists = false;
          return of(null);
        }
        return throwError(() => err);
      }),
    ));
    this.loadingStateService.end('settings-control');
  }

  public filterCapability(event: { name: string, status: boolean }) {
    this.capabilities.map(c => {
      if (c.alias === event.name) {
        c.status = event.status;
      }
      return c;
    });
  }

  public minimizeFrame() {
    this.uiService.minimizeFrame();
  }

  public maximizeFrame() {
    this.uiService.maximizeFrame();
  }

  public closeFrame() {
    this.uiService.closeFrame();
  }

  public async addTests() {
    const formTests = this.settingsForm.get('tests')?.value;
    const tests = new Set(this.providerTests.reduce<ProviderTestModel[]>((o, v) => [...o, ...v.tests], []));
    if (formTests && formTests.length) {
      formTests.forEach((t: ProviderTestModel) => {
        // compare data values too, since we have changed labels recently
        const found = [...tests.values()].some(v => v.id === t.id);
        if (!found) {
          let defaultGroup = this.providerTests.find(({ name }) => name === DEFAULT_GROUP_NAME);
          if (!defaultGroup) {
            defaultGroup = {
              name: DEFAULT_GROUP_NAME,
              order: 0,
              tests: []
            };
            this.providerTests.unshift(defaultGroup);
          }
          defaultGroup.tests.push(t);
        }
      });
    }
    this.settingsForm.get('tests')?.patchValue([]);
  }

  public submitTestingCapabilities() {
    if (this.settingsForm.invalid) {
      validateAllFormFields(this.settingsForm);
      return;
    }
    const capabilities = this.capabilities.filter(c => c.status).map(c => c.alias);
    this.loadingStateService.start(['settings-control', 'settings-control-testing']);
    firstValueFrom(
      (
        this.capabilitiesExists
          ? this.settingsHttpService.update(this.activeUserService.user?.id!, 'testing_capabilities', capabilities)
          : this.settingsHttpService.create(this.activeUserService.user?.id!, 'testing_capabilities', capabilities)
      ).pipe(
        tap(() => {
          this.clinicalNoteService.refreshSettings(true);
          this.dialogService.show({
            variant: 'success',
            title: 'In House Testing Capabilities Saved Successfully'
          })
        }),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.dialogService.httpError(err);
          }
          return throwError(() => err);
        }),
        finalize(() => this.loadingStateService.end(['settings-control', 'settings-control-testing']))
      )
    );
  }

  public async removeTest(test: ProviderTestModel, group: string) {
    if (confirm("Are you sure you wish to delete the test: \"" + test.label + "\" ?\n\nPress OK to confirm this action.")) {
      const groupData = this.providerTests.find(({ name }) => name === group)!;
      const idx = groupData.tests.findIndex(v => v.id === test.id);
      groupData.tests.splice(idx, 1);
      this.loadingStateService.start('settings-control');
      firstValueFrom(
        this.settingsHttpService.update(this.activeUserService.user?.id!, `pathology::${this.selectedProvider}`, this.providerTests)
      );
      this.loadingStateService.end('settings-control');
    }
  }

  public getTests() {
    return merge(
      this.$testsDefault,
      this.$testSearchTerm.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.uiService.request('providers', 'tests-list', {
          provider: this.selectedProvider,
          searchTerm: term
        }))
      )
    );
  }

  public getScopedId(scope: SettingsTypes | undefined) {
    if (scope == 'practice') {
      return this.activeUserService.practiceId!;
    }
    return this.activeUserService.id!;
  }

  public doCustomBuilderDelete(builder: CustomBuilderConfigItemModel) {
    let message = '"Are you sure you wish to delete this custom builder?"';
    if (builder.scope === 'practice') message += '\n\nThis is a practice builder.\nIf you remove it, it will no longer be available to any clinical members of this practice';
    if (confirm(message) === false) {
      return;
    }

    this.loadingStateService.start('settings-control-custom-builders');
    this.settingsHttpService.find<CustomBuilderConfigModel>(this.getScopedId(builder.scope), builder.alias, undefined, builder.scope).pipe(
      finalize(() => this.loadingStateService.end('settings-control-custom-builders')),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(null);
        return throwError(() => err);
      }),
      switchMap(res => {
        if (!res) {
          return of(null);
        }
        res.data.active = false;
        return this.settingsHttpService.update<CustomBuilderConfigModel>(this.getScopedId(res.data.scope), builder.alias, res.data, undefined, res.data.scope)
      }),
      switchMap(config => {
        const scope = config ? config.data.scope : builder.scope;
        const alias = config ? config.data.alias : builder.alias;
        return this.settingsHttpService.find<CustomBuilderListModel>(this.getScopedId(scope), CUSTOM_BUILDER_LIST_ALIAS, undefined, scope).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) of(null);
            return throwError(() => err);
          }),
          switchMap((res) => {
            const data = res ? res.data : { builders: [] };

            const idx = data.builders.findIndex(b => b.alias === alias);
            if (idx === -1) return of(false);
            data.builders.splice(idx, 1);

            if (res) return this.settingsHttpService.update<CustomBuilderListModel>(this.getScopedId(scope), CUSTOM_BUILDER_LIST_ALIAS, data, undefined, scope)
            return this.settingsHttpService.create<CustomBuilderListModel>(this.getScopedId(scope), CUSTOM_BUILDER_LIST_ALIAS, data, undefined, scope)
          })
        )
      }),
      switchMap(() => {
        return from(this.clinicalNoteService.refreshSettings(true));
      })
    ).subscribe();
  }

  public submitPathologyTests() {
    if (!this.selectedProvider) {
      return;
    }
    const tests = this.providerTests.reduce<ProviderTestModel[]>(
      (o, v, i) => [...o, ...v.tests.map(t => ({ ...t, _group: v.name, _groupOrder: i }))],
      [] as ProviderTestModel[]
    );
    firstValueFrom((
      this.pathologyExists
        ? this.settingsHttpService.update<ProviderTestModel[]>(this.activeUserService.user?.id!, `pathology::${this.selectedProvider}`, tests)
        : this.settingsHttpService.create<ProviderTestModel[]>(this.activeUserService.user?.id!, `pathology::${this.selectedProvider}`, tests)
    ).pipe(
      tap((res: SettingsModel) => {
        this.providerTests = this.groupTests(res.data);
        this.pathologyExists = true;
        this.settingsForm.get('tests')?.reset();
        this.clinicalNoteService.refreshSettings(true);
        this.dialogService.show({
          variant: 'success',
          title: 'In House Testing Capabilities Saved Successfully'
        })
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.dialogService.httpError(err);
        }
        return throwError(() => err);
      }),
      finalize(() => this.loadingStateService.end(['settings-control', 'settings-control-testing']))
    )
    );
  }

  public async loadViewSetting() {

    await firstValueFrom(this.settingsHttpService.find<SettingsModel>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW).pipe(
      tap((res: SettingsModel) => {
        this.sidebarViewSetting = res;
        this.sideBarListView = res.data.list;
      }),
      catchError(err => {
        if (err.status === 404) {
        }
        return throwError(() => err);
      }),
    ));
  }

  public submitSideBarListView() {
    firstValueFrom((
      this.sidebarViewSetting
        ? this.settingsHttpService.update<{ list: boolean, locked: boolean }>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW, { ...this.sidebarViewSetting.data, list: this.sideBarListView })
        : this.settingsHttpService.create<{ list: boolean }>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW, { list: this.sideBarListView })
    ).pipe(
      tap((res: SettingsModel) => {
        this.sideBarListView = res.data.list;
        this.dialogService.show({
          variant: 'success',
          title: 'Sidebar setting saved successfully'
        })
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.dialogService.httpError(err);
        }
        return throwError(() => err);
      }),
    )
    );
  }
}
