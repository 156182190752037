import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, concat, debounceTime, distinctUntilChanged, filter, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { MedpraxIcd10HttpService } from '../../services/http/medprax-icd10-http.service';
import { MedpraxNappiMaterialHttpService } from '../../services/http/medprax-nappi-material-http.service';
import { MedpraxNappiMedicineHttpService } from '../../services/http/medprax-nappi-medicine-http.service';
import { MedpraxTariffHttpService } from '../../services/http/medprax-tariff-http.service';
import { MedpraxICD10CodeQueryItem, MedpraxNappiCodeQueryItem, MedpraxTariffCodeQueryItem } from '../../types/medprax';
import { PaginatedResponse } from '../../types/paginatedResponse';

export type MedpraxCode = MedpraxICD10CodeQueryItem | MedpraxNappiCodeQueryItem | MedpraxTariffCodeQueryItem;
export type MedpraxCodeValue = MedpraxCode | string | null | Array<MedpraxCode | string | null>;

@Component({
  selector: 'medprax-code-typeahead',
  templateUrl: './medprax-code-typeahead.component.html',
  styleUrls: ['./medprax-code-typeahead.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MedpraxCodeTypeaheadComponent,
    multi: true
  }]
})
export class MedpraxCodeTypeaheadComponent implements OnInit, ControlValueAccessor {
  @Input() public type!: 'icd10' | 'material' | 'medicine' | 'tariff';
  @Input() public multiple: boolean = false;
  @Input() public placeholder: string = 'Search Medprax...';
  @Output('change') public changeEvent: EventEmitter<MedpraxCodeValue> = new EventEmitter();
  public codes$!: Observable<Array<MedpraxCode>>;
  public codesLoading = false;
  public codesInput$ = new Subject<string>();
  public disabled: boolean = false;
  public _selectedCodes: MedpraxCodeValue = null;
  public searchTerm: string = '';
  private disableOnBlur: boolean = false;
  private _onChange?: (value: MedpraxCodeValue) => void;

  get selectedCodes(): MedpraxCodeValue {
    return this._selectedCodes
  }
  set selectedCodes(val: MedpraxCodeValue) {
    if (typeof val === 'string') {
      this._selectedCodes = this.multiple ? [val] : val;
    } else if (this.multiple) {
      this._selectedCodes = Array.isArray(val) ? val : (val ? [val] : null);
    } else {
      this._selectedCodes = Array.isArray(val) ? val[0] : val;
    }
    this.onChange(this.selectedCodes);
    if (this.onTouched) {
      this.onTouched();
    }
  }

  get bindLabel() {
    return {
      'icd10': 'Code',
      'medicine': 'NappiCode',
      'material': 'NappiCode',
      'tariff': 'Code'
    }[this.type];
  }

  get isNappi() {
    return ['medicine', 'material'].includes(this.type);
  }
  constructor(
    private medpraxIcd10HttpService: MedpraxIcd10HttpService,
    private medpraxNappiMaterialHttpService: MedpraxNappiMaterialHttpService,
    private medpraxNappiMedicineHttpService: MedpraxNappiMedicineHttpService,
    private medpraxTariffMaterialHttpService: MedpraxTariffHttpService
  ) { }

  writeValue(obj: MedpraxCodeValue): void {
    this.selectedCodes = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  public registerOnChange(fn: any): void {
    this._onChange = (value: MedpraxCodeValue) => {
      this.changeEvent.emit(value);
      if (fn) {
        fn(value);
      }
    };
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.loadCodes();
  }

  trackByFn(item: MedpraxCode) {
    return item.Code;
  }

  onKeyUp(event: Event, codeSelect: any): void {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Enter') {
      this.handleSelectedTag(codeSelect);
    }
  }

  onBlur(codeSelect: any): void {
    if (!this.disableOnBlur) {
      this.handleSelectedTag(codeSelect);
    }
    this.disableOnBlur = false;
  }

  isString(item: any): boolean {
    return typeof item === 'string';
  }

  onSelect(): void {
    this.disableOnBlur = true;
  }

  onDropdownOpen(): void {
    this.disableOnBlur = false;
  }

  private handleSelectedTag(codeSelect: any): void {
    const options = codeSelect.itemsList.filteredItems;
    const searchTerm = this.searchTerm;
    if (options.length > 0) {
      this.selectedCodes = this.multiple ? [options[0].value] : options[0].value;
    }
    else if (searchTerm) {
      this.selectedCodes = this.multiple ? [searchTerm] : searchTerm;
    }
    this.onChange(this.selectedCodes);
  }

  private onChange: (value: MedpraxCodeValue) => void = (value) => {
    let output: MedpraxCodeValue = null;
    function processValue(v: MedpraxCode | string | null): MedpraxCode | string | null {
      if (v !== null && typeof v !== 'string') {
        return Object.keys(v).length === 1 && Object.keys(v)[0] === 'Code' ? (v as MedpraxCode).Code : v;
      }
      return v;
    }
    if (Array.isArray(value)) {
      output = value.map(v => processValue(v));
    } else {
      output = processValue(value);
    }

    if (this._onChange) {
      this._onChange(output);
    } else {
      this.changeEvent.emit(output);
    }
  };

  private onTouched?: VoidFunction;

  private loadCodes() {
    this.codes$ = concat(
      of([]), // default items
      this.codesInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(v => v !== null),
        tap(() => this.codesLoading = true),
        switchMap(term => this.loadData(term).pipe(
          map(d => d.data),
          catchError(() => of([])), // empty list on error
        )),
        tap(() => this.codesLoading = false)
      )
    );
  }

  private loadData(term: string): Observable<PaginatedResponse<MedpraxCode>> {
    const params = {
      search: term,
      page: '1',
      limit: '50',
    };
    this.searchTerm = term;
    switch (this.type) {
      case 'icd10':
        return this.medpraxIcd10HttpService.list({ params });
      case 'medicine':
        return this.medpraxNappiMedicineHttpService.list({ params });
      case 'material':
        return this.medpraxNappiMaterialHttpService.list({ params });
      case 'tariff':
        return this.medpraxTariffMaterialHttpService.list({ params });
      default:
        throw new Error('Invalid Medprax Code Type');
    }
  }
}
