<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--Msk Notes-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">MSK Notes</label>
      </div>
      <div class="col">
        <div class="input-group">
          <button class="btn btn-primary btn-sm" jargonLoadingState="builder-control">
            <mat-icon>add_circle</mat-icon>
          </button>
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput()">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>