import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PastMedicalHistoryComponent } from './components/past-medical-history/past-medical-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { BuilderItemComponent } from './shared/components/builder-item/builder-item.component';
import { FamilyHistoryComponent } from './components/family-history/family-history.component';
import { AllergiesComponent } from './components/allergies/allergies.component';
import { SocialHistoryComponent } from './components/social-history/social-history.component';
import { VaccinationHistoryComponent } from './components/vaccination-history/vaccination-history.component';
import { MatIconModule } from '@angular/material/icon';
import { StatusCommentComponent } from './shared/components/status-comment/status-comment.component';
import { MedicalScreeningComponent } from './components/medical-screening/medical-screening.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CvsHistoryComponent } from './components/cvs-history/cvs-history.component';
import { RespiratoryHistoryComponent } from './components/respiratory-history/respiratory-history.component';
import { GitHistoryComponent } from './components/git-history/git-history.component';
import { UgtHistoryComponent } from './components/ugt-history/ugt-history.component';
import { CnsHistoryComponent } from './components/cns-history/cns-history.component';
import { GynaeHistoryComponent } from './components/gynae-history/gynae-history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VitalSignsComponent } from './components/vital-signs/vital-signs.component';
import { CadjcolComponent } from './components/cadjcol/cadjcol.component';
import { CnsExamComponent } from './components/cns-exam/cns-exam.component';
import { HeadAndNeckExamComponent } from './components/head-and-neck-exam/head-and-neck-exam.component';
import { CvsExamComponent } from './components/cvs-exam/cvs-exam.component';
import { RespiratoryExamComponent } from './components/respiratory-exam/respiratory-exam.component';
import { MskExamComponent } from './components/msk-exam/msk-exam.component';
import { UgtExamComponent } from './components/ugt-exam/ugt-exam.component';
import { GitExamComponent } from './components/git-exam/git-exam.component';
import { UrinalysisComponent } from './components/urinalysis/urinalysis.component';
import { EcgTestComponent } from './components/ecg-test/ecg-test.component';
import { BloodGlucoseTestComponent } from './components/blood-glucose-test/blood-glucose-test.component';
import { RapidCovidTestComponent } from './components/rapid-covid-test/rapid-covid-test.component';
import { RapidMalariaTestComponent } from './components/rapid-malaria-test/rapid-malaria-test.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { HivTestComponent } from './components/hiv-test/hiv-test.component';
import { QuickLabsComponent } from './components/quick-labs/quick-labs.component';
import { WoundCareManagementComponent } from './components/wound-care-management/wound-care-management.component';
import { InjectionVaccinationsComponent } from './components/injection-vaccinations/injection-vaccinations.component';
import { InjectionFamilyPlanningComponent } from './components/injection-family-planning/injection-family-planning.component';
import { InjectionMedicationsComponent } from './components/injection-medications/injection-medications.component';
import { InjectionVitaminsComponent } from './components/injection-vitamins/injection-vitamins.component';
import { CustomBuilderComponent } from './components/custom/custom.component';
import { TravelHistoryComponent } from './components/travel-history/travel-history.component';

@NgModule({
  declarations: [
    BuilderItemComponent,
    PastMedicalHistoryComponent,
    FamilyHistoryComponent,
    AllergiesComponent,
    SocialHistoryComponent,
    TravelHistoryComponent,
    VaccinationHistoryComponent,
    StatusCommentComponent,
    MedicalScreeningComponent,
    CvsHistoryComponent,
    RespiratoryHistoryComponent,
    GitHistoryComponent,
    UgtHistoryComponent,
    CnsHistoryComponent,
    GynaeHistoryComponent,
    VitalSignsComponent,
    CadjcolComponent,
    CnsExamComponent,
    HeadAndNeckExamComponent,
    CvsExamComponent,
    RespiratoryExamComponent,
    MskExamComponent,
    UgtExamComponent,
    GitExamComponent,
    UrinalysisComponent,
    EcgTestComponent,
    BloodGlucoseTestComponent,
    RapidCovidTestComponent,
    RapidMalariaTestComponent,
    HivTestComponent,
    QuickLabsComponent,
    WoundCareManagementComponent,
    InjectionVaccinationsComponent,
    InjectionFamilyPlanningComponent,
    InjectionMedicationsComponent,
    InjectionVitaminsComponent,
    CustomBuilderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    NgSelectModule,
    NgbDatepickerModule
  ],
  exports: [
    BuilderItemComponent
  ]
})
export class BuildersModule { }
