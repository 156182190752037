import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Pagination } from '../../../../shared/types/paginatedResponse';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { TableConfig } from '../../../../shared/components/jargon-table/jargon-table.component';
import { InfoDataOptions } from '../../../../shared/services/common/client-info.service';
import { catchError, debounceTime, distinctUntilChanged, finalize, firstValueFrom, map, of, Subject, Subscription, tap } from 'rxjs';
import { UiService } from '../../../../shared/services/common/ui.service';
import { Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

export type LabResult = {
  id: number;
  date: string;
  path_lab: string;
  referring_doctor: string;
  tests_run: string;
  result_summary: string;
  link: string;
};

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss']
})
export class LabsComponent extends AbstractEHRTabComponent implements AfterViewInit, OnDestroy {

  public pagination?: Pagination;
  public loading: boolean = false;
  public errored: boolean = false;
  public tableConfig: TableConfig<LabResult> = {
    columns: [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Pathology Lab',
        field: 'path_lab'
      },
      {
        title: 'Referring Practitioner',
        field: 'referring_doctor'
      },
      {
        title: 'Test(s) Run',
        field: 'tests_run'
      },
      {
        title: 'Results Summary',
        field: 'result_summary'
      },
      {
        title: 'Action',
        column_slot: 'action'
      }
    ],
    data: []
  };
  public options: InfoDataOptions = {
    page: 1,
    limit: 5
  };
  public unsubscribe: Subscription[] = [];
  public labsSearch: FormControl = new FormControl<any>('');

  constructor(
    private uiService: UiService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.loadData();
    this.unsubscribe.push(this.labsSearch.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(v => {
        if (v && v !== '') {
          this.options.search = v as string;
        } else {
          delete this.options.search;
        }
        this.loadData();
      })
    ).subscribe());
  }

  public ngOnDestroy(): void {
    this.unsubscribe.forEach(s => s.unsubscribe());
  }

  public pageChange(page: number) {
    this.options.page = page;
    this.loadData();
  }

  public viewResults(link: string) {
    this.uiService.message('client-info', 'lab-result-view', {link});
  }

  public handleSorting($event: { active: string, direction: 'asc' | 'desc' }) {
    this.options.sort = [$event.active, $event.direction]!
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.tableConfig.data = [];
    this.pagination = undefined;
    firstValueFrom(this.uiService.request('client-info', 'labs-list', this.options).pipe(
      finalize(() => this.loading = false),
      catchError(err => {
        console.error(err);
        this.errored = true;
        return of(null);
      }),
      tap(res => {
        this.errored = !!res;
        if (res) {
          this.tableConfig.data = res.data;
          this.pagination = res.pagination;
        }
      })
    ));
  }

}
