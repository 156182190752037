import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GITExamRegions, RawGitExamFormData } from './git-exam.config';
import { validateAllFormFields } from '../../../../shared/validators';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

type GitExamDynamicRows = 'soft' | 'non_tender' | 'tender' | 'masses' | 'flank_pain';

interface GenericGitExamData extends BuilderPresentNote {
  data: Array<{
    present: boolean;
    regions: Array<string>;
    note: string;
  }>;
}

export interface GitExamFormData extends BuilderDataAbstract {
  soft: GenericGitExamData;
  non_tender: GenericGitExamData;
  tender: GenericGitExamData;
  masses: GenericGitExamData;
  flank_pain: GenericGitExamData;
  r_g?: BuilderPresentNote;
  peritonism: BuilderPresentNote & { classifier: string };
  b_s: BuilderPresentNote;
  pr: BuilderPresentNote;
}

@Component({
  selector: 'app-git-exam',
  templateUrl: './git-exam.component.html',
  styleUrls: ['./git-exam.component.scss']
})
export class GitExamComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public regions: Array<{ name: string }> = GITExamRegions();
  public regionsTenderness: Array<{ name: string }> = GITExamRegions([
    { name: 'epigastric' }
  ]);
  public peritonism_classifiers: string[] = ['Guarding', 'Rigid'];

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      soft: this.fb.group({
        present: [null],
        regions: [[]],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            regions: [[]],
            note: [null, Validators.maxLength(500)]
          })
        ])
      }),
      non_tender: this.fb.group({
        present: [null],
        regions: [[]],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            regions: [[]],
            note: [null, Validators.maxLength(500)]
          })
        ])
      }),
      tender: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            regions: [[]],
            note: [null, Validators.maxLength(500)]
          })
        ])
      }),
      masses: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            regions: [[]],
            note: [null, Validators.maxLength(500)]
          })
        ])
      }),
      flank_pain: this.fb.group({
        present: [null],
        regions: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            regions: [[]],
            note: [null, Validators.maxLength(500)]
          })
        ])
      }),
      // r_g: this.fb.group({
      //   present: [null],
      //   note: [null, Validators.maxLength(500)]
      // }),
      peritonism: this.fb.group({
        present: [null],
        classifier: [null],
        note: [null, Validators.maxLength(500)]
      }),
      b_s: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      pr: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
    });
  }

  public getRow(form: keyof GitExamFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: keyof GitExamFormData, idx: number) {
    this.getRow(form).removeAt(idx);
  }

  public clearInputs(form: keyof GitExamFormData) {
    this.getRow(form).reset();
  }

  public getDataCount(alias: GitExamDynamicRows): number {
    return ((this.formData.get(alias) as FormGroup).get('data') as FormArray).length;
  }

  public deleteRow(form: GitExamDynamicRows) {
    return (idx: number) => {
      ((this.formData.get(form) as FormGroup).get('data') as FormArray).removeAt(idx);
      if (((this.formData.get(form) as FormGroup).get('data') as FormArray).length === 0) {
        this.addRow(form);
      } else {
        const present = this.isPresent<GitExamDynamicRows>(form);
        (this.formData.get(form) as FormGroup).get('present')?.patchValue(!present);
        setTimeout(() => (this.formData.get(form) as FormGroup).get('present')?.patchValue(present), 0);
      }
    };
  }

  public addRow(form: GitExamDynamicRows) {
    const group = this.fb.group(RawGitExamFormData()[form]);
    ((this.formData.get(form) as FormGroup).get('data') as FormArray).push(group);
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: GitExamFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'GIT EXAM:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: GitExamFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: GIT EXAM`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.present ? 'Present' : 'Not Present'}${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as GitExamFormData;
    data.soft['_modified'] = moment().valueOf();
    data.non_tender['_modified'] = moment().valueOf();
    data.tender['_modified'] = moment().valueOf();
    data.masses['_modified'] = moment().valueOf();
    data.flank_pain['_modified'] = moment().valueOf();
    // data.r_g['_modified'] = moment().valueOf();
    data.peritonism['_modified'] = moment().valueOf();
    data.b_s['_modified'] = moment().valueOf();
    data.pr['_modified'] = moment().valueOf();

    Object.entries(data).forEach(([control, controlValue]) => {
      // remove data objects when not present
      if (data[control as GitExamDynamicRows]['data'] && !this.isPresent<GitExamDynamicRows>(control as GitExamDynamicRows)) {
        (data[control as GitExamDynamicRows]).data = [];
      }
    });

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['soft'] = data.soft;
      builderData['non_tender'] = data.non_tender;
      builderData['tender'] = data.tender;
      builderData['masses'] = data.masses;
      builderData['flank_pain'] = data.flank_pain;
      builderData['r_g'] = data.r_g;
      builderData['peritonism'] = data.peritonism;
      builderData['b_s'] = data.b_s;
      builderData['pr'] = data.pr;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    Object.entries(data).forEach(([key, value]) => {
      if (value && value.present) {
        if (data[key] && data[key].data && data[key].data.length) {
          for (let i = 1; i < data[key].data.length; i++) {
            this.addRow(key as GitExamDynamicRows);
          }
        }
      }
    });
    super.setBuilderData(data);
  }

  private prepareSerializedList(data: GitExamFormData) {
    let softValue, nonTenderValue, tenderValue, massesValue, flankPainValue;

    const getControlPreview = (control: GenericGitExamData) => {
      if (control.present && control.data && control.data.length) {
        if (control.data.length === 1 && !control.data[0].note && control.data[0].regions.length === 0) {
          return '';
        } else {
          return control.data
            .map((c, i) => ` [${'(' + (i + 1) + ') '}${this.getJoinedList(c.regions)}${c.note ? ' - ' + c.note : ''}]`)
            .join('; ');
        }
      }
      return '';
    }

    softValue = getControlPreview(data.soft);
    nonTenderValue = getControlPreview(data.non_tender);
    tenderValue = getControlPreview(data.tender);
    massesValue = getControlPreview(data.masses);
    flankPainValue = getControlPreview(data.flank_pain);

    const list: Array<{ title: string, present: boolean, value: string | null | undefined }> = [
      { title: 'Soft', present: data.soft.present, value: softValue ? softValue : `${data.soft.present === false && data.soft.note ? ' [' + data.soft.note + ']' : ''}` },
      { title: 'Non - Tender', present: data.non_tender.present, value: nonTenderValue ? nonTenderValue : `${data.non_tender.present === false && data.non_tender.note ? ' [' + data.non_tender.note + ']' : ''}` },
      { title: 'Tender', present: data.tender.present, value: tenderValue ? tenderValue : `${data.tender.present === false && data.tender.note ? ' [' + data.tender.note + ']' : ''}` },
      { title: 'Masses', present: data.masses.present, value: massesValue ? massesValue : `${data.masses.present === false && data.masses.note ? ' [' + data.masses.note + ']' : ''}` },
      { title: 'Flank Pain', present: data.flank_pain.present, value: flankPainValue ? flankPainValue : `${data.flank_pain.present === false && data.flank_pain.note ? ' [' + data.flank_pain.note + ']' : ''}` },
      // { title: 'R/G', present: data.r_g.present, value: `${data.r_g.note ? ' [' + data.r_g.note + ']' : ''}` },
      { title: 'Peritonism', present: data.peritonism.present, value: `${data.peritonism.classifier ? ' - ' + data.peritonism.classifier + ' ' : ''}${data.peritonism.note ? ' [' + data.peritonism.note + ']' : ''}` },
      { title: 'B/S', present: data.b_s.present, value: `${data.b_s.note ? ' [' + data.b_s.note + ']' : ''}` },
      { title: 'PR', present: data.pr.present, value: `${data.pr.note ? ' [' + data.pr.note + ']' : ''}` }
    ].filter(v => v.present !== null);

    return list;
  }

}
