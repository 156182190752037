<div class="form-check" [ngbTooltip]="tooltip">
  <ng-container *ngIf="jargonLoadingState; else noLoadingState">
    <input class="form-check-input" [jargonLoadingState]="jargonLoadingState" type="checkbox" [id]="id"
           [checked]="checked" (change)="change()">
  </ng-container>
  <ng-template #noLoadingState>
    <input class="form-check-input" type="checkbox" [id]="id" [checked]="checked" (change)="change()">
  </ng-template>
  <label class="form-check-label" [for]="id">
    {{ label }}
  </label>
</div>