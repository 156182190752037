<div class="modal-header">
    <h4 class="modal-title">Case Attachment {{ file.name }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="">
        <div class="row g-0">
            <div class="col-4 attachment-details">
                <div class="row h-100">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-auto" *ngFor="let field of displayFields">
                                <div class="form-group">
                                    <label class="form-label">{{ field.title }}</label>
                                    <div class="ms-2">
                                        <p *ngIf="field.type !== 'Array'">{{field.value}}</p>
                                        <ul *ngIf="field.type === 'Array'" class="row">
                                            <li *ngFor="let item of field.value" class="col-6">{{item}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 align-self-end">
                        <button type="button" class="btn btn-outline-primary"
                                (click)="download()">Download Attachment</button>
                        <button type="button" class="btn btn-outline-danger ms-3"
                                (click)="delete()">Delete Attachment</button>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="attachment-preview-wrapper d-flex aligns-items-center justify-content-center">
                    <ng-container [ngSwitch]="file.mime">

                        <!-- Images -->
                        <div *ngSwitchCase="'image/gif'" class="image align-self-center"><img [src]="source" /></div>
                        <div *ngSwitchCase="'image/png'" class="image align-self-center"><img [src]="source" /></div>
                        <div *ngSwitchCase="'image/jpeg'" class="image align-self-center"><img [src]="source" /></div>
                        <div *ngSwitchCase="'image/bmp'" class="image align-self-center"><img [src]="source" /></div>
                        <div *ngSwitchCase="'image/webp'" class="image align-self-center"><img [src]="source" /></div>

                        <!-- Audio -->
                        <div *ngSwitchCase="'audio/webm'" class="audio align-self-center">
                            <audio controls [src]="source">
                                <a [href]="source" download target="_blank">Download Audio Clip</a>
                            </audio>
                        </div>
                        <div *ngSwitchCase="'audio/ogg'" class="audio align-self-center">
                            <audio controls [src]="source">
                                <a [href]="source" download target="_blank">Download Audio Clip</a>
                            </audio>
                        </div>
                        <div *ngSwitchCase="'audio/wav'" class="audio align-self-center">
                            <audio controls [src]="source">
                                <a [href]="source" download target="_blank">Download Audio Clip</a>
                            </audio>
                        </div>

                        <!-- Video -->
                        <div *ngSwitchCase="'video/webm'" class="video align-self-center">
                            <video controls [src]="source">
                                <a [href]="source" download target="_blank">Download Video Clip</a>
                            </video>
                        </div>
                        <div *ngSwitchCase="'video/ogg'" class="video align-self-center">
                            <video controls [src]="source">
                                <a [href]="source" download target="_blank">Download Video Clip</a>
                            </video>
                        </div>
                        <div *ngSwitchCase="'video/mp4'" class="video align-self-center">
                            <video controls [src]="source">
                                <a [href]="source" download target="_blank">Download Video Clip</a>
                            </video>
                        </div>

                        <!-- PDF Docs -->
                        <iframe *ngSwitchCase="'application/pdf'" [src]="source"></iframe>

                        <!-- Default -->
                        <div *ngSwitchDefault class="no-preview align-self-center text-center">
                            <mat-icon [inline]="true">image_not_supported</mat-icon>
                            <div>No Preview Available</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>