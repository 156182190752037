

export const RESPExamRegions = (): Array<{ name: string, value: number}> => ([
  {
    name: 'Throughout',
    value: 0
  },
  {
    name: 'Left Isolated',
    value: 1
  },
  {
    name: 'Upper Left',
    value: 2
  },
  {
    name: 'Middle Left',
    value: 3
  },
  {
    name: 'Lower Left',
    value: 4
  },
  {
    name: 'Right isolated',
    value: 5
  },
  {
    name: 'Upper Right',
    value: 6
  },
  {
    name: 'Middle Right',
    value: 7
  },
  {
    name: 'Lower Right',
    value: 8
  },
].sort((a, b) => a.name.localeCompare(b.name)));
