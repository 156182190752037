import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { firstValueFrom, tap } from 'rxjs';
import { ATTACHMENT_TYPES } from 'src/app/shared/components/attachments-modal/attachments-modal.component';
import { AttachmentModel } from 'src/app/shared/models/attachment.model';
import { AttachmentsService } from 'src/app/shared/services/common/attachments.service';

type DisplayField = { title: string, alias: string, value: any, type: string };

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss']
})
export class AttachmentPreviewComponent implements OnInit {
  @Input('file') file!: AttachmentModel;
  public characterBreakLimit = 50;
  public displayFields?: DisplayField[];
  public source?: SafeResourceUrl;

  constructor(
    public modalRef: NgbActiveModal,
    private attachmentService: AttachmentsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.resolveDisplayFields();
    this.setPreviewSource();
  }

  public async download() {
    const blob = await firstValueFrom(this.attachmentService.downloadBlob(this.file._id));
    const nav = (navigator as Navigator & { msSaveBlob?: Function });
    if (blob !== null && nav.msSaveBlob) {
      nav.msSaveBlob(blob, this.file.name);
      return;
    }
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.style.display = 'none';
    a.href = url;
    a.download = this.file.name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public delete() {
    if (confirm("Are you sure you wish to delete the file: \"" + this.file.name + "\" ?\n NOTE: This action Cannot be undone!\n\nPress OK to confirm this action.") === true) {
      firstValueFrom(this.attachmentService.delete(this.file._id).pipe(
        tap(() => this.modalRef.dismiss())
      ));
    }
  }

  private resolveDisplayFields() {
    let fieldMappings: { [key: string]: string } = {
      'name': 'File Name',
      'note': 'Notes/Comments',
    };
    switch (this.file.type) {
      case ATTACHMENT_TYPES.IMAGING.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.radiologist.name': 'Radiologist Name',
          'meta.date_time': 'Date',
          'meta.type': 'Imaging Type',
          'meta.reason': 'Reason',
          'meta.regions': 'Body Regions(s)'
        };
        break;
      case ATTACHMENT_TYPES.LABS.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.pathology_lab.name': 'Pathology Lab Name',
          'meta.reason': 'Reason',
          'meta.date_time': 'Date of Test',
          'meta.result_date_time': 'Date of Result',
          'meta.type': 'Test Category',
          'meta.tests': 'Test(s) Run',
          'meta.summary': 'Summary of Results'
        };
        break;
      case ATTACHMENT_TYPES.PHOTO.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.reason': 'Reason',
          'meta.date_time': 'Date'
        };
        break;
      case ATTACHMENT_TYPES.REFERRAL_LETTER.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.referring_doctor.name': 'Referring Practitioner',
          'meta.reason': 'Reason',
          'meta.date_time': 'Date'
        };
        break;
      case ATTACHMENT_TYPES.SCRIPT.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.reason': 'Reason',
          'meta.type': 'Script Type',
          'meta.date_time': 'Date of Script',
        };
        break;
      case ATTACHMENT_TYPES.OTHER.alias:
        fieldMappings = {
          ...fieldMappings,
          'meta.name': 'Name/Type',
          'meta.reason': 'Reason',
          'meta.date_time': 'Date',
        };
    }
    const fieldValue = (obj: any, keys: string[]): any => {
      const key = keys.shift();
      if (!key) {
        return obj;
      }
      return obj ? (keys.length ? fieldValue(obj[key], keys) : obj[key]) : null;
    }
    this.displayFields = Object.keys(fieldMappings).reduce((out, key) => {
      let value = fieldValue(this.file, key.split('.'));
      if (key.includes('date_time')) {
        value = moment(value);
        if (value.format('HH:mm:ss') === '00:00:00') {
          value = value.format('YYYY-MM-DD');
        } else {
          value = value.format('YYYY-MM-DD HH:mm:ss Z');
        }
      }
      if (value === null || value === undefined) {
        return out;
      }
      return [
        ...out,
        {
          title: fieldMappings[key],
          alias: key,
          value,
          type: value && value.constructor ? value.constructor.name : 'null'
        }
      ];
    }, [] as DisplayField[]);
  }

  private setPreviewSource() {
    this.attachmentService.downloadDataUrl(this.file._id).subscribe({
      next: url => {
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(url as string);
      }
    })
  }

}
