import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ROW_DATA } from './medical-screening.config';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { ClinicalNoteService } from '../../../../shared/services/common/clinical-note.service';

type MSDynamicRows = 'skin' | 'colonoscopy' | 'prostate' | 'papSmear' | 'mammogram';

type ScreeningData = {
  date: number;
  practitioner_name: string;
  note: string;
}

interface Screening {
  present: boolean;
  date: number;
  practitioner_name: string;
  note: string;
  _modified: number;
  data: Array<ScreeningData>;
}

interface MedicalScreeningFormData extends BuilderDataAbstract {
  skin: Screening;
  colonoscopy: Screening;
  prostate: Screening;
  papSmear: Screening;
  mammogram: Screening;
  gender: string;
}

@Component({
  selector: 'app-medical-screening',
  templateUrl: './medical-screening.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './medical-screening.component.scss']
})
export class MedicalScreeningComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public isFemale: boolean | null = null;

  constructor(
    private fb: FormBuilder,
  ) {
    super();
    this.formData = this.fb.group({
      skin: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([])
      }),
      colonoscopy: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([])
      })
    });
    this.addRow('skin');
    this.addRow('colonoscopy');
    if (this.isFemale === null) {
      this.isFemale = this.clinicalNoteService.getPatient().sex === 'female';
      this.genderToggle(this.isFemale);
    }
    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  public getRow(form: keyof MedicalScreeningFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: keyof MedicalScreeningFormData, idx: number) {
    this.getRow(form).removeAt(idx);
  }

  public clearInputs(form: keyof MedicalScreeningFormData) {
    this.getRow(form).reset();
  }

  public addRow(form: MSDynamicRows) {
    const group = this.fb.group(ROW_DATA()[form]);
    ((this.formData.get(form) as FormGroup).get('data') as FormArray).push(group);
  }

  public getDataCount(alias: keyof MedicalScreeningFormData): number {
    return ((this.formData.get(alias) as FormGroup).get('data') as FormArray).length;
  }

  public deleteRow(form: MSDynamicRows) {
    return (idx: number) => {
      ((this.formData.get(form) as FormGroup).get('data') as FormArray).removeAt(idx);
      if (((this.formData.get(form) as FormGroup).get('data') as FormArray).length === 0) {
        this.addRow(form);
      } else {
        const present = this.isPresent<MSDynamicRows>(form);
        (this.formData.get(form) as FormGroup).get('present')?.patchValue(!present);
        setTimeout(() => (this.formData.get(form) as FormGroup).get('present')?.patchValue(present), 0);
      }
    };
  }

  public genderToggle(checked: boolean) {
    this.isFemale = checked;
    if (this.isFemale) {
      this.formData.removeControl('prostate');
      this.formData.addControl('papSmear', this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            date: [null, Validators.required],
            practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
            note: [null, [Validators.maxLength(500)]]
          })
        ])
      }));
      this.formData.addControl('mammogram', this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            date: [null, Validators.required],
            practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
            note: [null, [Validators.maxLength(500)]]
          })
        ])
      }));
    } else {
      this.formData.removeControl('papSmear');
      this.formData.removeControl('mammogram');
      this.formData.addControl('prostate', this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        data: this.fb.array([
          this.fb.group({
            date: [null, Validators.required],
            practitioner_name: [null, [Validators.required, Validators.maxLength(200)]],
            note: [null, [Validators.maxLength(500)]]
          })
        ])
      }));
    }
    this.registerFormListener();
  }
  public override async validate() {
    const data = this.formData.value as MedicalScreeningFormData;

    let screeningControls: Array<MSDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      const screeningData: ScreeningData[] = controlValue.data;
      if (
        typeof data[control as keyof MedicalScreeningFormData] !== 'string'
        && this.isPresent<keyof MedicalScreeningFormData>(control as keyof MedicalScreeningFormData)
        && screeningData
        && screeningData.length === 1
      ) {
        const empty = Object.values(screeningData[0]).reduce((out, value) => {
          return out && (value === null || value === '')
        }, true);
        if (empty) {
          ((this.formData.get((control as keyof MedicalScreeningFormData)) as FormGroup).get('data') as FormArray).removeAt(0);
          screeningControls.push(control as MSDynamicRows);
        }
      }
    });

    validateAllFormFields(this.formData);
    let res = this.formData.valid;

    if (screeningControls.length > 0) {
      screeningControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  public override async saveData() {
    const data = this.formData.value as MedicalScreeningFormData;

    let screeningControls: Array<MSDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      const screeningData: ScreeningData[] = controlValue.data;
      if (
        typeof data[control as keyof MedicalScreeningFormData] !== 'string'
        && this.isPresent<keyof MedicalScreeningFormData>(control as keyof MedicalScreeningFormData)
        && screeningData
        && screeningData.length === 1
      ) {
        const empty = Object.values(screeningData[0]).reduce((out, value) => {
          return out && (value === null || value === '')
        }, true);
        if (empty) {
          ((this.formData.get((control as keyof MedicalScreeningFormData)) as FormGroup).get('data') as FormArray).removeAt(0);
          screeningControls.push(control as MSDynamicRows);
        }
      }
    });

    const res = await super.saveData();

    if (screeningControls.length > 0) {
      screeningControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  serialize(data: MedicalScreeningFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: `PREVENTATIVE SCREENING ${this.isFemale ? '(F)' : '(M)'}:`,
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: MedicalScreeningFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: PREVENTATIVE SCREENING ${this.isFemale ? '(F)' : '(M)'}`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value ? v.itemData.map((c, i) => `(${i + 1}): ${moment(c.date).format('YYYY-MM-dd')}, ${c.practitioner_name}${c.note ? ', ' + c.note : ''}`).join('; ') : v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.value as MedicalScreeningFormData;
    data['gender'] = this.isFemale ? 'Female' : 'Male';
    data.skin['_modified'] = moment().valueOf();
    data.skin['date'] = moment(data.skin['date']).valueOf();
    data.colonoscopy['_modified'] = moment().valueOf();
    data.colonoscopy['date'] = moment(data.colonoscopy['date']).valueOf();

    Object.entries(data).forEach(([control, controlValue]) => {
      const screeningData: ScreeningData[] = controlValue.data;
      // remove data objects when not present
      if (
        typeof data[control as keyof MedicalScreeningFormData] !== 'string'
        && !this.isPresent<keyof MedicalScreeningFormData>(control as keyof MedicalScreeningFormData)
      ) {
        (data[control as keyof MedicalScreeningFormData] as Screening).data = [];
        if ((data[control as keyof MedicalScreeningFormData] as Screening).present === null) {
          (data[control as keyof MedicalScreeningFormData] as Screening).note = '';
        }
      }
      // resolve unix date on each data object
      else if (screeningData && screeningData.length && typeof data[control as keyof MedicalScreeningFormData] !== 'string') {
        (data[control as keyof MedicalScreeningFormData] as Screening).data = screeningData.map(d => ({
          ...d,
          date: moment(d.date).valueOf()
        }));
      }
    });

    if (this.isFemale) {
      data.papSmear['_modified'] = moment().valueOf();
      data.papSmear['date'] = moment(data.papSmear['date']).valueOf();
      data.mammogram['_modified'] = moment().valueOf();
      data.mammogram['date'] = moment(data.mammogram['date']).valueOf();
    }
    if (!this.isFemale) {
      data.prostate['_modified'] = moment().valueOf();
      data.prostate['date'] = moment(data.prostate['date']).valueOf();
    }

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['skin'] = data.skin;
      builderData['colonoscopy'] = data.colonoscopy;
      builderData['papSmear'] = data.papSmear;
      builderData['mammogram'] = data.mammogram;
      builderData['prostate'] = data.prostate;
      builderData['gender'] = data.gender;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    Object.entries(data).forEach(([key, value]) => {
      if (value && value.present) {
        if (data[key] && data[key].data.length) {
          for (let i = 1; i < data[key].data.length; i++) {
            this.addRow(key as MSDynamicRows);
          }
        }
      }
    });

    this.isFemale = (data as MedicalScreeningFormData).gender !== null
      ? (data as MedicalScreeningFormData).gender === 'Female'
      : this.clinicalNoteService.getPatient().sex === 'female';
    this.genderToggle(this.isFemale);
    super.setBuilderData(data);
  }


  private prepareSerializedList(data: MedicalScreeningFormData) {
    let list = [
      { title: 'Skin', value: data.skin.present, note: data.skin.note, itemData: data.skin.data },
      { title: 'Colonoscopy', value: data.colonoscopy.present, note: data.colonoscopy.note, itemData: data.colonoscopy.data }
    ];

    if (this.isFemale) {
      data.papSmear ? list.push({ title: 'Pap Smear', value: data.papSmear.present, note: data.papSmear.note, itemData: data.papSmear.data }) : '';
      data.mammogram ? list.push({ title: 'Mammogram', value: data.mammogram.present, note: data.mammogram.note, itemData: data.mammogram.data }) : '';
    } else
      if (this.isFemale === false) {
        data.prostate ? list.push({ title: 'Prostate', value: data.prostate.present, note: data.prostate.note, itemData: data.prostate.data }) : '';
      }
    list = list.filter(v => {
      // remove entries with empty data or nulls
      if (v.value === false) {
        return !!v.note;
      }
      if (v.value && v.itemData) {
        if (v.itemData.length === 0) {
          return false;
        }
        return v.itemData.reduce((out, value) => out && !this.isEmpty(value, ['date', 'practitioner_name', 'note']), true);
      }
      return v.value !== null;
    });
    return list;
  }
}
