<div class="container-fluid">
  <div class="row">
    <label class="sectionTitle">IN-HOUSE TESTING CAPABILITIES</label>
    <p>
      Select the testing capabilities available at this practice, for which Clinical Notes documentation can be
      completed:
    </p>
    <div class="ps-4 mb-4">
      <ng-container *ngFor="let capability of capabilities">
        <app-filter-checkbox *ngIf="capability.group === 'In-House Testing'"
                             [color]="'#000000'"
                             [label]="capability.title"
                             [checked]="capability.status"
                             jargonLoadingState="settings-control"
                             (onChange)="filterCapability($event)"
                             [name]="capability.alias">
        </app-filter-checkbox>
      </ng-container>
    </div>
    <div class="row mb-3 d-flex justify-content-end">
      <button class="btn btn-primary ms-2" style="width: 15em" jargonLoadingState="settings-control"
              (click)="submitTestingCapabilities()">Save In-House Testing</button>
    </div>
  </div>

  <div class="row">
    <label class="sectionTitle">SIDEBAR PREFRENCES</label>
    <div class="ps-4 mb-4">

      <div class="form-check form-switch">
        <input class="form-check-input" [(ngModel)]="sideBarListView"  type="checkbox" role="switch" id="sideBarListView" (change)="submitSideBarListView()">
        <label class="form-check-label" for="sideBarListView">Enable Buillders List View</label>
      </div>
    </div>
  </div>

  
  <div class="row" [formGroup]="settingsForm">
    <ng-container *ngIf="providers.length">
      <label class="sectionTitle">QUICK LABS SETUP</label>
      <p>
        Select the preferred pathology provider, and commonly requested tests/panels that will be used in the Quick
        Labs
        feature. For other tests, practitioners can use the Refer to Pathology option.
      </p>
      <div class="row mb-3">
        <div class="col-3">Preferred Pathology Provider:</div>
        <div class="col-3">
          <select type="text"
                  class="form-control"
                  formControlName="provider"
                  jargonLoadingState="settings-control">
            <option [value]="null" disabled>Select...</option>
            <option *ngFor="let provider of providers" [value]="provider.type.alias">{{ provider.type.provider }}
            </option>
          </select>
        </div>
        <div class="col-6"></div>
      </div>
      <div class="row" *ngIf="loadingTests">
        <div class="col-12">
          <p class="text-muted">Loading Provider Tests...</p>
        </div>
      </div>
      <ng-container *ngIf="settingsForm.get('provider')?.value !== null && !loadingTests">
        <div class="row mb-3">
          <div class="col">
            <ng-select formControlName="tests"
                       [items]="$tests | async"
                       [closeOnSelect]="true"
                       bindLabel="title"
                       [multiple]="true"
                       [typeahead]="$testSearchTerm"
                       placeholder="Start typing to search..."
                       jargonLoadingState="settings-control"
                       loadingText="Loading...">
            </ng-select>
          </div>
          <div class="col-auto pe-0">
            <button class="btn btn-outline-primary" style="width: 8em" jargonLoadingState="settings-control"
                    (click)="addTests()">Add</button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12" cdkDropListGroup
               cdkDropList
               [cdkDropListData]="providerTests"
               [cdkDropListSortPredicate]="testSortablePredicate"
               (cdkDropListDropped)="dropGroup($event)">
            <div class="row mt-3" *ngFor="let group of providerTests"
                 cdkDrag
                 [cdkDragData]="group"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 [cdkDropListData]="group.tests"
                 (cdkDropListDropped)="drop($event)">
              <div class="col-12" *ngIf="group.name !== 'default'">
                <h4 style="border-bottom: 1px solid #ccc">
                  <mat-icon [inline]="true" class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
                  &nbsp;{{ group.name }}
                  <mat-icon [inline]="true" class="group-remove" (click)="removeGroup(group.name)">delete</mat-icon>
                </h4>
              </div>
              <div class="col-auto" *ngIf="group.name !== 'default' && (!group.tests || group.tests.length === 0)">
                <p class="text-muted">Empty Group</p>
              </div>
              <div class="col-auto" *ngFor="let test of group.tests" cdkDrag [cdkDragData]="test">
                <button class="btn btn-outline-dark d-flex mb-2" jargonLoadingState="settings-control">
                  <mat-icon [inline]="true" class="drag-handle">drag_handle</mat-icon>
                  {{ test.label }}
                  <mat-icon class="ms-2" style="color: #dc3545"
                            (click)="removeTest(test, group.name)">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-auto mt-4">
            <button class="btn btn-primary" (click)="showGroupInput = true" *ngIf="!showGroupInput"
                    jargonLoadingState="settings-control">
              + Add Group
            </button>
            <div class="input-group" *ngIf="showGroupInput">
              <input type="text" class="form-control" placeholder="Group Name" #groupName
                     jargonLoadingState="settings-control" />
              <button class="btn btn-primary" (click)="addGroup(groupName)" jargonLoadingState="settings-control">
                Add Group
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-3 d-flex justify-content-end">
          <button class="btn btn-primary ms-2" style="width: 15em" jargonLoadingState="settings-control"
                  (click)="submitPathologyTests()">Save Quick Labs</button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="row">
    <label class="sectionTitle">CUSTOM BUILDERS</label>
    <p>
      Create and manage custom builders with configured fields to suit your clinical purposes.
    </p>
    <div class="d-flex justify-content-start">
      <a jargonLoadingState="settings-control-custom-builders" class="btn btn-outline-primary ps-3"
         routerLink="/settings/custom-builder">Add</a>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Builder Name</th>
            <th>Category</th>
            <th>Availability</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let builder of customBuilders">
            <td>{{builder.title}}</td>
            <td>{{builder.group || 'N/A'}}</td>
            <td>{{builder.scope === 'user' ? 'Private' : 'Practice'}}</td>
            <td>
              <a jargonLoadingState="settings-control-custom-builders" class="btn btn-outline-primary"
                 [routerLink]="['/settings/custom-builder', builder.scope, builder.alias]">Edit</a>
              <button jargonLoadingState="settings-control-custom-builders" class="btn btn-outline-danger"
                      (click)="doCustomBuilderDelete(builder)">Delete</button>
            </td>
          </tr>
          <tr *ngIf="!customBuilders || customBuilders.length === 0">
            <td colspan="4">
              <p class="text-center text-muted">
                No Custom Builders Yet
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>