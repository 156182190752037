<div class="container-fluid py-4" [formGroup]="formData">

  <div class="row">

    <div class="col-6">
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Flu Annual</label>
        </div>
        <div class="col-auto" formGroupName="flu_annual">
          <app-status-comment formControlName="present" jargonLoadingState="builder-control">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Varicella</label>
        </div>
        <div class="col-auto" formGroupName="varicella">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Meningococcal/MCV</label>
        </div>
        <div class="col-auto" formGroupName="meningococcal">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">DPT (every 10yrs)</label>
        </div>
        <div class="col-auto" formGroupName="dpt">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Hepatitis B >50</label>
        </div>
        <div class="col-auto" formGroupName="hepatitis">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">PCV13/Prevnar13>50</label>
        </div>
        <div class="col-auto" formGroupName="pvc">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">PPSV23Pneumovax>60 Zostavax</label>
        </div>
        <div class="col-auto" formGroupName="ppsv">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Other</label>
        </div>
        <div class="col-auto" formGroupName="other">
          <app-status-comment formControlName="present">
            <ng-template appTemplateRef="positive" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
            <ng-template appTemplateRef="negative" let-data="data">
              <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                     jargonLoadingState="builder-control">
            </ng-template>
          </app-status-comment>
        </div>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value ? 'YES' : 'NO' }}{{item.note ? ': ' + item.note : ''}}]</span>
  </p>
</ng-template>