import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
let INSTANCES = 0;

@Component({
  selector: 'app-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss']
})
export class FilterCheckboxComponent implements OnInit {

  @Input() name!: string;
  @Input() color!: string;
  @Input() label!: string;
  @Input() checked: boolean = false;
  @Input('jargonLoadingState') jargonLoadingState?: string | string[];
  @Output() onChange = new EventEmitter<any>();
  public id: string;
  private _tooltip?: string;


  @Input() set tooltip(val: string) {
    this._tooltip = val;
  }

  get tooltip(): string {
    return this._tooltip ? this._tooltip : this.label;
  }

  constructor() {
    // hard reset at arbitrary limit
    if (INSTANCES > 999999) {
      INSTANCES = 0;
    }
    INSTANCES++;
    this.id = `filter-checkbox-${INSTANCES}__${this.name}`;
  }

  ngOnInit() {
  }

  public change() {
    this.checked = !this.checked;
    const obj = {
      name: this.name,
      status: this.checked
    };
    this.onChange.emit(obj);
  }

}
