<div class="container-fluid py-4" [formGroup]="formData">

    <ng-container *ngIf="builderConfig && initialized;else Loading">
        <div class="col-12 mb-4" *ngFor="let field of builderConfig.fields">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">{{field.label}}</label>
                </div>
            </div>
            <ng-container *ngIf="field.multiple" [formArrayName]="getControlName(field.label)">
                <div class="row g-2"
                     *ngFor="let row of getArray(field.label).controls; let i = index"
                     [formGroupName]="i">
                    <ng-container *ngIf="field.style === 'single'">
                        <div class="col input-group">
                            <ng-container *jargonViewMode="'write'">
                                <button class="btn btn-outline-secondary btn-sm" type="button"
                                        jargonLoadingState="builder-control"
                                        (click)="getArray(field.label).removeAt(i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngFor="let input of field.inputs; let j = index"
                                          [ngTemplateOutlet]="{
                                                text: customInputTmpl_text,
                                                textarea: customInputTmpl_textarea,
                                                select: customInputTmpl_select,
                                                multiselect: customInputTmpl_multiselect,
                                                checkbox: customInputTmpl_checkbox,
                                                radio: customInputTmpl_radio
                                            }[input.type]"
                                          [ngTemplateOutletContext]="{
                                                key: getControlName(field.label)+'__field__'+i+'__'+getControlName(input.label)+'__input__'+j,
                                                control: row.get(getControlName(input.label)),
                                                options: input,
                                                style: field.style
                                            }">

                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="field.style === 'multiple'">
                        <div class="col-auto">
                            <ng-container *jargonViewMode="'write'">
                                <button class="btn btn-outline-secondary btn-sm" type="button"
                                        jargonLoadingState="builder-control"
                                        (click)="getArray(field.label).removeAt(i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                        <div class="col">
                            <ng-container *ngFor="let input of field.inputs; let j = index"
                                          [ngTemplateOutlet]="{
                                                text: customInputTmpl_text,
                                                textarea: customInputTmpl_textarea,
                                                select: customInputTmpl_select,
                                                multiselect: customInputTmpl_multiselect,
                                                checkbox: customInputTmpl_checkbox,
                                                radio: customInputTmpl_radio
                                            }[input.type]"
                                          [ngTemplateOutletContext]="{
                                                key: getControlName(field.label)+'__field__'+i+'__'+getControlName(input.label)+'__input__'+j,
                                                control: row.get(getControlName(input.label)),
                                                options: input,
                                                style: field.style
                                            }">

                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <ng-container *jargonViewMode="'write'">
                    <div class="row mt-1">
                        <div class="col-12">
                            <button class="btn btn-primary" jargonLoadingState="builder-control"
                                    (click)="addMultiEntry(getArray(field.label), field)">
                                Add +
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!field.multiple" [formGroupName]="getControlName(field.label)">
                <div class="row g-2">
                    <ng-container *ngIf="field.style === 'single'">
                        <div class="col input-group">
                            <ng-container *ngFor="let input of field.inputs; let j = index"
                                          [ngTemplateOutlet]="{
                                                text: customInputTmpl_text,
                                                textarea: customInputTmpl_textarea,
                                                select: customInputTmpl_select,
                                                multiselect: customInputTmpl_multiselect,
                                                checkbox: customInputTmpl_checkbox,
                                                radio: customInputTmpl_radio
                                            }[input.type]"
                                          [ngTemplateOutletContext]="{
                                                key: getControlName(field.label)+'__field__'+getControlName(input.label)+'__input__'+j,
                                                control: getGroup(field.label).get(getControlName(input.label)),
                                                options: input,
                                                style: field.style
                                            }">

                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="field.style === 'multiple'">
                        <div class="col" *ngFor="let input of field.inputs; let j = index">
                            <ng-container [ngTemplateOutlet]="{
                                                text: customInputTmpl_text,
                                                textarea: customInputTmpl_textarea,
                                                select: customInputTmpl_select,
                                                multiselect: customInputTmpl_multiselect,
                                                checkbox: customInputTmpl_checkbox,
                                                radio: customInputTmpl_radio
                                            }[input.type]"
                                          [ngTemplateOutletContext]="{
                                                key: getControlName(field.label)+'__field__'+getControlName(input.label)+'__input__'+j,
                                                control: getGroup(field.label).get(getControlName(input.label)),
                                                options: input,
                                                style: field.style
                                            }">

                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <ng-container *jargonViewMode="'write'">
            <div class="col-auto mb-4 d-flex justify-content-end">
                <button class="btn btn-fill btn-outline-primary ms-3"
                        jargonLoadingState="builder-control"
                        (click)="deleteBuilder()">
                    Remove
                </button>
                <button class="btn btn-fill btn-primary ms-3"
                        jargonLoadingState="builder-control"
                        (click)="saveData()">
                    Save
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #Loading>
    <h4>Loading Builder Configuration...</h4>
</ng-template>

<ng-template #preview let-data="data">
    <p *ngFor="let item of data; let i = index">
        <strong>{{item.label}} </strong>
        <span>[{{ item.value }}]</span>
    </p>
</ng-template>


<ng-template #customInputTmpl_text let-control="control" let-options="options" let-key="key">
    <input type="text" class="form-control" [formControl]="control" [placeholder]="options.label">
</ng-template>

<ng-template #customInputTmpl_textarea let-control="control" let-options="options" let-key="key">
    <textarea class="form-control" [formControl]="control" [placeholder]="options.label" rows="1"></textarea>
</ng-template>

<ng-template #customInputTmpl_select let-control="control" let-options="options" let-key="key">
    <ng-select
               [items]="options.details"
               [multiple]="false"
               [placeholder]="options.label"
               [hideSelected]="true"
               [formControl]="control"></ng-select>
</ng-template>

<ng-template #customInputTmpl_multiselect let-control="control" let-options="options" let-key="key">
    <ng-select
               [items]="options.details"
               [multiple]="true"
               [placeholder]="options.label"
               [hideSelected]="true"
               [formControl]="control"></ng-select>
</ng-template>

<ng-template #customInputTmpl_checkbox let-control="control" let-options="options" let-key="key" let-style="style">
    <div class="input-group-text" *ngIf="style === 'single'">
        <div class="form-check">
            <input type="checkbox"
                   [formControl]="control"
                   [placeholder]="options.label"
                   class="form-check-input"
                   [attr.id]="key+'__checkbox'" />
            <label class="form-check-label" [attr.for]="key+'__checkbox'">{{options.details}}</label>
        </div>
    </div>
    <div class="form-check" *ngIf="style === 'multiple'">
        <input type="checkbox"
               [formControl]="control"
               [placeholder]="options.label"
               class="form-check-input"
               [attr.id]="key+'__checkbox'" />
        <label class="form-check-label" [attr.for]="key+'__checkbox'">{{options.details}}</label>
    </div>
</ng-template>

<ng-template #customInputTmpl_radio let-control="control" let-options="options" let-key="key" let-style="style">
    <div class="input-group-text" *ngIf="style === 'single'">
        <div class="form-check form-check-inline" *ngFor="let opt of options.details;index as i">
            <input class="form-check-input"
                   type="radio"
                   [name]="key+'__radio'"
                   [attr.id]="key+'__radio__'+i"
                   [value]="opt"
                   [formControl]="control">
            <label class="form-check-label" [attr.for]="key+'__radio__'+i">{{opt}}</label>
        </div>
    </div>
    <ng-container *ngIf="style === 'multiple'">
        <div class="form-check form-check-inline" *ngFor="let opt of options.details;index as i">
            <input class="form-check-input"
                   type="radio"
                   [name]="key+'__radio'"
                   [attr.id]="key+'__radio__'+i"
                   [value]="opt"
                   [formControl]="control">
            <label class="form-check-label" [attr.for]="key+'__radio__'+i">{{opt}}</label>
        </div>
    </ng-container>
</ng-template>