import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import { filter, Subscription, tap } from 'rxjs';
import { UIComponentAlias, UiService, UIToggleEvent } from '../../../shared/services/common/ui.service';

@Component({
  selector: 'ui-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('headerRef') headerRef!: ElementRef<any>;
  @ViewChild('builderBarRef') builderBarRef!: ElementRef<any>;
  @ViewChild('commentBarRef') commentBarRef!: ElementRef<any>;
  public topOffset: number = 0;
  public leftOffset: number = 0;
  public rightOffset: number = 0;
  public sidebarTopOffset: number = 0;
  public openReviews: boolean = false;

  private timeouts: { [key: string]: any } = {
    left: undefined,
    top: undefined
  };
  private subscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    activeUser: ActiveUserService,
    private router: Router,
    private zone: NgZone,
    private uiService: UiService
  ) {
    if (!route.snapshot.params['role']) {
      this.router.navigate(['main', activeUser.user!.role], { queryParams: route.snapshot.queryParams, queryParamsHandling: 'merge' });
    }
  }

  ngOnInit(): void {
    this.subscription = this.uiService.events.pipe(
      filter(({ component }: UIToggleEvent) => component === UIComponentAlias.SIDEBAR_REVIEWS),
      tap(({ state }: UIToggleEvent) => {
        this.openReviews = state;
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.topOffset = this.headerRef.nativeElement.clientHeight;

      if (this.builderBarRef) {
        this.leftOffset = this.builderBarRef.nativeElement.clientWidth;
        new ResizeObserver(([entry]) => {
          this.zone.run(() => {
            this.leftOffset = entry.contentRect.width;
          });
        }).observe(this.builderBarRef.nativeElement);
      }

      if (this.commentBarRef) {
        this.rightOffset = this.commentBarRef.nativeElement.clientWidth;
        new ResizeObserver(([entry]) => {
          this.zone.run(() => {
            this.rightOffset = entry.contentRect.width;
          });
        }).observe(this.commentBarRef.nativeElement);
      }

      this.sidebarTopOffset = this.headerRef.nativeElement.clientHeight;
      new ResizeObserver(([entry]) => {
        this.zone.run(() => {
          this.sidebarTopOffset = entry.contentRect.height;
        });
      }).observe(this.headerRef.nativeElement);
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
