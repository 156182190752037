<div class="container-fluid py-4" [formGroup]="formData">
  <!-- Travel -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Travel</label>
      </div>
    </div>
    <ng-container *jargonViewMode="'review'">
      <div class="row g-2">
        <div class="col-2"><label class="text-muted">Date</label></div>
        <div class="col-3"><label class="text-muted">Destination</label></div>
        <div class="col-2"><label class="text-muted">Risk Area</label></div>
        <div class="col-2"><label class="text-muted">Reason for travel</label></div>
        <div class="col-3"><label class="text-muted">Risk Factors/Notes</label></div>
      </div>
    </ng-container>
    <ng-container formArrayName="travel">
      <div class="row g-2" *ngFor="let row of getRow('travel').controls; let i = index" [formGroupName]="i">
        <div class="col-2">
          <input class="form-control" placeholder="Date" formControlName="date_time"
                 jargonLoadingState="builder-control" ngbDatepicker container="body" #shDate="ngbDatepicker"
                 (click)="shDate.toggle()">
        </div>
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Destination" formControlName="destination"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <ng-select [items]="flags"
                     [multiple]="true"
                     formControlName="flags"
                     appendTo="#app-wrapper"
                     placeholder="Risk Area"
                     jargonLoadingState="builder-control"
                     bindLabel="name"
                     bindValue="name">
          </ng-select>
        </div>
        <div class="col-2">
          <input type="text" class="form-control" placeholder="Reason for travel" formControlName="reason"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Risk Factors/Notes" formControlName="note"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('travel', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('travel')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>

</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{item.value}}]</span>
  </p>
</ng-template>