<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--Trachea-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Trachea</label>
      </div>
      <div class="col" formGroupName="trachea">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select class="form-control" formControlName="tug" jargonLoadingState="builder-control">
              <option disabled [value]="null">Tug</option>
              <option [value]="true">Yes</option>
              <option [value]="false">No</option>
            </select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Percussion-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Percussion</label>
      </div>
      <div class="col" formGroupName="percussion">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('percussion')"
                            [count]="getDataCount('percussion')"
                            (remove)="deleteRow('percussion')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           appendTo="#app-wrapper"
                           formControlName="regions"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('percussion')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('percussion')">Add+
          </button>
        </ng-container>
      </div>
    </div>

    <!--Auscultation-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Auscultation</label>
      </div>
      <div class="col" formGroupName="auscultation">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('auscultation')"
                            [count]="getDataCount('auscultation')"
                            (remove)="deleteRow('auscultation')($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           appendTo="#app-wrapper"
                           [hideSelected]="true"
                           formControlName="regions"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('auscultation')" class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('auscultation')">Add+
          </button>
        </ng-container>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}}: </strong>
    <span>{{item.present ? 'Present' : 'Not Present'}}{{item.value}}</span>
  </p>
</ng-template>