import { Component } from '@angular/core';
import * as moment from 'moment';
import { catchError, finalize, firstValueFrom, forkJoin, map, of, take, tap } from 'rxjs';
import { FhirFamilyMemberHistoryModel } from 'src/app/shared/models/fhir/family-member-history.model';
import { FhirBundleResourceModel } from 'src/app/shared/models/fhir/fhir-resource.model';
import { FhirObservationComponentModel, FhirObservationModel } from 'src/app/shared/models/fhir/observation.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { FhirEHRHttpService } from 'src/app/shared/services/http/fhir-ehr-http.service';
import { __values } from 'tslib';
import { EHRSectionModel } from '../../../../shared/components/medical-and-family-history/medical-and-family-history.component';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';

const DATA_CACHE: {
  timestamp: number;
  data: EHRSectionModel[];
} = {
  timestamp: 0,
  data: []
};

@Component({
  selector: 'app-family-and-social-history',
  templateUrl: './family-and-social-history.component.html',
  styleUrls: ['./family-and-social-history.component.scss']
})
export class FamilyAndSocialHistoryComponent extends AbstractEHRTabComponent {

  public familyAndSocialHistorySections: EHRSectionModel[] = [];
  public loadingStates: { [key: string]: boolean } = {};

  constructor(
    private fhirhttp: FhirEHRHttpService,
    private clinicalNoteService: ClinicalNoteService
  ) {
    super();
  }

  get isLoading(): boolean {
    return false === Object.keys(this.loadingStates).reduce((out, key) => {
      return out && this.loadingStates[key];
    }, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // if (DATA_CACHE.timestamp < moment().subtract(1, 'minute').valueOf()) {
    firstValueFrom(forkJoin([
      this.loadFamilyMemberHistory().pipe(catchError(e => { console.error(e); return of(null); })),
      this.loadObservations().pipe(catchError(e => { console.error(e); return of(null); }))
    ]).pipe(
      tap((sections: Array<EHRSectionModel | null>) => {
        this.familyAndSocialHistorySections = (sections.filter(v => v !== null) as EHRSectionModel[]).sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        DATA_CACHE.data = this.familyAndSocialHistorySections;
        DATA_CACHE.timestamp = moment().valueOf();
      })
    ));
    // } else {
    //   this.familyAndSocialHistorySections = DATA_CACHE.data;
    // }
  }

  private loadFamilyMemberHistory() {
    const title = 'Relatives With Illnesses';
    this.loadingStates[title] = false;
    return this.fhirhttp.Patient_Compartment<FhirFamilyMemberHistoryModel>('FamilyMemberHistory', this.clinicalNoteService.fhirPatientId!).pipe(
      take(1),
      map((data) => {
        const section: EHRSectionModel = {
          title: title,
          alias: 'relatives',
          order: 1,
          data: data.entry.map(entry => `${entry.resource.relationship.text}: ${entry.resource.condition.map(c => c.code.text).join(',')} (${entry.resource.note.map(n => n.text).join(';')})`)
        };
        return section
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

  private loadObservations() {
    const title = 'Social History';
    this.loadingStates[title] = false;
    const getComponent = (entity: FhirBundleResourceModel<FhirObservationModel>, code: string): FhirObservationComponentModel | undefined => {
      return entity.resource.component.find(c => c.code.coding?.some(s => s.code === code));
    };
    const getComponents = (entity: FhirBundleResourceModel<FhirObservationModel>, code: string): FhirObservationComponentModel[] => {
      return entity.resource.component.filter(c => c.code.coding?.some(s => s.code === code));
    };
    return forkJoin([
      this.fhirhttp.Patient(this.clinicalNoteService.fhirPatientId!),
      this.fhirhttp.Patient_Compartment<FhirObservationModel>('Observation', this.clinicalNoteService.fhirPatientId!, { params: { 'category.*.coding.*.code': 'social-history' } })
    ]).pipe(
      take(1),
      map(([patient, data]) => {
        const records: Array<string | EHRSectionModel> = [];
        const travel: EHRSectionModel = {
          title: 'Travel',
          alias: 'travel',
          order: 1,
          data: []
        };
        const habits: EHRSectionModel = {
          title: 'Hobbies and Habits',
          alias: 'hobbies_habits',
          order: 2,
          data: []
        };
        if (patient.maritalStatus) {
          records.push(`Martial Status: ${patient.maritalStatus.text}`);
        }
        data.entry.forEach(entry => {
          if (entry.resource.code.text === 'Hobbies and Habits') {
            habits.data.push(entry.resource.valueString || '');
            return;
          }
          switch (entry.resource.code.coding![0].code) {
            case '11341-5': // Occupational History
              const start = moment(entry.resource.effectivePeriod.start).format('DD MMMM YYYY');
              const end = entry.resource.effectivePeriod.end ? moment(entry.resource.effectivePeriod.end).format('DD MMMM YYYY') : 'Current';
              records.push(`Occupation: ${getComponent(entry, '71591-2')?.valueString} (${entry.resource.valueString}, ${start} - ${end})`);
              break;
            case '98532-5': // pets
              records.push(`Pets in the home: ${entry.resource.valueBoolean ? 'Yes' : 'No'}`);
              break;
            case '81364-2': // religion
              records.push(`Religion: ${entry.resource.valueString}`);
              break;
            case '85950-4': // living situation
              records.push(`Living Situation: ${entry.resource.valueString}`);
              break;
            case '89569-8': // mobility
              records.push(`Mobility: ${entry.resource.valueString}`);
              break;
            case '76460-5': // physical activity
              records.push(`Physical Activity: ${entry.resource.valueString}`);
              break;
            case '61144-2': // diet
              records.push(`Regular Diet: ${entry.resource.valueString}`);
              break;
            case '88028-6': // tobacco use panel
              const packYears = parseFloat(`${getComponent(entry, '8663-7')?.valueQuantity?.value}`) * parseInt(`${getComponent(entry, '88029-4')?.valueInteger}`);
              records.push(`Smoking Status: ${entry.resource.valueString} ${packYears && !Number.isNaN(packYears) ? `(${packYears})` : ''}`);
              break;
            case '72110-0': // alcohol use
              records.push(`Alcohol: ${parseFloat(`${getComponent(entry, '68519-8')?.valueString}`) * 7} units/week`);
              break;
            case '8691-8': // travel history
              // 82752-7 date
              const dateComponent = getComponent(entry, '82752-7');
              const date = dateComponent ? moment(dateComponent?.valueDateTime).format('DD MMMM YYYY') : '';
              // 94651-7 location
              const location = getComponent(entry, '94651-7');
              // 34782-3 risk
              const risks = getComponents(entry, '34782-3');
              travel.data.push(
                `${date}, ${location?.valueString}, Risk Factors: ${risks.length ? risks.map(c => c.valueString).join(',') : 'None'}`
              )
              break;
          }
        });
        if (habits.data.length) {
          records.push(habits);
        }
        if (travel.data.length) {
          records.push(travel);
        }
        const section: EHRSectionModel = {
          title: title,
          alias: 'social_history',
          order: 3,
          data: records
        };
        return section
      }),
      finalize(() => this.loadingStates[title] = true)
    )
  }

}
