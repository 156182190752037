
export const CNSExtent = (): Array<string> => ([
  'Move against slight resistance',
  'Move against gravity',
  'Cannot move at all'
].sort((a, b) => a.localeCompare(b)));

export const HeadacheTypes = (): Array<string> => ([
  'Sinus',
  'Tension',
  'Migraine',
  'Cluster',
  'Other'
].sort((a, b) => a.localeCompare(b)));

export const BodyRegions = (): Array<{ id: number, name: string }> => ([
  {
    id: 1,
    name: 'UL L'
  },
  {
    id: 2,
    name: 'UL R'
  },
  {
    id: 3,
    name: 'LL L'
  },
  {
    id: 4,
    name: 'LL R'
  }
].sort((a, b) => a.name.localeCompare(b.name)));
