import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { validateAllFormFields } from 'src/app/shared/validators';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';

type FamilyPlanningData = {
  _modified: number;
  date_time: Date | number;
  injection: string;
  site: string;
  next_date_time: Date | number | null;
}

interface FamilyPlanningFormData extends BuilderDataAbstract {
  records: Array<FamilyPlanningData>;
}

@Component({
  selector: 'app-injection-family-planning',
  templateUrl: './injection-family-planning.component.html',
  styleUrls: ['./injection-family-planning.component.scss']
})
export class InjectionFamilyPlanningComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;
  public injectionList: string[] = [
    'Depo Provera',
    'Nur Isterate',
  ];
  public siteList: string[] = [
    'Right Thigh',
    'Left Thigh',
    'Right Hip',
    'Left Hip',
    'Right Buttock',
    'Left Buttock',
  ].sort((a, b) => a.localeCompare(b));

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      records: this.fb.array([])
    });
    this.addRow();
  }

  public getRow(): FormArray {
    return this.formData.get('records') as FormArray;
  }

  public removeRow(idx: number) {
    this.getRow().removeAt(idx);
    if (this.getRow().length === 0) {
      this.addRow();
    }
  }

  public addRow() {
    this.getRow().push(this.fb.group({
      date_time: [null, Validators.required],
      injection: [null, Validators.required],
      site: [null, Validators.required],
      next_date_time: [null],
    }));
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: FamilyPlanningFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'FAMILY PLANNING:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: FamilyPlanningFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: FAMILY PLANNING`,
      data.records.map(
        (c, i) => `(${i + 1}): ${moment(c.date_time).format('YYYY-MM-dd')} - ${c.injection} ${c.site} ${c.next_date_time ? ` (${moment(c.next_date_time).format('YYYY-MM-dd')})` : ''}`
      ).join('; ')
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as FamilyPlanningFormData;
    data.records = data.records.map(d => ({ ...{ date_time: moment(d.date_time).valueOf() }, ...d, _modified: moment().valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['records'] = data.records;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['records'].length; i++) {
      this.addRow();
    }
    super.setBuilderData(data);
  }

}
