import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge, Subscription } from 'rxjs';

type BaseUrinalysisData = {
  result: string;
  note: string;
  _modified: number;
}

interface UrinalysisFormData extends BuilderDataAbstract {
  hb: BaseUrinalysisData;
  ery: BaseUrinalysisData;
  bil: BaseUrinalysisData;
  ubg: BaseUrinalysisData;
  ket: BaseUrinalysisData;
  glu: BaseUrinalysisData;
  pro: BaseUrinalysisData;
  ph: BaseUrinalysisData;
  nit: BaseUrinalysisData;
  leu: BaseUrinalysisData;
  bhcg: BaseUrinalysisData & { present: boolean };
  gender: string;
}

@Component({
  selector: 'app-urinalysis',
  templateUrl: './urinalysis.component.html',
  styleUrls: ['./urinalysis.component.scss']
})
export class UrinalysisComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public isFemale: boolean | null = null;
  private bhcgSub?: Subscription;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      hb: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['+1', Validators.required]
      }),
      ery: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      bil: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      ubg: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['norm.', Validators.required]
      }),
      ket: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      glu: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['norm.', Validators.required]
      }),
      pro: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      ph: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['5', Validators.required]
      }),
      nit: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      leu: this.fb.group({
        note: [null, Validators.maxLength(500)],
        result: ['neg.', Validators.required]
      }),
      // gets added by call to genderToggle
      // only present for females, add to start with for patchValue compatability on init
      // bhcg: this.fb.group({
      //   present: [null],
      //   note: [null, Validators.maxLength(500)],
      //   result: [null]
      // })
    });
    if (this.isFemale === null) {
      this.isFemale = this.clinicalNoteService.getPatient().sex === 'female';
      this.genderToggle(this.isFemale);
    }
    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  public clearInput(group: string) {
    (this.formData.get(group) as FormGroup).get('note')?.reset();
    (this.formData.get(group) as FormGroup).get('result')?.reset();
  }

  public genderToggle(checked: boolean) {
    if (checked) {
      if (!this.formData.get('bhcg')) {
        const group = this.fb.group({
          present: [null],
          note: [null, Validators.maxLength(500)],
          result: [null]
        });
        if (this.bhcgSub) {
          this.bhcgSub.unsubscribe();
        }
        this.bhcgSub = group.get('present')?.valueChanges.subscribe({
          next: (present) => {
            if (present) {
              group.get('result')?.setValidators(Validators.required);
            } else {
              group.get('result')?.clearValidators();
            }
            group.get('result')?.updateValueAndValidity();
          }
        });
        this.formData.addControl('bhcg', group);
      }
      // queue for after digest, so that the formgroup is added porperly before the dom updates to display it
      setTimeout(() => this.isFemale = checked);
    } else {
      this.isFemale = checked
      // queue the removal so teh dom removed the elements before removing this field
      if (this.formData.get('bhcg')) {
        setTimeout(() => {
          if (this.bhcgSub) {
            this.bhcgSub.unsubscribe();
          }
          this.formData.removeControl('bhcg');
        });
      }
    }
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: UrinalysisFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'URINALYSIS:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list.filter(v => v.value !== null)]
    }];
  }

  asString(data: UrinalysisFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: URINALYSIS`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.result}${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as UrinalysisFormData;
    data.hb['_modified'] = moment().valueOf();
    data.ery['_modified'] = moment().valueOf();
    data.bil['_modified'] = moment().valueOf();
    data.ubg['_modified'] = moment().valueOf();
    data.ket['_modified'] = moment().valueOf();
    data.glu['_modified'] = moment().valueOf();
    data.pro['_modified'] = moment().valueOf();
    data.ph['_modified'] = moment().valueOf();
    data.nit['_modified'] = moment().valueOf();
    data.leu['_modified'] = moment().valueOf();
    data['gender'] = this.isFemale ? 'female' : 'male';
    if (this.isFemale) {
      data.bhcg['_modified'] = moment().valueOf();
    }

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['hb'] = data.hb;
      builderData['ery'] = data.ery;
      builderData['bil'] = data.bil;
      builderData['ubg'] = data.ubg;
      builderData['ket'] = data.ket;
      builderData['glu'] = data.glu;
      builderData['pro'] = data.pro;
      builderData['ph'] = data.ph;
      builderData['nit'] = data.nit;
      builderData['leu'] = data.leu;
    }
    if (data.bhcg && data.bhcg.present !== null) {
      builderData['bhcg'] = data.bhcg;
    } else {
      delete builderData['bhcg'];
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    this.isFemale = (data as UrinalysisFormData).gender !== null
      ? (data as UrinalysisFormData).gender === 'female'
      : this.clinicalNoteService.getPatient().sex === 'female';
    this.genderToggle(this.isFemale);
    super.setBuilderData(data);
  }

  protected override registerFormListener() {
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: control => {
        switch (control.get('present')?.value) {
          case true:
          case false:
            control.get('note')?.enable({ emitEvent: false });
            control.get('result')?.enable({ emitEvent: false });
            break;
          case null:
            control.get('note')?.disable({ emitEvent: false });
            control.get('result')?.disable({ emitEvent: false });
            control.get('note')?.patchValue(null, { emitEvent: false });
            control.get('result')?.patchValue(null, { emitEvent: false });
            break;
        }
      }
    });
  }


  private prepareSerializedList(data: UrinalysisFormData) {
    let list = [
      { title: 'Hb', value: true, result: `${data.hb.result ? data.hb.result : ''}`, note: `${data.hb.note ? data.hb.result ? '; ' + data.hb.note : data.hb.note : ''}` },
      { title: 'ERY', value: true, result: `${data.ery.result ? data.ery.result : ''}`, note: `${data.ery.note ? data.ery.result ? '; ' + data.ery.note : data.ery.note : ''}` },
      { title: 'BIL', value: true, result: `${data.bil.result ? data.bil.result : ''}`, note: `${data.bil.note ? data.bil.result ? '; ' + data.bil.note : data.bil.note : ''}` },
      { title: 'UBG', value: true, result: `${data.ubg.result ? data.ubg.result : ''}`, note: `${data.ubg.note ? data.ubg.result ? '; ' + data.ubg.note : data.ubg.note : ''}` },
      { title: 'KET', value: true, result: `${data.ket.result ? data.ket.result : ''}`, note: `${data.ket.note ? data.ket.result ? '; ' + data.ket.note : data.ket.note : ''}` },
      { title: 'GLU', value: true, result: `${data.glu.result ? data.glu.result : ''}`, note: `${data.glu.note ? data.glu.result ? '; ' + data.glu.note : data.glu.note : ''}` },
      { title: 'PRO', value: true, result: `${data.pro.result ? data.pro.result : ''}`, note: `${data.pro.note ? data.pro.result ? '; ' + data.pro.note : data.pro.note : ''}` },
      { title: 'pH', value: true, result: `${data.ph.result ? data.ph.result : ''}`, note: `${data.ph.note ? data.ph.result ? '; ' + data.ph.note : data.ph.note : ''}` },
      { title: 'NIT', value: true, result: `${data.nit.result ? data.nit.result : ''}`, note: `${data.nit.note ? data.nit.result ? '; ' + data.nit.note : data.nit.note : ''}` },
      { title: 'LEU', value: true, result: `${data.leu.result ? data.leu.result : ''}`, note: `${data.leu.note ? data.leu.result ? '; ' + data.leu.note : data.leu.note : ''}` },
    ];

    if (data.bhcg) {
      list.push({ title: 'bHCG', value: data.bhcg.present, result: `${data.bhcg.result ? data.bhcg.result : ''}`, note: `${data.bhcg.note ? data.bhcg.result ? '; ' + data.bhcg.note : data.bhcg.note : ''}` })
    }

    return list;
  }
}
