import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MedpraxICD10CodeQueryItem } from '../../types/medprax';
import { HttpApiService } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class MedpraxIcd10HttpService extends HttpApiService<MedpraxICD10CodeQueryItem> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/coding/medprax/icd10';
}