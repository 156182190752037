import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { PMHDeliveryRoutes, PMHDosage, PMHFrequency, PMHSystems, PMHTypes } from './past-medical-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { MedpraxICD10CodeQueryItem, MedpraxTariffCodeQueryItem } from 'src/app/shared/types/medprax';
import { MedpraxCodeValue } from 'src/app/shared/components/medprax-code-typeahead/medprax-code-typeahead.component';
import { Subscription, map, merge, tap } from 'rxjs';
import { AllergiesData } from '../allergies/allergies.component';

type PMHDynamicRows = 'conditions' | 'admissions' | 'procedures' | 'medications' | 'allergies';

interface PMHData {
  _modified: number;
}

interface PMHFormDataCondition extends PMHData {
  system: string;
  type: string;
  icd10: MedpraxICD10CodeQueryItem;
  description: string;
  date_time: number;
}

interface PMHFormDataAdmission extends PMHData {
  reason: MedpraxICD10CodeQueryItem;
  facility: string;
  details: string;
  date_time: number;
}

interface PMHFormDataProcedure extends PMHData {
  code: MedpraxTariffCodeQueryItem;
  facility: string;
  details: string;
  date_time: number;
}

interface PMHFormDataMedication extends PMHData {
  name: string;
  type: string;
  dosage: {
    quantity: number;
    unit: string;
    freq: number;
    period: string;
    route: string;
  };
  date_time: number;
  date_year: string;
}

interface PMHFormData extends BuilderDataAbstract {
  conditions: Array<PMHFormDataCondition>;
  admissions: Array<PMHFormDataAdmission>;
  procedures: Array<PMHFormDataProcedure>;
  medications: Array<PMHFormDataMedication>;
  allergies: Array<AllergiesData>
  nil_conditions: boolean;
  nil_admissions: boolean;
  nil_procedures: boolean;
  nil_medications: boolean;
  nil_allergies: boolean;
}

@Component({
  selector: 'builder-past-medical-history',
  templateUrl: './past-medical-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './past-medical-history.component.scss']
})
export class PastMedicalHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;
  public systems: Array<string> = PMHSystems();
  public types: Array<string> = PMHTypes();
  public dosageTypes: Array<string> = PMHDosage();
  public frequency: Array<string> = PMHFrequency();
  public deliveryRoutes: Array<string> = PMHDeliveryRoutes();
  public formStep: number = 0;

  private subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      conditions: this.fb.array([]),
      admissions: this.fb.array([]),
      procedures: this.fb.array([]),
      medications: this.fb.array([]),
      allergies: this.fb.array([]),
      nil_conditions: [false],
      nil_admissions: [false],
      nil_procedures: [false],
      nil_medications: [false],
      nil_allergies: [false],
    });
    this.addRow('conditions');
    this.addRow('admissions');
    this.addRow('procedures');
    this.addRow('medications');
    this.addRow('allergies');
    this.subscriptions.push(
      merge(
        this.formData.get('nil_conditions')!.valueChanges.pipe(map(v => ['conditions', v])),
        this.formData.get('nil_admissions')!.valueChanges.pipe(map(v => ['admissions', v])),
        this.formData.get('nil_procedures')!.valueChanges.pipe(map(v => ['procedures', v])),
        this.formData.get('nil_medications')!.valueChanges.pipe(map(v => ['medications', v])),
        this.formData.get('nil_allergies')!.valueChanges.pipe(map(v => ['allergies', v])),
      ).pipe(
        tap(([key, value]) => {
          if (value) {
            (this.formData.get(key) as FormArray).clear();
          } else if ((this.formData.get(key) as FormArray).controls.length === 0) {
            this.addRow(key);
          }
        })
      ).subscribe()
    );
  }

  public getRow(form: keyof PMHFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: PMHDynamicRows, idx: number) {
    this.getRow(form).removeAt(idx);
    if (this.getRow(form).length === 0) {
      this.addRow(form);
    }
  }

  public addRow(form: PMHDynamicRows) {
    const row = {
      conditions: () => ({
        system: [null],
        icd10: [null],
        description: [null, Validators.maxLength(100)],
        date_time: [null],
        type: [null],
      }),
      admissions: () => ({
        reason: [null],
        facility: [null],
        details: [null],
        date_time: [null],
      }),
      procedures: () => ({
        code: [null],
        facility: [null],
        details: [null],
        date_time: [null],
      }),
      medications: () => ({
        name: [null, [Validators.required, Validators.maxLength(100)]],
        type: [null],
        dosage: this.fb.group({
          quantity: [null, Validators.required], // number
          unit: [null, Validators.required],
          freq: [null, Validators.required], // number
          period: [null, [Validators.required, Validators.maxLength(100)]],
          route: [null, Validators.required],
        }),
        date_time: [null],
        date_year: [null],
      }),
      allergies: () => ({
        allergy: [null, Validators.required],
        description: [null],
        date_time: [null]
      })
    }[form];
    const group = this.fb.group(row());
    this.getRow(form).push(group);
  }

  public patchConditionData(index: number, condition: MedpraxCodeValue | MedpraxCodeValue[] | null) {
    if (condition === null) {
      (this.formData.get('conditions') as FormArray).controls[index].patchValue({ description: '' });
      return;
    }
    condition = Array.isArray(condition) ? condition : [condition];
    (this.formData.get('conditions') as FormArray).controls[index].patchValue({
      description: condition.map(c => (c as MedpraxICD10CodeQueryItem).Description).join(', ')
    })
  }

  public override async validate() {
    const data = this.formData.value as PMHFormData;

    let pmhControls: Array<PMHDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      if (control.startsWith('nil_')) return;
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          if (value !== null && typeof value === 'object') {
            return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
          }
          return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
        }, true);
        if (empty) {
          (this.formData.get(control as PMHDynamicRows) as FormArray).removeAt(0);
          pmhControls.push(control as PMHDynamicRows);
        }
      }
    });
    validateAllFormFields(this.formData);

    const res = this.formData.valid;

    if (pmhControls.length > 0) {
      pmhControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  public override async saveData() {
    const data = this.formData.value as PMHFormData;

    let pmhControls: Array<PMHDynamicRows> = [];
    Object.entries(data).forEach(([control, controlValue]) => {
      if (control.startsWith('nil_')) return;
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          if (value !== null && typeof value === 'object') {
            return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
          }
          return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
        }, true);
        if (empty) {
          (this.formData.get(control as PMHDynamicRows) as FormArray).removeAt(0);
          pmhControls.push(control as PMHDynamicRows);
        }
      }
    });

    let res = await super.saveData();

    if (pmhControls.length > 0) {
      pmhControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  serialize(data: PMHFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'PMH:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: PMHFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: PMH`,
      ...(data.allergies && data.allergies.length ? [
        `Allergies:`,
        ...(data.nil_allergies ? [`Nil Known`] : data.allergies.map(
          (c, i) => `(${i + 1}): ${c.allergy} ${!c.description || !c.date_time ? '' : '-'}${c.description} ${moment(c.date_time).format('YYYY-MM-DD')}`
        )),
      ] : []),
      ...(data.conditions && data.conditions.length ? [
        `Conditions:`,
        ...(data.nil_conditions ? [`Nil Known`] : data.conditions.map(
          (c, i) => `(${i + 1}): ${c.description} (${c.icd10.Code}) ${moment(c.date_time).format('YYYY-MM-DD')}`
        )),
      ] : []),
      ...(data.admissions && data.admissions.length ? [
        `Admissions:`,
        ...(data.nil_admissions ? [`Nil Known`] : data.admissions.map(
          (c, i) => `(${i + 1}): ${c.reason ? c.reason?.Description + ' (' + c.reason?.Code + ')' : ''} at ${c.facility} on ${moment(c.date_time).format('YYYY-MM-DD')}${c.details ? '; ' + c.details : ''}`
        )),
      ] : []),
      ...(data.procedures && data.procedures.length ? [
        `PSH:`,
        ...(data.nil_procedures ? [`Nil Known`] : data.procedures.map(
          (c, i) => `(${i + 1}): ${c.code ? c.code?.Description + ' (' + c.code?.Code + '),' : ''} ${c.facility}, ${moment(c.date_time).format('YYYY-MM-DD')}`
        )),
      ] : []),
      ...(data.medications && data.medications.length ? [
        `Medications:`,
        ...(data.nil_medications ? [`Nil Known`] : data.medications.map(
          (c, i) => `(${i + 1}): ${c.name} ${c.dosage.quantity}${c.dosage.unit} ${c.dosage.freq} ${c.dosage.period} ${c.dosage.route}${c.date_time ? ', ' + moment(c.date_time).format('YYYY-MM-DD') : (c.date_year ? ', ' + c.date_year : '')}`
        )),
      ] : []),
    ].join('\n');
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as PMHFormData;
    data.conditions = data.conditions.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    data.admissions = data.admissions.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    data.procedures = data.procedures.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    data.medications = data.medications.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    data.allergies = data.allergies.filter(v => !this.isEmpty(v)).map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['conditions'] = data.conditions;
      builderData['admissions'] = data.admissions;
      builderData['procedures'] = data.procedures;
      builderData['medications'] = data.medications;
      builderData['allergies'] = data.allergies;
      builderData['nil_conditions'] = !!data.nil_conditions;
      builderData['nil_admissions'] = !!data.nil_admissions;
      builderData['nil_procedures'] = !!data.nil_procedures;
      builderData['nil_medications'] = !!data.nil_medications;
      builderData['nil_allergies'] = !!data.nil_allergies;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    // add standard row if not nil
    if (true === !!data['nil_conditions']) this.removeRow('conditions', 0);
    if (true === !!data['nil_admissions']) this.removeRow('admissions', 0);
    if (true === !!data['nil_procedures']) this.removeRow('procedures', 0);
    if (true === !!data['nil_medications']) this.removeRow('medications', 0);
    if (true === !!data['nil_allergies']) this.removeRow('allergies', 0);
    Object.entries(data).forEach(([key, value]) => {
      if (key.startsWith('nil_')) return;
      if (data[key] && data[key].length) {
        for (let i = 1; i < data[key].length; i++) {
          this.addRow(key as PMHDynamicRows);
        }
      }
    });
    super.setBuilderData(data);
  }
}
