<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--Cyanosis-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Cyanosis</label>
      </div>
      <div class="col" formGroupName="cyanosis">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Anemia-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Anemia</label>
      </div>
      <div class="col" formGroupName="anemia">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Jaundice-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Jaundice</label>
      </div>
      <div class="col" formGroupName="jaundice">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Dehydration/deformities-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Dehydration/deformities</label>
      </div>
      <div class="col" formGroupName="dehydration">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Clubbing-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Clubbing</label>
      </div>
      <div class="col" formGroupName="clubbing">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Oedema-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Oedema</label>
      </div>
      <div class="col" formGroupName="oedema">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Lymphadenopathy-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Lymphadenopathy</label>
      </div>
      <div class="col" formGroupName="lymphadenopathy">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" (click)="deleteBuilder()"
              jargonLoadingState="builder-control">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" (click)="saveData()" jargonLoadingState="builder-control">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value ? 'Present' : 'Not Present' }}{{item.note}}]</span>
  </p>
</ng-template>