import { Component, OnInit } from '@angular/core';

@Component({ template: '' })
export class AbstractEHRTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
