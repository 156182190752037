<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!--General-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">General</label>
      </div>
      <div class="col" formGroupName="general">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Fundi-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Fundi</label>
      </div>
      <div class="col" formGroupName="fundi">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Focal Signs-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Focal Signs</label>
      </div>
      <div class="col" formGroupName="focal_signs">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Diffuse Signs-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Diffuse Signs</label>
      </div>
      <div class="col" formGroupName="diffuse_signs">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Cranial Nerves-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Cranial Nerves</label>
      </div>
      <div class="col" formGroupName="cranial_nerves">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--GCS-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">GCS</label>
      </div>
      <div class="col" formGroupName="gcs">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="number" class="form-control" formControlName="value" jargonLoadingState="builder-control">
              <option hidden disabled [ngValue]="null">Scale</option>
              <option *ngFor="let s of scale" [value]="s">{{ s }}</option>
            </select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Reflexes-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Reflexes</label>
      </div>
      <div class="col" formGroupName="reflexes">
        <app-status-comment formControlName="present"
                            [multi]="isPresent('reflexes')"
                            [count]="getDataCount()"
                            (remove)="deleteRow()($event)">
          <ng-template appTemplateRef="positive" let-index="index">
            <ng-container formGroupName="data">
              <ng-container [formGroupName]="index">
                <ng-select [items]="regions"
                           [multiple]="true"
                           placeholder="Region"
                           [hideSelected]="true"
                           formControlName="region"
                           appendTo="#app-wrapper"
                           jargonLoadingState="builder-control"
                           bindLabel="name"
                           bindValue="name">
                </ng-select>
                <select type="number" class="form-control" formControlName="score" jargonLoadingState="builder-control">
                  <option hidden disabled [ngValue]="null">Score</option>
                  <option *ngFor="let s of score" [value]="s.value">{{ s.name }}</option>
                </select>
                <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                       jargonLoadingState="builder-control">
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template appTemplateRef="negative" let-index="index">
            <input type="text" class="form-control" placeholder="Comment" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
        <ng-container *jargonViewMode="'write'">
          <button *ngIf="isPresent('reflexes')" class="btn btn-outline-primary" (click)="addReflexes()">Add+</button>
        </ng-container>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>{{ item.value ? 'Present ' : 'Not Present ' }}{{item.note}}</span>
  </p>
</ng-template>