import { EHRTabConfigModel } from '../../../shared/models/ehr-tab-config.model';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import * as moment from 'moment';
import { UiService } from 'src/app/shared/services/common/ui.service';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input('topOffset') topOffset!: number;
  @ViewChild('headerRef') headerRef!: ElementRef;
  public tagline?: string;
  public medicalTagline?: string;
  public active: number = 0;
  public previousTab: number = this.active;
  public navOpen: boolean = false;
  public navTabs!: EHRTabConfigModel[];

  get isInFrame() {
    return this.uiService.isInFrame;
  }

  constructor(
    private clinicalNoteService: ClinicalNoteService,
    public uiService: UiService
  ) {
    this.clinicalNoteService.config.subscribe(
      (res) => (this.navTabs = res.tabs)
    );
  }

  ngOnInit(): void {
    const {
      first_name,
      last_name,
      sex,
      dob,
      id_number,
      email,
      phone,
      medical_aid_scheme,
      medical_aid_option,
      medical_aid_number
    } = this.clinicalNoteService.getPatient();

    this.tagline = [
      first_name,
      last_name,
      id_number,
      dob
        ? [
            dob
              ? `${dob} (${moment().diff(dob, 'years', false)} yo)`
              : null,
          ]
            .filter(Boolean)
            .join('')
        : null,
      sex,
      phone,
      email,
    ]
      .filter(Boolean)
      .join(' ');

    this.medicalTagline = [medical_aid_scheme, medical_aid_option, medical_aid_number].filter(Boolean).join(' ');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.addEventListener('scroll', () => {
        if (window.scrollY > this.topOffset) {
          this.headerRef.nativeElement.classList.add('small');
        } else if (window.scrollY < this.topOffset) {
          this.headerRef.nativeElement.classList.remove('small');
        }
      });
    }, 0);
  }

  public toggle(event?: number | NgbNavChangeEvent): void {
    switch (typeof event) {
      case 'number':
        if (this.previousTab === event) {
          this.navOpen = !this.navOpen;
        }
        this.previousTab = this.active;
        break;
      case 'object':
        this.navOpen = true;
        this.previousTab = event.activeId;
        break;
      default:
        this.navOpen = !this.navOpen;
    }
  }

  minimizeFrame() {
    this.uiService.minimizeFrame();
  }

  maximizeFrame() {
    this.uiService.maximizeFrame();
  }

  closeFrame() {
    this.uiService.closeFrame();
  }

  public printFrame() {
    window.print();
  }
}
