import { Component, OnDestroy, OnInit } from '@angular/core';
import { BuilderConfigItemModel, BuilderConfigModel } from '../../../shared/models/builder-config.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import {
  catchError,
  firstValueFrom,
  tap,
  Subscription,
  filter,
  throwError,
  of,
  finalize
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UIComponentAlias, UiService, UIToggleEvent } from '../../../shared/services/common/ui.service';
import { BUILDER_GROUP_ORDER } from '../config/builders';
import { SettingsHttpService } from 'src/app/shared/services/http/settings-http.service';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { SettingsModel } from 'src/app/shared/models/settings.model';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { UI_SETTINGS } from '../config/settings';
import { ToastService } from 'src/app/shared/services/common/toast.service';
@Component({
  selector: 'ui-builder-sidebar',
  templateUrl: './builder-sidebar.component.html',
  styleUrls: ['./builder-sidebar.component.scss']
})
export class BuilderSidebarComponent implements OnInit, OnDestroy {
  public sidebarOpen: boolean = false;
  public sidebarNav!: BuilderConfigModel[];
  public currentSectionNav!: BuilderConfigModel;
  public groupedBuilders!: { [key: string]: Array<BuilderConfigItemModel> };
  public orderedGroups!: string[];
  public sidebarViewSetting?: SettingsModel;
  public sideBarLocked: boolean = false;
  public buildersListView: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private clinicalNoteService: ClinicalNoteService,
    private uiService: UiService,
    private settingsHttpService: SettingsHttpService,
    private activeUserService: ActiveUserService,
    private loadingStateService: LoadingStateService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    this.clinicalNoteService.config.subscribe(res => {
      this.sidebarNav = res.builders;
      this.changeSelected(this.sidebarNav[0]);
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.uiService.events.pipe(
      filter(({ component }: UIToggleEvent) => component === UIComponentAlias.SIDEBAR_BUILDERS),
      tap(({ state }: UIToggleEvent) => {
        if (this.sideBarLocked) return;
        this.sidebarOpen = state;
        if (state) {
          this.uiService.close(UIComponentAlias.SIDEBAR_REVIEWS);
        }
      })
    ).subscribe());
    this.loadViewSetting();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.map(sub => sub.unsubscribe());
    }
  }

  public setGroupedBuilders() {
    this.groupedBuilders = this.currentSectionNav.builders.reduce((o: { [key: string]: Array<BuilderConfigItemModel> }, b) => {
      const group = b.group === null ? 'default' : b.group;
      o[group] = !o[group] ? [b] : [...o[group], b];
      return o;
    }, {});
    this.orderedGroups = Object.keys(this.groupedBuilders).sort((a, b) => {
      // not exist, go to end
      if (BUILDER_GROUP_ORDER[a] === undefined) {
        return 1;
      }
      // other not exist, bring forward
      if (BUILDER_GROUP_ORDER[b] === undefined) {
        return -1;
      }
      // high go to end
      if (BUILDER_GROUP_ORDER[a] > BUILDER_GROUP_ORDER[b]) {
        return 1;
      }
      // low go to front
      if (BUILDER_GROUP_ORDER[a] < BUILDER_GROUP_ORDER[b]) {
        return -1;
      }
      //default
      return 0;
    });
  }

  public toggle(section?: BuilderConfigModel): void {
    let state = this.sidebarOpen;
    if (section) {
      const changed = this.changeSelected(section);
      state = changed ? true : !state;
    } else {
      state = !state;
    }
    // will trigger the subscription above to open/close, so it's handled in 1 place
    this.uiService.toggle(UIComponentAlias.SIDEBAR_BUILDERS, state);
  }

  public changeSelected(filteredSection: BuilderConfigModel): boolean {
    if (this.currentSectionNav === filteredSection) {
      return false;
    }
    this.currentSectionNav = filteredSection;
    this.setGroupedBuilders();
    return true;
  }

  public async loadViewSetting() {
    this.loadingStateService.start('settings-control');

    await firstValueFrom(this.settingsHttpService.find<SettingsModel>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW).pipe(
      tap((res: SettingsModel) => {
        this.sidebarViewSetting = res;
        this.sideBarLocked = res.data.locked;
        this.sidebarOpen = !!res.data.locked;
        this.buildersListView = res.data.list;
      }),
      catchError(err => {
        if (err.status === 404) {
          return of(false);
        }
        return throwError(() => err);
      }),
    ));
    this.loadingStateService.end('settings-control');
  }

  public submitViewSetting() {
    this.sideBarLocked = !this.sideBarLocked;
    this.loadingStateService.start(['settings-control', 'settings-control-testing']);
    firstValueFrom((
      this.sidebarViewSetting
        ? this.settingsHttpService.update<{ locked: boolean, list: boolean }>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW, { ...this.sidebarViewSetting.data, locked: this.sideBarLocked })
        : this.settingsHttpService.create<{ locked: boolean }>(this.activeUserService.user?.id!, UI_SETTINGS.SIDEBAR_VIEW, { locked: this.sideBarLocked })
    ).pipe(
      tap((res: SettingsModel) => {
        this.toastService.show('Sidebar setting saved successfully', { variant: 'success' });
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.toastService.show('Failed to save sidebar settings', { variant: 'danger' });
        }
        return throwError(() => err);
      }),
      finalize(() => this.loadingStateService.end(['settings-control', 'settings-control-testing']))
    )
    );
  }
}
