import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

export type AllergiesData = {
  _modified: number;
  allergy: string;
  description: string;
  date_time: Date | number;
}

interface AllergiesFormData extends BuilderDataAbstract {
  allergies: Array<AllergiesData>;
}

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './allergies.component.scss']
})
export class AllergiesComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      allergies: this.fb.array([])
    });
    this.addRow();
  }

  public getRow(): FormArray {
    return this.formData.get('allergies') as FormArray;
  }

  public removeRow(idx: number) {
    this.getRow().removeAt(idx);
    if (this.getRow().length === 0) {
      this.addRow();
    }
  }

  public addRow() {
    this.getRow().push(this.fb.group({
      allergy: [null, Validators.required],
      description: [null],
      date_time: [null]
    }));
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: AllergiesFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'ALLERGIES:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: AllergiesFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: ALLERGIES`,
      data.allergies.map((c, i) => `(${i + 1}): ${c.allergy} ${!c.description || !c.date_time ? '' : '-'}${c.description} ${moment(c.date_time).format('YYYY-MM-dd')}`).join('; ')
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as AllergiesFormData;
    data.allergies = data.allergies.map(d => ({ ...d, _modified: moment().valueOf(), date_time: moment(d.date_time).valueOf() }));
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['allergies'] = data.allergies;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    for (let i = 1; i < data['allergies'].length; i++) {
      this.addRow();
    }
    super.setBuilderData(data);
  }

}
