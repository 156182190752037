import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { AuthRedirectGuard } from 'src/app/shared/guards/auth-redirect.guard';
import { CustomBuilderSetupComponent } from './custom-builder-setup/custom-builder-setup.component';
import { GeneralSettingsComponent } from './general/general.component';

const routes: Routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthRedirectGuard],
        title: 'Clinical Notes - Settings',
        data: { icon: 'settings' },
        children: [
            { title: 'Clinical Notes - Settings', path: '', component: GeneralSettingsComponent },
            { title: 'Settings - Create Custom Builder', path: 'custom-builder', component: CustomBuilderSetupComponent },
            { title: 'Settings - Edit Custom Builder', path: 'custom-builder/:scope/:alias', component: CustomBuilderSetupComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule { }
