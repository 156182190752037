<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <!--Last Period-->
    <div class="row mb-3" formGroupName="last_period">
      <div class="col-2">
        <label class="form-label">Last Period</label>
      </div>
      <div class="col-3">
        <input class="form-control" placeholder="Date" formControlName="date" ngbDatepicker container="body"
               [maxDate]="maxDateNow"
               #gynaeDate="ngbDatepicker" (click)="gynaeDate.toggle()" jargonLoadingState="builder-control">
      </div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                 jargonLoadingState="builder-control">
          <ng-container *jargonViewMode="'write'">
            <button class="btn btn-outline-secondary btn-sm"
                    type="button"
                    jargonLoadingState="builder-control"
                    (click)="clearInput('last_period')">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <!--Missed Period-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Missed Period</label>
      </div>
      <div class="col" formGroupName="missed_period">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Pregnant-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Pregnant</label>
      </div>
      <div class="col" formGroupName="pregnant">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control" jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--PVD-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">PVD</label>
      </div>
      <div class="col" formGroupName="pvd">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Previous Pregnancies-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Previous Pregnancies</label>
      </div>
      <div class="col" formGroupName="previous_pregnancy">
        <app-status-comment formControlName="present" [buttonStyle]="{ 'margin-top': '7px' }">
          <ng-template appTemplateRef="positive" let-data="data">
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="para" jargonLoadingState="builder-control">
              <span class="input-group-text">Para</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="gravida" jargonLoadingState="builder-control">
              <span class="input-group-text">Gravida</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="ectopic_pregnancies"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">Ectopic</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="vaginal_birth"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">Vaginal Births</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="c_section"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">Caesareans</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="vaginal_birth"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">VBACs</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" formControlName="miscarriages"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">Miscarriages</span>
            </div>
            <div class="previous-pregnancy-group input-group col">
              <input type="number" class="form-control" style="margin-right: 0px !important;"
                     formControlName="stillbirths"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">Stillbirths</span>
            </div>
            <div style="flex-basis: 100%" class="mt-2"></div>
            <input type="text" class="form-control" style="margin-left: 42px !important;margin-top: 7px;"
                   placeholder="Complications"
                   formControlName="note" jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note" style="margin-top: 7px;"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{item.value}}]</span>
  </p>
</ng-template>