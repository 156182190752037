<ng-container cdkDropListGroup>
    <ng-container *jargonViewMode="'write'">
        <div
             #builderBarRef
             class="builder-bar-wrapper"
             [ngStyle]="{ 'margin-top.px': sidebarTopOffset }">
            <ui-builder-sidebar></ui-builder-sidebar>
        </div>
    </ng-container>
    <div
         #commentBarRef
         class="comment-bar-wrapper"
         [ngStyle]="{ 'margin-top.px': sidebarTopOffset }">
        <ui-comments></ui-comments>
    </div>
    <div class="header-wrapper" #headerRef>
        <ui-header [topOffset]="topOffset"></ui-header>
    </div>
    <div class="container-fluid content-wrapper" [style.margin-top.px]="topOffset" [style.margin-left.px]="leftOffset"
         [style.margin-right.px]="rightOffset">
        <div class="row">
            <div class="col">
                <ui-notes [topOffset]="topOffset"></ui-notes>
            </div>
        </div>
    </div>
    <ui-footer [leftOffset]="leftOffset"></ui-footer>
</ng-container>