import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ActiveUserService } from './shared/services/common/active-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jargon';
  constructor(
    route: ActivatedRoute,
    router: Router,
    activeUser: ActiveUserService,
    ngbDatePickerConfigService: NgbDatepickerConfig
  ) {
    // set default min date to 150 years ago (current default 10year, this should resolve older profiles with a large buffer)
    ngbDatePickerConfigService.minDate = {
      day: moment().date(),
      month: moment().month() + 1,
      year: moment().year() - 150
    };

    /**
     * NB: This is a workaround for angular loosing query params on load with:
     * { pathMatch: 'full', redirectTo: 'main' }
     * inside the routing, if fails to keep query param on init.
     * 
     * This way we are grabbing them manually and forcing the token param into the redirect
    */
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let redirect = ['main', activeUser.user!.role];
    if (/^\#\/widget/.test(window.location.hash)) {
      redirect = ['widget', 'preview'];
    }

    router.navigate(redirect, {
      queryParamsHandling: 'merge',
      queryParams: {
        ...route.snapshot.queryParams,
        ...[...params.entries()].reduce((o, [k, v]) => ({ ...o, [k]: v }), {})
      }
    });
  }
}
