<div class="container-fluid footer-container" [style.margin-left.px]="leftOffset">
  <div class="row justify-content-between align-items-center">
    <ng-container *jargonViewMode="'write'">

      <div class="col-3">
        <button class="w-100 btn btn-primary" (click)="submit()"
                [jargonLoadingState]="['builder-control', 'builder-submission']">
          {{
          isSaving
          ? "Submitting Clinical Notes..."
          : "Finalise Clinical Notes (Push to EHR)"
          }}
        </button>
      </div>

      <div class="col-2">
        <button class="w-100 btn btn-primary" (click)="save()"
                [jargonLoadingState]="['builder-control', 'builder-submission']">
          {{ isSavingSections ? "Saving Clinical Notes..." : "Save Clinical Notes" }}
        </button>
      </div>

    </ng-container>
    <ng-container *jargonViewMode="'review'">

      <div class="col-2">
        <button class="w-100 mb-5 btn btn-primary" (click)="submitReview()"
                [jargonLoadingState]="['builder-control', 'builder-submission']">
          Submit Review
        </button>
      </div>

    </ng-container>
  </div>
</div>