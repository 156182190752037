<div class="container-fluid py-4" [formGroup]="formData">

  <!-- Occupation -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Occupation</label>
      </div>
    </div>
    <ng-container formArrayName="occupation">
      <ng-container *jargonViewMode="'review'">
        <div class="row g-2">
          <div class="col-3"><label class="text-muted">Job Title</label></div>
          <div class="col-3"><label class="text-muted">Company</label></div>
          <div class="col-2"><label class="text-muted">From</label></div>
          <div class="col-2"><label class="text-muted">To</label></div>
          <div class="col-2"><label class="text-muted">Risk Factors</label></div>
        </div>
      </ng-container>
      <div class="row g-2" *ngFor="let row of getRow('occupation').controls; let i = index" [formGroupName]="i">
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Job Title" formControlName="title"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Company" formControlName="company"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <input class="form-control" placeholder="From" formControlName="from" [maxDate]="maxDateNow" ngbDatepicker
                 container="body"
                 #startEmployment="ngbDatepicker" jargonLoadingState="builder-control"
                 (click)="startEmployment.toggle()">
        </div>
        <div class="col-2">
          <input class="form-control" placeholder="To" formControlName="to" [maxDate]="maxDateNow" ngbDatepicker
                 container="body"
                 #endEmployment="ngbDatepicker" jargonLoadingState="builder-control" (click)="endEmployment.toggle()">
        </div>
        <div class="col-2">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Risk Factors" formControlName="risk"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('occupation', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('occupation')">Add
            +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Bibliographic Details -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Bibliographic Details</label>
      </div>
    </div>
    <ng-container formGroupName="bibliographic">
      <ng-container *jargonViewMode="'review'">
        <div class="row g-2">
          <div class="col-3"><label class="text-muted">Marital Status</label></div>
          <div class="col-2"><label class="text-muted">Religion</label></div>
          <div class="col-2"><label class="text-muted">Living Situation</label></div>
          <div class="col-2"><label class="text-muted">Mobility</label></div>
          <div class="col-3"><label class="text-muted">Pets</label></div>
        </div>
      </ng-container>
      <div class="row g-2">
        <div class="col-3">
          <select type="text" class="form-control" formControlName="marital_status"
                  jargonLoadingState="builder-control">
            <option hidden disabled [ngValue]="null">Marital Status</option>
            <option *ngFor="let status of maritalStatuses" [value]="status">{{ status }}</option>
          </select>
        </div>
        <div class="col-2">
          <select type="text" class="form-control" formControlName="religion" jargonLoadingState="builder-control">
            <option hidden disabled [ngValue]="null">Religion</option>
            <option *ngFor="let religion of religions" [value]="religion">{{ religion }}</option>
          </select>
        </div>
        <div class="col-2">
          <select type="text" class="form-control" formControlName="living_situation"
                  jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Living Situation</option>
            <option *ngFor="let situation of livingSituation" [value]="situation">{{ situation }}</option>
          </select>
        </div>
        <div class="col-2">
          <select type="text" class="form-control" formControlName="mobility" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Mobility</option>
            <option value="Able to drive self">Able to drive self</option>
            <option value="Reliant on others for transport">Reliant on others for transport</option>
            <option value="Reliant on public transport">Reliant on public transport</option>
          </select>
        </div>

        <div class="col-3">
          <select type="text" class="form-control" formControlName="pets" jargonLoadingState="builder-control">
            <option hidden disabled [ngValue]="null">Pets</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Diet and Exercise -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Diet and Exercise</label>
      </div>
    </div>
    <ng-container formGroupName="regimen">
      <ng-container *jargonViewMode="'review'">
        <div class="row g-2">
          <div class="col-4"><label class="text-muted">Physical Activity</label></div>
          <div class="col-8"><label class="text-muted">Description of regular diet</label></div>
        </div>
      </ng-container>
      <div class="row g-2">
        <div class="col-4">
          <select type="text" class="form-control" formControlName="exercise" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Physical Activity</option>
            <option *ngFor="let activity of physicalActivity" [value]="activity">{{ activity }}</option>
          </select>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" placeholder="Description of regular diet" formControlName="diet"
                 jargonLoadingState="builder-control">
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Smoking -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Smoking</label>
      </div>
    </div>
    <ng-container formGroupName="smoking">
      <ng-container *jargonViewMode="'review'">
        <div class="row g-2">
          <div class="col-3"><label class="text-muted">Status</label></div>
          <div class="col-2"><label class="text-muted">Start Date</label></div>
          <div class="col-2"><label class="text-muted">Stop Date</label></div>
          <div class="col-2"><label class="text-muted">Cigarettes per day</label></div>
          <div class="col-3"><label class="text-muted">Years</label></div>
        </div>
      </ng-container>
      <div class="row g-2">
        <div class="col-3">
          <select type="text" class="form-control" formControlName="status" jargonLoadingState="builder-control"
                  (change)="toggleSmoking()">
            <option hidden disabled [ngValue]="null">Status</option>
            <option value="Current">Current</option>
            <option value="Never">Never</option>
            <option value="Previous">Previous</option>
          </select>
        </div>
        <div class="col-2">
          <input class="form-control" placeholder="Start Date" formControlName="start_date_time" ngbDatepicker
                 container="body" #startDate="ngbDatepicker" [maxDate]="maxDateNow" jargonLoadingState="builder-control"
                 (click)="startDate.toggle()">
        </div>
        <div class="col-2">
          <input type="text" class="form-control" placeholder="Stop Date" formControlName="stop_date_time" ngbDatepicker
                 container="body" #stopDate="ngbDatepicker" [maxDate]="maxDateNow" jargonLoadingState="builder-control"
                 (click)="stopDate.toggle()">
        </div>
        <div class="col-2">
          <input type="number" class="form-control" placeholder="Cigarettes per day" formControlName="qty_per_day"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <input type="number" class="form-control" placeholder="Years" formControlName="years"
                 jargonLoadingState="builder-control">
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Alcohol Consumption -->
  <div class="col-12 mb-4" formGroupName="alcohol">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Alcohol Consumption</label>
      </div>
    </div>
    <ng-container *jargonViewMode="'review'">
      <div class="row g-2">
        <div class="col-12"><label class="text-muted">Units consumed/week</label></div>
      </div>
    </ng-container>
    <div class="row g-2 mb-3">
      <div class="col-12">
        <input type="number" class="form-control" placeholder="Units consumed/week" formControlName="consumption"
               jargonLoadingState="builder-control">
      </div>
    </div>
    <div *ngIf="shouldAddCage" formGroupName="cage" class="ps-5 pe-5">
      <div class="row" *ngFor="let cage of cages; let i = index">
        <div class="col-8">
          <p><b>{{ cage.symbol }}: </b>{{ cage.question }}</p>
        </div>
        <div class="col-2">
          <label for="cage-{{i}}-yes">
            <input id="cage-{{i}}-yes" type="radio" [value]="true" [formControlName]="cage.control">
            Yes
          </label>
        </div>
        <div class="col-2">
          <label for="cage-{{i}}-no">
            <input id="cage-{{i}}-no" type="radio" [value]="false" [formControlName]="cage.control">
            No
          </label>
        </div>
      </div>
    </div>
    <ng-container *jargonViewMode="'write'">
      <button class="btn btn-outline-primary"
              [ngClass]="{'ms-5': shouldAddCage}"
              (click)="toggleCage()">{{shouldAddCage ? 'Remove CAGE' : 'Add CAGE'}}</button>
    </ng-container>

  </div>

  <!-- Substances -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Substances</label>
      </div>
    </div>
    <ng-container *jargonViewMode="'review'">
      <div class="row g-2">
        <div class="col-3"><label class="text-muted">Status</label></div>
        <div class="col-6"><label class="text-muted">Description</label></div>
        <div class="col-3"><label class="text-muted">Years</label></div>
      </div>
    </ng-container>
    <ng-container formArrayName="substances">
      <div class="row g-2" *ngFor="let row of getRow('substances').controls; let i = index" [formGroupName]="i">
        <div class="col-3">
          <select type="text" class="form-control" formControlName="status" jargonLoadingState="builder-control">
            <option hidden disabled [ngValue]="null">Status</option>
            <option value="Current">Current</option>
            <option value="Previous">Previous</option>
          </select>
        </div>
        <div class="col-6">
          <input type="text" class="form-control" placeholder="Description" formControlName="note"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <div class="input-group mb-3">
            <input type="number" class="form-control" placeholder="years" formControlName="years"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('substances', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control"
                  (click)="addRow('substances')">Add
            +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Hobbies and Habits -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Hobbies and Habits</label>
      </div>
    </div>
    <ng-container *jargonViewMode="'review'">
      <div class="row g-2">
        <div class="col-12"><label class="text-muted">Description</label></div>
      </div>
    </ng-container>
    <ng-container formArrayName="habits">
      <div class="row g-2" *ngFor="let row of getRow('habits').controls; let i = index" [formGroupName]="i">
        <div class="col-12">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Description" formControlName="note"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button"
                      (click)="removeRow('habits', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('habits')">Add
            +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Travel -->
  <div class="col-12 mb-4" *ngIf="getRow('travel').controls.length">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Travel</label>
      </div>
    </div>
    <ng-container *jargonViewMode="'review'">
      <div class="row g-2">
        <div class="col-2"><label class="text-muted">Date</label></div>
        <div class="col-3"><label class="text-muted">Destination</label></div>
        <div class="col-2"><label class="text-muted">Risk Area</label></div>
        <div class="col-2"><label class="text-muted">Reason for travel</label></div>
        <div class="col-3"><label class="text-muted">Risk Factors/Notes</label></div>
      </div>
    </ng-container>
    <ng-container formArrayName="travel">
      <div class="row g-2" *ngFor="let row of getRow('travel').controls; let i = index" [formGroupName]="i">
        <div class="col-2">
          <input class="form-control" placeholder="Date" formControlName="date_time"
                 jargonLoadingState="builder-control" ngbDatepicker container="body" #shDate="ngbDatepicker"
                 (click)="shDate.toggle()">
        </div>
        <div class="col-3">
          <input type="text" class="form-control" placeholder="Destination" formControlName="destination"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-2">
          <ng-select [items]="flags"
                     [multiple]="true"
                     formControlName="flags"
                     appendTo="#app-wrapper"
                     placeholder="Risk Area"
                     jargonLoadingState="builder-control"
                     bindLabel="name"
                     bindValue="name">
          </ng-select>
        </div>
        <div class="col-2">
          <input type="text" class="form-control" placeholder="Reason for travel" formControlName="reason"
                 jargonLoadingState="builder-control">
        </div>
        <div class="col-3">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Risk Factors/Notes" formControlName="note"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button" jargonLoadingState="builder-control"
                      (click)="removeRow('travel', i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('travel')">Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>

</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{item.value}}]</span>
  </p>
</ng-template>