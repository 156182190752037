import { PastMedicalHistoryComponent } from '../../ehr-tabs/components/past-medical-history/past-medical-history.component';
import { EHRTabConfigModel } from '../../../shared/models/ehr-tab-config.model';
import { Role } from 'src/app/shared/types/enum/user';
import { VisitsComponent } from '../../ehr-tabs/components/visits/visits.component';
import { FamilyAndSocialHistoryComponent } from '../../ehr-tabs/components/family-and-social-history/family-and-social-history.component';
import { LabsComponent } from '../../ehr-tabs/components/labs/labs.component';
import { ImagingComponent } from '../../ehr-tabs/components/imaging/imaging.component';
import { VaccinationsComponent } from '../../ehr-tabs/components/vaccinations/vaccinations.component';

export const PRESETS: (role: string) => EHRTabConfigModel[] = (role: string) => ({
  [Role.GP]: [
    { title: 'Past Medical History', component: PastMedicalHistoryComponent },
    { title: 'Family and Social History', component: FamilyAndSocialHistoryComponent },
    { title: 'Visits', component: VisitsComponent },
    { title: 'Labs', component: LabsComponent },
  ],
  [Role.SP]: [
    { title: 'Past Medical History', component: PastMedicalHistoryComponent },
    { title: 'Family and Social History', component: FamilyAndSocialHistoryComponent },
    { title: 'Visits', component: VisitsComponent },
    { title: 'Labs', component: LabsComponent },
  ],
  [Role.PCN]: [
    { title: 'Past Medical History', component: PastMedicalHistoryComponent },
    { title: 'Family and Social History', component: FamilyAndSocialHistoryComponent },
    { title: 'Visits', component: VisitsComponent },
    { title: 'Labs', component: LabsComponent },
  ],
  [Role.SCN]: [
    { title: 'Past Medical History', component: PastMedicalHistoryComponent },
    { title: 'Family and Social History', component: FamilyAndSocialHistoryComponent },
    { title: 'Visits', component: VisitsComponent },
    { title: 'Labs', component: LabsComponent },
  ],
}[role] || [
    { title: 'Past Medical History', component: PastMedicalHistoryComponent },
    { title: 'Family and Social History', component: FamilyAndSocialHistoryComponent },
    { title: 'Visits', component: VisitsComponent },
    { title: 'Labs', component: LabsComponent },
  ]);
