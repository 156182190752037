import { Validators } from '@angular/forms';

export const RawGitExamFormData = () => ({
  soft: {
    present: [null],
    regions: [null],
    note: [null, Validators.maxLength(500)]
  },
  non_tender: {
    present: [null],
    regions: [null],
    note: [null, Validators.maxLength(500)]
  },
  tender: {
    present: [null],
    regions: [null],
    note: [null, Validators.maxLength(500)]
  },
  masses: {
    present: [null],
    regions: [null],
    note: [null, Validators.maxLength(500)]
  },
  flank_pain: {
    present: [null],
    regions: [null],
    note: [null, Validators.maxLength(500)]
  }
});

export const GITExamRegions = (extra?: Array<{ name: string }>): Array<{ name: string }> => ([
  { name: 'Throughout' },
  { name: 'RUQ' },
  { name: 'RLQ' },
  { name: 'LUQ' },
  { name: 'LLQ' },
  ...(extra ? extra : [])
].sort((a, b) => a.name.localeCompare(b.name)));
