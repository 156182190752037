import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PaginatedResponse } from "../../../types/paginatedResponse";
import { MessageResponse } from "../../../types/messageResponse";
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { throwError, Observable, of, ObservableInput, forkJoin } from 'rxjs';
import { CallsAPI } from "../../../services/abstract/http/calls-api";

@Injectable({
  providedIn: 'root'
})
export abstract class HttpApiService<T = any, L = PaginatedResponse<T>, D = MessageResponse> extends CallsAPI<T, L> {

  /**
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public list(options?: HttpOptions): Observable<L> {
    return this.http.get<L>(this.buildAction(''), this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpNestedApiService
   */
  public listAll(options?: HttpOptions): Observable<T[]> {
    let allData: T[] = [];
    return this.list(options).pipe(
      switchMap<L, ObservableInput<T[]>>(res => {
        if (this.isPaginatedResponse<T>(res)) {
          allData = res.data;
          if (res.meta.pagination.total_pages > 1) {
            let pages = [];
            // already loaded 1st page, only load subsequent pages
            for (let page = 2; page <= res.meta.pagination.total_pages; page++) {
              let pageOptions: HttpOptions = Object.assign({ params: {} }, options);
              // set limit unless explicitly stated
              (pageOptions.params as { [param: string]: string })['limit'] = (pageOptions.params as { [param: string]: string })['limit'] ? (pageOptions.params as { [param: string]: string })['limit'] : '100';
              // set current page
              (pageOptions.params as { [param: string]: string })['page'] = `${page}`;

              pages.push(
                this.list(pageOptions)
                  .pipe(
                    tap(res => {
                      allData = allData.concat((res as unknown as PaginatedResponse<T>).data);
                    }),
                    map(res => null) // clear
                  )
              );
            }
            return forkJoin(pages).pipe(
              map(() => allData)
            );
          }
        }
        else {
          allData = res as unknown as T[];
        }
        return of(allData);
      })
    );
  }

  /**
   * @param {*} id
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public find(id: any, options?: HttpOptions): Observable<T> {
    return this.http.get<T>(this.buildAction('/{:id}', { id }), this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public create(body: any, options?: HttpOptions): Observable<T> {
    return this.http.post<T>(this.buildAction(''), body, this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {*} id
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public update(id: any, body: any, options?: HttpOptions): Observable<T> {
    let req;
    if (body instanceof FormData) {
      body.append('_method', 'PATCH');
      req = this.http.post<T>(this.buildAction('/{:id}', { id }), body, this.buildOptions(options));
    } else {
      req = this.http.patch<T>(this.buildAction('/{:id}', { id }), body, this.buildOptions(options));
    }
    return req.pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param {*} id
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public delete(id: any, options?: HttpOptions): Observable<D> {
    return this.http.delete<D>(this.buildAction('/{:id}', { id }), this.buildOptions(options)).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

}

/**
 * HTTP default options
 * @export
 * @interface HttpOptions
 */
export interface HttpOptions {
  headers?:
  | HttpHeaders
  | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?:
  | HttpParams
  | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
