import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { AttachmentModel } from '../../models/attachment.model';
import { AttachmentModalOptions, AttachmentsService } from '../../services/common/attachments.service';

@Component({
  selector: 'jargon-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit, AfterViewInit {
  @ViewChild('inputRef') public inputRef?: ElementRef;
  @Input('multiple') public multiple: boolean = true;
  @Input('modal') public shouldOpenModal: boolean = false;
  @Input('modalOptions') public modalOptions?: AttachmentModalOptions;

  @Output('onModalSave') public onModalSave: EventEmitter<AttachmentModel[]> = new EventEmitter();
  @Output('filesChange') public filesChange: EventEmitter<File[]> = new EventEmitter();
  public _files: File[] = [];
  public _label: string = 'Upload Attachment';
  @Input('label') public set label(v: string) {
    this._label = v;
    this.customLabel = true;
  }
  private customLabel: boolean = false;


  @Input('files') public set files(files: File[]) {
    this._files = files;
    if (!this.customLabel) {
      this.setLabel();
    }
  }
  public get files(): File[] {
    return this._files;
  }

  constructor(private attachmentService: AttachmentsService) { }

  ngOnInit(): void {
    if (!this.customLabel) {
      this.setLabel();
    }
  }

  ngAfterViewInit(): void {
    this.inputRef?.nativeElement.addEventListener('dragenter', this.onEnter.bind(this), false);
    this.inputRef?.nativeElement.addEventListener('dragleave', this.onLeave.bind(this), false);
    this.inputRef?.nativeElement.addEventListener('dragover', this.onOver.bind(this), false);
    this.inputRef?.nativeElement.addEventListener('drop', this.onDrop.bind(this), false);
  }

  onFilesChange(evt: any) {
    this.files = [...evt.target.files];
    this.filesChange.emit(this.files);
  }

  onEnter(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.inputRef?.nativeElement.classList.add('hovered');
  }
  onLeave(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.inputRef?.nativeElement.classList.remove('hovered');
  }
  onOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }
  onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.inputRef?.nativeElement.classList.remove('hovered');
    this.files = [...event.dataTransfer.files];
    this.filesChange.emit(this.files);

    if (this.shouldOpenModal) {
      this.attachmentService.upload({
        attachments: [...this.files],
        ...this.modalOptions
      });
      this.files = [];
    }
  }

  public testModal(event: Event) {
    if (this.shouldOpenModal) {
      event.preventDefault();
      event.stopPropagation();
      firstValueFrom(this.attachmentService.upload(this.modalOptions).pipe(
        tap(files => { if (files) this.onModalSave.emit(files); })
      ));
    }
  }

  private setLabel() {
    if (this.files.length) {
      this._label = [
        ...this.files.map(a => a.name).slice(0, 3),
        ...(this.files.length > 3 ? [`\nAnd ${this.files.length - 3} More Files...`] : [])
      ].join('\n');
    } else {
      this._label = 'Upload Attachment';
    }
  }
}
