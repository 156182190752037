import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FhirAllergyIntoleranceModel } from '../../models/fhir/allergy-intolerance.model';
import { FhirBundleModel, FhirResourceModel } from '../../models/fhir/fhir-resource.model';
import { FhirPatientModel } from '../../models/fhir/patient.model';
import { CallsAPI } from '../abstract/http/calls-api';
import { HttpOptions } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class FhirEHRHttpService extends CallsAPI<FhirResourceModel<any>, FhirBundleModel<any>> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/fhir/';

    /**
     * @param {*} id
     * @param {HttpOptions} options
     * @returns
     * @memberof HttpApiService
     */
    public Patient(patientId: number, options?: HttpOptions): Observable<FhirPatientModel> {
        return this.http.get<FhirPatientModel>(this.buildAction('Patient/{:patientId}', { patientId }), this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
     * @param {*} id
     * @param {HttpOptions} options
     * @returns
     * @memberof HttpApiService
     */
    public Patient_Compartment<R>(resourceType: string, patientId: number, options?: HttpOptions): Observable<FhirBundleModel<R>> {
        return this.http.get<FhirBundleModel<R>>(this.buildAction('Patient/{:patientId}/{:resourceType}', { patientId, resourceType }), this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }
}