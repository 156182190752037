import { InputModalityDetector } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public sizeValue: number = 1;
  public sizeUnit: string = 'em';

  constructor() { }
  @Input('size') set size(val: string) {
    this.sizeValue = parseFloat(/([0-9]+\.?[0-9]+?).*/.exec(val)![1]);
    this.sizeUnit = /([0-9]+\.?[0-9]+?).*/.exec(val)![1];
  }

  get wrapperSize() {
    return this.sizeValue + (this.sizeValue / 10) + this.sizeUnit;
  }

  get style() {
    return {
      width: this.sizeValue + this.sizeUnit,
      height: this.sizeValue + this.sizeUnit,
      margin: this.sizeValue / 10 + this.sizeUnit,
      'border-wdith': this.sizeValue / 10 + this.sizeUnit + ' solid #244ea2',
    }
  }

  ngOnInit(): void {
  }

}
