import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { tap } from 'rxjs';
import { ActiveUserService } from '../services/common/active-user.service';
import { ClinicalNoteService } from '../services/common/clinical-note.service';
import { ViewModeEnum } from '../types/enum/viewMode';
import { ViewMode } from '../types/util';

@Directive({
  selector: '[jargonViewMode]'
})
export class ViewModeDirective implements OnInit {

  private mode!: ViewMode;
  private activeMode: ViewMode;
  private rendered: boolean = false;

  constructor(
    private clinicalNoteService: ClinicalNoteService,
    private activeUserService: ActiveUserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.activeMode = this.activeUserService.claims!['mode'] as ViewMode;
  }

  @Input('jargonViewMode') set allowedMode(mode: ViewMode) {
    this.mode = mode;
  }

  ngOnInit() {
    this.resolveRendering();
    this.clinicalNoteService.config.pipe(
      tap(() => this.resolveRendering())
    ).subscribe();
  }

  resolveRendering() {
    if (this.activeMode === this.mode) {
      if (this.mode === ViewModeEnum.WRITE && this.clinicalNoteService.isReadOnly) {
        this.viewContainer.clear();
        this.rendered = false;
      } else {
        if (!this.rendered) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.rendered = true;
        }
      }
    } else {
      this.viewContainer.clear();
      this.rendered = false;
    }
  }
}
