<div class="container-fluid header-container" #headerRef>
  <div class="row">
    <div class="col tagline">
      <h1>{{tagline}}</h1>
      <h2>{{ medicalTagline }}</h2>
    </div>
    <div class="col-auto ui-toolbar">
      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="printFrame()">
        <mat-icon>print</mat-icon>
      </button>
      <button class="btn btn-sm btn-secondary" routerLink="/settings">
        <mat-icon>settings</mat-icon>
      </button>
      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="minimizeFrame()">
        <mat-icon>minimize</mat-icon>
      </button>
      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="maximizeFrame()">
        <mat-icon *ngIf="uiService.isMaximized">close_fullscreen</mat-icon>
        <mat-icon *ngIf="!uiService.isMaximized">open_in_full</mat-icon>
      </button>
      <button class="btn btn-sm btn-primary ml-2" *ngIf="isInFrame" (click)="closeFrame()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row ehr-nav-tabs">
    <div class="col-12 px-0">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="toggle($event)" [animation]="false">
        <li [ngbNavItem]="idx" *ngFor="let tab of navTabs; index as idx">
          <a ngbNavLink
             (click)="toggle(idx)"
             [ngClass]="{'active-tab': idx === active, 'inactive-tab': idx !== active}">
            {{ tab.title }}
          </a>
          <ng-template ngbNavContent>
            <ehrTab-item *ngIf="navOpen" [config]="tab"></ehrTab-item>
          </ng-template>
        </li>
        <div class="col ep-collapse-tabs"
             [ngClass]="{'opened': navOpen, 'closed': !navOpen}"
             (click)="toggle()">
          <img src="/assets/images/icons/ep-arrow-icon-blue.svg" alt="">
        </div>
      </ul>
      <div class="col-12 px-0">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>
