<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">
    <!--Cough-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Cough</label>
      </div>
      <div class="col" formGroupName="cough">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="text" class="form-control" formControlName="type" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Type</option>
              <option *ngFor="let type of coughType" [value]="type">{{ type }}</option>
            </select>
            <select type="text" class="form-control" placeholder="Sputum" formControlName="sputum"
                    jargonLoadingState="builder-control">
              <option hidden disabled [ngValue]="null">Sputum</option>
              <option *ngFor="let s of sputum" [value]="s">{{ s }}</option>
            </select>
            <div class="input-group w-auto">
              <span class="input-group-text">+/-</span>
              <input type="number" class="form-control" placeholder="ml of Sputum" formControlName="volume_daily_ml"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">ml/day</span>
            </div>
            <div class="input-group w-auto">
              <input type="number" class="form-control" placeholder="How long (days)" formControlName="duration_days"
                     jargonLoadingState="builder-control">
              <span class="input-group-text">{{ durationUnit }}</span>
            </div>
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Sore Throat-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Sore Throat</label>
      </div>
      <div class="col" formGroupName="sore_throat">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--PND-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">PND</label>
      </div>
      <div class="col" formGroupName="pnd">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Runny Nose-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Runny Nose</label>
      </div>
      <div class="col" formGroupName="running_nose">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Congestion-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Congestion</label>
      </div>
      <div class="col" formGroupName="congestion">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

    <!--Dyspnoea-->
    <div class="row mb-3">
      <div class="col-2">
        <label class="form-label">Dyspnoea</label>
      </div>
      <div class="col" formGroupName="dyspnoea">
        <app-status-comment formControlName="present">
          <ng-template appTemplateRef="positive" let-data="data">
            <select type="text" class="form-control" placeholder="MMRC Scale" formControlName="duration"
                    jargonLoadingState="builder-control">
              <option hidden disabled [ngValue]="null">MMRC Scale</option>
              <option *ngFor="let m of mmrc" [value]="m">{{ m }}</option>
            </select>
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
          <ng-template appTemplateRef="negative" let-data="data">
            <input type="text" class="form-control" placeholder="Notes" formControlName="note"
                   jargonLoadingState="builder-control">
          </ng-template>
        </app-status-comment>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngFor="let item of data; let i = index">
    <strong>{{item.title}} </strong>
    <span>[{{ item.value ? 'Present' : 'Not Present' }}{{item.note}}]</span>
  </p>
</ng-template>