import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview/preview.component';
import { WidgetRoutingModule } from './widget-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    PreviewComponent
  ],
  imports: [
    WidgetRoutingModule,
    CommonModule,
    SharedModule
  ]
})
export class WidgetModule { }
