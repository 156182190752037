import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { BuilderData } from 'src/app/shared/models/clincal-note.model';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { validateAllFormFields } from 'src/app/shared/validators';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';

type EncounterType = 'initial' | 'follow-up';

interface InitialWCMFormData {
  type: string;
  cause: string;
  description: string;
  treatment: string;
  action: string;
  home_care: string;
}
interface FollowUpWCMFormData {
  treatment: string;
  action: string;
  home_care: string;
}

interface WCMFormData extends BuilderDataAbstract {
  encounter_type: EncounterType;
  data: InitialWCMFormData | FollowUpWCMFormData;
}

@Component({
  selector: 'app-wound-care-management',
  templateUrl: './wound-care-management.component.html',
  styleUrls: ['./wound-care-management.component.scss']
})
export class WoundCareManagementComponent extends AbstractBuilderComponent {

  @ViewChild('preview') preview!: TemplateRef<unknown>;
  public woundTypeList: string[] = [
    'Infected',
    'Laceration',
    'Pressure Ulcer',
    'Skin Tear',
    'Abrasion',
    'Other',
  ].sort((a, b) => a.localeCompare(b));

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      encounter_type: this.fb.control<EncounterType | null>(null, { validators: [Validators.required] }),
      data: this.fb.group({})
    });
  }

  public setEncounterType(type: EncounterType | null) {
    if (this.formData.get('encounter_type')?.value === type) {
      // do nothing if already set to value
      return;
    }
    switch (type) {
      case 'initial':
        this.formData.setControl('data', this.fb.group({
          type: [null, [Validators.required]],
          cause: [null, [Validators.required]],
          description: [null, [Validators.required]],
          treatment: [null, [Validators.required]],
          action: [null, [Validators.required]],
          home_care: [null],
        }));
        break;
      case 'follow-up':
        this.formData.setControl('data', this.fb.group({
          treatment: [null, [Validators.required]],
          action: [null, [Validators.required]],
          home_care: [null],
        }));
        break;
      case null:
      default:
        this.formData.setControl('data', this.fb.group({}));
    }
    this.formData.patchValue({ encounter_type: type });
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: WCMFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    return [{
      type: 'builder',
      builderTitle: 'WCM: ' + {
        'initial': 'Initial Wound Encounter',
        'follow-up': 'Follow-Up Encounter',
      }[data.encounter_type],
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, data]
    }];
  }

  asString(data: WCMFormData): string {
    if (!data) return '';

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: WCM: ${{ 'initial': 'Initial Wound Encounter', 'follow-up': 'Follow-Up Encounter' }[data.encounter_type]}`,
      ...(data.encounter_type === 'initial' ? [
        (data.data as InitialWCMFormData).type ? `Type of Wound: ${(data.data as InitialWCMFormData).type}` : null,
        (data.data as InitialWCMFormData).cause ? `Cause/History of Wound: ${(data.data as InitialWCMFormData).cause}` : null,
        (data.data as InitialWCMFormData).description ? `Initial Description of Wound: ${(data.data as InitialWCMFormData).description}` : null,
      ] : []),
      data.data.treatment ? `Assessment for Treatment Plan: ${data.data.treatment}` : null,
      data.data.action ? `Action Taken: ${data.data.action}` : null,
      data.data.treatment ? `Home Care Plan: ${data.data.treatment}` : null,
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as WCMFormData;
    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    }
    return builderData;
  }

  protected override setBuilderData(data: BuilderData): void {
    this.setEncounterType(data['encounter_type']);
    super.setBuilderData(data);
  }
}
