<div class="container-fluid">
  <div class="row header-container">
    <div class="col tagline">
      <h1>
        <a [routerLink]="back" class="back-link">
          <mat-icon>arrow_back</mat-icon>
        </a>
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{title}}
      </h1>
    </div>
    <div class="col-auto ui-toolbar">
      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="minimizeFrame()">
        <mat-icon>minimize</mat-icon>
      </button>
      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="isInFrame" (click)="maximizeFrame()">
        <mat-icon *ngIf="uiService.isMaximized">close_fullscreen</mat-icon>
        <mat-icon *ngIf="!uiService.isMaximized">open_in_full</mat-icon>
      </button>
      <button class="btn btn-sm btn-primary ml-2" *ngIf="isInFrame" (click)="closeFrame()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row settings-body">
    <router-outlet></router-outlet>
  </div>
</div>