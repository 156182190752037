import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { Sites } from './git-history.config';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

interface GITHistoryFormData extends BuilderDataAbstract {
  abdominal_pain: {
    present: boolean;
    regions: Array<string>;
    note: string;
    _modified: number;
  },
  diarrhoea: {
    present: boolean;
    note: string;
    _modified: number;
  },
  constipation: {
    present: boolean;
    note: string;
    _modified: number;
  },
  nausea: {
    present: boolean;
    note: string;
    _modified: number;
  },
  vomiting: {
    present: boolean;
    note: string;
    _modified: number;
  }
}

@Component({
  selector: 'app-git-history',
  templateUrl: './git-history.component.html',
  styleUrls: ['../../shared/components/abstract-builder/abstract-builder.component.scss', './git-history.component.scss']
})
export class GitHistoryComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public sites: Array<{ name: string }> = Sites();

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      abdominal_pain: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)],
        regions: [],
      }),
      diarrhoea: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      constipation: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      nausea: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      vomiting: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      })
    });

    if (!this.isReadOnly) {
      this.registerFormListener();
    }
  }

  public getRow(form: keyof GITHistoryFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public clearInputs(form: keyof GITHistoryFormData) {
    this.getRow(form).reset();
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: GITHistoryFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'GIT HISTORY:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: GITHistoryFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);

    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: GIT HISTORY`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.value ? 'Present' : 'Not Present'}${v.note}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as GITHistoryFormData;
    data.abdominal_pain['_modified'] = moment().valueOf();
    data.diarrhoea['_modified'] = moment().valueOf();
    data.constipation['_modified'] = moment().valueOf();
    data.nausea['_modified'] = moment().valueOf();
    data.vomiting['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['abdominal_pain'] = data.abdominal_pain;
      builderData['diarrhoea'] = data.diarrhoea;
      builderData['constipation'] = data.constipation;
      builderData['nausea'] = data.nausea;
      builderData['vomiting'] = data.vomiting;
    }
    return builderData;
  }

  override registerFormListener() {
    if (this.controlSub) this.controlSub.unsubscribe();
    this.controlSub = merge(
      ...Object.values(this.formData.controls)
        .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
    ).subscribe({
      next: control => {
        switch (control.get('present')?.value) {
          case true:
          case false:
            control.get('note')?.enable({ emitEvent: false });
            control.get('regions')?.enable({ emitEvent: false });
            break;
          case null:
            control.get('note')?.disable({ emitEvent: false });
            control.get('regions')?.disable({ emitEvent: false });
            control.get('note')?.patchValue('', { emitEvent: false });
            control.get('regions')?.patchValue([[]], { emitEvent: false });
            break;
        }
      }
    });
  }


  private prepareSerializedList(data: GITHistoryFormData) {
    return [
      { title: 'Abdo Pain', value: data.abdominal_pain.present, note: `${data.abdominal_pain.regions && data.abdominal_pain.regions.length ? ' - ' + this.getJoinedList(data.abdominal_pain.regions) : ''}${data.abdominal_pain.note ? '; ' + data.abdominal_pain.note : ''}` },
      { title: 'Diarrhoea', value: data.diarrhoea.present, note: `${data.diarrhoea.note ? ': ' + data.diarrhoea.note : ''}` },
      { title: 'Constipation', value: data.constipation.present, note: `${data.constipation.note ? ': ' + data.constipation.note : ''}` },
      { title: 'Nausea', value: data.nausea.present, note: `${data.nausea.note ? ': ' + data.nausea.note : ''}` },
      { title: 'Vomiting', value: data.vomiting.present, note: `${data.vomiting.note ? ': ' + data.vomiting.note : ''}` },
    ].filter(v => v.value !== null);
  }
}
