import { Component } from '@angular/core';
import { AbstractSectionComponent } from '../../shared/components/abstract-section/abstract-section.component';
import { ActiveUserService } from '../../../../shared/services/common/active-user.service';
import { ClinicalNoteService } from '../../../../shared/services/common/clinical-note.service';
import { ReviewService } from '../../../../shared/services/common/review.service';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentsModalComponent, ATTACHMENT_TYPES } from '../../../../shared/components/attachments-modal/attachments-modal.component';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { AttachmentModel } from 'src/app/shared/models/attachment.model';
import { firstValueFrom, switchMap } from 'rxjs';
import { UI_SECTIONS } from 'src/app/modules/ui/config/sections';
import { AttachmentsService } from 'src/app/shared/services/common/attachments.service';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { DialogService } from 'src/app/shared/services/common/dialog.service';

interface GroupedFilesData {
  [key: string]: {
    title?: string;
    files: AttachmentModel[];
  }
}

@Component({
  selector: 'app-attachments-section',
  templateUrl: './attachments-section.component.html',
  styleUrls: ['../../shared/components/abstract-section/abstract-section.component.scss', './attachments-section.component.scss']
})
export class AttachmentsSectionComponent extends AbstractSectionComponent {
  public disabled: boolean = false;

  constructor(
    private modalService: NgbModal,
    private attachmentService: AttachmentsService
  ) {
    super();
  }

  get files(): undefined | GroupedFilesData {
    return this.data.files?.reduce((o, f) => {
      if (!o[f.type]) o[f.type] = { title: Object.values(ATTACHMENT_TYPES).find(v => v.alias === f.type)?.title, files: [] };
      o[f.type].files.push(f);
      return o;
    }, {} as GroupedFilesData);
  }

  public openFilePreview(file: AttachmentModel) {
    const modalRef = this.modalService.open(AttachmentPreviewComponent, { size: '90' });
    modalRef.componentInstance.file = file;
  }

  public downloadFile(file: AttachmentModel) {
    firstValueFrom(this.attachmentService.download(file._id));
  }

  public deleteFile(file: AttachmentModel) {
    if (confirm("Are you sure you wish to delete the file: \"" + file.name + "\" ?\n NOTE: This action Cannot be undone!\n\nPress OK to confirm this action.") === true) {
      firstValueFrom(this.attachmentService.delete(file._id));
    }
  }

  public override async validate(): Promise<boolean> {
    return true;
  }

  public override async save(): Promise<boolean | null> {
    // nothing additional to save, attachemnt files are saved via attahcment endpoints directly
    return super.save();
  }
}
