import { Directive, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingStateService } from '../services/common/loading-state.service';

@Directive({
  selector: '[jargonLoadingState]'
})
export class LoadingStateDirective {

  /**
   * @private
   * @type {(string | string[])}
   * @memberof LoadingStateDirective
   */
  @Input('jargonLoadingState') public aliasList!: string | string[];

  /**
   * @private
   * @type {(string | null)}
   * @memberof LoadingStateDirective
   */
  @Input('jargonLoadingStateChild') private loadingStateChild: string | null = null;

  /**
   * @private
   * @type {Subscription}
   * @memberof LoadingStateDirective
   */
  private stateSub!: Subscription;

  /**
   * @private
   * @type {*}
   * @memberof LoadingStateDirective
   */
  private originalDisabled: any;

  constructor(
    private element: ElementRef,
    private loading: LoadingStateService
  ) { }

  /**
   * @memberof LoadingStateDirective
   */
  ngAfterViewInit() {
    this.stateSub = this.loading.on(this.aliasList).subscribe(change => {
      change ? this.loadingOn() : this.loadingOff();
    });
  }

  /**
   * @memberof LoadingStateDirective
   */
  ngOnDestroy() {
    if (this.stateSub) {
      this.stateSub.unsubscribe();
    }
  }

  /**
   * @memberof LoadingStateDirective
   */
  loadingOn() {
    if (this.element.nativeElement && typeof this.element.nativeElement.getAttribute === 'function') {
      this.originalDisabled = this.element.nativeElement.getAttribute('disabled');
      this.element.nativeElement.setAttribute('disabled', true);
      this.element.nativeElement.classList.add('loading-state');
    }
  }

  /**
   * @memberof LoadingStateDirective
   */
  loadingOff() {
    if (this.element.nativeElement && typeof this.element.nativeElement.getAttribute === 'function') {
      if (this.originalDisabled == null) {
        this.element.nativeElement.removeAttribute('disabled');
      } else {
        this.element.nativeElement.setAttribute('disabled', this.originalDisabled);
      }
      this.element.nativeElement.classList.remove('loading-state');
    }
  }

  handleChildElement(state: boolean) {
    if (!this.loadingStateChild) {
      return;
    }

    const elem = this.element.nativeElement ? this.element.nativeElement.querySelector(this.loadingStateChild) : null;

    if (!elem) {
      return;
    }

    if (state) {
      elem.setAttribute('disabled', true);
      elem.classList.add('loading-state');
    } else {
      elem.removeAttribute('disabled');
      elem.classList.remove('loading-state');
    }
  }

}
