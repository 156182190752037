import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AbstractEHRTabComponent } from '../../shared/components/abstract-ehr-tab/abstract-ehr-tab.component';
import { Pagination } from '../../../../shared/types/paginatedResponse';
import { TableConfig } from '../../../../shared/components/jargon-table/jargon-table.component';
import { ClientInfoService, InfoDataOptions, VisitHistory } from 'src/app/shared/services/common/client-info.service';
import { catchError, debounceTime, distinctUntilChanged, finalize, firstValueFrom, map, of, Subject, Subscription, tap, throwError } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent extends AbstractEHRTabComponent implements OnDestroy, AfterViewInit {
  public errored: boolean = false;
  public loading: boolean = false;
  public pagination?: Pagination;
  public tableConfig: TableConfig<VisitHistory> = {
    columns: [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Practitioner',
        field: 'practitioner'
      },
      {
        title: 'Diagnosis',
        field: 'diagnosis'
      },
      {
        title: 'Action',
        column_slot: 'action'
      }
    ],
    data: []
  };
  public options: InfoDataOptions = {
    page: 1,
    limit: 5
  };
  public unsubscribe: Subscription[] = [];
  public visitsSearch: FormControl = new FormControl<any>('');

  constructor(private clientInfoService: ClientInfoService) {
    super();
  }

  public ngAfterViewInit(): void {
    this.loadData();
    this.unsubscribe.push(this.visitsSearch.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(v => {
        if (v && v !== '') {
          this.options.search = v as string;
        } else {
          delete this.options.search;
        }
        this.loadData();
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(s => s.unsubscribe());
  }

  public pageChange(page: number) {
    this.options.page = page;
    this.loadData();
  }

  public viewVisit(visit: VisitHistory) {
    this.clientInfoService.viewVisit(visit);
  }

  private loadData() {
    this.loading = true;
    this.tableConfig.data = [];
    this.pagination = undefined;
    firstValueFrom(this.clientInfoService.loadVisits(this.options).pipe(
      finalize(() => this.loading = false),
      catchError(err => {
        console.error(err);
        this.errored = true;
        return of(null);
      }),
      tap(res => {
        this.errored = !!res;
        if (res) {
          this.tableConfig.data = res.data;
          this.pagination = res.pagination;
        }
      })
    ));
  }
}
