import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActiveUserService } from 'src/app/shared/services/common/active-user.service';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { ViewMode } from 'src/app/shared/types/util';
import { TemplateRefDirective } from '../../../../../shared/directives/template-ref.directive';

export type StatusComment = {
  present: boolean;
  note?: string;
  _modified?: number;
}

@Component({
  selector: 'app-status-comment',
  templateUrl: './status-comment.component.html',
  styleUrls: ['./status-comment.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: StatusCommentComponent,
    multi: true
  }]
})
export class StatusCommentComponent implements OnInit, ControlValueAccessor {

  @ContentChildren(TemplateRefDirective) templateList?: QueryList<TemplateRefDirective>;
  @Input() public multi: boolean = false;
  @Input() public count: number = 0;
  @Input() public buttonStyle: {
    [key: string]: string
  } = {};

  @Output() public remove: EventEmitter<number> = new EventEmitter<number>();
  public viewMode: 'readonly' | 'write';

  public get templates(): { [key: string]: TemplateRef<unknown> } {
    return this.templateList ? this.templateList.reduce((out, tmpl) => ({
      ...out,
      [tmpl.name]: tmpl.templateRef
    }), {}) : {};
  }

  public get hasStatusComment(): boolean {
    return this.present !== null;
  }

  public present?: boolean | null;
  public disabled: boolean = false;

  private onChange?: (value: boolean | null) => void;
  private onTouched?: VoidFunction;


  constructor(
    activeUserService: ActiveUserService,
    clinicalNoteService: ClinicalNoteService
  ) {
    this.viewMode = activeUserService.viewMode === 'review' || clinicalNoteService.isReadOnly
      ? 'readonly'
      : 'write';
  }

  ngOnInit(): void {
  }

  public toggleStatus(status: boolean) {
    if (this.disabled) {
      return;
    }

    this.present = status;
    this.onChange!(this.present);
    this.onTouched!();
  }

  public clearInput(idx?: number) {
    if (idx !== undefined) {
      this.remove.emit(idx);
      if (this.count > 1) {
        return;
      }
    }
    this.present = null;
    this.onChange!(this.present);
    this.onTouched!();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(status: boolean | null): void {
    this.present = status;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
