import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReviewService, ReviewSubjectModel } from '../../../shared/services/common/review.service';
import { FormControl, Validators } from '@angular/forms';
import { ActiveUserService } from '../../../shared/services/common/active-user.service';
import { filter, finalize, firstValueFrom, Subscription, tap } from 'rxjs';
import { ReviewCommentDataModel, ReviewCommentModel } from '../../../shared/models/clincal-note.model';
import { ClinicalNoteService } from 'src/app/shared/services/common/clinical-note.service';
import { LoadingStateService } from 'src/app/shared/services/common/loading-state.service';
import { UIComponentAlias, UiService, UIToggleEvent } from 'src/app/shared/services/common/ui.service';

@Component({
  selector: 'ui-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  @ViewChild('commentListRef') public commentListRef!: ElementRef<any>;
  public sidebarOpen: boolean = false;
  public subjectData?: ReviewSubjectModel;
  public reviewer?: string;
  public addNew: boolean = false;
  public commentToEdit?: ReviewCommentModel;
  public contentEdit: FormControl = new FormControl<string>('', [
    Validators.required, Validators.minLength(2), Validators.maxLength(500)
  ]);
  public contentCreate: FormControl = new FormControl<string>('', [
    Validators.required, Validators.minLength(2), Validators.maxLength(500)
  ]);

  private subscriptions: Subscription[] = [];

  constructor(
    private activeUserService: ActiveUserService,
    private clinicalNoteService: ClinicalNoteService,
    private reviewService: ReviewService,
    private loading: LoadingStateService,
    private uiService: UiService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.uiService.events.pipe(
      filter(({ component }: UIToggleEvent) => component === UIComponentAlias.SIDEBAR_REVIEWS),
      tap(({ state }: UIToggleEvent) => {
        this.sidebarOpen = state;
        if (state) {
          this.uiService.close(UIComponentAlias.SIDEBAR_BUILDERS);
        }
      })
    ).subscribe());
    this.reviewer = this.activeUserService.user!.name;
    this.subscriptions.push(this.reviewService.reviewSubject.pipe(tap(res => {
      if (res.action === 'open') {
        this.subjectData = res;
      }
      if (res.action === 'data') {
        this.subjectData = res;
      }
      if (res.action === 'add') {
        this.addNew = true;
        setTimeout(() => this.commentListRef.nativeElement.scrollTop = this.commentListRef.nativeElement.scrollHeight, 0);
      }
    })).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public acknowledge(comment: ReviewCommentModel) {
    const idx = this.subjectData?.comments?.findIndex(({ _id }) => _id === comment._id);
    if (idx !== undefined && idx !== -1) {
      this.subjectData?.comments?.splice(idx, 1);
    }
  }

  public saveComment(type: 'create' | 'edit') {
    let commentData: ReviewCommentDataModel = {
      author: {
        id: this.activeUserService.user!.id,
        name: this.activeUserService.user!.name,
        role: this.activeUserService.user!.role
      },
      content: this.contentCreate.value
    }

    if (type === 'create') {
      if (!this.contentCreate.valid) {
        return;
      }
    }
    if (type === 'edit') {
      if (!this.contentEdit.valid) {
        return;
      }
      commentData = {
        ...this.commentToEdit as ReviewCommentDataModel,
        content: this.contentEdit.value
      };
    }

    this.loading.start('comment-' + type);
    firstValueFrom(this.clinicalNoteService.saveComment(this.subjectData!.config!.alias, commentData).pipe(
      finalize(() => {
        this.cancelCommenting(type);
        this.loading.end('comment-' + type);
      })
    ));
  }

  public edit(comment: ReviewCommentModel) {
    this.commentToEdit = comment;
    this.contentEdit.patchValue(comment.content);
  }

  public delete(id: string) {
    this.loading.start('comment-edit');
    firstValueFrom(this.clinicalNoteService.deleteComment(this.subjectData!.config!.alias, id).pipe(
      finalize(() => this.loading.end('comment-edit'))
    ));
  }

  public cancelCommenting(type: 'create' | 'edit') {
    if (type === 'edit') {
      this.contentEdit.patchValue('');
      this.contentEdit.setErrors(null);
      this.commentToEdit = undefined;
    } else {
      this.contentCreate.patchValue('');
      this.contentCreate.setErrors(null);
      this.addNew = false;
    }
  }
}
