import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentHostDirective } from 'src/app/shared/directives/component-host.directive';
import { AbstractEHRTabComponent } from '../abstract-ehr-tab/abstract-ehr-tab.component';
import { EHRTabConfigModel } from 'src/app/shared/models/ehr-tab-config.model';

@Component({
  selector: 'ehrTab-item',
  templateUrl: './ehr-tab-item.component.html',
  styleUrls: ['./ehr-tab-item.component.scss']
})
export class EHRTabItemComponent implements OnInit {

  @Input() public config!: EHRTabConfigModel;
  @ViewChild(ComponentHostDirective, { static: true }) tabHost!: ComponentHostDirective;

  constructor() { }

  ngOnInit(): void {
    this.tabHost.viewContainerRef.clear();
    const componentRef = this.tabHost.viewContainerRef.createComponent<AbstractEHRTabComponent>(this.config.component);
    // componentRef.instance.data = {?};
  }

}
