<ng-select #tagSelect [items]="options$ | async"
           [multiple]="multiple"
           appendTo="#app-wrapper"
           [placeholder]="placeholder"
           [hideSelected]="true"
           [minTermLength]="2"
           [disabled]="disabled"
           [loading]="optionsLoading"
           [addTag]="true"
           addTagText="Use - "
           typeToSearchText="Please enter 2 or more characters"
           [typeahead]="optionsInput$"
           (keyup.enter)="onKeyUp($event, tagSelect)"
           (blur)="onBlur(tagSelect)"
           [(ngModel)]="selectedOptions">
</ng-select>