import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRedirectGuard } from 'src/app/shared/guards/auth-redirect.guard';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  { path: 'main', component: MainComponent, canActivate: [AuthRedirectGuard] },
  { path: 'main/:role', component: MainComponent, canActivate: [AuthRedirectGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UIRoutingModule { }
