
export const CVSRegions = (): Array<{ id: number, name: string }> => ([
  {
    id: 1,
    name: 'Suprasternal'
  },
  {
    id: 2,
    name: 'Upper sternal'
  },
  {
    id: 3,
    name: 'Lower sternal'
  },
  {
    id: 4,
    name: 'Left (Supra-clavicular)'
  },
  {
    id: 5,
    name: 'Left (Infraclavicular)'
  },
  {
    id: 6,
    name: 'Left (Mammary)'
  },
  {
    id: 7,
    name: 'Left (Inframammory)'
  },
  {
    id: 8,
    name: 'Right (Supra-clavicular)'
  },
  {
    id: 9,
    name: 'Right (Infraclavicular)'
  },
  {
    id: 10,
    name: 'Right (Mammary)'
  },
  {
    id: 11,
    name: 'Right (Inframammory)'
  },
].sort((a, b) => a.name.localeCompare(b.name)));
