<div class="container-fluid py-4" [formGroup]="formData">

  <!-- Relatives With Illnesses -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Relatives with Illnesses</label>
      </div>
    </div>
    <ng-container formArrayName="relatives">
      <div class="row g-2" *ngFor="let row of getRow('relatives').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <select type="text" class="form-control" formControlName="relation" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Relation</option>
            <option *ngFor="let relation of relations" [value]="relation">{{ relation }}</option>
          </select>
        </div>
        <div class="col-3">
          <preferences-typeahead
                                 alias="family_history-illness"
                                 formControlName="illness"
                                 placeholder="Illness"
                                 jargonLoadingState="builder-control"></preferences-typeahead>
        </div>
        <div class="col-7">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Description" formControlName="description"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button"
                      (click)="removeRow('relatives', i)" jargonLoadingState="builder-control">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('relatives')">
            Add +
          </button>
        </div>
      </div>
    </ng-container>
  </div>


  <!-- Infectious Diseases -->
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Infectious Diseases</label>
      </div>
    </div>
    <ng-container formArrayName="diseases">
      <div class="row g-2" *ngFor="let row of getRow('diseases').controls; let i = index"
           [formGroupName]="i">
        <div class="col-2">
          <select type="text" class="form-control" placeholder="Relation" formControlName="relation"
                  jargonLoadingState="builder-control" jargonLoadingState="builder-control">
            <option hidden disabled [value]="null">Relation</option>
            <option *ngFor="let relation of relations" [value]="relation">{{ relation }}</option>
          </select>
        </div>
        <div class="col-3">
          <preferences-typeahead
                                 alias="family_history-illness"
                                 formControlName="illness"
                                 placeholder="Illness"
                                 jargonLoadingState="builder-control"></preferences-typeahead>
        </div>
        <div class="col-7">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Description" formControlName="description"
                   jargonLoadingState="builder-control">
            <ng-container *jargonViewMode="'write'">
              <button class="btn btn-outline-secondary btn-sm" type="button"
                      (click)="removeRow('diseases', i)" jargonLoadingState="builder-control">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *jargonViewMode="'write'">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-primary" jargonLoadingState="builder-control" (click)="addRow('diseases')">Add
            +
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="data.relatives && data.relatives.length">
    <strong>Relatives with Illnesses</strong>
    [<span *ngFor="let c of data.relatives; let i = index">
      ({{i + 1}}): {{c.relation}} - {{c.illness}}, {{c.description}}{{ i === data.relatives.length - 1 ? '' : ';'}}
    </span>]
  </p>
  <p *ngIf="data.diseases && data.diseases.length">
    <strong>Infectious Diseases</strong>
    [<span *ngFor="let c of data.diseases; let i = index">
      ({{i + 1}}): {{c.relation}} - {{c.illness}}, {{c.description}}{{ i === data.diseases.length - 1 ? '' : ';'}}
    </span>]
  </p>
</ng-template>