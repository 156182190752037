<div class="container-fluid py-4" [formGroup]="formData">

  <div class="col-12 mb-4">

    <!-- Files -->
    <ng-container *jargonViewMode="'write'">
      <ng-container *ngIf="!files.length; else fileList">
        <div class="col-12 mb-3">
          <jargon-dropzone [modal]="true" [modalOptions]="options" (onModalSave)="attachmentsAdded($event)"
                           label="Upload ECG Result">
          </jargon-dropzone>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #fileList>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 attachment-wrapper"
             *ngFor="let file of files">
          <span class="attachment-container" [title]="file.name">
            <mat-icon [inline]="true">file_present</mat-icon>
            <label>{{file.name}}</label>
            <ng-container *jargonViewMode="'write'">
              <mat-icon [inline]="true" class="delete" [title]="'Delete '+file.name" (click)="deleteFile(file)">delete
              </mat-icon>
            </ng-container>
          </span>
        </div>
      </div>
    </ng-template>

    <!--Notes-->
    <div class="row mb-3">
      <div class="col-12">
        <textarea type="text" class="form-control" placeholder="Notes/Comments on ECG Result"
                  jargonLoadingState="builder-control" formControlName="note"></textarea>
      </div>
    </div>

  </div>

  <ng-container *jargonViewMode="'write'">
    <div class="col-auto mb-4 d-flex justify-content-end">
      <button class="btn btn-fill btn-outline-primary ms-3" jargonLoadingState="builder-control"
              (click)="deleteBuilder()">Remove
      </button>
      <button class="btn btn-fill btn-primary ms-3" jargonLoadingState="builder-control" (click)="saveData()">Save
      </button>
    </div>
  </ng-container>
</div>

<ng-template #preview let-data="data">
  <p *ngIf="data.fileNames">
    <strong>File </strong>
    <span>[{{data.fileNames}}]{{data.note ? ';' : ''}}</span>
  </p>
  <p *ngIf="data.note">
    <strong>Notes/Comments </strong>
    <span>[{{data.note}}]</span>
  </p>
</ng-template>